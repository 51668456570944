import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm, Upload } from 'antd';
import store from "../store/store.js";
import { PlusOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import moment from 'moment';
import Chxz from '../public/chxz.js';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                pjflist: [],
                wxflist: [],
                wxdfj: [],
            },
            list: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value, Option) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        // return
        setTimeout(function () {
            if (name == 'chid') {
                let data2 = Object.assign({}, that.state.condition, {
                    cp: Option.key ? that.state.chid_react[Option.key]['cp'] : null,
                    sblx: Option.key ? that.state.chid_react[Option.key]['sblx'] : null,
                    sj1: Option.key ? that.state.chid_react[Option.key]['sj1'] : null,
                    sj2: Option.key ? that.state.chid_react[Option.key]['sj2'] : null,
                })
                that.setState({
                    condition: data2
                })
            } else if (name == "sfbljs") {
                if (value == 1) {

                } else if (value == 2) {
                    let data2 = Object.assign({}, that.state.condition, {
                        jsrq: null,
                    })
                    that.setState({
                        condition: data2
                    })
                }
            }
        })
    }
    getSgbw = (id) => {
        let that = this
        fetch('/Common/getSgbw', {
            method: "POST",
            body: JSON.stringify({
                id: id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.setState({
                    sgbw_react: data.sgbw_react ? data.sgbw_react : [],
                })
            })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        setTimeout(function () {
            if (name == 'gs' || name == 'dj') {
                let gs = data.gs ? data.gs : 0
                let dj = data.dj ? data.dj : 0
                var je = (Number(gs) * Number(dj)).toFixed(2)
                let data2 = Object.assign({}, that.state.condition, { je: je })
                that.setState({
                    condition: data2
                })
            }
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            fetch('/Clwxmx/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg)
                        return
                    }
                    if (this.state.condition.id) {
                        that.setState({
                            condition: data.data,
                        })
                    }
                    that.setState({
                        chid_react: data.chid_react ? data.chid_react : [],
                        sj_react: data.sj_react ? data.sj_react : [],
                        guanli_react: data.guanli_react ? data.guanli_react : [],
                        wxdw_react: data.wxdw_react ? data.wxdw_react : [],
                        wxnr_react: data.wxnr_react ? data.wxnr_react : [],
                        ljmc_react: data.ljmc_react ? data.ljmc_react : [],
                        sfbljs_react: data.sfbljs_react ? data.sfbljs_react : [],
                    })
                })
            if (that.props.notable != 1) {
                that.getdata2()
            }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                    pjflist: [],
                    wxflist: [],
                    wxdfj: [],
                },
                loading: false
            })
        }
    }
    sub(isnext = 0, e) {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Clwxmx/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    if (isnext == 1) {
                        that.nextAdd()
                    } else {
                        this.mdchange()
                    }
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    getdata2(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        fetch('/Clwxmx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify({
                limit: 3
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count)
                })
            })
    }
    //继续添加
    nextAdd = (e) => {
        let that = this
        that.setState({
            condition: {
                id: 0,
            },
            list: [],
            loading: false
        })
        that.getdata2()
    }
    addItem_wxdw = () => {
        let that = this
        fetch('/Wxdw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, wxdw: data.wxdw ? data.wxdw : null })
                    this.setState({
                        condition: condition,
                        wxdw_react: data.wxdw_react ? data.wxdw_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    // addItem_wxnr = (index, e) => {
    //     let that = this
    //     fetch('/Wxnr/additem', {
    //         method: "POST",
    //         body: JSON.stringify(that.state.condition)
    //     })
    //         .then(res => res.json())
    //         .then(data => {
    //             if (data.status == 1) {
    //                 let wxflist = that.state.condition.wxflist ? that.state.condition.wxflist.concat() : []
    //                 wxflist[index]['wxnrid'] = data.wxnr ? data.wxnr : null
    //                 let condition = Object.assign({}, this.state.condition, { newfield: null, wxflist: wxflist })
    //                 this.setState({
    //                     condition: condition,
    //                     wxnr_react: data.wxnr_react ? data.wxnr_react : []
    //                 })
    //             } else {
    //                 message.error(data.msg)
    //             }
    //         })
    // }
    // addItem_ljmc = (index, e) => {
    //     let that = this
    //     fetch('/Ljmc/additem', {
    //         method: "POST",
    //         body: JSON.stringify(that.state.condition)
    //     })
    //         .then(res => res.json())
    //         .then(data => {
    //             if (data.status == 1) {
    //                 let pjflist = that.state.condition.pjflist ? that.state.condition.pjflist.concat() : []
    //                 pjflist[index]['ljmcid'] = data.ljmc ? data.ljmc : null
    //                 let condition = Object.assign({}, this.state.condition, { newfield: null, pjflist: pjflist })
    //                 this.setState({
    //                     condition: condition,
    //                     ljmc_react: data.ljmc_react ? data.ljmc_react : []
    //                 })
    //             } else {
    //                 message.error(data.msg)
    //             }
    //         })
    // }
    clearNewField = (open) => {
        let that = this
        setTimeout(() => {
            let condition = Object.assign({}, that.state.condition, { newfield: null })
            that.setState({
                condition: condition,
            })
        });
    }
    delwxf = (index, e) => {
        let that = this
        let wxflist = that.state.condition.wxflist.concat()
        wxflist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { wxflist: wxflist })
        this.setState({
            condition: data
        })
        setTimeout(() => {
            that.zje()
        });
    }
    addwxf = () => {
        let that = this
        let wxflist = that.state.condition.wxflist.concat()
        let arr = { id: '', wxnr: null, gsf: null }
        wxflist.push(arr)
        let data = Object.assign({}, this.state.condition, { wxflist: wxflist })
        this.setState({
            condition: data
        })
    }
    arrWxf(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <>
                    <Col span={19}>
                        <Form.Item
                            label={<Space>
                                <span>维修内容</span>
                                <span className='ljinfo'>最近维修时间：{item.lasttime}</span>
                                <span className='ljinfo'>目标方量：{item.zfl}方 /
                                    <span className={`c` + ((item.sm && item.zfl < item.sm) || (item.sm2 && item.zfl > item.sm2) ? 2 : 1)}>
                                        <Space split="~"><span>{item.sm}</span>{item.sm2 ? <span>{item.sm2}</span> : null}</Space>方
                                    </span>
                                    {(item.sm && item.zfl < item.sm) ? <span className='hongse'>（维修异常）</span> : null}
                                    {(item.sm2 && item.zfl > item.sm2) ? <span className='hongse'>（保养异常）</span> : null}
                                </span>
                                <span className='ljinfo'>目标时限：{item.during}天/
                                    <span className={`c` + ((item.sx && item.during < item.sx) || (item.sx2 && item.during > item.sx2) ? 2 : 1)}>
                                        <Space split="~"><span>{item.sx}</span>{item.sx2 ? <span>{item.sx2}</span> : null}</Space>天
                                    </span>
                                    {(item.sx && item.during < item.sx) ? <span className='hongse'>（维修异常）</span> : null}
                                    {(item.sx2 && item.during > item.sx2) ? <span className='hongse'>（保养异常）</span> : null}
                                </span>
                                <DeleteOutlined className="hongse" onClick={this.delwxf.bind(this, index)} /></Space>
                            }
                            required={true}
                        >
                            <Select
                                placeholder="请选择" showSearch optionFilterProp="children"
                                value={item.wxnrid ? item.wxnrid : null}
                                onChange={this.onSelChange2.bind(this, 'wxflist', 'wxnrid', index)}
                            >
                                {this.arrsel(this.state.wxnr_react)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            label={
                                <Space>
                                    <span>工时费</span>
                                    <span className='ljinfo'>目标价格：<span className={`c` + (item.mbdj && item.gsf > item.mbdj ? 2 : 1)}>{item.mbdj}元</span> </span>
                                </Space>
                            }
                            required={true}
                        >
                            <Input placeholder="请输入" value={item.gsf ? item.gsf : null} onChange={this.onChange2.bind(this, 'wxflist', 'gsf', index)} type="text" suffix="元" />
                        </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                </>
            )
        }
    }
    delpjf = (index, e) => {
        let that = this
        let pjflist = that.state.condition.pjflist.concat()
        pjflist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { pjflist: pjflist })
        this.setState({
            condition: data
        })
        setTimeout(() => {
            that.zje2()
        });
    }
    addpjf = () => {
        let that = this
        let pjflist = that.state.condition.pjflist.concat()
        let arr = { id: '', ljmc: null, sl: null, dj: null, ljf: null }
        pjflist.push(arr)
        let data = Object.assign({}, this.state.condition, { pjflist: pjflist })
        this.setState({
            condition: data
        })
    }
    arrPjf(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <>
                    <Col span={24}>
                        <Form.Item
                            label={<Space>
                                <span>零件名称</span>
                                <span className='ljinfo'>最近维修时间：{item.lasttime}</span>
                                <span className='ljinfo'>目标方量：{item.zfl}方 /
                                    <span className={`c` + ((item.sm && item.zfl < item.sm) || (item.sm2 && item.zfl > item.sm2) ? 2 : 1)}>
                                        <Space split="~"><span>{item.sm}</span>{item.sm2 ? <span>{item.sm2}</span> : null}</Space>方
                                    </span>
                                    {(item.sm && item.zfl < item.sm) ? <span className='hongse'>（维修异常）</span> : null}
                                    {(item.sm2 && item.zfl > item.sm2) ? <span className='hongse'>（保养异常）</span> : null}
                                </span>
                                <span className='ljinfo'>目标时限：{item.during}天/
                                    <span className={`c` + ((item.sx && item.during < item.sx) || (item.sx2 && item.during > item.sx2) ? 2 : 1)}>
                                        <Space split="~"><span>{item.sx}</span>{item.sx2 ? <span>{item.sx2}</span> : null}</Space>天
                                    </span>
                                    {(item.sx && item.during < item.sx) ? <span className='hongse'>（维修异常）</span> : null}
                                    {(item.sx2 && item.during > item.sx2) ? <span className='hongse'>（保养异常）</span> : null}
                                </span>
                                <DeleteOutlined className="hongse" onClick={this.delpjf.bind(this, index)} /></Space>
                            }
                            required={true}
                        >
                            <Select
                                placeholder="请选择" showSearch optionFilterProp="children"
                                value={item.ljmcid ? item.ljmcid : null}
                                onChange={this.onSelChange2.bind(this, 'pjflist', 'ljmcid', index)}
                            >
                                {this.arrsel(this.state.ljmc_react)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="数量"
                            required={true}
                        >
                            <Input placeholder="请输入" value={item.sl ? item.sl : null} onChange={this.onChange2.bind(this, 'pjflist', 'sl', index)} type="text" step={1} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={
                                <Space>
                                    <span>单价</span>
                                    <span className='ljinfo'>目标价格：<span className={`c` + (item.mbdj && item.dj > item.mbdj ? 2 : 1)}>{item.mbdj}元</span> </span>
                                </Space>
                            }
                            required={true}
                        >
                            <Input placeholder="请输入" value={item.dj ? item.dj : null} onChange={this.onChange2.bind(this, 'pjflist', 'dj', index)} type="text" suffix="元" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="零件费"
                            required={true}
                        >
                            <Input placeholder="请输入" value={item.ljf ? item.ljf : null} onChange={this.onChange2.bind(this, 'ljflist', 'ljf', index)} type="text" suffix="元" disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={18}></Col>
                </>
            )
        }
    }
    onChange2(listname, name, index, e) {
        let that = this
        let value = e.target.value
        console.log(listname, name, index)
        let list = that.state.condition[listname].concat()
        list[index][name] = value
        if (listname == 'pjflist' && (name == 'dj' || name == "sl")) {
            list[index]['ljf'] = (Number(list[index]['dj']) * Number(list[index]['sl'])).toFixed(2)
        }
        let data = Object.assign({}, this.state.condition, { [listname]: list })
        this.setState({
            condition: data
        })
        setTimeout(() => {
            if (listname == 'wxflist') {
                that.zje()
            } else if (listname == 'pjflist') {
                that.zje2()
            }
        });
    }
    onSelChange2(listname, name, index, value, Option) {
        let that = this
        console.log(listname, name, index)
        let list = that.state.condition[listname].concat()
        list[index][name] = value
        let data = Object.assign({}, this.state.condition, { [listname]: list })
        this.setState({
            condition: data
        })
        if (name == 'ljmcid') {
            fetch('/common/jslj', {
                method: "POST",
                body: JSON.stringify({
                    lid: value,
                    chid: this.state.condition.chid,
                    riqi: this.state.condition.riqi,
                    id: this.props.id
                })
            })
                .then(res => res.json())
                .then(data2 => {
                    list[index]['zfl'] = data2.zfl
                    list[index]['mbdj'] = data2.ljmc.dj
                    list[index]['sx'] = data2.ljmc.sx
                    list[index]['sm'] = data2.ljmc.sm
                    list[index]['during'] = data2.during
                    list[index]['lasttime'] = data2.lasttime
                    data = Object.assign({}, data, { [listname]: list })
                    this.setState({
                        condition: data
                    })
                })
        } else if (name == 'wxnrid') {
            fetch('/common/jswxnr', {
                method: "POST",
                body: JSON.stringify({
                    wxnrid: value,
                    chid: this.state.condition.chid,
                    riqi: this.state.condition.riqi,
                    id: this.props.id
                })
            })
                .then(res => res.json())
                .then(data2 => {
                    list[index]['zfl'] = data2.zfl
                    list[index]['mbdj'] = data2.wxnr.dj
                    list[index]['sx'] = data2.wxnr.sx
                    list[index]['sm'] = data2.wxnr.sm
                    list[index]['during'] = data2.during
                    list[index]['lasttime'] = data2.lasttime
                    data = Object.assign({}, data, { [listname]: list })
                    this.setState({
                        condition: data
                    })
                })
        }
    }
    zje = () => {
        let that = this
        let data = that.state.condition.wxflist.concat()
        let gsf = 0
        for (const key in data) {
            const item = data[key];
            gsf = gsf + Number(item.gsf)
        }
        gsf = gsf.toFixed(2)
        let data2 = Object.assign({}, this.state.condition, { gsf: gsf })
        this.setState({
            condition: data2
        })
        // return gsf
    }
    zje2 = () => {
        let that = this
        let data = that.state.condition.pjflist.concat()
        let ljf = 0
        for (const key in data) {
            const item = data[key];
            ljf = ljf + Number(item.ljf)
        }
        ljf = ljf.toFixed(2)
        let data2 = Object.assign({}, this.state.condition, { je: ljf })
        this.setState({
            condition: data2
        })
        // return ljf
    }
    // 上传图片
    picChange(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log(info.file.response)
            if (info.file.response.status == 0) {
                message.error(info.file.response.msg)
                return
            }

            // var fjlist = this.state.condition.fjlist
            // fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: info.file.response.id, [name + '_url']: info.file.response.url, [name + '_name']: info.file.response.name })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    // 上传图片
    picChange2(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file.response)

            var fjlist = this.state.condition[name]
            if (!fjlist) {
                fjlist = []
            }
            fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: fjlist })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    delpic(index, name, e) {
        var fjlist = this.state.condition[name]
        fjlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { [name]: fjlist })
        this.setState({
            condition: data,
        })
    }
    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 80,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '日期',
                dataIndex: 'riqi_str',
                key: 'riqi_str',
                ellipsis: {
                    showTitle: false,
                },
                fixed: 'left',
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '维修单位',
                dataIndex: 'wxdw_str',
                key: 'wxdw_str',
                ellipsis: {
                    showTitle: false,
                },
                fixed: 'left',
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车辆编号',
                dataIndex: 'clbh',
                key: 'clbh',
                width: 150,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车牌',
                dataIndex: 'cp',
                key: 'cp',
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '类型',
                dataIndex: 'sblx',
                key: 'sblx',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '司机一',
                dataIndex: 'sj1_str',
                key: 'sj1_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '司机二',
                dataIndex: 'sj2_str',
                key: 'sj2_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },


            {
                title: '配件费',
                dataIndex: 'je',
                key: 'je',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '工时费',
                dataIndex: 'gsf',
                key: 'gsf',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '外购材料费',
                dataIndex: 'wgclf',
                key: 'wgclf',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '外加工费',
                dataIndex: 'wjgf',
                key: 'wjgf',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '不可预估费',
                dataIndex: 'bkygf',
                key: 'bkygf',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '配件处理清理',
                dataIndex: 'pjclql',
                key: 'pjclql',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '赠送配件情况',
                dataIndex: 'zspjqk',
                key: 'zspjqk',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '审核人',
                dataIndex: 'shr_str',
                key: 'shr_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },

            {
                title: '备注',
                dataIndex: 'beizhu',
                key: 'beizhu',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
        ]
        const { tableloading } = this.state;
        let gsf = Number(this.state.condition.gsf ? this.state.condition.gsf : 0)
        let je = Number(this.state.condition.je ? this.state.condition.je : 0)
        let wgclf = Number(this.state.condition.wgclf ? this.state.condition.wgclf : 0)
        let wjgf = Number(this.state.condition.wjgf ? this.state.condition.wjgf : 0)
        let bkygf = Number(this.state.condition.bkygf ? this.state.condition.bkygf : 0)
        const fyhj = (gsf + je + wgclf + wjgf + bkygf).toFixed(2)
        return (
            <>
                {this.state.condition.id ?
                    <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx6 ? (store.getState().data.info.qx6 == 1 ? (this.props.disabled ? '查看' : '编辑') : '查看') : '查看'}</a>
                    :
                    <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>
                }
                <Modal maskClosable={false} title="编辑" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx6 ? (store.getState().data.info.qx6 == 1 ? (this.props.disabled ? this.props.disabled : false) : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={8}>
                                <Form.Item
                                    label="日期"
                                    required={true}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'riqi')} value={this.state.condition.riqi ? moment(this.state.condition.riqi) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="维修单位"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.wxdw ? this.state.condition.wxdw : null}
                                        onChange={this.onSelChange.bind(this, 'wxdw')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')} />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_wxdw.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.wxdw_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="车辆信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="车辆编号"
                                    required={true}
                                >
                                    <Input.Group compact>
                                        <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.chid ? this.state.condition.chid : null} onChange={this.onSelChange.bind(this, 'chid')} style={{ width: 'calc(100% - 89px)' }} disabled={true} >
                                            {this.arrsel(this.state.chid_react)}
                                        </Select>
                                        <Chxz value={this.state.condition.chid ? this.state.condition.chid : null} onChange={this.onSelChange.bind(this)} chid_react={this.state.chid_react ? this.state.chid_react : []} />
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="车牌"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.cp ? this.state.condition.cp : null} onChange={this.onChange.bind(this, 'cp')} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="类型"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.sblx ? this.state.condition.sblx : null} onChange={this.onChange.bind(this, 'sblx')} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="司机一"
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.sj1 ? this.state.condition.sj1 : null} onChange={this.onSelChange.bind(this, 'sj1')}>
                                        {this.arrsel(this.state.sj_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="司机二"
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.sj2 ? this.state.condition.sj2 : null} onChange={this.onSelChange.bind(this, 'sj2')} allowClear>
                                        {this.arrsel(this.state.sj_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="维修项目及费用" banner style={{ marginBottom: 24 }} />
                            </Col>
                            {this.arrWxf(this.state.condition.wxflist)}
                            <Col span={24}>
                                <Button type="dashed" size="small" style={{ borderColor: '#1890ff', color: '#1890ff', width: '100%', marginBottom: 24 }} onClick={this.addwxf.bind(this)} >增加</Button>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                // label="总金额"
                                >
                                    <Row justify="center">总金额：<a style={{ cursor: "default" }}>{this.state.condition.gsf ? this.state.condition.gsf : 0}元</a></Row>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="配件费" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="配件处理清理"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.pjclql ? this.state.condition.pjclql : null} onChange={this.onChange.bind(this, 'pjclql')} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="赠送配件情况"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.zspjqk ? this.state.condition.zspjqk : null} onChange={this.onChange.bind(this, 'zspjqk')} />
                                </Form.Item>
                            </Col>
                            <Col span={8}></Col>
                            {this.arrPjf(this.state.condition.pjflist)}
                            <Col span={24}>
                                <Button type="dashed" size="small" style={{ borderColor: '#1890ff', color: '#1890ff', width: '100%', marginBottom: 24 }} onClick={this.addpjf.bind(this)} >增加</Button>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                // label="总金额"
                                >
                                    <Row justify="center">总金额：<a style={{ cursor: "default" }}>{this.state.condition.je ? this.state.condition.je : 0}元</a></Row>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="维修费用核算" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="维修工时费"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.gsf ? this.state.condition.gsf : null} onChange={this.onChange.bind(this, 'gsf')} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="配件费"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.je ? this.state.condition.je : null} onChange={this.onChange.bind(this, 'je')} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="外购材料费"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.wgclf ? this.state.condition.wgclf : null} onChange={this.onChange.bind(this, 'wgclf')} type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="外加工费"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.wjgf ? this.state.condition.wjgf : null} onChange={this.onChange.bind(this, 'wjgf')} type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="不可预估费"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.bkygf ? this.state.condition.bkygf : null} onChange={this.onChange.bind(this, 'bkygf')} type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="费用合计"
                                >
                                    <Input placeholder="请输入" value={fyhj} suffix="元" disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Alert type="info" message="其他信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="是否办理结算"
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.sfbljs ? this.state.condition.sfbljs : null} allowClear={false} onChange={this.onSelChange.bind(this, 'sfbljs')} >
                                        {this.arrsel(this.state.sfbljs_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="结算日期"
                                    required={this.state.condition.sfbljs == 1 ? true : false}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'jsrq')} value={this.state.condition.jsrq ? moment(this.state.condition.jsrq) : null} disabled={this.state.condition.sfbljs != 1 ? true : false} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="审核人"
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.shr ? this.state.condition.shr : null} onChange={this.onSelChange.bind(this, 'shr')}>
                                        {this.arrsel(this.state.guanli_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="备注"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.beizhu ? this.state.condition.beizhu : null} onChange={this.onChange.bind(this, 'beizhu')} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="维修单附件"
                                >
                                    <Row justify="space-between">
                                        <Space align="center">
                                            <Upload
                                                showUploadList={false}
                                                action="/Common/uploadfile"
                                                onChange={this.picChange2.bind(this, 'wxdfj')}
                                                multiple={true}
                                            >
                                                <Button icon={<UploadOutlined />}>上传</Button>
                                            </Upload>
                                            <Space wrap>
                                                {this.state.condition.wxdfj ? this.state.condition.wxdfj.map((item, index) =>
                                                    <div>
                                                        <a href={item.url} target="_blank">{item.name} </a> <DeleteOutlined className="hongse" onClick={this.delpic.bind(this, index, 'wxdfj')} />
                                                    </div>) : null}

                                            </Space>
                                        </Space>
                                    </Row>
                                </Form.Item>
                            </Col>
                            {this.props.notable == 1 ?
                                null
                                :
                                <>
                                    <Col span={24}>
                                        <Divider orientation="left" style={{ marginTop: 0 }}>最近添加信息</Divider>
                                    </Col>
                                    <Col span={24}>
                                        <Table
                                            bordered
                                            columns={columns}
                                            sticky={false}
                                            dataSource={this.state.list}
                                            pagination={false}
                                            loading={tableloading}
                                            // rowSelection={rowSelection}
                                            scroll={{ x: 2500, }}
                                            className='table'
                                            size="small"
                                        />
                                    </Col>
                                </>
                            }
                        </Row>
                        {store.getState().data.info.qx6 ? (store.getState().data.info.qx6 == 1 ?
                            <Row justify="center">
                                <Space>
                                    <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this)}>完成</Button>
                                    {!this.state.condition.id ?
                                        <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this, '1')}>完成并继续添加下一条</Button>
                                        : null}
                                </Space>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}