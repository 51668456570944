import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm, TimePicker } from 'antd';
import store from "../store/store.js";
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
            },
            list: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value, Option) {
        let that = this
        console.log(name, value, Option)

        let data = Object.assign({}, that.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }
    getdata = (arrname, e) => {
        let that = this
        fetch('/Stxstj/fkview', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                if (arrname) {
                    that.setState({
                        [arrname]: data[arrname] ? data[arrname] : [],
                    })
                    return
                }
                if (this.state.condition.id) {
                    that.setState({
                        condition: data.data,
                    })
                }
            })
    }
    sub(isnext = 0, e) {
        let that = this
        that.setState({
            loading: true
        })
        let arr = that.state.condition
            arr.type = that.props.type
            arr.dwid = that.props.dwid
            arr.hntjbzid = that.props.hntjbzid
            arr.year = that.props.year
        fetch('/Stxstj/dofkview', {
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    if (isnext == 1) {
                        that.nextAdd()
                    } else {
                        this.mdchange()
                    }
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    addItem_projectid = () => {
        let that = this
        fetch('/Project/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, projectid: data.projectid ? data.projectid : null })
                    this.setState({
                        condition: condition,
                        projectid_react: data.projectid_react ? data.projectid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_sgdwid = () => {
        let that = this
        fetch('/Sgdw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, sgdwid: data.sgdwid ? data.sgdwid : null })
                    this.setState({
                        condition: condition,
                        sgdwid_react: data.sgdwid_react ? data.sgdwid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_hntjbzid = () => {
        let that = this
        fetch('/Hntjbz/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, hntjbzid: data.hntjbzid ? data.hntjbzid : null })
                    this.setState({
                        condition: condition,
                        hntjbzid_react: data.hntjbzid_react ? data.hntjbzid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_wdbdw2 = (index, e) => {
        let that = this
        fetch('/Wdbdw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                console.log(index)
                if (data.status == 1) {
                    let arrSblx = that.state.condition.arrSblx.concat()
                    arrSblx[index]['wdbdw'] = data.wdbdw ? data.wdbdw : null
                    let condition = Object.assign({}, this.state.condition, { newfield: null, arrSblx: arrSblx })
                    this.setState({
                        condition: condition,
                        wdbdw_react: data.wdbdw_react ? data.wdbdw_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    clearNewField = (open) => {
        let that = this
        setTimeout(() => {
            let condition = Object.assign({}, that.state.condition, { newfield: null })
            that.setState({
                condition: condition,
            })
        });
    }
    addSblx = () => {
        let that = this
        let arrSblx = that.state.condition.arrSblx.concat()
        let arr = { id: '', type: 1, chid: null, wdbdw: null, sblx: null, gg: null, zlrq: null }
        arrSblx.push(arr)
        let data = Object.assign({}, this.state.condition, { arrSblx: arrSblx })
        this.setState({
            condition: data
        })
    }
    onSelChange2(name, index, value, Option) {
        let that = this
        console.log(name, value, Option)
        let arrSblx = that.state.condition.arrSblx.concat()
        arrSblx[index][name] = value
        let data = Object.assign({}, this.state.condition, { arrSblx: arrSblx })
        this.setState({
            condition: data
        })
    }
    onSelChange3(index, value, Option) {
        let that = this
        console.log(value, Option)
        let siji = that.state.condition.siji.concat()
        siji[index] = value
        let data = Object.assign({}, this.state.condition, { siji: siji })
        this.setState({
            condition: data
        })

    }
    onChange2(name, index, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let arrSblx = that.state.condition.arrSblx.concat()
        arrSblx[index][name] = value
        let data = Object.assign({}, this.state.condition, { arrSblx: arrSblx })
        this.setState({
            condition: data
        })
    }
    onTimeChange2(name, index, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let arrSblx = that.state.condition.arrSblx.concat()
        arrSblx[index][name] = dateString
        let data = Object.assign({}, this.state.condition, { arrSblx: arrSblx })
        this.setState({
            condition: data
        })
    }

    render() {
        return (
            <>
                {this.state.condition.id ? <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx34 ? (store.getState().data.info.qx34 == 1 ? '编辑' : '查看') : '查看'}</a> : <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>}
                <Modal maskClosable={false} title="编辑" width={800} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx34 ? (store.getState().data.info.qx34 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={12}>
                                <Form.Item
                                    label="付款日期"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'fkrq')} value={this.state.condition.fkrq ? moment(this.state.condition.fkrq) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="付款金额"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.fkje ? this.state.condition.fkje : null} onChange={this.onChange.bind(this, 'fkje')}  min={0} type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="支付方式"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.zffs ? this.state.condition.zffs : null} onChange={this.onChange.bind(this, 'zffs')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="付款说明"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.fksm ? this.state.condition.fksm : null} onChange={this.onChange.bind(this, 'fksm')}  />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="备注"
                                >
                                    <TextArea placeholder="请输入" value={this.state.condition.beizhu ? this.state.condition.beizhu : null} onChange={this.onChange.bind(this, 'beizhu')}  rows={6} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {store.getState().data.info.qx34 ? (store.getState().data.info.qx34 == 1 ?
                            <Row justify="center">
                                <Space>
                                    <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this)}>完成</Button>
                                </Space>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}