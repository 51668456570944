import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm, TimePicker } from 'antd';
import store from "../store/store.js";
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment';
import Chxz from '../public/chxz.js';
import KhxxEdit from '../khxx/add.js';
const { Option } = Select;
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                arrSblx: [],
                siji: [],
            },
            list: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value, Option) {
        let that = this
        console.log(name, value, Option)
        let data = Object.assign({}, that.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
        // return
        setTimeout(function () {
            if (name == 'chid') {
                let data2 = Object.assign({}, that.state.condition, {
                    cp: that.state.chid_react[Option.key] ? that.state.chid_react[Option.key]['cp'] : null,
                    sblx: that.state.chid_react[Option.key] ? that.state.chid_react[Option.key]['sblx'] : null,
                    gg: that.state.chid_react[Option.key] ? that.state.chid_react[Option.key]['gg'] : null
                })
                that.setState({
                    condition: data2
                })
            }
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                    arrSblx: [],
                    siji: []
                },
                loading: false
            })
        }
    }
    getdata = (arrname, e) => {
        let that = this
        fetch('/Xmjh/view', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                if (arrname) {
                    that.setState({
                        [arrname]: data[arrname] ? data[arrname] : [],
                    })
                    return
                }
                if (this.state.condition.id) {
                    that.setState({
                        condition: data.data,
                    })
                }
                that.setState({
                    chid_react: data.chid_react ? data.chid_react : [],
                    projectid_react: data.projectid_react ? data.projectid_react : [],
                    sgdwid_react: data.sgdwid_react ? data.sgdwid_react : [],
                    hntjbzid_react: data.hntjbzid_react ? data.hntjbzid_react : [],
                    sblx_react: data.sblx_react ? data.sblx_react : [],
                    sbly_react: data.sbly_react ? data.sbly_react : [],
                    wdbdw_react: data.wdbdw_react ? data.wdbdw_react : [],
                    khid_react: data.khid_react ? data.khid_react : [],
                    sj_react: data.sj_react ? data.sj_react : [],
                    xclx_react: data.xclx_react ? data.xclx_react : [],
                })
            })
    }
    sub(isnext = 0, e) {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Xmjh/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    if (isnext == 1) {
                        that.nextAdd()
                    } else {
                        this.mdchange()
                    }
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    addItem_projectid = () => {
        let that = this
        fetch('/Project/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, projectid: data.projectid ? data.projectid : null })
                    this.setState({
                        condition: condition,
                        projectid_react: data.projectid_react ? data.projectid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_sgdwid = () => {
        let that = this
        fetch('/Sgdw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, sgdwid: data.sgdwid ? data.sgdwid : null })
                    this.setState({
                        condition: condition,
                        sgdwid_react: data.sgdwid_react ? data.sgdwid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_hntjbzid = () => {
        let that = this
        fetch('/Hntjbz/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, hntjbzid: data.hntjbzid ? data.hntjbzid : null })
                    this.setState({
                        condition: condition,
                        hntjbzid_react: data.hntjbzid_react ? data.hntjbzid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_wdbdw2 = (index, e) => {
        let that = this
        fetch('/Wdbdw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                console.log(index)
                if (data.status == 1) {
                    let arrSblx = that.state.condition.arrSblx.concat()
                    arrSblx[index]['wdbdw'] = data.wdbdw ? data.wdbdw : null
                    let condition = Object.assign({}, this.state.condition, { newfield: null, arrSblx: arrSblx })
                    this.setState({
                        condition: condition,
                        wdbdw_react: data.wdbdw_react ? data.wdbdw_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    clearNewField = (open) => {
        let that = this
        setTimeout(() => {
            let condition = Object.assign({}, that.state.condition, { newfield: null })
            that.setState({
                condition: condition,
            })
        });
    }
    addSblx = () => {
        let that = this
        let arrSblx = that.state.condition.arrSblx.concat()
        let arr = { id: '', type: 1, chid: null, wdbdw: null, sblx: null, gg: null, zlrq: null }
        arrSblx.push(arr)
        let data = Object.assign({}, this.state.condition, { arrSblx: arrSblx })
        this.setState({
            condition: data
        })
    }
    arrSblx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <>
                    <Col span={12}>
                        <Form.Item
                            label={<Space><span>设备来源</span><DeleteOutlined className="hongse" onClick={this.delSblx.bind(this, index)} /></Space>}
                            required={true}
                        >
                            <Select placeholder="请选择" showSearch optionFilterProp="children" value={item.type ? item.type : null} allowClear onChange={this.onSelChange2.bind(this, 'type', index)}  >
                                {this.arrsel(this.state.sbly_react)}
                            </Select>
                        </Form.Item>
                    </Col>
                    {item.type == 1 ?
                        <>
                            <Col span={12}>
                                <Form.Item
                                    label="车牌"
                                    required={true}
                                >
                                    <Input.Group compact>
                                        <Input placeholder="请输入" value={item.cp ? item.cp : null} onChange={this.onChange2.bind(this, 'cp', index)} style={{ width: 'calc(100% - 89px)' }}  disabled={true} />
                                        <Chxz value={item.chid ? item.chid : null} index={index} onChange={this.onSelChange2.bind(this)} chid_react={this.state.chid_react ? this.state.chid_react : []} />
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="类型"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={item.zy_sblx ? item.zy_sblx : null} onChange={this.onChange2.bind(this, 'zy_sblx', index)}  disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="规格"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={item.zy_gg ? item.zy_gg : null} onChange={this.onChange2.bind(this, 'zy_gg', index)}  disabled={true} />
                                </Form.Item>
                            </Col>
                            {/* <Col span={12}>
                                <Form.Item
                                    label="司机1"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={item.sj1_str ? item.sj1_str : null} onChange={this.onChange2.bind(this, 'sj1_str', index)}  disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="司机2"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={item.sj2_str ? item.sj2_str : null} onChange={this.onChange2.bind(this, 'sj2_str', index)}  disabled={true} />
                                </Form.Item>
                            </Col> */}
                        </>
                        :
                        <>
                            <Col span={12}>
                                <Form.Item
                                    label="外租泵单位"
                                    required={true}
                                >
                                    {/* <Input placeholder="请输入" value={item.wdbdw ? item.wdbdw : null} onChange={this.onChange2.bind(this, 'wdbdw')}  /> */}
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={item.wdbdw ? item.wdbdw : null}
                                        onChange={this.onSelChange2.bind(this, 'wdbdw', index)}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_wdbdw2.bind(this, index)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.wdbdw_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="类型"
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={item.sblx ? item.sblx : null} allowClear onChange={this.onSelChange2.bind(this, 'sblx', index)}>
                                        {this.arrsel(this.state.sblx_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="规格"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={item.gg ? item.gg : null} onChange={this.onChange2.bind(this, 'gg', index)}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="租赁日期"
                                    required={true}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange2.bind(this, 'zlrq', index)} value={item.zlrq ? moment(item.zlrq) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={12}></Col>
                        </>
                    }
                </>
            )
        }
    }
    onSelChange2(name, index, value, Option) {
        let that = this
        console.log(name, value, Option)
        let arrSblx = that.state.condition.arrSblx.concat()
        arrSblx[index][name] = value
        let data = Object.assign({}, this.state.condition, { arrSblx: arrSblx })
        this.setState({
            condition: data
        })
        // return
        setTimeout(function () {
            if (name == 'chid') {
                arrSblx[index]['cp'] = that.state.chid_react[Option.key] ? that.state.chid_react[Option.key]['cp'] : null
                arrSblx[index]['zy_sblx'] = that.state.chid_react[Option.key] ? that.state.chid_react[Option.key]['sblx'] : null
                arrSblx[index]['zy_gg'] = that.state.chid_react[Option.key] ? that.state.chid_react[Option.key]['gg'] : null
                arrSblx[index]['sj1_str'] = that.state.chid_react[Option.key] ? that.state.chid_react[Option.key]['sj1_str'] : null
                arrSblx[index]['sj2_str'] = that.state.chid_react[Option.key] ? that.state.chid_react[Option.key]['sj2_str'] : null
                let data2 = Object.assign({}, that.state.condition, { arrSblx: arrSblx })
                that.setState({
                    condition: data2
                })
            }
        })
    }
    onSelChange3(index, value, Option) {
        let that = this
        console.log(value, Option)
        let siji = that.state.condition.siji.concat()
        siji[index] = value
        let data = Object.assign({}, this.state.condition, { siji: siji })
        this.setState({
            condition: data
        })

    }
    onChange2(name, index, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let arrSblx = that.state.condition.arrSblx.concat()
        arrSblx[index][name] = value
        let data = Object.assign({}, this.state.condition, { arrSblx: arrSblx })
        this.setState({
            condition: data
        })
    }
    onTimeChange2(name, index, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let arrSblx = that.state.condition.arrSblx.concat()
        arrSblx[index][name] = dateString
        let data = Object.assign({}, this.state.condition, { arrSblx: arrSblx })
        this.setState({
            condition: data
        })
    }
    delSblx = (index, e) => {
        let that = this
        let arrSblx = that.state.condition.arrSblx.concat()
        arrSblx.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { arrSblx: arrSblx })
        this.setState({
            condition: data
        })
    }
    addsiji = () => {
        let that = this
        let siji = that.state.condition.siji.concat()
        let arr = null
        siji.push(arr)
        let data = Object.assign({}, this.state.condition, { siji: siji })
        this.setState({
            condition: data
        })
    }
    arrsiji(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={12} key={index}>
                    <Form.Item
                        label={
                            <Space>
                                <span>司机{index + 1}</span>
                                <DeleteOutlined className='hongse' onClick={this.delsiji.bind(this, index)} />
                            </Space>
                        }
                    >
                        <Select
                            placeholder="请选择"
                            showSearch optionFilterProp="children"
                            value={item ? item : null}
                            onChange={this.onSelChange3.bind(this, index)}
                        >
                            {this.arrsel(this.state.sj_react)}
                        </Select>
                    </Form.Item>
                </Col>
            )
        }
    }
    delsiji = (index, e) => {
        let that = this
        let siji = that.state.condition.siji.concat()
        siji.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { siji: siji })
        this.setState({
            condition: data
        })
    }
    render() {
        return (
            <>
                {this.state.condition.id ? <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx25 ? (store.getState().data.info.qx25 == 1 ? '编辑' : '查看') : '查看'}</a> : <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>}
                <Modal maskClosable={false} title="编辑" width={800} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx25 ? (store.getState().data.info.qx25 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={12}>
                                <Form.Item
                                    label="计划名称"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jhmc ? this.state.condition.jhmc : null} onChange={this.onChange.bind(this, 'jhmc')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={<Space><>客户</> {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ? <KhxxEdit type="link" refresh={this.getdata.bind(this, 'khid_react')} /> : null) : null} </Space>}
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.khid ? this.state.condition.khid : null} allowClear onChange={this.onSelChange.bind(this, 'khid')}>
                                        {this.arrsel(this.state.khid_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="生产日期"
                                    required={true}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'scrq')} value={this.state.condition.scrq ? moment(this.state.condition.scrq) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="项目名称"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择"
                                        showSearch optionFilterProp="children"
                                        value={this.state.condition.projectid ? this.state.condition.projectid : null}
                                        onChange={this.onSelChange.bind(this, 'projectid')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_projectid.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.projectid_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="生产时间"
                                // required={true}
                                >
                                    <TimePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'scsj')} value={this.state.condition.scsj ? moment(this.state.condition.scsj, 'HH:mm') : null} format={'HH:mm'} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="计划方量"
                                // required={true}
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.jhfl ? this.state.condition.jhfl : null} onChange={this.onChange.bind(this, 'jhfl')}  />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="行车路线"
                                    // required={true}
                                >
                                    <Select
                                        placeholder="请选择"
                                        showSearch
                                        optionFilterProp="children"
                                        value={this.state.condition.xclx ? this.state.condition.xclx : null}
                                        onChange={this.onSelChange.bind(this, 'xclx')}
                                    >
                                        {this.arrsel(this.state.xclx_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {this.state.condition.xclx == 3 ?
                                <Col span={18}>
                                    <Form.Item
                                        label="绕行原因"
                                        required={true}
                                    >
                                        <Input placeholder="请输入" value={this.state.condition.rx_reason ? this.state.condition.rx_reason : null} onChange={this.onChange.bind(this, 'rx_reason')}  />
                                    </Form.Item>
                                </Col>
                                : null}
                            <Col span={24}>
                                <Form.Item
                                    label="备注"
                                // required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.beizhu ? this.state.condition.beizhu : null} onChange={this.onChange.bind(this, 'beizhu')}  />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Divider>设备信息</Divider>
                            </Col>
                            {this.arrSblx(this.state.condition.arrSblx)}
                            <Col span={24}>
                                <Button type="dashed" size="small" style={{ borderColor: '#1890ff', color: '#1890ff', width: '100%', marginBottom: 24 }} onClick={this.addSblx.bind(this)} >增加</Button>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="施工单位"
                                >
                                    <Select
                                        placeholder="请选择"
                                        showSearch optionFilterProp="children"
                                        value={this.state.condition.sgdwid ? this.state.condition.sgdwid : null}
                                        onChange={this.onSelChange.bind(this, 'sgdwid')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_sgdwid.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.sgdwid_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="混凝土搅拌站"
                                >
                                    <Select
                                        placeholder="请选择"
                                        showSearch optionFilterProp="children"
                                        value={this.state.condition.hntjbzid ? this.state.condition.hntjbzid : null}
                                        onChange={this.onSelChange.bind(this, 'hntjbzid')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_hntjbzid.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.hntjbzid_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Divider>司机信息</Divider>
                            </Col>
                            {this.arrsiji(this.state.condition.siji)}
                            <Col span={24}>
                                <Button type="dashed" size="small" style={{ borderColor: '#1890ff', color: '#1890ff', width: '100%', marginBottom: 24 }} onClick={this.addsiji.bind(this)} >增加</Button>
                            </Col>
                        </Row>
                        {store.getState().data.info.qx25 ? (store.getState().data.info.qx25 == 1 ?
                            <Row justify="center">
                                <Space>
                                    <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this)}>完成</Button>
                                </Space>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}
