import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm } from 'antd';
import store from "../store/store.js";
import { PlusOutlined } from '@ant-design/icons'
import moment from 'moment';
import Chxz from '../public/chxz.js';
import Chxm from '../public/chxm.js';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                sclist: [],
            },
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            fetch('/Scjhfx/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg)
                        return
                    }
                    if (this.state.condition.id) {
                        that.setState({
                            condition: data.data,
                        })
                    }
                })
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }


    arrlist1(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <>
                    <tr key={index}>
                        <td>{item.cp1}</td>
                        <td><Space wrap>{item.sjlist1}</Space></td>
                        <td>{item.cp2}</td>
                        <td><Space wrap>{item.sjlist2}</Space></td>
                        <td>{item.fl}</td>
                    </tr>
                </>
            )
        }
    }
    arrlist2(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <>
                    <tr key={index}>
                        <td colSpan={2}>{item.wdbdw1}</td>
                        <td colSpan={2}>{item.wdbdw2}</td>
                        <td>{item.fl}</td>
                    </tr>
                </>
            )
        }
    }

    render() {
        const { tableloading } = this.state;
        return (
            <>
                <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >查看</a>
                <Modal maskClosable={true} title="查看" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="horizontal"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        labelAlign="left"
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={8}>
                                <Form.Item
                                    label={<span className='hvtitle'>计划名称</span>}
                                >
                                    <span className='heititle'>{this.state.condition.jhmc}</span>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={<span className='hvtitle'>生产日期</span>}
                                >
                                    <span className='heititle'>{this.state.condition.scrq_str}</span>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={<span className='hvtitle'>生产时间</span>}
                                >
                                    <span className='heititle'>{this.state.condition.scsj}</span>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={<span className='hvtitle'>客户名称</span>}
                                >
                                    <span className='heititle'>{this.state.condition.khmc}</span>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={<span className='hvtitle'>项目名称</span>}
                                >
                                    <span className='heititle'>{this.state.condition.xmmc}</span>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="计划与生产对比" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={24}>
                                <table border="1" className='jhtable'>
                                    <tr className='title1'>
                                        <td colSpan={2}>计划方量（{this.state.condition.jhfl}）</td>
                                        <td colSpan={3}>生产方量（{this.state.condition.scfl}）</td>
                                    </tr>
                                    <tr className='title2'>
                                        <td colSpan={5}>自有设备</td>
                                    </tr>
                                    <tr className='title3'>
                                        <td>车辆</td>
                                        <td>司机</td>
                                        <td>车辆</td>
                                        <td>司机</td>
                                        <td>方量</td>
                                    </tr>
                                    {this.arrlist1(this.state.condition.list1)}
                                    <tr className='title2'>
                                        <td colSpan={5}>外租设备</td>
                                    </tr>
                                    {this.arrlist2(this.state.condition.list2)}
                                </table>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </>
        )
    }
}