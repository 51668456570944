import React from 'react';
import {Button, Checkbox, DatePicker, message, Popconfirm, Row, Select, Space, Table, Tooltip} from 'antd';
import store from "../store/store.js";
import moment from 'moment';
import Add from './add';

const {Option} = Select;
const {RangePicker} = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {},
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx26)
        })
    }

    componentDidMount() {
        this.props.NAV_CHANGE('/swcb', '商务成本')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }

    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 150 - selheight
        that.setState({
            tableHeight: yheight
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, {[name]: dateString})
        this.setState({
            newcondition: data
        })
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
    };

    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, {current: page, pagesize: pagesize})
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }

    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, {current: 1, pagesize: old.pagesize})
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Swcb/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    khid_react: data.khid_react ? data.khid_react : [],
                    projectid_react: data.projectid_react ? data.projectid_react : [],
                })
            })
    }

    delSel() {
        let that = this
        fetch('/Swcb/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    delSel2(id, e) {
        let that = this
        fetch('/Swcb/delSel2', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Swcb/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }

    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, {
            [name + '1']: dateStrings[0],
            [name + '2']: dateStrings[1]
        })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }

    reset() {
        let that = this
        that.setState({
            newcondition: {},
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }

    render() {
        const columns = [
            // {
            //     title: '序号',
            //     dataIndex: 'xh',
            //     key: 'xh',
            //     align: "center",
            //     width: 80,
            //     fixed: 'left',
            //     ellipsis: {
            //         showTitle: false,
            //     },
            //     render: (row, record) => (
            //         <Tooltip placement="topLeft" title={row}>
            //             {row}
            //         </Tooltip>
            //     ),
            // },
            {
                title: '创建日期',
                dataIndex: 'addtime_str',
                key: 'addtime_str',
                fixed: 'left',
                width: 160,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '客户',
                dataIndex: 'khmc',
                key: 'khmc',
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '项目',
                dataIndex: 'xmmc',
                key: 'xmmc',
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '中介费',
                dataIndex: 'zjf',
                key: 'zjf',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '节礼',
                dataIndex: 'jl',
                key: 'jl',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '客情',
                dataIndex: 'kq',
                key: 'kq',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作人',
                dataIndex: 'aidname',
                key: 'aidname',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={record.updatetime}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 150,
                fixed: 'right',
                render: (row, record) => {
                    return (
                        <>
                            {record.noedit == 1 ? null
                                :
                                <Space>
                                    <Add id={record.id} refresh={this.getdata.bind(this)}/>
                                    {store.getState().data.info.qx26 ? (store.getState().data.info.qx26 == 1 ?
                                        <Popconfirm
                                            title="确定删除?"
                                            onConfirm={this.delSel2.bind(this, record.id)}
                                            okText="确定"
                                            cancelText="取消"
                                        >
                                            <a href="#" className="hongse">删除</a>
                                        </Popconfirm>
                                        : null) : null}
                                </Space>
                            }

                        </>
                    )
                },
            },
        ]
        const {tableloading, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll: true,
            preserveSelectedRowKeys: false,
            // renderCell: function (checked, record, index, originNode) {
            //     if (record.status != 1) {
            //         return originNode
            //     } else {
            //         return false
            //     }
            // },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" align="top">
                        <Space wrap style={{width: 'calc(100% - 200px)'}}>
                            <Select placeholder="客户名称"
                                    value={this.state.newcondition.khid ? this.state.newcondition.khid : null}
                                    onChange={this.onSelChange.bind(this, 'khid')} allowClear style={{width: 300}}
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.khid_react)}
                            </Select>
                            <Select placeholder="项目名称"
                                    value={this.state.newcondition.projectid ? this.state.newcondition.projectid : null}
                                    onChange={this.onSelChange.bind(this, 'projectid')} allowClear style={{width: 300}}
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.projectid_react)}
                            </Select>
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'addtime')}
                                         value={[(this.state.newcondition.addtime1 ? moment(this.state.newcondition.addtime1) : null), (this.state.newcondition.addtime2 ? moment(this.state.newcondition.addtime2) : null)]}
                                         placeholder={['创建日期', '创建日期']}/>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                        {store.getState().data.info.qx26 ? (store.getState().data.info.qx26 == 1 ?
                            <Space wrap>
                                <Add refresh={this.getdata.bind(this)}/>
                                {/* <Button type="primary" className='blue'>导出</Button> */}
                            </Space>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    scroll={{y: this.state.tableHeight ? this.state.tableHeight : null}}
                    className='table'
                    size="small"
                />
                {/* <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div> */}
                {/*<div className='page page3'>*/}
                {/*    <div className='l'>*/}
                {/*        /!* <Space wrap>*/}
                {/*            {hasSelected ?*/}
                {/*                <>*/}
                {/*                    {store.getState().data.info.qx26 ? (store.getState().data.info.qx26 == 1 ?*/}
                {/*                        <>*/}

                {/*                        </>*/}
                {/*                        : null) : null}*/}
                {/*                </>*/}
                {/*                : null}*/}
                {/*        </Space> *!/*/}
                {/*    </div>*/}
                {/*    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />*/}
                {/*</div>*/}
            </>
        )
    }
}
