import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm, TimePicker } from 'antd';
import store from "../store/store.js";
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment';
import Chxz from '../public/chxz.js';
import KhxxEdit from '../khxx/add.js';
const { Option } = Select;
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
            },
            list: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value, Option) {
        let that = this
        console.log(name, value, Option)

        let data = Object.assign({}, that.state.condition, { [name]: value })
        if (name == 'sl') {
            data.slnum = Option.children
        }
        if (name == 'gysfjs' && value == 2) {
            data.gyjsrq = null
        }
        if (name == 'jhsfjs' && value == 2) {
            data.jhjsrq = null
        }
        if (name == 'yssfjs' && value == 2) {
            data.ysjsrq = null
        }
        if (name == 'sl') {
            setTimeout(() => {
                that.jisuan(data, 0)
            });
        } else {
            that.setState({
                condition: data
            })
        }
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        setTimeout(() => {
            if (name == 'gysl' || name == 'ysdj') {
                that.jisuan(false, 1)
            } else if (name == 'gydj' || name == 'ccsl' || name == 'ccdj' || name == 'yf') {
                that.jisuan(false, 0)
            }
        })
    }
    // 税金，供应金额，利润金额，进货金额计算
    jisuan = (array, type) => {
        let that = this
        console.log(array)
        let arr = array ? array : that.state.condition
        let slnum = arr.slnum ? arr.slnum : 0 //税率（值）
        // console.log('slnum:'+slnum)
        let gysl = arr.gysl ? arr.gysl : 0 //供应数量
        let gydj = arr.gydj ? arr.gydj : 0 //供应单价
        let gyje = (gysl * gydj).toFixed(2) //供应金额=供应数量*供应单价
        let sj = ((Number(gyje) / (1 + (slnum / 100))) * (slnum / 100)).toFixed(2) //税金=(供应金额/1+税率)*税率
        let ccsl = arr.ccsl ? arr.ccsl : 0 //出厂数量
        let ccdj = arr.ccdj ? arr.ccdj : 0 //出厂单价
        let ysdj = arr.ysdj ? arr.ysdj : 0 //运输单价

        let yf = type == 1 ? (gysl * ysdj).toFixed(2) : arr.yf //运费=供应数量*运输单价
        // let jhje = (ccsl * ccdj + Number(yf)).toFixed(2)//进货金额=出厂数量*出厂单价+运费
        let jhje = (ccsl * ccdj).toFixed(2)//进货金额=出厂数量*出厂单价
        let lrje = (gyje - jhje - yf).toFixed(2)
        let data = Object.assign({}, arr, { gysl, gydj, gyje, sj, ccsl, ccdj, ysdj, yf, jhje, lrje })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }
    getdata = (arrname, e) => {
        let that = this
        fetch('/Jcxs/view', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                if (arrname) {
                    that.setState({
                        [arrname]: data[arrname] ? data[arrname] : [],
                    })
                    return
                }
                if (this.state.condition.id) {
                    that.setState({
                        condition: data.data,
                    })
                }
                that.setState({
                    hwmc_react: data.hwmc_react ? data.hwmc_react : [],
                    qydw_react: data.qydw_react ? data.qydw_react : [],
                    htbh_react: data.htbh_react ? data.htbh_react : [],
                    sl_react: data.sl_react ? data.sl_react : [],
                    gydw_react: data.gydw_react ? data.gydw_react : [],
                    jhdw_react: data.jhdw_react ? data.jhdw_react : [],
                    ysdw_react: data.ysdw_react ? data.ysdw_react : [],
                    sfbljs_react: data.sfbljs_react ? data.sfbljs_react : [],
                })
            })
    }
    sub(e) {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Jcxs/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    addItem_hwmc = () => {
        let that = this
        fetch('/Hwmc/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, hwmc: data.hwmc ? data.hwmc : null })
                    this.setState({
                        condition: condition,
                        hwmc_react: data.hwmc_react ? data.hwmc_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_qydw = () => {
        let that = this
        fetch('/Qydw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, qydw: data.qydw ? data.qydw : null })
                    this.setState({
                        condition: condition,
                        qydw_react: data.qydw_react ? data.qydw_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_htbh = () => {
        let that = this
        fetch('/Htbh/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, htbh: data.htbh ? data.htbh : null })
                    this.setState({
                        condition: condition,
                        htbh_react: data.htbh_react ? data.htbh_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_sl = () => {
        let that = this
        fetch('/Sl/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, sl: data.sl ? data.sl : null })
                    this.setState({
                        condition: condition,
                        sl_react: data.sl_react ? data.sl_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_gydw = () => {
        let that = this
        fetch('/Gydw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, gydw: data.gydw ? data.gydw : null })
                    this.setState({
                        condition: condition,
                        gydw_react: data.gydw_react ? data.gydw_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_jhdw = () => {
        let that = this
        fetch('/Jhdw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, jhdw: data.jhdw ? data.jhdw : null })
                    this.setState({
                        condition: condition,
                        jhdw_react: data.jhdw_react ? data.jhdw_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_ysdw = () => {
        let that = this
        fetch('/Ysdw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, ysdw: data.ysdw ? data.ysdw : null })
                    this.setState({
                        condition: condition,
                        ysdw_react: data.ysdw_react ? data.ysdw_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    clearNewField = (open) => {
        let that = this
        setTimeout(() => {
            let condition = Object.assign({}, that.state.condition, { newfield: null })
            that.setState({
                condition: condition,
            })
        });
    }
    addSblx = () => {
        let that = this
        let arrSblx = that.state.condition.arrSblx.concat()
        let arr = { id: '', type: 1, chid: null, wdbdw: null, sblx: null, gg: null, zlrq: null }
        arrSblx.push(arr)
        let data = Object.assign({}, this.state.condition, { arrSblx: arrSblx })
        this.setState({
            condition: data
        })
    }
    onSelChange2(name, index, value, Option) {
        let that = this
        console.log(name, value, Option)
        let arrSblx = that.state.condition.arrSblx.concat()
        arrSblx[index][name] = value
        let data = Object.assign({}, this.state.condition, { arrSblx: arrSblx })
        this.setState({
            condition: data
        })
    }
    onSelChange3(index, value, Option) {
        let that = this
        console.log(value, Option)
        let siji = that.state.condition.siji.concat()
        siji[index] = value
        let data = Object.assign({}, this.state.condition, { siji: siji })
        this.setState({
            condition: data
        })

    }
    onChange2(name, index, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let arrSblx = that.state.condition.arrSblx.concat()
        arrSblx[index][name] = value
        let data = Object.assign({}, this.state.condition, { arrSblx: arrSblx })
        this.setState({
            condition: data
        })
    }
    onTimeChange2(name, index, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let arrSblx = that.state.condition.arrSblx.concat()
        arrSblx[index][name] = dateString
        let data = Object.assign({}, this.state.condition, { arrSblx: arrSblx })
        this.setState({
            condition: data
        })
    }

    render() {
        return (
            <>
                {this.state.condition.id ? <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx30 ? (store.getState().data.info.qx30 == 1 ? '编辑' : '查看') : '查看'}</a> : <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>}
                <Modal maskClosable={false} title="编辑" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx30 ? (store.getState().data.info.qx30 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={8}>
                                <Form.Item
                                    label="供应日期"
                                    required={true}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'gyrq')} value={this.state.condition.gyrq ? moment(this.state.condition.gyrq) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="货物名称"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择"
                                        showSearch optionFilterProp="children"
                                        value={this.state.condition.hwmc ? this.state.condition.hwmc : null}
                                        onChange={this.onSelChange.bind(this, 'hwmc')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')} />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_hwmc.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.hwmc_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="签约单位"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择"
                                        showSearch optionFilterProp="children"
                                        value={this.state.condition.qydw ? this.state.condition.qydw : null}
                                        onChange={this.onSelChange.bind(this, 'qydw')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')} />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_qydw.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.qydw_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="合同编号"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择"
                                        showSearch optionFilterProp="children"
                                        value={this.state.condition.htbh ? this.state.condition.htbh : null}
                                        onChange={this.onSelChange.bind(this, 'htbh')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')} />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_htbh.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.htbh_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="税率"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择"
                                        suffixIcon="%"
                                        showSearch optionFilterProp="children"
                                        value={this.state.condition.sl ? this.state.condition.sl : null}
                                        onChange={this.onSelChange.bind(this, 'sl')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')} />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_sl.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.sl_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="税金"
                                    tooltip="(供应金额/1+税率)*税率"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.sj ? this.state.condition.sj : null} onChange={this.onChange.bind(this, 'sj')} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="供应信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="供应单位"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择"
                                        showSearch optionFilterProp="children"
                                        value={this.state.condition.gydw ? this.state.condition.gydw : null}
                                        onChange={this.onSelChange.bind(this, 'gydw')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')} />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_gydw.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.gydw_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="供应数量"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.gysl ? this.state.condition.gysl : null} onChange={this.onChange.bind(this, 'gysl')} type="text" min={0} step={1} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="供应单价"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.gydj ? this.state.condition.gydj : null} onChange={this.onChange.bind(this, 'gydj')} type="text" min={0} suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="供应金额"
                                    tooltip="供应数量*供应单价"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.gyje ? this.state.condition.gyje : null} onChange={this.onChange.bind(this, 'gyje')} type="text" min={0} suffix="元" disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="利润金额"
                                    tooltip="供应金额-进货金额-运费"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.lrje ? this.state.condition.lrje : null} onChange={this.onChange.bind(this, 'lrje')} type="text" min={0} suffix="元" disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="进货信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="进货单位"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择"
                                        showSearch optionFilterProp="children"
                                        value={this.state.condition.jhdw ? this.state.condition.jhdw : null}
                                        onChange={this.onSelChange.bind(this, 'jhdw')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')} />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_jhdw.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.jhdw_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="运输单位"
                                    // required={true}
                                >
                                    <Select
                                        placeholder="请选择"
                                        showSearch optionFilterProp="children"
                                        value={this.state.condition.ysdw ? this.state.condition.ysdw : null}
                                        onChange={this.onSelChange.bind(this, 'ysdw')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        allowClear
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')} />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_ysdw.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.ysdw_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="出厂数量"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.ccsl ? this.state.condition.ccsl : null} onChange={this.onChange.bind(this, 'ccsl')} type="text" min={0} step={1} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="出厂单价"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.ccdj ? this.state.condition.ccdj : null} onChange={this.onChange.bind(this, 'ccdj')} type="text" min={0} suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="运输单价"
                                    // required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.ysdj ? this.state.condition.ysdj : null} onChange={this.onChange.bind(this, 'ysdj')} type="text" min={0} suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="运费"
                                    tooltip="供应数量*运输单价"
                                    // required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.yf ? this.state.condition.yf : null} onChange={this.onChange.bind(this, 'yf')} type="text" min={0} suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="进货金额"
                                    tooltip="出厂数量*出厂单价"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jhje ? this.state.condition.jhje : null} onChange={this.onChange.bind(this, 'jhje')} type="text" min={0} suffix="元" disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="其他信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="供应是否结算"
                                >
                                    <Select
                                        placeholder="请选择"
                                        showSearch optionFilterProp="children"
                                        value={this.state.condition.gysfjs ? this.state.condition.gysfjs : null}
                                        onChange={this.onSelChange.bind(this, 'gysfjs')}
                                    >
                                        {this.arrsel(this.state.sfbljs_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="供应结算日期"
                                    required={this.state.condition.gysfjs == 1 ? true : false}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'gyjsrq')} value={this.state.condition.gyjsrq ? moment(this.state.condition.gyjsrq) : null} disabled={this.state.condition.gysfjs == 1 ? false : true} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="进货是否结算"
                                >
                                    <Select
                                        placeholder="请选择"
                                        showSearch optionFilterProp="children"
                                        value={this.state.condition.jhsfjs ? this.state.condition.jhsfjs : null}
                                        onChange={this.onSelChange.bind(this, 'jhsfjs')}
                                    >
                                        {this.arrsel(this.state.sfbljs_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="进货结算日期"
                                    required={this.state.condition.jhsfjs == 1 ? true : false}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'jhjsrq')} value={this.state.condition.jhjsrq ? moment(this.state.condition.jhjsrq) : null} disabled={this.state.condition.jhsfjs == 1 ? false : true} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="运输是否结算"
                                >
                                    <Select
                                        placeholder="请选择"
                                        showSearch optionFilterProp="children"
                                        value={this.state.condition.yssfjs ? this.state.condition.yssfjs : null}
                                        onChange={this.onSelChange.bind(this, 'yssfjs')}
                                    >
                                        {this.arrsel(this.state.sfbljs_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="运输结算日期"
                                    required={this.state.condition.yssfjs == 1 ? true : false}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'ysjsrq')} value={this.state.condition.ysjsrq ? moment(this.state.condition.ysjsrq) : null} disabled={this.state.condition.yssfjs == 1 ? false : true} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="备注"
                                >
                                    <TextArea rows={6} placeholder="请输入" value={this.state.condition.beizhu ? this.state.condition.beizhu : null} onChange={this.onChange.bind(this, 'beizhu')} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {store.getState().data.info.qx30 ? (store.getState().data.info.qx30 == 1 ?
                            <Row justify="center">
                                <Space>
                                    <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this)}>完成</Button>
                                </Space>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}
