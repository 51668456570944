import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm, AutoComplete, Drawer, List, Typography } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Text } = Typography;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                id: props.id ? props.id : 0
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            count: 0,
            list: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata(1, null)
        } else {
            that.setState({
                newcondition: {
                    id: that.props.id ? that.props.id : 0
                },
                oldcondition: {},
                list: [],
                tableloading: false,
                count: 0,
                visible: false,
                loading: false
            })
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('Xmjh/history', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count)
                })
            })
    }
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    render() {
        const { tableloading } = this.state;
        return (
            <>
                <a onClick={this.mdchange.bind(this)}>修改记录</a>
                <Drawer title="修改记录" visible={this.state.visible} width="600" onClose={this.mdchange.bind(this)} footer={null}>
                    <div style={{maxHeight:"calc(100% - 32px)",overflowY:'auto'}}>
                        <List
                            itemLayout="vertical"
                            sticky={false}
                    dataSource={this.state.list}
                            loading={tableloading}
                            
                            renderItem={(item) => (
                                <List.Item className=''>
                                    <Row className='p1' justify='start'>
                                        <div className='a1'>{item.uname}：</div>
                                        <div className='a2'>
                                            更新了{item.field_name}&nbsp;&nbsp;
                                            <Space split=">>" wrap>
                                                <Text type="danger">{item.kaishi?item.kaishi:'null'}</Text>
                                                <Text type="success">{item.jieshu?item.jieshu:'null'}</Text>
                                            </Space>
                                        </div>
                                    </Row>
                                    <div className='p2'>{item.addtime}</div>
                                </List.Item>
                            )}
                        />
                    </div>
                    <div className='page'>
                        <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                    </div>
                </Drawer>
            </>
        )
    }
}