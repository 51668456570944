import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Avatar, message, Badge } from 'antd';
import {
	PoweroffOutlined,
	UserOutlined,
	HomeOutlined,
	FileProtectOutlined,
	SettingOutlined,
	ReadOutlined,
	UsbOutlined,
	BarChartOutlined,
	EditOutlined,
	LayoutOutlined
} from '@ant-design/icons';
import AddUser from '../user/adduser';
import moment from 'moment';
import 'moment/locale/zh-cn';
import '../index.css';
import 'braft-extensions/dist/table.css'
import store from "../store/store.js";
moment.locale('zh-cn');
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

class SiderDemo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			breadcrumb: [store.getState().data.navname ? store.getState().data.navname : '控制台'],
			defaultnav: '1',
			current: '/',
			isLogin: store.getState().data.isLogin,
			name: store.getState().data.info.name,
			name2: '',
			openKeys: []
		}
		// props.GET_INFO();
		store.subscribe(() => {
			// console.log('state状态改变了，新状态如下')
			this.setState({
				islogin: store.getState().data.isLogin,
				current: store.getState().data.nav,
				breadcrumb: [store.getState().data.navname],
			});
		})
	}
	state = {
		collapsed: false,
	};
	componentWillReceiveProps(nextProps) {
		// console.log('路由变化了')
		// 判断跳转路由不等于当前路由
		if (nextProps.location.pathname !== this.props.location.pathname && nextProps.location.pathname != '/login' && nextProps.location.pathname != '/') {
			this.islogin()
		}
	}
	breadcrumbItem() {
		return (
			this.state.breadcrumb.map((list, index) =>
				<Breadcrumb.Item key={index} style={{ fontSize: 18 }}>{list}</Breadcrumb.Item>
			)
		)
	}
	onCollapse = collapsed => {
		this.setState({ collapsed });
	};
	handleClick = e => {
		this.setState({
			current: store.getState().data.nav,
			// breadcrumb:[e.item.props.title],
			breadcrumb: [store.getState().data.navname],
		});
	};
	componentWillMount() {
		// console.log(this.props)
		this.props.GET_INFO();
		this.setState(store.getState())
		this.islogin()
	}
	islogin() {
		fetch('/Login/checklogin', {
			method: "POST",
		})
			.then(res => res.json())
			.then(data => {
				// console.log(data)
				this.props.SET_WEBINFO(data.webinfo)
				if (data.state == 1) {
					this.props.SET_INFO(data.logininfo)
					this.setState({
						isLogin: true,
						// name:data.name,
					})
					if (data.status != 1) {
						this.logout();
					}
				} else {
					this.props.OUT_LOGIN();
					// console.log(store.getState().data.isLogin );
					this.setState({
						isLogin: false
					})
				}
			})
	}
	componentDidMount() {
		let that = this
		// let time = setInterval(() => {
		this.islogin()
		let time = setInterval(() => {
			that.islogin()
		}, 600000)
		that.setState({
			time: time
		})
	}
	componentWillUnmount() {
		clearInterval(this.state.time)
	}
	aa(nav, navname, opennav = '', e) {
		this.props.NAV_CHANGE(nav, navname, opennav)
	}
	logout() {
		fetch('/Login/logout', {
			method: "POST",
		})
			.then(res => res.json())
			.then(data => {
				if (data.status == 1) {
					this.props.history.push("/login")
					// message.success(data.msg);
					// this.props.OUT_LOGIN()
					// setTimeout(
					// 	() => {
					// 		this.setState({ isLogin: store.getState().data.isLogin })
					// 	}
					// 	, 1000);
				} else {
					this.setState({ isLogin: store.getState().data.isLogin })
				}
			})
	}
	// onOpenChange = (openKeys) => {
	// 	let that = this
	// 	console.log(openKeys)
	// 	let arr
	// 	if (openKeys.length) {
	// 		arr = [openKeys[openKeys.length - 1]]
	// 		if (openKeys[openKeys.length - 1] == 'jckgl' || openKeys[openKeys.length - 1] == 'clwxmx') {
	// 			arr = openKeys
	// 		}
	// 	} else {
	// 		arr = []
	// 	}
	// 	that.setState({
	// 		openKeys: arr
	// 	})
	// }
	onOpenChange = (keys) => {
		let that = this
		const rootSubmenuKeys = ['gongzuobiao', 'shujubiao', 'tongjibiao']
		let openKeys = that.state.openKeys.concat()
		const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
		if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			that.setState({
				openKeys: keys
			})
		} else {
			that.setState({
				openKeys: latestOpenKey ? [latestOpenKey] : []
			})
		}
	}
	render() {
		if (this.state.isLogin == false) {
			return <Redirect to={'/login'} />;
		}
		return (
			<>
				{this.state.isLogin ?
					<>
						<Header className="header">
							<div className="logo" >朗达数据管理系统</div>
							<div style={{ marginRight: 25 }} className="pageheader">
								{/* <Avatar icon={<UserOutlined />} size="small" src={store.getState().data.info.logo} style={{ marginRight: 10 }} /> */}
								{store.getState().data.info.name}
								{/* <AddUser refresh={function () { console.log('....') }} id={store.getState().data.info.id} setinfo={this.props.SET_INFO} isbase={1} /> */}
								<PoweroffOutlined title='退出登录' style={{ marginLeft: 15, color: '#fff' }} onClick={this.logout.bind(this)} />
							</div>
						</Header>
						<Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
							<Sider
								collapsible
								collapsed={this.state.collapsed}
								onCollapse={this.onCollapse}
							// width={280}
							>
								<Menu
									theme="dark"
									// onClick={this.handleClick}
									onOpenChange={this.onOpenChange.bind(this)}
									selectedKeys={[store.getState().data.nav ? store.getState().data.nav : this.props.location.pathname]}
									openKeys={this.state.openKeys ? this.state.openKeys : []}
									mode="inline"
								>
									<Menu.Item key="/" title="首页" >
										<Link to={{ pathname: '/' }} >
											<HomeOutlined />
											<span>首页</span>
										</Link>
									</Menu.Item>
									<Menu.SubMenu key="gongzuobiao" title="工作表" icon={<EditOutlined />}>
										{store.getState().data.info.qx25 ? (store.getState().data.info.qx25 != -1 ?
											<Menu.Item key="/xmjh" title="生产计划" >
												<Link to={{ pathname: '/xmjh' }} >
													<span>生产计划</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx1 ? (store.getState().data.info.qx1 != -1 ?
											<Menu.Item key="/mrtjjl" title="生产记录" >
												<Link to={{ pathname: '/mrtjjl' }} >
													<span>生产记录</span>
												</Link>
											</Menu.Item>
											: null) : null}

										{store.getState().data.info.qx3 ? (store.getState().data.info.qx3 != -1 ?
											<Menu.Item key="/jymx" title="加油统计" >
												<Link to={{ pathname: '/jymx' }} >
													<span>加油统计</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{(store.getState().data.info.qx4 && store.getState().data.info.qx5) ? ((store.getState().data.info.qx4 != -1 || store.getState().data.info.qx5 != -1) ?
											<Menu.SubMenu key="jckgl" title="进出库管理" >
												{store.getState().data.info.qx4 ? (store.getState().data.info.qx4 != -1 ?
													<Menu.Item key="/jckgl" title="物资收发" >
														<Link to={{ pathname: '/jckgl' }} >
															<span>物资收发</span>
														</Link>
													</Menu.Item>
													: null) : null}
												{store.getState().data.info.qx5 ? (store.getState().data.info.qx5 != -1 ?
													<Menu.Item key="/jckgl2" title="泵管收发" >
														<Link to={{ pathname: '/jckgl2' }} >
															<span>泵管收发</span>
														</Link>
													</Menu.Item>
													: null) : null}
											</Menu.SubMenu>
											: null) : null}
										{store.getState().data.info.qx6 ? (store.getState().data.info.qx6 != -1 ?
											<Menu.Item key="/clwxmx" title="车辆维修" >
												<Link to={{ pathname: '/clwxmx' }} >
													<span>车辆维修</span>
												</Link>
											</Menu.Item> : null) : null}
										{/* <Menu.SubMenu key="clwxmx" title="车辆维修" >
											{store.getState().data.info.qx6 ? (store.getState().data.info.qx6 != -1 ?
												<Menu.Item key="/clwxmx" title="车辆维修" >
													<Link to={{ pathname: '/clwxmx' }} >
														<span>车辆维修</span>
													</Link>
												</Menu.Item> : null) : null}
											{store.getState().data.info.qx7 ? (store.getState().data.info.qx7 != -1 ?
												<Menu.Item key="/wxftj" title="维修费统计" >
													<Link to={{ pathname: '/wxftj' }} >
														<span>维修费统计</span>
													</Link>
												</Menu.Item>
												: null) : null}
										</Menu.SubMenu> */}
										{store.getState().data.info.qx8 ? (store.getState().data.info.qx8 != -1 ?
											<Menu.Item key="/dhntmx" title="商砼销售" >
												<Link to={{ pathname: '/dhntmx' }} >
													<span>商砼销售</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx9 ? (store.getState().data.info.qx9 != -1 ?
											<Menu.SubMenu key="ysyf" title="应收应付" >
												<Menu.Item key="/yszkmx" title="应收应付" >
													<Link to={{ pathname: '/yszkmx' }} >
														<span>应收应付</span>
													</Link>
												</Menu.Item>
												<Menu.Item key="/hkmx" title="回款明细" >
													<Link to={{ pathname: '/hkmx' }} >
														<span>回款明细</span>
													</Link>
												</Menu.Item>
											</Menu.SubMenu>
											: null) : null}
										{/* {store.getState().data.info.qx9 ? (store.getState().data.info.qx9 != -1 ?
											<Menu.Item key="/yszkmx" title="应收应付" >
												<Link to={{ pathname: '/yszkmx' }} >
													<span>应收应付</span>
												</Link>
											</Menu.Item>
											: null) : null} */}
										{store.getState().data.info.qx11 ? (store.getState().data.info.qx11 != -1 ?
											<Menu.Item key="/szqk" title="财务收支" >
												<Link to={{ pathname: '/szqk' }} >
													<span>财务收支</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx11 ? (store.getState().data.info.qx11 != -1 ?
											<Menu.SubMenu key="szqk2" title="财务收支2" >
												<Menu.Item key="/liushui" title="流水" >
													<Link to={{ pathname: '/liushui' }} >
														<span>流水</span>
													</Link>
												</Menu.Item>
												<Menu.Item key="/fapiao" title="发票" >
													<Link to={{ pathname: '/fapiao' }} >
														<span>发票</span>
													</Link>
												</Menu.Item>
											</Menu.SubMenu>
											: null) : null}
										{store.getState().data.info.qx12 ? (store.getState().data.info.qx12 != -1 ?
											<Menu.Item key="/kq" title="员工考勤" >
												<Link to={{ pathname: '/kq' }} >
													<span>员工考勤</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx13 ? (store.getState().data.info.qx13 != -1 ?
											<Menu.Item key="/gzjs" title="工资计算" >
												<Link to={{ pathname: '/gzjs' }} >
													<span>工资计算</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx14 ? (store.getState().data.info.qx14 != -1 ?
											<Menu.Item key="/jsmx" title="结算信息" >
												<Link to={{ pathname: '/jsmx' }} >
													<span>结算信息</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx15 ? (store.getState().data.info.qx15 != -1 ?
											<Menu.Item key="/htxx" title="合同信息" >
												<Link to={{ pathname: '/htxx' }} >
													<span>合同信息</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx26 ? (store.getState().data.info.qx26 != -1 ?
											<Menu.Item key="/swcb" title="商务成本" >
												<Link to={{ pathname: '/swcb' }} >
													<span>商务成本</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx30 ? (store.getState().data.info.qx30 != -1 ?
											<Menu.Item key="/jcxs" title="建材销售" >
												<Link to={{ pathname: '/jcxs' }} >
													<span>建材销售</span>
												</Link>
											</Menu.Item>
											: null) : null}
									</Menu.SubMenu>
									<Menu.SubMenu key="shujubiao" title="数据表" icon={<LayoutOutlined />}>
										{store.getState().data.info.qx16 ? (store.getState().data.info.qx16 != -1 ?
											<Menu.Item key="/ygxx" title="员工信息表" >
												<Link to={{ pathname: '/ygxx' }} >
													<span>员工信息表</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx17 ? (store.getState().data.info.qx17 != -1 ?
											<Menu.Item key="/clxx" title="车辆信息表" >
												<Link to={{ pathname: '/clxx' }} >
													<span>车辆信息表</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx18 ? (store.getState().data.info.qx18 != -1 ?
											<Menu.Item key="/xinxibiao" title="信息表" >
												<Link to={{ pathname: '/xinxibiao' }} >
													<span>信息表</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{/* {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 != -1 ?
											<Menu.Item key="/khxx" title="客户信息表" >
												<Link to={{ pathname: '/khxx' }} >
													<span>客户信息表</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx19 ? (store.getState().data.info.qx19 != -1 ?
											<Menu.Item key="/gcxx" title="工程信息表" >
												<Link to={{ pathname: '/gcxx' }} >
													<span>工程信息表</span>
												</Link>
											</Menu.Item>
											: null) : null} */}
										{store.getState().data.info.qx20 ? (store.getState().data.info.qx20 != -1 ?
											<Menu.Item key="/user" title="系统账号" >
												<Link to={{ pathname: '/user' }} >
													<span>系统账号</span>
												</Link>
											</Menu.Item>
											: null) : null}
									</Menu.SubMenu>
									<Menu.SubMenu key="tongjibiao" title="统计表" icon={<BarChartOutlined />}>
										{store.getState().data.info.qx2 ? (store.getState().data.info.qx2 != -1 ?
											<Menu.Item key="/wdbmx" title="外租统计" >
												<Link to={{ pathname: '/wdbmx' }} >
													<span>外租统计</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{/* {store.getState().data.info.qx21 ? (store.getState().data.info.qx21 != -1 ?
											<Menu.Item key="/zfl" title="总方量" >
												<Link to={{ pathname: '/zfl' }} >
													<span>总方量</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx22 ? (store.getState().data.info.qx22 != -1 ?
											<Menu.Item key="/ywcbhj" title="业务成本合计" >
												<Link to={{ pathname: '/ywcbhj' }} >
													<span>业务成本合计</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx23 ? (store.getState().data.info.qx23 != -1 ?
											<Menu.Item key="/qjfy" title="期间费用" >
												<Link to={{ pathname: '/qjfy' }} >
													<span>期间费用</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx24 ? (store.getState().data.info.qx24 != -1 ?
											<Menu.Item key="/lrtj" title="利润统计" >
												<Link to={{ pathname: '/lrtj' }} >
													<span>利润统计</span>
												</Link>
											</Menu.Item>
											: null) : null} */}
										{store.getState().data.info.qx27 ? (store.getState().data.info.qx27 != -1 ?
											<Menu.Item key="/scjhfx" title="生产统计" >
												<Link to={{ pathname: '/scjhfx' }} >
													<span>生产统计</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx28 ? (store.getState().data.info.qx28 != -1 ?
											<Menu.Item key="/yhtj" title="油耗分析" >
												<Link to={{ pathname: '/yhtj' }} >
													<span>油耗分析</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx29 ? (store.getState().data.info.qx29 != -1 ?
											<Menu.Item key="/wxtj" title="维修统计" >
												<Link to={{ pathname: '/wxtj' }} >
													<span>维修统计</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx31 ? (store.getState().data.info.qx31 != -1 ?
											<Menu.Item key="/jcxstj" title="销售统计" >
												<Link to={{ pathname: '/jcxstj' }} >
													<span>销售统计</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx32 ? (store.getState().data.info.qx32 != -1 ?
											<Menu.Item key="/cbfx" title="成本分析" >
												<Link to={{ pathname: '/cbfx' }} >
													<span>成本分析</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx33 ? (store.getState().data.info.qx33 != -1 ?
											<Menu.Item key="/lrfx" title="利润分析" >
												<Link to={{ pathname: '/lrfx' }} >
													<span>利润分析</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx34 ? (store.getState().data.info.qx34 != -1 ?
											<Menu.Item key="/stxstj" title="商砼统计" >
												<Link to={{ pathname: '/stxstj' }} >
													<span>商砼统计</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx35 ? (store.getState().data.info.qx35 != -1 ?
											<Menu.Item key="/wjstj" title="未结算统计" >
												<Link to={{ pathname: '/wjstj' }} >
													<span>未结算统计</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.qx36 ? (store.getState().data.info.qx36 != -1 ?
											<>
												<Menu.Item key="/yetj" title="余额统计" >
													<Link to={{ pathname: '/yetj' }} >
														<span>余额统计</span>
													</Link>
												</Menu.Item>
												<Menu.Item key="/fpjs" title="发票结算" >
													<Link to={{ pathname: '/fpjs' }} >
														<span>发票结算</span>
													</Link>
												</Menu.Item>
											</>
											: null) : null}
									</Menu.SubMenu>
								</Menu>
							</Sider>
							<Layout className="site-layout">
								<Content style={{ margin: '0 16px' }} >
									{(store.getState().data.nav != '/'
										&& store.getState().data.nav != '/zfl'
										&& store.getState().data.nav != '/ywcbhj'
										&& store.getState().data.nav != '/qjfy'
										&& store.getState().data.nav != '/lrtj'
										&& store.getState().data.nav != '/scjhfx'
										&& store.getState().data.nav != '/yhtj'
										&& store.getState().data.nav != '/wxtj'
										&& store.getState().data.nav != '/wxtj'
										&& store.getState().data.nav != '/cbfx'
										// && store.getState().data.nav != '/lrfx'
									) ?
										<div className="site-layout-background" style={{ padding: '0 24px', marginTop: 24, height: 'calc(100vh - 105px)', overflowY: 'auto', backgroundColor: '#fff' }}>
											<Breadcrumb style={store.getState().data.nav == '/jcxstj' ? { margin: '16px 0 0 0' } : { margin: '16px 0' }}>
												{this.breadcrumbItem()}
											</Breadcrumb>
											{this.props.children}
										</div>
										:
										<div className="site-layout-background" style={{ backgroundColor: '#f0f2f5', height: 'calc(100vh - 100px)', paddingTop: 16, overflowY: 'auto' }}>
											{this.props.children}
										</div>
									}
								</Content>
							</Layout>
						</Layout>
					</>
					:
					null
				}
			</>
		);
	}
}
export default withRouter(SiderDemo);
