import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm, AutoComplete, TimePicker } from 'antd';
import store from "../store/store.js";
import { PlusOutlined } from '@ant-design/icons'
import moment from 'moment';
import Chxz from '../public/chxz.js';
import Chjh from '../public/chjh.js';
import KhxxEdit from '../khxx/add.js';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                now: new Date()
            },
            list: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value, Option) {
        let that = this
        console.log(name, value, Option)
        let data = Object.assign({}, that.state.condition, { [name]: value })
        // console.log(data)
        that.setState({
            condition: data
        })
        // return
        setTimeout(function () {
            if (name == 'chid') {
                let data2 = Object.assign({}, that.state.condition, {
                    cp: Option.key ? that.state.chid_react[Option.key]['cp'] : null,
                    sblx: Option.key ? that.state.chid_react[Option.key]['sblx'] : null,
                    gg: Option.key ? that.state.chid_react[Option.key]['gg'] : null,
                    sj1: Option.key ? that.state.chid_react[Option.key]['sj1'] : null,
                    sj2: Option.key ? that.state.chid_react[Option.key]['sj2'] : null,
                })
                that.setState({
                    condition: data2
                })
            } else if (name == 'sgbw') {
                that.getSgbw(value)
            } else if (name == 'jhid') {
                that.getXmjh(value)
            } else if (name == "sfbljs") {
                if(value == 1){
                    data = Object.assign({}, data, { jsfl: data.jsfl ? data.jsfl : data.fl, jsdj: data.jsdj ? data.jsdj : data.dj, jsje: data.jsje ? data.jsje : data.je })
                    that.jisuan1(data,0)
                }else if(value == 2){
                    let data2 = Object.assign({}, that.state.condition, {
                        jsrq:null,
                    })
                    that.setState({
                        condition: data2
                    })
                }
            }
        })
    }
    getSgbw = (name) => {
        let that = this
        fetch('/Common/getSgbw2', {
            method: "POST",
            body: JSON.stringify({
                name: name
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.setState({
                    sgbw_react: data.sgbw_react ? data.sgbw_react : [],
                })
            })
    }
    getXmjh = (id) => {
        let that = this
        fetch('/Xmjh/view', {
            method: "POST",
            body: JSON.stringify({
                id: id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                let data2 = Object.assign({}, that.state.condition, {
                    jhmc: data.data.jhmc,
                    scrq: data.data.scrq,
                    projectid: data.data.projectid,
                    scsj: data.data.scsj,
                    jhfl: data.data.jhfl,
                    // chid: data.data.chid,
                    khid: data.data.khid,
                    // cp: data.data.cp,
                    // sblx: data.data.sblx,
                    // sj1: data.data.sj1,
                    // sj2: data.data.sj2,
                    sgdwid: data.data.sgdwid,
                    hntjbzid: data.data.hntjbzid,
                    beizhu2: data.data.beizhu,
                })
                that.setState({
                    condition: data2
                })
            })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        // 工资放量默认等于前面手动录入的方量
        // 有一种特殊情况，录入的方量小于等于60时，工资方量统一为60
        // 大于等于60时 ，方量填多少，工资放量就是多少
        // 金额等于工资方量乘以单价
        // 【结算金额】=【结算方量】 乘以 【结算单价】
        // 【结算方量差异】= 【结算方量】 减去 【方量】
        // 【结算单价差异】= 【结算单价】 减去 【单价】
        // 【结算金额差异】= 【结算金额】 减去 【金额】
        setTimeout(function () {
            if (name == 'fl' || name == 'dj') {
                that.jisuan1(data,1)
            }else if (name == 'je' || name == 'jsfl' || name == 'jsdj' || name == 'jsje') {
                that.jisuan1(data,0)
            }else if (name == 'wzfl' || name == 'wzdj') {
                // 外租方量
                var wzfl = data.wzfl ? data.wzfl : 0
                // 外租单价
                var wzdj = data.wzdj ? data.wzdj : 0
                // 外租金额
                var wzje = (Number(wzfl) * Number(wzdj)).toFixed(2)
                let data2 = Object.assign({}, that.state.condition, { wzfl: wzfl, wzdj: wzdj, wzje: wzje })
                that.setState({
                    condition: data2
                })
            }
        })
    }
    jisuan1 = (data,aa) => {
        console.log(data)
        let that = this
        if (data.fl <= 60 && data.fl > 0) {
            var gzfl = 60
        } else {
            var gzfl = data.fl
        }
        //单价
        let dj = data.dj ? data.dj : 0
        // 方量
        let fl = data.fl ? data.fl : 0
        // 金额
        if(aa == 1){
            var je = (Number(fl) * Number(dj)).toFixed(2)
        }else{
            var je = data.je ? data.je : 0
        }
        // 结算方量
        let jsfl = data.jsfl ? data.jsfl : 0
        // 结算单价
        let jsdj = data.jsdj ? data.jsdj : 0

        // 结算金额
        // var jsje = (Number(jsfl) * Number(jsdj)).toFixed(2)
        var jsje = data.jsje ? data.jsje : 0
        // 结算方量差异
        var jsflcy = (Number(jsfl) - Number(fl)).toFixed(2)
        // 结算单价差异
        var jsdjcy = (Number(jsdj) - Number(dj)).toFixed(2)
        // 结算金额差异
        var jsjecy = (Number(jsje) - Number(je)).toFixed(2)
        let data2 = Object.assign({}, data, { gzfl: gzfl, je: je, jsje: jsje, jsjecy: jsjecy, jsflcy: jsflcy, jsdjcy: jsdjcy })
        that.setState({
            condition: data2
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
            if (that.props.notable != 1) {
                that.getdata2()
            }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                    now: new Date()
                },
                loading: false
            })
        }
    }
    getdata = (arrname, e) => {
        let that = this
        fetch('/Mrtjjl/view', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                if (arrname) {
                    that.setState({
                        [arrname]: data[arrname] ? data[arrname] : [],
                    })
                    return
                }
                if (this.state.condition.id) {
                    that.setState({
                        condition: data.data,
                    })
                }
                that.setState({
                    chid_react: data.chid_react ? data.chid_react : [],
                    projectid_react: data.projectid_react ? data.projectid_react : [],
                    sgdwid_react: data.sgdwid_react ? data.sgdwid_react : [],
                    sbzldw_react: data.sbzldw_react ? data.sbzldw_react : [],
                    sj_react: data.sj_react ? data.sj_react : [],
                    // sgbw_react: data.sgbw_react ? data.sgbw_react : [],
                    xpsfqq_react: data.xpsfqq_react ? data.xpsfqq_react : [],
                    sfbljs_react: data.sfbljs_react ? data.sfbljs_react : [],
                    sfkjfp_react: data.sfkjfp_react ? data.sfkjfp_react : [],
                    jscy_react: data.jscy_react ? data.jscy_react : [],
                    hntjbzid_react: data.hntjbzid_react ? data.hntjbzid_react : [],
                    bh_react: data.bh_react ? data.bh_react : [],
                    sbly_react: data.sbly_react ? data.sbly_react : [],
                    sblx_react: data.sblx_react ? data.sblx_react : [],
                    wdbdw_react: data.wdbdw_react ? data.wdbdw_react : [],
                    jkzt_react: data.jkzt_react ? data.jkzt_react : [],
                })
            })
    }
    sub(isnext = 0, e) {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Mrtjjl/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    if (isnext == 1) {
                        that.nextAdd()
                    } else {
                        this.mdchange()
                    }
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    getdata2(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        fetch('/Mrtjjl/index', {
            // post提交
            method: "POST",
            body: JSON.stringify({
                limit: 3
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count)
                })
            })
    }
    //继续添加
    nextAdd = (e) => {
        let that = this
        that.setState({
            condition: {
                id: 0,
            },
            list: [],
            loading: false
        })
        that.getdata2()
    }
    addItem_projectid = () => {
        let that = this
        fetch('/Project/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, projectid: data.projectid ? data.projectid : null })
                    this.setState({
                        condition: condition,
                        projectid_react: data.projectid_react ? data.projectid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_sgdwid = () => {
        let that = this
        fetch('/Sgdw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, sgdwid: data.sgdwid ? data.sgdwid : null })
                    this.setState({
                        condition: condition,
                        sgdwid_react: data.sgdwid_react ? data.sgdwid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_hntjbzid = () => {
        let that = this
        fetch('/Hntjbz/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, hntjbzid: data.hntjbzid ? data.hntjbzid : null })
                    this.setState({
                        condition: condition,
                        hntjbzid_react: data.hntjbzid_react ? data.hntjbzid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_bh = () => {
        let that = this
        fetch('/Bh/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, bh: data.bh ? data.bh : null })
                    this.setState({
                        condition: condition,
                        bh_react: data.bh_react ? data.bh_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_wdbdw = () => {
        let that = this
        fetch('/Wdbdw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, wdbdw: data.wdbdw ? data.wdbdw : null })
                    this.setState({
                        condition: condition,
                        wdbdw_react: data.wdbdw_react ? data.wdbdw_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    clearNewField = (open) => {
        let that = this
        setTimeout(() => {
            let condition = Object.assign({}, that.state.condition, { newfield: null })
            that.setState({
                condition: condition,
            })
        });
    }
    render() {
        const columns = [{
            title: '序号',
            dataIndex: 'xh',
            key: 'xh',
            align: "center",
            width: 60,
            fixed: 'left',
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '创建日期',
            dataIndex: 'addtime_str',
            key: 'addtime_str',
            fixed: 'left',
            width: 100,
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '结算日期',
            dataIndex: 'jsrq_str',
            key: 'jsrq',
            width: 100,
            fixed: 'left',
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '生产日期',
            dataIndex: 'scrq_str',
            key: 'scrq_str',
            width: 100,
            fixed: 'left',
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '客户名称',
            dataIndex: 'sbzldw_str',
            key: 'sbzldw_str',
            fixed: 'left',
            ellipsis: {
                showTitle: false,
            },
            width:200,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '计划名称',
            dataIndex: 'jhmc',
            key: 'jhmc',
            fixed: 'left',
            width: 100,
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '项目名称',
            dataIndex: 'xmmc',
            key: 'xmmc',
            fixed: 'left',
            ellipsis: {
                showTitle: false,
            },
            width:200,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '计划方量',
            dataIndex: 'jhfl',
            key: 'jhfl',
            width: 80,
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '设备来源',
            dataIndex: 'type_str',
            key: 'type_str',
            width: 80,
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '车牌',
            dataIndex: 'cp',
            key: 'cp',
            width: 90,
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '类型',
            dataIndex: 'sblx',
            key: 'sblx',
            ellipsis: {
                showTitle: false,
            },
            width: 60,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '方量',
            dataIndex: 'fl',
            key: 'fl',
            ellipsis: {
                showTitle: false,
            },
            width: 80,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '单价',
            dataIndex: 'dj',
            key: 'dj',
            ellipsis: {
                showTitle: false,
            },
            width: 60,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '金额',
            dataIndex: 'je',
            key: 'je',
            ellipsis: {
                showTitle: false,
            },
            width: 100,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '结算方量',
            dataIndex: 'jsfl',
            key: 'jsfl',
            ellipsis: {
                showTitle: false,
            },
            width: 80,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '砂浆',
            dataIndex: 'sj',
            key: 'sj',
            ellipsis: {
                showTitle: false,
            },
            width: 60,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '台班',
            dataIndex: 'tb',
            key: 'tb',
            ellipsis: {
                showTitle: false,
            },
            width: 60,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '司机一',
            dataIndex: 'sj1_str',
            key: 'sj1_str',
            ellipsis: {
                showTitle: false,
            },
            width: 80,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '司机二',
            dataIndex: 'sj2_str',
            key: 'sj2_str',
            ellipsis: {
                showTitle: false,
            },
            width: 80,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '工资方量',
            dataIndex: 'gzfl',
            key: 'gzfl',
            width: 80,
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '外租泵单位',
            dataIndex: 'wdbdw',
            key: 'wdbdw',
            ellipsis: {
                showTitle: false,
            },
            width:200,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '类型',
            dataIndex: 'wdsblx_str',
            key: 'wdsblx_str',
            ellipsis: {
                showTitle: false,
            },
            width: 60,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '外租泵回票日期',
            dataIndex: 'wzbhprq_str',
            key: 'wzbhprq_str',
            ellipsis: {
                showTitle: false,
            },
            width: 120,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '结款状态',
            dataIndex: 'jkzt_str',
            key: 'jkzt_str',
            ellipsis: {
                showTitle: false,
            },
            width: 80,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '外租方量',
            dataIndex: 'wzfl',
            key: 'wzfl',
            ellipsis: {
                showTitle: false,
            },
            width: 80,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '外租台班',
            dataIndex: 'wztb',
            key: 'wztb',
            ellipsis: {
                showTitle: false,
            },
            width: 80,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '施工单位',
            dataIndex: 'sgdw_str',
            key: 'sgdw_str',
            ellipsis: {
                showTitle: false,
            },
            width:200,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '混凝土搅拌站',
            dataIndex: 'hntjbz_str',
            key: 'hntjbz_str',
            ellipsis: {
                showTitle: false,
            },
            width:200,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '外租单价',
            dataIndex: 'wzdj',
            key: 'wzdj',
            ellipsis: {
                showTitle: false,
            },
            width: 80,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },

        {
            title: '外租金额',
            dataIndex: 'wzje',
            key: 'wzje',
            ellipsis: {
                showTitle: false,
            },
            width: 80,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '结算单价',
            dataIndex: 'jsdj',
            key: 'jsdj',
            ellipsis: {
                showTitle: false,
            },
            width: 80,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '结算金额',
            dataIndex: 'jsje',
            key: 'jsje',
            ellipsis: {
                showTitle: false,
            },
            width: 100,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '结算方量差异',
            dataIndex: 'jsflcy',
            key: 'jsflcy',
            ellipsis: {
                showTitle: false,
            },
            width: 120,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '结算单价差异',
            dataIndex: 'jsdjcy',
            key: 'jsdjcy',
            ellipsis: {
                showTitle: false,
            },
            width: 110,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '结算金额差异',
            dataIndex: 'jsjecy',
            key: 'jsjecy',
            ellipsis: {
                showTitle: false,
            },
            width: 110,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '结算差异',
            dataIndex: 'jscy_str',
            key: 'jscy_str',
            ellipsis: {
                showTitle: false,
            },
            width: 80,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '备注',
            dataIndex: 'beizhu',
            key: 'beizhu',
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        }]
        const { tableloading } = this.state;
        return (
            <>
                {this.state.condition.id ? <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx1 ? (store.getState().data.info.qx1 == 1 ? '编辑' : '查看') : '查看'}</a> : <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>}
                <Modal maskClosable={false} title="编辑" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx1 ? (store.getState().data.info.qx1 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={8}>
                                <Form.Item
                                    label="创建日期"
                                    required={true}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'addtime')} value={this.state.condition.addtime ? moment(this.state.condition.addtime) : moment(this.state.condition.now)} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="生产计划" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="计划名称"
                                    required={true}
                                >
                                    <Input.Group compact>
                                        <Input placeholder="请输入" value={this.state.condition.jhmc ? this.state.condition.jhmc : null} onChange={this.onChange.bind(this, 'jhmc')}  readOnly={this.state.condition.jhid ? false : true} style={{ width: 'calc(100% - 117px)' }} />
                                        <Chjh value={this.state.condition.jhid ? this.state.condition.jhid : null} allowClear onChange={this.onSelChange.bind(this)} chid_react={this.state.chid_react ? this.state.chid_react : []} />
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={<Space><>客户</> {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ? <KhxxEdit type="link" refresh={this.getdata.bind(this, 'sbzldw_react')} /> : null) : null} </Space>}
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.khid ? this.state.condition.khid : null} allowClear onChange={this.onSelChange.bind(this, 'khid')}>
                                        {this.arrsel(this.state.sbzldw_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="生产日期"
                                    required={true}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'scrq')} value={this.state.condition.scrq ? moment(this.state.condition.scrq) : null} readOnly={this.state.condition.jhid ? false : true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="项目名称"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.projectid ? this.state.condition.projectid : null}
                                        onChange={this.onSelChange.bind(this, 'projectid')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_projectid.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.projectid_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="生产时间"
                                // required={true}
                                >
                                    <TimePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'scsj')} value={this.state.condition.scsj ? moment(this.state.condition.scsj, 'HH:mm') : null} format={'HH:mm'} readOnly={this.state.condition.jhid ? false : true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="计划方量"
                                // required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jhfl ? this.state.condition.jhfl : null} onChange={this.onChange.bind(this, 'jhfl')}  disabled={true} />
                                </Form.Item>
                            </Col>
                            {/* <Col span={8}>
                                <Form.Item
                                    label="车辆编号"
                                >
                                    <Input.Group compact>
                                        <Select placeholder="请选择"  showSearch optionFilterProp="children" value={this.state.condition.chid ? this.state.condition.chid : null} allowClear onChange={this.onSelChange.bind(this, 'chid')} style={{ width: 'calc(100% - 89px)' }} disabled={true} >
                                            {this.arrsel(this.state.chid_react)}
                                        </Select>
                                        <Chxz value={this.state.condition.chid ? this.state.condition.chid : null} allowClear onChange={this.onSelChange.bind(this)} chid_react={this.state.chid_react ? this.state.chid_react : []} readOnly={this.state.condition.jhid ? false : true} />
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="车牌"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.cp ? this.state.condition.cp : null} onChange={this.onChange.bind(this, 'cp')}  disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="类型"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.sblx ? this.state.condition.sblx : null} onChange={this.onChange.bind(this, 'sblx')}  disabled={true} />
                                </Form.Item>
                            </Col> */}
                            <Col span={8}>
                                <Form.Item
                                    label="施工单位"
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.sgdwid ? this.state.condition.sgdwid : null}
                                        onChange={this.onSelChange.bind(this, 'sgdwid')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_sgdwid.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.sgdwid_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="混凝土搅拌站"
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.hntjbzid ? this.state.condition.hntjbzid : null}
                                        onChange={this.onSelChange.bind(this, 'hntjbzid')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_hntjbzid.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.hntjbzid_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="备注"
                                // required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.beizhu2 ? this.state.condition.beizhu2 : null} onChange={this.onChange.bind(this, 'beizhu2')}  disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="工程信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            {/* <Col span={8}>
                                <Form.Item
                                    label={<Space><>客户</> {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ? <KhxxEdit type="link" refresh={this.getdata.bind(this, 'sbzldw_react')} /> : null) : null} </Space>}
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.sbzldw ? this.state.condition.sbzldw : null} allowClear onChange={this.onSelChange.bind(this, 'sbzldw')}>
                                        {this.arrsel(this.state.sbzldw_react)}
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            <Col span={8}>
                                <Form.Item
                                    label="施工部位"
                                // required={true}
                                >
                                    <AutoComplete
                                        options={this.state.sgbw_react ? this.state.sgbw_react : []}
                                        allowClear onChange={this.onSelChange.bind(this, 'sgbw')}
                                        placeholder="请输入"
                                        value={this.state.condition.sgbw ? this.state.condition.sgbw : null}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="标号"
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.bh ? this.state.condition.bh : null}
                                        onChange={this.onSelChange.bind(this, 'bh')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_bh.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.bh_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="方量"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.fl ? this.state.condition.fl : null} onChange={this.onChange.bind(this, 'fl')}  />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="单价"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.dj ? this.state.condition.dj : null} onChange={this.onChange.bind(this, 'dj')}  suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="台班"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.tb ? this.state.condition.tb : null} onChange={this.onChange.bind(this, 'tb')}  />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="工资方量"
                                    tooltip={<>【方量】小于等于60时，【工资方量】为60。<br />【方量】大于60时 ，【工资方量】等于【方量】。</>}
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.gzfl ? this.state.condition.gzfl : null} onChange={this.onChange.bind(this, 'gzfl')}  disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="砂浆"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.sj ? this.state.condition.sj : null} onChange={this.onChange.bind(this, 'sj')}  type="text" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="金额"
                                    tooltip="【方量】乘以【单价】"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.je ? this.state.condition.je : 0} onChange={this.onChange.bind(this, 'je')}  />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="设备来源" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="设备来源"
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.type ? this.state.condition.type : null} allowClear onChange={this.onSelChange.bind(this, 'type')}  >
                                        {this.arrsel(this.state.sbly_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {this.state.condition.type == 1 ?
                                <>
                                    <Col span={8}>
                                        <Form.Item
                                            label="车辆编号"
                                        >
                                            <Input.Group compact>
                                                <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.chid ? this.state.condition.chid : null} allowClear onChange={this.onSelChange.bind(this, 'chid')} style={{ width: 'calc(100% - 89px)' }} disabled={true} >
                                                    {this.arrsel(this.state.chid_react)}
                                                </Select>
                                                <Chxz value={this.state.condition.chid ? this.state.condition.chid : null} allowClear onChange={this.onSelChange.bind(this)} chid_react={this.state.chid_react ? this.state.chid_react : []} readOnly={this.state.condition.jhid ? false : true} />
                                            </Input.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="车牌"
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.cp ? this.state.condition.cp : null} onChange={this.onChange.bind(this, 'cp')}  disabled={true} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="类型"
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.sblx ? this.state.condition.sblx : null} onChange={this.onChange.bind(this, 'sblx')}  disabled={true} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="规格"
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.gg ? this.state.condition.gg : null} onChange={this.onChange.bind(this, 'gg')}  disabled={true} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="司机一"
                                            required={true}
                                        >
                                            <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.sj1 ? this.state.condition.sj1 : null} allowClear onChange={this.onSelChange.bind(this, 'sj1')}>
                                                {this.arrsel(this.state.sj_react)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="司机二"
                                        >
                                            <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.sj2 ? this.state.condition.sj2 : null} allowClear onChange={this.onSelChange.bind(this, 'sj2')}>
                                                {this.arrsel(this.state.sj_react)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </>
                                : null
                            }
                            {this.state.condition.type == 2 ?
                                <>
                                    <Col span={8}>
                                        <Form.Item
                                            label="外租泵单位"
                                        >
                                            {/* <Input placeholder="请输入" value={this.state.condition.wdbdw ? this.state.condition.wdbdw : null} onChange={this.onChange.bind(this, 'wdbdw')}  /> */}
                                            <Select
                                                placeholder="请选择" showSearch optionFilterProp="children"
                                                value={this.state.condition.wdbdw ? this.state.condition.wdbdw : null}
                                                onChange={this.onSelChange.bind(this, 'wdbdw')}
                                                onDropdownVisibleChange={this.clearNewField.bind(this)}
                                                dropdownRender={(menu) => (
                                                    <>
                                                        {menu}
                                                        {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                            <>
                                                                <Divider
                                                                    style={{
                                                                        margin: '8px 0',
                                                                    }}
                                                                />
                                                                <Space
                                                                    style={{
                                                                        padding: '0 8px 4px',
                                                                    }}
                                                                >
                                                                    <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                                    <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_wdbdw.bind(this)}>
                                                                        创建并选择
                                                                    </Button>
                                                                </Space>
                                                            </>
                                                            : null) : null}
                                                    </>
                                                )}
                                            >
                                                {this.arrsel(this.state.wdbdw_react)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="类型"
                                        >
                                            <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.wdsblx ? this.state.condition.wdsblx : null} allowClear onChange={this.onSelChange.bind(this, 'wdsblx')}>
                                                {this.arrsel(this.state.sblx_react)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="规格"
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.wdgg ? this.state.condition.wdgg : null} onChange={this.onChange.bind(this, 'wdgg')}  />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="租赁日期"
                                        >
                                            <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'zlrq')} value={this.state.condition.zlrq ? moment(this.state.condition.zlrq) : null} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="外租方量"
                                        >
                                            <Input type="text" placeholder="请输入" value={this.state.condition.wzfl ? this.state.condition.wzfl : null} onChange={this.onChange.bind(this, 'wzfl')}  />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="外租单价"
                                            required={true}
                                        >
                                            <Input type="text" placeholder="请输入" value={this.state.condition.wzdj ? this.state.condition.wzdj : null} onChange={this.onChange.bind(this, 'wzdj')}  suffix="元" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="外租台班"
                                        >
                                            <Input type="text" placeholder="请输入" value={this.state.condition.wztb ? this.state.condition.wztb : null} onChange={this.onChange.bind(this, 'wztb')}  />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="外租金额"
                                            tooltip="【外租单价】乘以【外租方量】"
                                        >
                                            <Input type="text" placeholder="请输入" value={this.state.condition.wzje ? this.state.condition.wzje : null} onChange={this.onChange.bind(this, 'wzje')}  suffix="元" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="外租泵回票日期"
                                        >
                                            <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'wzbhprq')} value={this.state.condition.wzbhprq ? moment(this.state.condition.wzbhprq) : null} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="结款状态"
                                        >
                                            <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.jkzt ? this.state.condition.jkzt : null} allowClear onChange={this.onSelChange.bind(this, 'jkzt')}  >
                                                {this.arrsel(this.state.jkzt_react)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="是否办理结算"
                                        >
                                            <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.wz_sfbljs ? this.state.condition.wz_sfbljs : null} allowClear={false} onChange={this.onSelChange.bind(this, 'wz_sfbljs')} >
                                                {this.arrsel(this.state.sfbljs_react)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="结算日期"
                                            required={this.state.condition.wz_sfbljs == 1 ? true : false}
                                        >
                                            <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'wz_jsrq')} value={this.state.condition.wz_jsrq ? moment(this.state.condition.wz_jsrq) : null} disabled={this.state.condition.wz_sfbljs != 1 ? true : false} />
                                        </Form.Item>
                                    </Col>
                                </>
                                : null
                            }
                            <Col span={24}>
                                <Alert type="info" message="结算" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="是否办理结算"
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.sfbljs ? this.state.condition.sfbljs : null} allowClear={false} onChange={this.onSelChange.bind(this, 'sfbljs')} >
                                        {this.arrsel(this.state.sfbljs_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="结算日期"
                                    required={this.state.condition.sfbljs == 1 ? true : false}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'jsrq')} value={this.state.condition.jsrq ? moment(this.state.condition.jsrq) : null} disabled={this.state.condition.sfbljs != 1 ? true : false} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="结算方量"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jsfl ? this.state.condition.jsfl : null} onChange={this.onChange.bind(this, 'jsfl')}  />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="结算单价"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jsdj ? this.state.condition.jsdj : null} onChange={this.onChange.bind(this, 'jsdj')}  type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            {/* <Col span={8}>
                                <Form.Item
                                    label="结算金额"
                                    tooltip="【结算方量】 乘以 【结算单价】"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jsje ? this.state.condition.jsje : 0} onChange={this.onChange.bind(this, 'jsje')}  disabled={true} />
                                </Form.Item>
                            </Col> */}
                            <Col span={8}>
                                <Form.Item
                                    label="结算金额"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jsje ? this.state.condition.jsje : 0} onChange={this.onChange.bind(this, 'jsje')}  />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="结算方量差异"
                                    tooltip="【结算方量】 减去 【方量】"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jsflcy ? this.state.condition.jsflcy : 0} onChange={this.onChange.bind(this, 'jsflcy')}  disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="结算单价差异"
                                    tooltip="【结算单价】 减去 【单价】"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jsdjcy ? this.state.condition.jsdjcy : 0} onChange={this.onChange.bind(this, 'jsdjcy')}  disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="结算金额差异"
                                    tooltip="【结算金额】 减去 【金额】"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jsjecy ? this.state.condition.jsjecy : 0} onChange={this.onChange.bind(this, 'jsjecy')}  disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="结算差异"
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.jscy ? this.state.condition.jscy : null} allowClear onChange={this.onSelChange.bind(this, 'jscy')}>
                                        {this.arrsel(this.state.jscy_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="备注"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.beizhu ? this.state.condition.beizhu : null} onChange={this.onChange.bind(this, 'beizhu')}  />
                                </Form.Item>
                            </Col>
                            {this.props.nolast == 1 ? null :
                                <>
                                    <Col span={24}>
                                        <Divider orientation="left" style={{ marginTop: 0 }}>最近添加信息</Divider>
                                    </Col>
                                    <Col span={24}>
                                        <Table
                                            bordered
                                            columns={columns}
                                            sticky={false}
                                            dataSource={this.state.list}
                                            pagination={false}
                                            loading={tableloading}
                                            // rowSelection={rowSelection}
                                            scroll={{ x: 3200, }}
                                            className='table'
                                            size="small"
                                        />
                                    </Col>
                                </>}
                        </Row>
                        {(store.getState().data.info.qx1 || store.getState().data.info.qx2) ? ((store.getState().data.info.qx1 == 1 || store.getState().data.info.qx2 == 1) ?
                            <Row justify="center">
                                <Space>
                                    <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this)}>完成</Button>
                                    {!this.state.condition.id ?
                                        <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this, '1')}>完成并继续添加下一条</Button>
                                        : null}
                                </Space>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}