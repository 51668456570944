import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip,DatePicker } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: props.xmmc ? props.xmmc.map(Number) : [],
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            selectedRowKeys: nextProps.xmmc ? nextProps.xmmc.map(Number) : [],
        })
    }
    componentDidMount() {
        // this.getdata(1)

    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata(1)
        } else {
            that.setState({
                newcondition: {
                    year: new Date().getFullYear().toString(),
                },
                oldcondition: {},
                list: [],
                tableloading: false,
                // selectedRowKeys: [],
                count: 0,
                downloading: false,
            })
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, { [name + '1']: dateStrings[0], [name + '2']: dateStrings[1] })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        arr.noqx = 1
        arr.chooseid = that.props.xmmc
        fetch('Mrtjjl/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    sfbljs_react: data.sfbljs_react ? data.sfbljs_react : [],
                    month_react: data.month_react ? data.month_react : [],
                    sbzldw_react: data.sbzldw_react ? data.sbzldw_react : [],
                    jhid_react: data.jhid_react ? data.jhid_react : [],
                    projectid_react: data.projectid_react ? data.projectid_react : [],
                    chid_react: data.chid_react ? data.chid_react : [],
                    sgdwid_react: data.sgdwid_react ? data.sgdwid_react : [],
                    hntjbzid_react: data.hntjbzid_react ? data.hntjbzid_react : [],
                    sj_react: data.sj_react ? data.sj_react : [],
                    sbly_react: data.sbly_react ? data.sbly_react : [],
                })
            })
    }
    chooseCh = (value, e) => {
        let that = this
        let arr = that.state.selectedRowKeys
        that.props.onChange(arr)
        that.mdchange()
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
            },
            oldcondition: {},
            selectedRowKeys: this.props.xmmc ? this.props.xmmc.map(Number) : [],
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    render() {
        const columns = [
            {
                title: '生产日期',
                dataIndex: 'scrq_str',
                key: 'scrq_str',
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车牌',
                dataIndex: 'cp',
                key: 'cp',
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '计划名称',
                dataIndex: 'jhmc',
                key: 'jhmc',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            
            {
                title: '项目名称',
                dataIndex: 'xmmc',
                key: 'xmmc',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '方量',
                dataIndex: 'fl',
                key: 'fl',
                ellipsis: {
                    showTitle: false,
                },
                width:80,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '生产时间',
                dataIndex: 'scsj',
                key: 'scsj',
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '客户',
                dataIndex: 'sbzldw_str',
                key: 'sbzldw_str',
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
           
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll: true,
            preserveSelectedRowKeys: false,
            // renderCell: function (checked, record, index, originNode) {
            //     if (record.status != 1) {
            //         return originNode
            //     } else {
            //         return false
            //     }
            // },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <a onClick={this.mdchange.bind(this)} readOnly={this.props.readOnly}>选择生产名称</a>
                <Modal maskClosable={false} title="选择生产名称" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <div className='sellist'>
                        <Row justify="space-between">
                            <Space wrap>
                                <DatePicker style={{ width: '100%' }} placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null} allowClear={false} />
                                <Select placeholder="月份" style={{ width: 120 }} value={this.state.newcondition.month ? this.state.newcondition.month : 0} onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                    {this.arrsel(this.state.month_react)}
                                </Select>
                                <Select placeholder="是否办理结算" value={this.state.newcondition.sfbljs ? this.state.newcondition.sfbljs : null} onChange={this.onSelChange.bind(this, 'sfbljs')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                    {this.arrsel(this.state.sfbljs_react)}
                                </Select>
                                <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'jsrq')} value={[(this.state.newcondition.jsrq1 ? moment(this.state.newcondition.jsrq1) : null), (this.state.newcondition.jsrq2 ? moment(this.state.newcondition.jsrq2) : null)]} placeholder={['结算日期', '结算日期']} />
                                <Select placeholder="客户" value={this.state.newcondition.sbzldw ? this.state.newcondition.sbzldw : null} onChange={this.onSelChange.bind(this, 'sbzldw')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                    {this.arrsel(this.state.sbzldw_react)}
                                </Select>
                                <Select placeholder="计划名称" value={this.state.newcondition.jhid ? this.state.newcondition.jhid : null} onChange={this.onSelChange.bind(this, 'jhid')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                    {this.arrsel(this.state.jhid_react)}
                                </Select>
                                <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'scrq')} value={[(this.state.newcondition.scrq1 ? moment(this.state.newcondition.scrq1) : null), (this.state.newcondition.scrq2 ? moment(this.state.newcondition.scrq2) : null)]} placeholder={['生产日期', '生产日期']} />
                                <Select placeholder="项目名称" value={this.state.newcondition.projectid ? this.state.newcondition.projectid : null} onChange={this.onSelChange.bind(this, 'projectid')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                    {this.arrsel(this.state.projectid_react)}
                                </Select>
                                <Select placeholder="车辆编号" value={this.state.newcondition.chid ? this.state.newcondition.chid : null} onChange={this.onSelChange.bind(this, 'chid')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                    {this.arrsel(this.state.chid_react)}
                                </Select>
                                <Select placeholder="设备来源" value={this.state.newcondition.type ? this.state.newcondition.type : null} onChange={this.onSelChange.bind(this, 'type')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                    {this.arrsel(this.state.sbly_react)}
                                </Select>
                                <Select placeholder="施工单位" value={this.state.newcondition.sgdwid ? this.state.newcondition.sgdwid : null} onChange={this.onSelChange.bind(this, 'sgdwid')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                    {this.arrsel(this.state.sgdwid_react)}
                                </Select>
                                <Select placeholder="混凝土搅拌站" value={this.state.newcondition.hntjbzid ? this.state.newcondition.hntjbzid : null} onChange={this.onSelChange.bind(this, 'hntjbzid')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                    {this.arrsel(this.state.hntjbzid_react)}
                                </Select>
                                <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                                <Button type="primary" onClick={this.chooseCh.bind(this)} >选择</Button>
                            </Space>
                        </Row>
                    </div>
                    <Table
                        bordered
                        columns={columns}
                        sticky={false}
                        dataSource={this.state.list}
                        pagination={false}
                        loading={tableloading}
                        rowSelection={rowSelection}
                        scroll={{ y: 600 }}
                        className='table'
                        size="small"
                    />
                    <div className='page'>
                        <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                    </div>
                </Modal>
            </>
        )
    }
}
