import React from 'react';
import {Redirect, Link, withRouter} from 'react-router-dom';
import {
    Switch,
    Space,
    Select,
    Input,
    Button,
    Table,
    Modal,
    Row,
    Col,
    Form,
    message,
    Checkbox,
    Radio,
    Pagination,
    Tag,
    Alert,
    DatePicker,
    Divider,
    Tooltip,
    Popconfirm,
    TimePicker
} from 'antd';
import store from "../store/store.js";
import {PlusOutlined, DeleteOutlined} from '@ant-design/icons'
import moment from 'moment';

const {Option} = Select;
const {TextArea} = Input;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {

            },
            list: [], visible: false, loading: false
        };
    }

    componentDidMount() {
        let that = this
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, {[name]: value})
        this.setState({
            condition: data
        })
    }

    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {

                }, loading: false
            })
        }
    }

    getdata = (arrname, e) => {
        let that = this
        fetch('/Jcxstj/qcview', {
            method: "POST", body: JSON.stringify({
                type: that.props.type, hid: that.props.hid, jhdwid: that.props.jhdwid, ysdwid: that.props.ysdwid, year: that.props.year,
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    condition: data.data,
                })
            })
    }

    sub(isnext = 0, e) {
        let that = this
        that.setState({
            loading: true
        })
        let arr = that.state.condition
        arr.type = that.props.type
        arr.hid = that.props.hid
        arr.jhdwid = that.props.jhdwid
        arr.ysdwid = that.props.ysdwid
        arr.year = that.props.year
        fetch('/Jcxstj/doqcview', {
            method: "POST", body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }

    render() {
        return (<>
            <a href='javascript:void(0)' onClick={this.mdchange.bind(this)}>{this.props.num}</a>
            <Modal maskClosable={false} title="编辑" width={400} open={this.state.visible}
                   onCancel={this.mdchange.bind(this)} footer={null}>
                <Form
                    layout="vertical"
                    // labelCol={{ span: 2 }}
                    wrapperCol={{span: 24}}
                    disabled={store.getState().data.info.qx31 ? (store.getState().data.info.qx31 == 1 ? false : true) : true}
                >
                    <Row gutter={[20, 0]} justify="start" align="top">
                        <Col span={24}>
                            <Form.Item
                                label="期初"
                            >
                                <Input placeholder="请输入"
                                       value={this.state.condition.qc ? this.state.condition.qc : null}
                                       onChange={this.onChange.bind(this, 'qc')} min={0} type="text" suffix="元"/>
                            </Form.Item>
                        </Col>

                    </Row>
                    {store.getState().data.info.qx31 ? (store.getState().data.info.qx31 == 1 ? <Row justify="center">
                        <Space>
                            <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this)}>完成</Button>
                        </Space>
                    </Row> : null) : null}
                </Form>
            </Modal>
        </>)
    }
}
