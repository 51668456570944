import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip, DatePicker } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
import Add from './tzadd.js';
const { Option } = Select;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {

            },
            oldcondition: {},
            list: [],
            tableloading: false,
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({

        })
    }
    componentDidMount() {
        // this.getdata(1)

    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata(1)
        } else {
            that.setState({
                newcondition: {

                },
                oldcondition: {},
                list: [],
                tableloading: false,
                // selectedRowKeys: [],
                count: 0,
                downloading: false,
            })
            that.props.refresh()
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, { [name + '1']: dateStrings[0], [name + '2']: dateStrings[1] })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        arr.year = that.props.year
        arr.kid = that.props.kid
        arr.qid = that.props.qid
        arr.type = that.props.type
        fetch('/Yszkmx/tjlist', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    // count: Number(data.count),
                })
            })
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    delfk(id, e) {
        let that = this
        fetch('/Yszkmx/deltj', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.getdata(1, null)
            })
    }
    render() {
        const columns = [
            {
                title: '调整日期',
                dataIndex: 'tjtime_str',
                key: 'tjtime_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '调整金额',
                dataIndex: 'tzje',
                key: 'tzje',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '调整方式',
                dataIndex: 'tzfs',
                key: 'tzfs',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '调整说明',
                dataIndex: 'tzsm',
                key: 'tzsm',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '备注',
                dataIndex: 'bz',
                key: 'bz',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作人',
                dataIndex: 'aidname',
                key: 'aidname',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={record.updatetime}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'caozuo',
                key: 'caozuo',
                // fixed: 'left',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Space>
                        <Add id={record.id} year={this.props.year} kid={this.props.kid} qid={this.props.qid} type={this.props.type} refresh={this.getdata.bind(this)}  />
                        {store.getState().data.info.qx9 ? (store.getState().data.info.qx9 == 1 ?
                            <Popconfirm
                                title="确定删除?"
                                onConfirm={this.delfk.bind(this, record.id)}
                                okText="确定"
                                cancelText="取消"
                            >
                                <a href="#" className="hongse">删除</a>
                            </Popconfirm>
                            : null) : null}
                    </Space>
                ),
            },

        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // dwideSelectAll: true,
            preserveSelectedRowKeys: false,
            // renderCell: function (checked, record, index, originNode) {
            //     if (record.status != 1) {
            //         return originNode
            //     } else {
            //         return false
            //     }
            // },
        };
        // const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <a onClick={this.mdchange.bind(this)} >{this.props.num}</a>
                <Modal maskClosable={false} title="调整" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <div className='sellist'>
                        <Row justify="space-between">
                            {store.getState().data.info.qx9 ? (store.getState().data.info.qx9 == 1 ?
                                <Space wrap>
                                    <Add refresh={this.getdata.bind(this)} year={this.props.year} kid={this.props.kid} qid={this.props.qid} type={this.props.type} />
                                </Space>
                                : null) : null}
                        </Row>
                    </div>
                    <Table
                        bordered
                        columns={columns}
                        sticky={false}
                        dataSource={this.state.list}
                        pagination={false}
                        loading={tableloading}
                        // rowSelection={rowSelection}
                        scroll={{ y: 600 }}
                        className='table'
                        size="small"
                        summary={(pageData) => {
                            let totaltzje = 0;
                            pageData.forEach(({ tzje, gzfl, dj, je, jsfl, jsdj, jsje, jsflcy, jsdjcy, jsjecy }) => {
                                totaltzje += Number(tzje ? tzje : 0);
                            });
                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>{totaltzje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                    {/* <div className='page'>
                        <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                    </div> */}
                </Modal>
            </>
        )
    }
}
