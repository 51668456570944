import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm, TimePicker } from 'antd';
import store from "../store/store.js";
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
            },
            list: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value, Option) {
        let that = this
        console.log(name, value, Option)

        let data = Object.assign({}, that.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }
    getdata = (arrname, e) => {
        let that = this
        fetch('/Yszkmx/view4', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                if (this.state.condition.id) {
                    that.setState({
                        condition: data.data,
                    })
                }
            })
    }
    sub(e) {
        let that = this
        that.setState({
            loading: true
        })
        let arr = that.state.condition
        arr.year = that.props.year
        arr.kid = that.props.kid
        arr.qid = that.props.qid
        arr.type = that.props.type
        fetch('/Yszkmx/doview4', {
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }


    render() {
        return (
            <>
                {this.props.id ? <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx9 ? (store.getState().data.info.qx9 == 1 ? '编辑' : '查看') : '查看'}</a> : <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>}
                <Modal maskClosable={false} title="编辑" width={800} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx9 ? (store.getState().data.info.qx9 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={12}>
                                <Form.Item
                                    label="回款日期"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'tjtime')} value={this.state.condition.tjtime ? moment(this.state.condition.tjtime) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="月份"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择"
                                        showSearch optionFilterProp="children"
                                        value={this.state.condition.month ? this.state.condition.month : null}
                                        // allowClear 
                                        onChange={this.onSelChange.bind(this, 'month')}
                                        options={[
                                            { label: '1月', value: 1 },
                                            { label: '2月', value: 2 },
                                            { label: '3月', value: 3 },
                                            { label: '4月', value: 4 },
                                            { label: '5月', value: 5 },
                                            { label: '6月', value: 6 },
                                            { label: '7月', value: 7 },
                                            { label: '8月', value: 8 },
                                            { label: '9月', value: 9 },
                                            { label: '10月', value: 10 },
                                            { label: '11月', value: 11 },
                                            { label: '12月', value: 12 },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="回款金额"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.tzje ? this.state.condition.tzje : null} onChange={this.onChange.bind(this, 'tzje')} type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="回款方式"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.tzfs ? this.state.condition.tzfs : null} onChange={this.onChange.bind(this, 'tzfs')} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="回款说明"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.tzsm ? this.state.condition.tzsm : null} onChange={this.onChange.bind(this, 'tzsm')} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="备注"
                                >
                                    <TextArea placeholder="请输入" value={this.state.condition.bz ? this.state.condition.bz : null} onChange={this.onChange.bind(this, 'bz')} rows={6} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {store.getState().data.info.qx9 ? (store.getState().data.info.qx9 == 1 ?
                            <Row justify="center">
                                <Space>
                                    <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this)}>完成</Button>
                                </Space>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}