import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm, AutoComplete } from 'antd';
import store from "../store/store.js";
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                zjflist: [],
                jllist: [],
                kqlist: [],
            },
            list: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value, Option) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        if(name=='khid'){
            data = Object.assign({}, data, { projectid: null })
        }
        this.setState({
            condition: data
        })
        // return
        setTimeout(function () {
            if (name == 'khid') {
                that.getxm()
                that.getzfl()
            }else if(name == 'projectid'){
                that.getzfl()
            }
        })
    }
    getxm = () => {
        let that = this
        fetch('/Swcb/getxm', {
            method: "POST",
            body: JSON.stringify({
                khid: that.state.condition.khid,
                react: true
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.setState({
                    projectid_react: data ? data : [],
                })
            })
    }
    getzfl = () => {
        let that = this
        fetch('/Swcb/getzfl', {
            method: "POST",
            body: JSON.stringify({
                khid: that.state.condition.khid,
                projectid: that.state.condition.projectid,
                react: true
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                let condition = Object.assign({}, this.state.condition, { qcbzfl: data[0] ? data[0] : null, czbzfl: data[1] ? data[1] : null })
                this.setState({
                    condition: condition
                })
            })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        setTimeout(function () {

        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    onTimeChange2(name, index, arr, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let list = this.state.condition[arr].concat();
        list[index][name] = dateString
        let data = Object.assign({}, this.state.condition, { [arr]: list })
        this.setState({
            condition: data
        })
    }
    onChange2(name, index, arr, e) {
        let that = this
        let value = e.target.value
        let list = this.state.condition[arr].concat();
        list[index][name] = value
        let data = Object.assign({}, this.state.condition, { [arr]: list })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                    zjflist: [],
                    jllist: [],
                    kqlist: [],
                },
                loading: false
            })
        }
    }
    getdata = () => {
        let that = this
        fetch('/Swcb/view', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                if (this.state.condition.id) {
                    that.setState({
                        condition: data.data,
                    })
                }
                that.setState({
                    projectid_react: data.projectid_react ? data.projectid_react : [],
                    type_react: data.type_react ? data.type_react : [],
                    khid_react: data.khid_react ? data.khid_react : [],
                })
            })
    }
    sub(e) {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Swcb/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    addzjf = () => {
        let that = this
        let zjf = that.state.condition.zjflist.concat()
        let arr = { id: '', riqi: null, je: null, skr: null }
        zjf.push(arr)
        let data = Object.assign({}, this.state.condition, { zjflist: zjf })
        this.setState({
            condition: data
        })
    }
    arrzjf(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <>
                    <Col span={8} key={index}>
                        <Form.Item
                            label={
                                <Space>
                                    <span>日期</span>
                                    <DeleteOutlined className='hongse' onClick={this.delzjf.bind(this, index)} />
                                </Space>
                            }
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange2.bind(this, 'riqi', index, 'zjflist')} value={item.riqi ? moment(item.riqi) : null} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="金额"
                        >
                            <Input type="text" placeholder="请输入" value={item.je ? item.je : null} onChange={this.onChange2.bind(this, 'je', index, 'zjflist')}  />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="收款人"
                        >
                            <Input placeholder="请输入" value={item.skr ? item.skr : null} onChange={this.onChange2.bind(this, 'skr', index, 'zjflist')}  />
                        </Form.Item>
                    </Col>
                </>
            )
        }
    }
    delzjf = (index, e) => {
        let that = this
        let zjf = that.state.condition.zjflist.concat()
        zjf.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { zjflist: zjf })
        this.setState({
            condition: data
        })
    }

    addjl = () => {
        let that = this
        let jl = that.state.condition.jllist.concat()
        let arr = { id: '', riqi: null, mm: null, nr: null, beizhu: null, je: null, skr: null }
        jl.push(arr)
        let data = Object.assign({}, this.state.condition, { jllist: jl })
        this.setState({
            condition: data
        })
    }
    arrjl(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <>
                    <Col span={8} key={index}>
                        <Form.Item
                            label={
                                <Space>
                                    <span>日期</span>
                                    <DeleteOutlined className='hongse' onClick={this.deljl.bind(this, index)} />
                                </Space>
                            }
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange2.bind(this, 'riqi', index, 'jllist')} value={item.riqi ? moment(item.riqi) : null} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="名目"
                        >
                            <Input placeholder="请输入" value={item.mm ? item.mm : null} onChange={this.onChange2.bind(this, 'mm', index, 'jllist')}  />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="人员"
                        >
                            <Input placeholder="请输入" value={item.skr ? item.skr : null} onChange={this.onChange2.bind(this, 'skr', index, 'jllist')}  />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="金额"
                        >
                            <Input type="text" placeholder="请输入" value={item.je ? item.je : null} onChange={this.onChange2.bind(this, 'je', index, 'jllist')}  />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="内容"
                        >
                            <Input placeholder="请输入" value={item.nr ? item.nr : null} onChange={this.onChange2.bind(this, 'nr', index, 'jllist')}  />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="备注"
                        >
                            <Input placeholder="请输入" value={item.beizhu ? item.beizhu : null} onChange={this.onChange2.bind(this, 'beizhu', index, 'jllist')}  />
                        </Form.Item>
                    </Col>
                </>
            )
        }
    }
    deljl = (index, e) => {
        let that = this
        let jl = that.state.condition.jllist.concat()
        jl.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { jllist: jl })
        this.setState({
            condition: data
        })
    }


    addkq = () => {
        let that = this
        let kq = that.state.condition.kqlist.concat()
        let arr = { id: '', riqi: null, nr: null, beizhu: null, je: null, skr: null }
        kq.push(arr)
        let data = Object.assign({}, this.state.condition, { kqlist: kq })
        this.setState({
            condition: data
        })
    }
    arrkq(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <>
                    <Col span={8} key={index}>
                        <Form.Item
                            label={
                                <Space>
                                    <span>日期</span>
                                    <DeleteOutlined className='hongse' onClick={this.delkq.bind(this, index)} />
                                </Space>
                            }
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange2.bind(this, 'riqi', index, 'kqlist')} value={item.riqi ? moment(item.riqi) : null} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="人员"
                        >
                            <Input placeholder="请输入" value={item.skr ? item.skr : null} onChange={this.onChange2.bind(this, 'skr', index, 'kqlist')}  />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="金额"
                        >
                            <Input type="text" placeholder="请输入" value={item.je ? item.je : null} onChange={this.onChange2.bind(this, 'je', index, 'kqlist')}  />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="内容"
                        >
                            <Input placeholder="请输入" value={item.nr ? item.nr : null} onChange={this.onChange2.bind(this, 'nr', index, 'kqlist')}  />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="备注"
                        >
                            <Input placeholder="请输入" value={item.beizhu ? item.beizhu : null} onChange={this.onChange2.bind(this, 'beizhu', index, 'kqlist')}  />
                        </Form.Item>
                    </Col>
                    <Col span={8}></Col>
                </>
            )
        }
    }
    delkq = (index, e) => {
        let that = this
        let kq = that.state.condition.kqlist.concat()
        kq.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { kqlist: kq })
        this.setState({
            condition: data
        })
    }
    render() {
        return (
            <>
                {this.state.condition.id ? <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx26 ? (store.getState().data.info.qx26 == 1 ? '编辑' : '查看') : '查看'}</a> : <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>}
                <Modal maskClosable={false} title="编辑" width={800} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx26 ? (store.getState().data.info.qx26 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={12}>
                                <Form.Item
                                    label="客户"
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.khid ? this.state.condition.khid : null} allowClear onChange={this.onSelChange.bind(this, 'khid')}>
                                        {this.arrsel(this.state.khid_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {this.state.condition.khid ?
                                <Col span={12}>
                                    <Form.Item
                                        label="项目"
                                    >
                                        <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.projectid ? this.state.condition.projectid : null} allowClear onChange={this.onSelChange.bind(this, 'projectid')}>
                                            {this.arrsel(this.state.projectid_react)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                :
                                null
                            }
                            <Col span={12}>
                                <Form.Item
                                    label="类型"
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.type ? this.state.condition.type : null} allowClear onChange={this.onSelChange.bind(this, 'type')}>
                                        {this.arrsel(this.state.type_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}></Col>
                            {this.state.condition.type == 1 ?
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            label="汽车泵总方量"
                                        // required={true}
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.qcbzfl ? this.state.condition.qcbzfl : 0} onChange={this.onChange.bind(this, 'qcbzfl')} disabled={true}  />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="车载泵总方量"
                                        // required={true}
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.czbzfl ? this.state.condition.czbzfl : 0} onChange={this.onChange.bind(this, 'czbzfl')} disabled={true}  />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}></Col>
                                    {this.arrzjf(this.state.condition.zjflist)}
                                    <Col span={24}>
                                        <Button type="dashed" size="small" style={{ borderColor: '#1890ff', color: '#1890ff', width: '100%', marginBottom: 24 }} onClick={this.addzjf.bind(this)} >增加</Button>
                                    </Col>
                                </>
                                : null}
                            {this.state.condition.type == 2 ?
                                <>
                                    {this.arrjl(this.state.condition.jllist)}
                                    <Col span={24}>
                                        <Button type="dashed" size="small" style={{ borderColor: '#1890ff', color: '#1890ff', width: '100%', marginBottom: 24 }} onClick={this.addjl.bind(this)} >增加</Button>
                                    </Col>
                                </>
                                : null}
                            {this.state.condition.type == 3 ?
                                <>
                                    {this.arrkq(this.state.condition.kqlist)}
                                    <Col span={24}>
                                        <Button type="dashed" size="small" style={{ borderColor: '#1890ff', color: '#1890ff', width: '100%', marginBottom: 24 }} onClick={this.addkq.bind(this)} >增加</Button>
                                    </Col>
                                </>
                                : null}
                        </Row>
                        {store.getState().data.info.qx26 ? (store.getState().data.info.qx26 == 1 ?
                            <Row justify="center">
                                <Space>
                                    <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this)}>完成</Button>
                                </Space>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}