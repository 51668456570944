import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip, DatePicker, Typography, Progress } from 'antd';
import store from "../store/store.js";
import { Pie } from '@ant-design/plots';
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
import Add from './add';
const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
            month_react: [
                { id: 1, name: '1月' },
                { id: 2, name: '2月' },
                { id: 3, name: '3月' },
                { id: 4, name: '4月' },
                { id: 5, name: '5月' },
                { id: 6, name: '6月' },
                { id: 7, name: '7月' },
                { id: 8, name: '8月' },
                { id: 9, name: '9月' },
                { id: 10, name: '10月' },
                { id: 11, name: '11月' },
                { id: 12, name: '12月' },
            ],
            data1: [
                {
                    type: '计划总方量',
                    value: 0,
                },
                {
                    type: '自有设备完成方量',
                    value: 0,
                },
                {
                    type: '生产总方量',
                    value: 0,
                },
            ],
            data2: [
                {
                    type: '计划总数',
                    value: 0,
                },
                {
                    type: '异常',
                    value: 0,
                },

            ]
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        this.props.NAV_CHANGE('/scjhfx', '生产统计')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }
    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let scjhtjheight = document.querySelector('.scjhtj').clientHeight + 80

        let yheight = bgheight - 240 - selheight - scjhtjheight
        that.setState({
            tableHeight: yheight
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} >{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        if (subtype == 1) {
            arr.isall = 1
        }
        fetch('/Scjhfx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    month_react: data.month_react ? data.month_react : [],
                    status_react: data.status_react ? data.status_react : [],
                    khid_react: data.khid_react ? data.khid_react : [],
                    projectid_react: data.projectid_react ? data.projectid_react : [],
                })
                if (subtype == 1) {
                    that.setState({
                        data1: data.data1 ? data.data1 : [],
                        data2: data.data2 ? data.data2 : [],
                    })
                }
            })
    }
    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, { [name + '1']: dateStrings[0], [name + '2']: dateStrings[1] })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    render() {
        const config1 = {
            width: 180,
            height: 180,
            appendPadding: 10,
            data: this.state.data1,
            angleField: 'value',
            colorField: 'type',
            radius: 1,
            innerRadius: 0.8,
            legend: false,
            color: ['#84b7f9', '#1990ff', '#0c70ce'],
            label: false,
            interactions: [
                {
                    type: 'element-selected',
                },
                {
                    type: 'element-active',
                },
            ],
            statistic: {
                title: false,
                content: {
                    style: {
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: 18
                    },
                    content: (this.state.data1[1]['value'] / this.state.data1[0]['value'] * 100).toFixed(2) + '%'
                },
            },
        };
        const config2 = {
            width: 180,
            height: 180,
            appendPadding: 10,
            data: this.state.data2,
            angleField: 'value',
            colorField: 'type',
            radius: 1,
            innerRadius: 0.8,
            legend: false,
            color: ['#f9c78b', '#f39423'],
            label: false,
            interactions: [
                {
                    type: 'element-selected',
                },
                {
                    type: 'element-active',
                },
            ],
            statistic: {
                title: false,
                content: {
                    style: {
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: 18
                    },
                    content: (this.state.data2[1]['value'] / this.state.data2[0]['value'] * 100).toFixed(2) + '%'
                },
            },
        };
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 80,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '计划名称',
                dataIndex: 'jhmc',
                key: 'jhmc',
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '生产日期',
                dataIndex: 'scrq_str',
                key: 'scrq_str',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '生产时间',
                dataIndex: 'scsj_str',
                key: 'scsj_str',
                width: 80,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '项目名称',
                dataIndex: 'xmmc',
                key: 'xmmc',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '客户名称',
                dataIndex: 'khmc',
                key: 'khmc',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '计划方量完成度（计划/生产）',
                dataIndex: 'jhflwcd',
                key: 'jhflwcd',
                width: 220,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <>
                        <div className='wcd w1' >
                            <div className='p1'>方量：<span className='num'>{record.jhfl} / {record.scfl}</span></div>
                            <Space>
                                <Progress
                                    percent={Number(record.flbfb)}
                                    strokeColor="#1990FF"
                                    showInfo={false}
                                    status="normal"
                                    style={{ width: 130 }}
                                />
                                <span>{Number(record.flbfb)}%</span>
                            </Space>
                        </div>
                    </>
                ),
            },
            {
                title: '计划设备完成度（计划/生产）',
                dataIndex: 'jhflwcd',
                key: 'jhflwcd',
                width: 220,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <>
                        <div className='wcd w2' >
                            <div className='p1'>设备：<span className='num'>{record.jhsb} / {record.scsb}</span></div>
                            <Space>
                                <Progress
                                    percent={Number(record.sbbfb)}
                                    strokeColor="#F39322"
                                    showInfo={false}
                                    status="normal"
                                    style={{ width: 130 }}
                                />
                                <span>{Number(record.sbbfb)}%</span>
                            </Space>
                        </div>
                    </>
                ),
            },
            {
                title: '设备完成情况',
                dataIndex: 'sbwcqk',
                key: 'sbwcqk',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <>
                        {row == 1 ? <Tag color="green">{record.sbwcqk_str}</Tag> : null}
                        {row == 2 ? <Tag color="red">{record.sbwcqk_str}</Tag> : null}
                    </>
                ),
            },
            {
                title: '自有设备',
                dataIndex: 'chidlist',
                key: 'chidlist',
                width: 320,
                render: (row, record) => (
                    <>
                        <Space split="|" wrap>
                            {row}
                        </Space>
                    </>
                ),
            },
            {
                title: '外租设备',
                dataIndex: 'wdbdwlist',
                key: 'wdbdwlist',
                width: 320,
                render: (row, record) => (
                    <>
                        <Space split="|" wrap>
                            {row}
                        </Space>
                    </>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 80,
                fixed: 'right',
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <Add id={record.id} refresh={this.getdata.bind(this)} />
                            </Space>
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideSelectAll: true,
            preserveSelectedRowKeys: false,
            renderCell: function (checked, record, index, originNode) {
                if (record.status != 1) {
                    return originNode
                } else {
                    return false
                }
            },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between">
                        <Space wrap>
                            <DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null} />
                            <Select placeholder="月份" style={{ width: 120 }} value={this.state.newcondition.month ? this.state.newcondition.month : 0} onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                {this.arrsel(this.state.month_react)}
                            </Select>
                            <Input placeholder="计划名称" value={this.state.newcondition.jhmc ? this.state.newcondition.jhmc : null} onChange={this.onChange.bind(this, 'jhmc')} />
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'scrq')} value={[(this.state.newcondition.scrq1 ? moment(this.state.newcondition.scrq1) : null), (this.state.newcondition.scrq2 ? moment(this.state.newcondition.scrq2) : null)]} placeholder={['生产日期', '生产日期']} />
                            <Select placeholder="客户名称" style={{ width: 180 }} value={this.state.newcondition.khid ? this.state.newcondition.khid : null} onChange={this.onSelChange.bind(this, 'khid')} allowClear>
                                {this.arrsel(this.state.khid_react)}
                            </Select>
                            <Select placeholder="项目名称" style={{ width: 200 }} value={this.state.newcondition.projectid ? this.state.newcondition.projectid : null} onChange={this.onSelChange.bind(this, 'projectid')} showSearch optionFilterProp="children" allowClear>
                                {this.arrsel(this.state.projectid_react)}
                            </Select>
                            <Select placeholder="设备完成情况" style={{ width: 200 }} value={this.state.newcondition.status ? this.state.newcondition.status : null} onChange={this.onSelChange.bind(this, 'status')} showSearch optionFilterProp="children" allowClear>
                                {this.arrsel(this.state.status_react)}
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                    </Row>
                </div>
                <Row justify="space-between" className='scjhtj' style={{ marginBottom: 20 }}>
                    <div className='item'>
                        <div className='p1'>方量完成情况</div>
                        <div className='p2'>
                            <Pie {...config1} />
                            <div className='a a1'>
                                <div className='b1'>计划总方量</div>
                                <div className='b2'>{this.state.data1[0]['value']}</div>
                            </div>
                            <div className='a a5'>
                                <div className='b1'>自有设备完成方量</div>
                                <div className='b2'>{this.state.data1[1]['value']}</div>
                            </div>
                            <div className='a a2'>
                                <div className='b1'>生产总方量</div>
                                <div className='b2'>{this.state.data1[2]['value']}</div>
                            </div>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='p1'>设备异常率</div>
                        <div className='p2'>
                            <Pie {...config2} />
                            <div className='a a4'>
                                <div className='b1'>计划总数</div>
                                <div className='b2'>{this.state.data2[0]['value']}</div>
                            </div>
                            <div className='a a3'>
                                <div className='b1'>异常</div>
                                <div className='b2'>{this.state.data2[1]['value']}</div>
                            </div>
                        </div>
                    </div>
                </Row>
                <Title level={5}>生产计划分析</Title>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    scroll={{ y: this.state.tableHeight ? this.state.tableHeight : null, x: 2000 }}
                    className='table'
                    size="small"
                />
                <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div>
            </>
        )
    }
}
