import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm, Upload } from 'antd';
import store from "../store/store.js";
import { PlusOutlined, DeleteOutlined,UploadOutlined } from '@ant-design/icons'
import moment from 'moment';
import KhxxEdit from '../khxx/add.js';
const { Option } = Select;
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                mxlist: [

                ],
                lb: 1,
                jsdfj: [],
            },
            list: [],
            jsnr_react: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        if (name == 'lb') {
            data = Object.assign({}, data, { jsdw: null })
            this.setState({
                condition: data
            })
        }
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    getdata = (arrname, e) => {
        let that = this
        fetch('/Jsmx/view', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                if (arrname) {
                    arrname.map((item) => {
                        that.setState({
                            [item]: data[item] ? data[item] : [],
                        })
                    })
                    return
                }
                if (this.state.condition.id) {
                    that.setState({
                        condition: data.data,
                    })
                }
                that.setState({
                    jsdw_react1: data.jsdw_react1 ? data.jsdw_react1 : [],
                    jsdw_react2: data.jsdw_react2 ? data.jsdw_react2 : [],
                    jsnr_react: data.jsnr_react ? data.jsnr_react : [],
                    lb_react: data.lb_react ? data.lb_react : [],
                    qydw_react: data.qydw_react ? data.qydw_react : [],
                    gys_react: data.gys_react ? data.gys_react : [],
                    khlb_react: data.khlb_react ? data.khlb_react : [],
                    dzstatus_react: data.dzstatus_react ? data.dzstatus_react : [],
                })
            })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                    mxlist: [

                    ],
                    lb: 1,
                    jsdfk: [],
                },
                loading: false
            })
        }
    }
    sub(isnext = 0, e) {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Jsmx/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    addItem = () => {
        let that = this
        fetch('/Jsmx/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, jsnr: data.jsnrid ? data.jsnrid : null })
                    this.setState({
                        condition: condition,
                        jsnr_react: data.jsnr_react ? data.jsnr_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_qydw = () => {
        let that = this
        fetch('/Qydw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, qydw: data.qydw ? data.qydw : null })
                    this.setState({
                        condition: condition,
                        qydw_react: data.qydw_react ? data.qydw_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_gys = () => {
        let that = this
        fetch('/Gys/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, gys: data.gys ? data.gys : null })
                    this.setState({
                        condition: condition,
                        gys_react: data.gys_react ? data.gys_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    clearNewField = (open) => {
        let that = this
        setTimeout(() => {
            let condition = Object.assign({}, that.state.condition, { newfield: null })
            that.setState({
                condition: condition,
            })
        });
    }
    addmx = () => {
        let that = this
        let mxlist = that.state.condition.mxlist.concat()
        let arr = { id: '', jsnr: null, lb: null, jssl: null, jsje: null }
        mxlist.push(arr)
        let data = Object.assign({}, this.state.condition, { mxlist: mxlist })
        this.setState({
            condition: data
        })
    }
    delmx = (index, e) => {
        let that = this
        let mxlist = that.state.condition.mxlist.concat()
        mxlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { mxlist: mxlist })
        this.setState({
            condition: data
        })
    }
    arrMx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <>
                    <Col span={8}>
                        <Form.Item
                            label={<Space><span>结算内容</span><a className="hongse" onClick={this.delmx.bind(this, index)} ><DeleteOutlined /></a></Space>}
                            required={true}
                        >
                            <Select
                                placeholder="请选择" showSearch optionFilterProp="children"
                                value={item.jsnr ? item.jsnr : null}
                                onChange={this.onSelChange2.bind(this, 'jsnr', index)}
                                onDropdownVisibleChange={this.clearNewField.bind(this)}
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider
                                            style={{
                                                margin: '8px 0',
                                            }}
                                        />
                                        <Space
                                            style={{
                                                padding: '0 8px 4px',
                                            }}
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem.bind(this)}>
                                                创建并选择
                                            </Button>
                                        </Space>
                                    </>
                                )}
                            >
                                {this.arrsel(this.state.jsnr_react)}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                        <Form.Item
                            label="类别"
                            required={true}
                        >
                            <Select placeholder="请选择" showSearch optionFilterProp="children" value={item.lb ? item.lb : null} onChange={this.onSelChange2.bind(this, 'lb', index)}>
                                {this.arrsel(this.state.lb_react)}
                            </Select>
                        </Form.Item>
                    </Col> */}
                    <Col span={8}>
                        <Form.Item
                            label="结算数量"
                        >
                            <Input placeholder="请输入" value={item.jssl ? item.jssl : null} onChange={this.onChange2.bind(this, 'jssl', index)}  type="text" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="结算金额"
                            required={true}
                        >
                            <Input placeholder="请输入" value={item.jsje ? item.jsje : null} onChange={this.onChange2.bind(this, 'jsje', index)}  type="text" suffix="元" />
                        </Form.Item>
                    </Col>
                    {arr.length != index + 1 ?
                        <Col span={24}>
                            <Divider />
                        </Col>
                        : null
                    }
                </>
            )
        }
    }
    onChange2(name, index, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let mxlist = that.state.condition.mxlist.concat()
        mxlist[index][name] = value
        let data = Object.assign({}, this.state.condition, { mxlist: mxlist })
        this.setState({
            condition: data
        })
    }
    onSelChange2(name, index, value) {
        let that = this
        console.log(name, value)
        let mxlist = that.state.condition.mxlist.concat()
        mxlist[index][name] = value
        let data = Object.assign({}, this.state.condition, { mxlist: mxlist })
        this.setState({
            condition: data
        })
    }
    zje = () => {
        let that = this
        let data = that.state.condition.mxlist.concat()
        let jine = 0
        for (const key in data) {
            const item = data[key];
            // if (item.lb == 1) {
            //     jine = jine + Number(item.jsje)
            // } else if (item.lb == 2) {
            //     jine = jine - Number(item.jsje)
            // }
            jine = jine + Number(item.jsje)
        }
        jine = jine.toFixed(2)
        return jine
    }
    // 上传图片
    picChange(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log(info.file.response)
            if (info.file.response.status == 0) {
                message.error(info.file.response.msg)
                return
            }

            // var fjlist = this.state.condition.fjlist
            // fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: info.file.response.id, [name + '_url']: info.file.response.url, [name + '_name']: info.file.response.name })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    // 上传图片
    picChange2(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file.response)

            var fjlist = this.state.condition[name]
            if (!fjlist) {
                fjlist = []
            }
            fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: fjlist })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    delpic(index, name, e) {
        var fjlist = this.state.condition[name]
        fjlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { [name]: fjlist })
        this.setState({
            condition: data,
        })
    }
    render() {
        return (
            <>
                {this.state.condition.id ? <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx14 ? (store.getState().data.info.qx14 == 1 ? '编辑' : '查看') : '查看'}</a> : <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>}
                <Modal maskClosable={false} title="编辑" width={800} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx14 ? (store.getState().data.info.qx14 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={12}>
                                <Form.Item
                                    required={true}
                                    label="结算日期"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'jsrq')} value={this.state.condition.jsrq ? moment(this.state.condition.jsrq) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="结算单号"
                                // required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jsdh ? this.state.condition.jsdh : null} onChange={this.onChange.bind(this, 'jsdh')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="类别"
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.lb ? this.state.condition.lb : null} onChange={this.onSelChange.bind(this, 'lb')}>
                                        {this.arrsel(this.state.lb_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={
                                        <Space>
                                            <>
                                                {this.state.condition.lb == 1 ? '客户名称' : null}
                                                {this.state.condition.lb == 2 ? '供应商名称' : null}
                                            </>
                                            {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ? <KhxxEdit type="link" refresh={this.getdata.bind(this, ['jsdw_react1', 'jsdw_react2'])} /> : null) : null} </Space>
                                    }
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.jsdw ? this.state.condition.jsdw : null}
                                        onChange={this.onSelChange.bind(this, 'jsdw')}
                                    >
                                        {this.state.condition.lb == 1 ? this.arrsel(this.state.jsdw_react1) : null}
                                        {this.state.condition.lb == 2 ? this.arrsel(this.state.jsdw_react2) : null}
                                        {/* {this.arrsel(this.state.jsdw_react)} */}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="签约单位"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.qydw ? this.state.condition.qydw : null}
                                        onChange={this.onSelChange.bind(this, 'qydw')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_qydw.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.qydw_react)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            {/* <Col span={12}>
                                <Form.Item
                                    label="结算内容"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.jsnr ? this.state.condition.jsnr : null}
                                        onChange={this.onSelChange.bind(this, 'jsnr')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider
                                                    style={{
                                                        margin: '8px 0',
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: '0 8px 4px',
                                                    }}
                                                >
                                                    <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                    <Button type="text" icon={<PlusOutlined />} onClick={this.addItem.bind(this)}>
                                                        创建并选择
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.jsnr_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="类别"
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.lb ? this.state.condition.lb : null} onChange={this.onSelChange.bind(this, 'lb')}>
                                        {this.arrsel(this.state.lb_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="结算数量"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jssl ? this.state.condition.jssl : null} onChange={this.onChange.bind(this, 'jssl')}  type="text" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="结算金额"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jsje ? this.state.condition.jsje : null} onChange={this.onChange.bind(this, 'jsje')}  type="text" suffix="元" />
                                </Form.Item>
                            </Col> */}
                            <Col span={24}>
                                <Divider>结算明细</Divider>
                            </Col>
                            {this.arrMx(this.state.condition.mxlist)}
                            <Col span={24}>
                                <Button type="dashed" size="small" style={{ borderColor: '#1890ff', color: '#1890ff', width: '100%', marginBottom: 24 }} onClick={this.addmx.bind(this)} >增加</Button>
                            </Col>
                            {/* <Col span={12}>
                                <Form.Item
                                    label="总金额"
                                >
                                    <Input placeholder="请输入" value={this.zje()} disabled={true} suffix="元"  />
                                </Form.Item>
                            </Col> */}
                            <Col span={24}>
                                <Form.Item
                                // label="总金额"
                                >
                                    <Row justify="center">总金额：<a style={{ cursor: "default" }}>{this.zje()}元</a></Row>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Divider>对账记录</Divider>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="状态"
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.dzstatus ? this.state.condition.dzstatus : null} onChange={this.onSelChange.bind(this, 'dzstatus')}>
                                        {this.arrsel(this.state.dzstatus_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="对账日期"
                                    required={this.state.condition.dzstatus >= 2 ? true : false}
                                >
                                    <DatePicker disabled={this.state.condition.dzstatus >= 2 ? false : true} style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'dzrq')} value={this.state.condition.dzrq ? moment(this.state.condition.dzrq) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="回单日期"
                                    required={this.state.condition.dzstatus == 3 ? true : false}
                                >
                                    <DatePicker disabled={this.state.condition.dzstatus == 3 ? false : true} style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'hdrq')} value={this.state.condition.hdrq ? moment(this.state.condition.hdrq) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="备注"
                                >
                                    <TextArea rows={6} placeholder="请输入" value={this.state.condition.beizhu ? this.state.condition.beizhu : null} onChange={this.onChange.bind(this, 'beizhu')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="付款情况"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.fkqk ? this.state.condition.fkqk : null} onChange={this.onChange.bind(this, 'fkqk')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="收付款日期"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'skrq')} value={this.state.condition.skrq ? moment(this.state.condition.skrq) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="收付款金额"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.sfkje ? this.state.condition.sfkje : null} onChange={this.onChange.bind(this, 'sfkje')}  type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="开票日期"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'kprq')} value={this.state.condition.kprq ? moment(this.state.condition.kprq) : null} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="结算单附件"
                                >
                                    <Row justify="space-between">
                                        <Space align="center">
                                            <Upload
                                                showUploadList={false}
                                                action="/Common/uploadfile"
                                                onChange={this.picChange2.bind(this, 'jsdfj')}
                                                multiple={true}
                                            >
                                                <Button icon={<UploadOutlined />}>上传</Button>
                                            </Upload>
                                            <Space wrap>
                                                {this.state.condition.jsdfj ? this.state.condition.jsdfj.map((item, index) =>
                                                    <div>
                                                        <a href={item.url} target="_blank">{item.name} </a> <DeleteOutlined className="hongse" onClick={this.delpic.bind(this, index, 'jsdfj')} />
                                                    </div>) : null}

                                            </Space>
                                        </Space>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                        {store.getState().data.info.qx14 ? (store.getState().data.info.qx14 == 1 ?
                            <Row justify="center">
                                <Space>
                                    <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this)}>完成</Button>
                                </Space>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}
