import React from 'react';
import ReactDOM from 'react-dom';


import { Provider } from 'react-redux';
//reducers 状态树state和逻辑操作
import { persistor } from './store/store.js'
import { PersistGate } from 'redux-persist/lib/integration/react';
// import App from './App.js';
import AppAction from './store/action/AppAction.js';
import store from "./store/store.js";

//创建状态树和设置

//生成状态树对象
// const store = createStore(rootRedux);

//start 状态树应用到全局 通过Provider
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppAction /> 
    </PersistGate>
  </Provider>
  , document.getElementById('root'));

