import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm, AutoComplete } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                arrid: props.arrid ? props.arrid : 0,
            },
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        let that = this
        let data = Object.assign({}, that.state.condition, { arrid: nextProps.arrid })
        that.setState({
            condition: data
        })
    }
    onSelChange(name, value, Option) {
        let that = this
        console.log(name, value, Option)
        let data = Object.assign({}, that.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {

        } else {
            that.setState({
                condition: {
                    arrid: this.props.arrid,
                },
                loading: false
            })
        }
    }
    sub(e) {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/jsmx/delDuiZhang', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }

    render() {
        return (
            <>
                <Button type="primary" onClick={this.mdchange.bind(this)}>对账</Button>
                <Modal maskClosable={false} title="对账" width={600} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                    >

                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={12}>
                                <Form.Item
                                    label="状态"
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.dzstatus ? this.state.condition.dzstatus : null} onChange={this.onSelChange.bind(this, 'dzstatus')}>
                                        {this.arrsel(this.props.dzstatus_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="对账日期"
                                    required={this.state.condition.dzstatus >= 2 ? true : false}
                                >
                                    <DatePicker disabled={this.state.condition.dzstatus >= 2 ? false : true} style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'dzrq')} value={this.state.condition.dzrq ? moment(this.state.condition.dzrq) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="回单日期"
                                    required={this.state.condition.dzstatus == 3 ? true : false}
                                >
                                    <DatePicker disabled={this.state.condition.dzstatus == 3 ? false : true} style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'hdrq')} value={this.state.condition.hdrq ? moment(this.state.condition.hdrq) : null} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {store.getState().data.info.qx14 ? (store.getState().data.info.qx14 == 1 ?
                            <Row justify="center">
                                <Space>
                                    <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this)}>提交</Button>
                                </Space>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}
