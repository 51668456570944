import React from 'react'
import ReactDOM from 'react-dom';
import bj from '../images/bj.jpg'
import logo from '../images/logo.png'
import loginpic from '../images/loginpic.png'
import { Layout, Form, Input, Button, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types';
import ReactSimpleVerify from 'react-simple-verify'
import 'react-simple-verify/dist/react-simple-verify.css'
import { Redirect } from 'react-router'
import store from "../store/store.js";

class Formlogin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLogin: store.getState().data.isLogin,
			yz: false
		}
		store.subscribe(() => {
			// console.log('state状态改变了，新状态如下')
			// console.log(store.getState().data.isLogin)
		})
	}
	componentDidMount() {
		this.props.doLoginRedux.OUT_LOGIN();
	}
	onFinish(values) {
		// message.success('登录成功');
		// this.props.doLoginRedux.GOLOGIN('', this.props.doLoginRedux.history);
		// this.setState({ isLogin: store.getState().data.isLogin })
		console.log(values)
		if (this.state.yz) {
			fetch('/Login/dologin', {
				// post提交
				method: "POST",
				body: JSON.stringify(values)
			})
				.then(res => res.json())
				.then(data => {
					if (data.status == 1) {
						message.success(data.msg);
						this.props.doLoginRedux.GOLOGIN(data.user, this.props.doLoginRedux.history);
						this.setState({ isLogin: store.getState().data.isLogin })
					} else {
						message.error(data.msg);
					}
				})
		} else {
			message.error('请验证后登录');
		}
	};
	success() {
		this.setState({
			yz: true
		})
	}
	islogin() {
		if (this.state.isLogin == true) {
			return <Redirect to={'/'} />;
		}
	}
	render() {
		{ this.islogin() }
		return (
			<>
				<div className="right">
					<div className='head'>欢迎用户登录</div>
					<Form
						name="normal_login"
						className="login-form"
						initialValues={{
							type: 2
						}}
						onFinish={this.onFinish.bind(this)}
					>
						<Form.Item
							name="username"
							rules={[
								{
									required: true,
									message: '请输入账号!',
								},
							]}
						>
							<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="账号" className="input"  />
						</Form.Item>
						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: '请输入密码!',
								},
							]}
						>
							<Input
								prefix={<LockOutlined className="site-form-item-icon" />}
								type="password"
								placeholder="密码"
								className="input"

							/>
						</Form.Item>
						<Form.Item
						>
							<ReactSimpleVerify ref="verify" success={this.success.bind(this)} width="425" height={60} />
						</Form.Item>
						<Form.Item>
							<Button htmlType="submit" className="login-form-button" style={{ fontSize: 18 }} >
								立即登录
							</Button>
						</Form.Item>
					</Form>
				</div>
			</>
		);
	}
};
export default class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}
	render() {
		return (
			<div style={{'width': '100%', 'height': '100%', 'float': 'left' }} className="bj">
				{/* <img src={logo} className='logopic' ></img> */}
				<div className='l'>
					<img src={logo} className='logo' ></img>
				</div>
				<div className="loginpost">
					<Formlogin doLoginRedux={this.props} />
					<div className='footer'>© 2019-{new Date().getFullYear()}  天津市朗达工程科技有限公司 <a style={{color:'inherit'}} target={"_blank"} href={'https://beian.miit.gov.cn/'}>津ICP备2024020718号-1</a></div>
				</div>
			</div>
		)
	}
}
