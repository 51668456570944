import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            fetch('/Jymx/jytj', {
                method: "POST",
                body: JSON.stringify(this.props.condition ? this.props.condition : {})
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg)
                        return
                    }
                    that.setState({
                        list: data.data,
                    })
                })
        } else {
            that.setState({
                list: [],
                loading: false
            })
        }
    }

    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车辆编号',
                dataIndex: 'clbh',
                key: 'clbh',
                width: 150,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车牌',
                dataIndex: 'cp',
                key: 'cp',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '加油升数',
                dataIndex: 'jyss',
                key: 'jyss',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '方量',
                dataIndex: 'fl',
                key: 'fl',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '单方',
                dataIndex: 'df',
                key: 'df',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '总金额',
                dataIndex: 'zje',
                key: 'zje',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
        ]
        const { tableloading, tableloading2 } = this.state;
        return (
            <>
                <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >加油统计</a>
                <Modal maskClosable={false} title="加油统计" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <p>{this.props.condition.year}年{this.props.condition.month ? (this.props.condition.month + '月') : ''}</p>
                    <Table
                        bordered
                        columns={columns}
                        sticky={false}
                    dataSource={this.state.list}
                        pagination={false}
                        loading={tableloading}
                        // rowSelection={rowSelection}
                        className='table'
                        size="small"
                        summary={(pageData) => {
                            let totaljyss = 0;
                            let totalfl = 0;
                            let totalzje = 0;
                            pageData.forEach(({ jyss, fl, df,zje }) => {
                                totaljyss += Number(jyss ? jyss : 0);
                                totalfl += Number(fl ? fl : 0);
                                totalzje += Number(zje ? zje : 0);
                            });
                            var totaldf = totaljyss / totalfl;
                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>{totaljyss.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>{totalfl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>{totaldf.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>{totalzje.toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                </Modal>
            </>
        )
    }
}