import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, DatePicker, Divider, Cascader } from 'antd';
import store from "../store/store.js";
import { PlusOutlined } from '@ant-design/icons'
import moment from 'moment';
import Qtedit from "../qita/add.js";
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                type: null,
            },
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        if (name == 'zjly' && data.riqi) {
            setTimeout(() => {
                that.getSqjy(data.riqi, value)
            })
        }
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        if (name == 'srqk' || name == 'zcqk') {
            setTimeout(() => {
                let jy = Number(that.state.condition.syqjy) + Number(that.state.condition.srqk) - Number(that.state.condition.zcqk)
                let data2 = Object.assign({}, that.state.condition, { jy })
                that.setState({
                    condition: data2
                })
            })
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
        if (name == 'riqi' && data.zjly) {
            setTimeout(() => {
                that.getSqjy(dateString, data.zjly)
            })
        }
    }
    getSqjy = (riqi, zjly) => {
        let that = this
        fetch('/Szqk/getSqjy', {
            method: "POST",
            body: JSON.stringify({
                riqi,
                zjly,
                id: that.state.condition.id ? that.state.condition.id : 0
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                let condition = Object.assign({}, this.state.condition, { syqjy: data.syqjy })
                this.setState({
                    condition: condition
                })
                // let jy = Number(data.syqjy) + Number(that.state.condition.srqk) - Number(that.state.condition.zcqk)
                // let data2 = Object.assign({}, that.state.condition, { jy })
                // that.setState({
                //     condition: data2
                // })
            })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    getdata = (arrname, e) => {
        let that = this
        fetch('/Szqk/view', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                if (arrname) {
                    that.setState({
                        [arrname]: data[arrname] ? data[arrname] : [],
                    })
                    return
                }
                if (this.state.condition.id) {
                    that.setState({
                        condition: data.data,
                    })
                }
                that.setState({
                    yt_react: data.yt_react ? data.yt_react : [],
                    zjly_react: data.zjly_react ? data.zjly_react : [],
                    km_react: data.km_react ? data.km_react : [],
                    sfkrlx_react: data.sfkrlx_react ? data.sfkrlx_react : [],
                    sfkr_react1: data.sfkr_react1 ? data.sfkr_react1 : [],
                    sfkr_react2: data.sfkr_react2 ? data.sfkr_react2 : [],
                    sfkr_react3: data.sfkr_react3 ? data.sfkr_react3 : [],
                })
            })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Szqk/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    addItem = () => {
        let that = this
        fetch('/Yt/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, yt: data.yt ? data.yt : null })
                    this.setState({
                        condition: condition,
                        yt_react: data.yt_react ? data.yt_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_km = () => {
        let that = this
        fetch('/Kemu/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, km: data.kmid ? data.km : null })
                    this.setState({
                        condition: condition,
                        km_react: data.km_react ? data.km_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    clearNewField = (open) => {
        let that = this
        setTimeout(() => {
            let condition = Object.assign({}, that.state.condition, { newfield: null })
            that.setState({
                condition: condition,
            })
        });
    }
    render() {
        return (
            <>
                {this.state.condition.id ?
                    <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ? '编辑' : '查看') : '查看'}</a>
                    :
                    <>
                        {this.props.type == "link" ?
                            <a onClick={this.mdchange.bind(this)}>新增</a>
                            :
                            <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>
                        }

                    </>
                }
                <Modal maskClosable={false} title="编辑" width={800} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={12}>
                                <Form.Item
                                    label="日期"
                                    required={true}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'riqi')} value={this.state.condition.riqi ? moment(this.state.condition.riqi) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="科目"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.km ? this.state.condition.km : null}
                                        onChange={this.onSelChange.bind(this, 'km')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider
                                                    style={{
                                                        margin: '8px 0',
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: '0 8px 4px',
                                                    }}
                                                >
                                                    <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                    <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_km.bind(this)}>
                                                        创建并选择
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.km_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="收付款人类型"
                                // required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.sfkrlx ? this.state.condition.sfkrlx : null}
                                        onChange={this.onSelChange.bind(this, 'sfkrlx')}
                                    >
                                        {this.arrsel(this.state.sfkrlx_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={
                                        <Space>
                                            <>收付款人</>
                                            {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                <>
                                                    {this.state.condition.sfkrlx == 3 ?
                                                        <Qtedit type="link" refresh={this.getdata.bind(this, 'sfkr_react3')} />
                                                        : null}
                                                </>
                                                : null) : null}
                                        </Space>
                                    }
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.sfkr ? this.state.condition.sfkr : null}
                                        onChange={this.onSelChange.bind(this, 'sfkr')}
                                    >
                                        {this.state.condition.sfkrlx == 1 ? this.arrsel(this.state.sfkr_react1) : null}
                                        {this.state.condition.sfkrlx == 2 ? this.arrsel(this.state.sfkr_react2) : null}
                                        {this.state.condition.sfkrlx == 3 ? this.arrsel(this.state.sfkr_react3) : null}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="资金来源"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.zjly ? this.state.condition.zjly : null}
                                        onChange={this.onSelChange.bind(this, 'zjly')}
                                    >
                                        {this.arrsel(this.state.zjly_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="上一期结余"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.syqjy ? this.state.condition.syqjy : null} onChange={this.onChange.bind(this, 'syqjy')}  disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="收入情况"
                                    required={true}
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.srqk ? this.state.condition.srqk : null} onChange={this.onChange.bind(this, 'srqk')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="支出情况"
                                    required={true}
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.zcqk ? this.state.condition.zcqk : null} onChange={this.onChange.bind(this, 'zcqk')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="摘要"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.zy ? this.state.condition.zy : null} onChange={this.onChange.bind(this, 'zy')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="结余"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jy ? this.state.condition.jy : null} onChange={this.onChange.bind(this, 'jy')}  disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="备注"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.bz ? this.state.condition.bz : null} onChange={this.onChange.bind(this, 'bz')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="月末结存"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.ymjc ? this.state.condition.ymjc : null} onChange={this.onChange.bind(this, 'ymjc')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="用途"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.yt ? this.state.condition.yt : null}
                                        onChange={this.onSelChange.bind(this, 'yt')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider
                                                    style={{
                                                        margin: '8px 0',
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: '0 8px 4px',
                                                    }}
                                                >
                                                    <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                    <Button type="text" icon={<PlusOutlined />} onClick={this.addItem.bind(this)}>
                                                        创建并选择
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.yt_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            
                        </Row>
                        {store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ?
                            <Row justify="center">
                                <Button className='blue' style={{ width: 140 }} loading={this.state.loading} onClick={this.sub.bind(this)}>确认</Button>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}