import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                type: null,
            },
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            fetch('/Wxnr/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg)
                        return
                    }
                    if (this.state.condition.id) {
                        that.setState({
                            condition: data.data,
                        })
                    }
                    that.setState({
                        status_react: data.status_react
                    })
                })
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Wxnr/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }

    render() {
        return (
            <>
                {this.state.condition.id ?
                    <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ? '编辑' : '查看') : '查看'}</a>
                    :
                    <>
                        {this.props.type ?
                            <a onClick={this.mdchange.bind(this)}>新增</a>
                            :
                            <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>
                        }

                    </>
                }
                <Modal maskClosable={false} title="编辑" width={400} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={24}>
                                <Form.Item
                                    label="维修内容"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.name ? this.state.condition.name : null} onChange={this.onChange.bind(this, 'name')} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="方量"
                                // required={true}
                                >
                                    <Space>
                                        <Input type="text" min={0} placeholder="始" value={this.state.condition.sm ? this.state.condition.sm : null} onChange={this.onChange.bind(this, 'sm')} suffix="方" />
                                        ~
                                        <Input type="text" min={0} placeholder="终" value={this.state.condition.sm2 ? this.state.condition.sm2 : null} onChange={this.onChange.bind(this, 'sm2')} suffix="方" />
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="时限"
                                // required={true}
                                >
                                    <Space>
                                        <Input type="text" min={0} placeholder="请输入" value={this.state.condition.sx ? this.state.condition.sx : null} onChange={this.onChange.bind(this, 'sx')} suffix="天" />
                                        ~
                                        <Input type="text" min={0} placeholder="请输入" value={this.state.condition.sx2 ? this.state.condition.sx2 : null} onChange={this.onChange.bind(this, 'sx2')} suffix="天" />
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="单价"
                                    required={true}
                                >
                                    <Input type="text" min={0} placeholder="请输入" value={this.state.condition.dj ? this.state.condition.dj : null} onChange={this.onChange.bind(this, 'dj')} suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="状态"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'status')} value={this.state.condition.status ? this.state.condition.status : null} >
                                        {this.arrradio(this.state.status_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                            <Row justify="center">
                                <Button className='blue' style={{ width: 140 }} loading={this.state.loading} onClick={this.sub.bind(this)}>确认</Button>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}