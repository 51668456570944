import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {},
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        // this.getdata(1)

    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata(1)
        } else {
            that.setState({
                newcondition: {},
                oldcondition: {},
                list: [],
                tableloading: false,
                selectedRowKeys: [],
                count: 0,
                downloading: false,
            })
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Clxx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count)
                })
            })
    }
    chooseCh = (value, e) => {
        let that = this
        let chid_react = this.props.chid_react.concat()
        let index = this.props.index
        let option = {}
        for (const key in chid_react) {
            const item = chid_react[key];
            if (value == item.id) {
                option.key = key
            }
        }
        console.log(value,chid_react)
        if(index>=0){
            that.props.onChange('chid',index,value,option)
        }else{
            that.props.onChange('chid',value,option)
        }
        that.mdchange()
    }
    render() {
        const columns = [

            {
                title: '车辆编号',
                dataIndex: 'clbh',
                key: 'clbh',
                align: "center",
                width: 100,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车牌',
                dataIndex: 'cp',
                key: 'cp',
                fixed: 'left',
                width:100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        <a onClick={this.chooseCh.bind(this, record.id)}>{row}</a>
                    </Tooltip>
                ),
            },
            {
                title: '类型',
                dataIndex: 'sblx_str',
                key: 'sblx_str',
                width:100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '注册日期',
                dataIndex: 'zcrq_str',
                key: 'zcrq_str',
                width:100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '产权所有人',
                dataIndex: 'cqsyr',
                key: 'cqsyr',
                width:200,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '保险日期',
                dataIndex: 'bxrq_str',
                key: 'bxrq_str',
                width:100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '验车日期',
                dataIndex: 'ycrq_str',
                key: 'ycrq_str',
                width:100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '司机一',
                dataIndex: 'sj1_str',
                key: 'sj1_str',
                width:80,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '司机二',
                dataIndex: 'sj2_str',
                key: 'sj2_str',
                width:80,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '备注',
                dataIndex: 'beizhu',
                key: 'beizhu',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 150,
                fixed: 'right',
                render: (row, record) => {
                    return (
                        <>
                            {record.status == 2 ?
                                <a style={{color:'#aaa'}} >已关闭</a>
                            :
                            <Space>
                                {this.props.value == record.id ?
                                    <a className='hongse' onClick={this.chooseCh.bind(this, 0)}>取消选择</a>
                                    :
                                    <a onClick={this.chooseCh.bind(this, record.id)}>选择</a>
                                }
                            </Space>
                            }
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideSelectAll: true,
            preserveSelectedRowKeys: false,
            renderCell: function (checked, record, index, originNode) {
                if (record.status != 1) {
                    return originNode
                } else {
                    return false
                }
            },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <Button type="primary" onClick={this.mdchange.bind(this)} readOnly={this.props.readOnly}>车牌</Button>
                <Modal maskClosable={false} title="车牌" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    {/* <div className='sellist'>
                        <Row justify="space-between">
                            <div></div>
                        </Row>
                    </div> */}
                    <Table
                        bordered
                        columns={columns}
                        sticky={false}
                    dataSource={this.state.list}
                        pagination={false}
                        loading={tableloading}
                        // rowSelection={rowSelection}
                        scroll={{ x: 1600,y:800 }}
                        className='table'
                        size="small"
                    />
                    <div className='page'>
                        <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                    </div>
                </Modal>
            </>
        )
    }
}
