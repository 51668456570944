import React from 'react';
import {Redirect, Link, withRouter} from 'react-router-dom';
import {
    Switch,
    Space,
    Select,
    Input,
    Button,
    Table,
    Modal,
    Row,
    Col,
    Form,
    message,
    Checkbox,
    Radio,
    Pagination,
    Tag,
    Popconfirm,
    Tooltip,
    DatePicker,
    Tabs
} from 'antd';
import store from "../store/store.js";
import {FormOutlined} from '@ant-design/icons'
import moment from 'moment';
import Fkjl from './fkjl.js';
import QcEdit from "./qcadd";


const {Option} = Select;
const {RangePicker} = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
               /* year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,*/
            },
            oldcondition: {},
            list1: [],
            list2: [],
            list3: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
            type: '1'
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }

    componentDidMount() {
        this.props.NAV_CHANGE('/jcxstj', '销售统计')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }

    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 200 - selheight
        that.setState({
            tableHeight: yheight
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, {[name]: dateString})
        this.setState({
            newcondition: data
        })
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
    };

    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, {current: page, pagesize: pagesize})
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }

    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, {current: 1, pagesize: old.pagesize})
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('Jcxstj/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list1: data.data,
                    list2: data.data2,
                    list3: data.data3,
                    qk1: data.qk1,
                    qk2: data.qk2,
                    qk3: data.qk3,
                    tableloading: false,
                    count: Number(data.count),
                    status_react: data.status_react ? data.status_react : [],
                    htbh_react: data.htbh_react ? data.htbh_react : [],
                    gydw_react: data.gydw_react ? data.gydw_react : [],
                    jhdw_react: data.jhdw_react ? data.jhdw_react : [],
                    ysdw_react: data.ysdw_react ? data.ysdw_react : [],
                    qydw_react: data.qydw_react ? data.qydw_react : [],
                    month_react: data.month_react ? data.month_react : [],
                })
            })
    }

    delSel() {
        let that = this
        fetch('Jcxstj/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Jcxstj/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }

    delSel2(id, e) {
        let that = this
        fetch('/Jcxstj/delSel2', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, {
            [name + '1']: dateStrings[0],
            [name + '2']: dateStrings[1]
        })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }

    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }

    onTabChange = (value) => {
        let that = this
        that.setState({
            type: value
        })
    }
    statusChange = (type, hid, jhdwid, ysdwid, status2, e) => {
        let that = this
        fetch('/Jcxstj/statusChange', {
            method: "POST",
            body: JSON.stringify({
                type,
                hid,
                jhdwid,
                ysdwid,
                status2
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                } else {
                    message.success(data.msg)
                    that.getdata()
                    // if (type == 1) {
                    //     that.getdata()
                    // } else if (type == 2) {
                    //     that.getdata2()
                    // } else if (type == 3) {
                    //     that.getdata3()
                    // }
                }

            })
    }

    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                width: 80,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '合同编号',
                dataIndex: 'htbh_str',
                key: 'htbh_str',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应单位',
                dataIndex: 'gydw',
                key: 'gydw',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Space wrap split=",">{row}</Space>
                ),
            },
            {
                title: '供应单价',
                dataIndex: 'gydj',
                key: 'gydj',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应数量',
                dataIndex: 'gysl',
                key: 'gysl',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应结算数量',
                dataIndex: 'gyjssl',
                key: 'gyjssl',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应结算金额',
                dataIndex: 'gyjsje',
                key: 'gyjsje',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应未结算数量',
                dataIndex: 'gywjssl',
                key: 'gywjssl',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应未结算金额',
                dataIndex: 'gywjsje',
                key: 'gywjsje',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '已付款金额',
                dataIndex: 'yfkje1',
                key: 'yfkje1',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Fkjl type={1} hid={record.id} num={row} year={this.state.oldcondition.year}
                          refresh={this.getdata.bind(this)}/>
                ),
            },
            {
                title: '欠款金额',
                dataIndex: 'qkje1',
                key: 'qkje1',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            /*{
                title: '期初',
                dataIndex: 'qc',
                key: 'qc',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <QcEdit type={1} hid={record.id} num={row} year={this.state.oldcondition.year}
                          refresh={this.getdata.bind(this)}/>
                ),
            },
            {
                title: '合计欠款',
                dataIndex: 'hjqk',
                key: 'hjqk',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },*/
            {
                title: '签约单位',
                dataIndex: 'qydw',
                key: 'qydw',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Space wrap split=",">{row}</Space>
                ),
            },
            {
                title: '状态',
                dataIndex: 'status_str',
                key: 'status_str',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <>
                        {record.status == 1 ?
                            <Popconfirm
                                title="确定修改?"
                                onConfirm={this.statusChange.bind(this, 1, record.id, 0, 0, 2)}
                                okText="确定"
                                cancelText="取消"
                                disabled={store.getState().data.info.qx31 ? (store.getState().data.info.qx31 == 1 ? false : true) : true}
                            >
                                <Tag color='green'>{row}</Tag>
                            </Popconfirm>
                            : null}
                        {record.status == 2 ?
                            <Popconfirm
                                title="确定修改?"
                                onConfirm={this.statusChange.bind(this, 1, record.id, 0, 0, 1)}
                                okText="确定"
                                cancelText="取消"
                                disabled={store.getState().data.info.qx31 ? (store.getState().data.info.qx31 == 1 ? false : true) : true}
                            >
                                <Tag color='red'>{row}</Tag>
                            </Popconfirm>
                            : null}
                    </>
                ),
            },
        ]
        const columns2 = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                width: 80,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '合同编号',
                dataIndex: 'htbh_str',
                key: 'htbh_str',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '进货单位',
                dataIndex: 'jhdw_str',
                key: 'jhdw_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    row
                ),
            },
            // {
            //     title: '出厂单价',
            //     dataIndex: 'ccdj',
            //     key: 'ccdj',
            //     width: 100,
            //     ellipsis: {
            //         showTitle: false,
            //     },
            //     render: (row, record) => (
            //         <Tooltip placement="topLeft" title={row}>
            //             {row}
            //         </Tooltip>
            //     ),
            // },
            {
                title: '出厂数量',
                dataIndex: 'ccsl',
                key: 'ccsl',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '进货结算数量',
                dataIndex: 'jhjssl',
                key: 'jhjssl',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '进货结算金额',
                dataIndex: 'jhjsje',
                key: 'jhjsje',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '进货未结算数量',
                dataIndex: 'jhwjssl',
                key: 'jhwjssl',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '进货未结算金额',
                dataIndex: 'jhwjsje',
                key: 'jhwjsje',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '已付款金额',
                dataIndex: 'yfkje2',
                key: 'yfkje2',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Fkjl type={2} hid={record.id} jhdwid={record.jhdw} num={row} year={this.state.oldcondition.year}
                          refresh={this.getdata.bind(this)}/>
                ),
            },
            {
                title: '欠款金额',
                dataIndex: 'qkje2',
                key: 'qkje2',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            /*{
                title: '期初',
                dataIndex: 'qc',
                key: 'qc',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <QcEdit type={2} hid={record.id} jhdwid={record.jhdw} num={row} year={this.state.oldcondition.year}
                          refresh={this.getdata.bind(this)}/>
                ),
            },
            {
                title: '合计欠款',
                dataIndex: 'hjqk',
                key: 'hjqk',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },*/
            {
                title: '签约单位',
                dataIndex: 'qydw',
                key: 'qydw',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Space wrap split=",">{row}</Space>
                ),
            },
            {
                title: '状态',
                dataIndex: 'status_str',
                key: 'status_str',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <>
                        {record.status == 1 ?
                            <Popconfirm
                                title="确定修改?"
                                onConfirm={this.statusChange.bind(this, 2, record.id, record.jhdw, 0, 2)}
                                okText="确定"
                                cancelText="取消"
                                disabled={store.getState().data.info.qx31 ? (store.getState().data.info.qx31 == 1 ? false : true) : true}
                            >
                                <Tag color='green'>{row}</Tag>
                            </Popconfirm>
                            : null}
                        {record.status == 2 ?
                            <Popconfirm
                                title="确定修改?"
                                onConfirm={this.statusChange.bind(this, 2, record.id, record.jhdw, 0, 1)}
                                okText="确定"
                                cancelText="取消"
                                disabled={store.getState().data.info.qx31 ? (store.getState().data.info.qx31 == 1 ? false : true) : true}
                            >
                                <Tag color='red'>{row}</Tag>
                            </Popconfirm>
                            : null}
                    </>
                ),
            },
        ]
        const columns3 = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                width: 80,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '合同编号',
                dataIndex: 'htbh_str',
                key: 'htbh_str',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '运输单位',
                dataIndex: 'ysdw_str',
                key: 'ysdw_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    row
                ),
            },
            {
                title: '运输单价',
                dataIndex: 'ysdj',
                key: 'ysdj',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '运输数量',
                dataIndex: 'yssl',
                key: 'yssl',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '运输结算数量',
                dataIndex: 'ysjssl',
                key: 'ysjssl',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '运输结算金额',
                dataIndex: 'ysjsje',
                key: 'ysjsje',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '运输未结算数量',
                dataIndex: 'yswjssl',
                key: 'yswjssl',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '运输未结算金额',
                dataIndex: 'yswjsje',
                key: 'yswjsje',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '已付款金额',
                dataIndex: 'yfkje3',
                key: 'yfkje3',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Fkjl type={3} hid={record.id} ysdwid={record.ysdw} num={row} year={this.state.oldcondition.year}
                          refresh={this.getdata.bind(this)}/>
                ),
            },
            {
                title: '欠款金额',
                dataIndex: 'qkje3',
                key: 'qkje3',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            /*{
                title: '期初',
                dataIndex: 'qc',
                key: 'qc',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <QcEdit type={3} hid={record.id} ysdwid={record.ysdw} num={row} year={this.state.oldcondition.year}
                          refresh={this.getdata.bind(this)}/>
                ),
            },
            {
                title: '合计欠款',
                dataIndex: 'hjqk',
                key: 'hjqk',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },*/
            {
                title: '签约单位',
                dataIndex: 'qydw',
                key: 'qydw',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Space wrap split=",">{row}</Space>
                ),
            },
            {
                title: '状态',
                dataIndex: 'status_str',
                key: 'status_str',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <>
                        {record.status == 1 ?
                            <Popconfirm
                                title="确认修改?"
                                onConfirm={this.statusChange.bind(this, 3, record.id, 0, record.ysdw, 2)}
                                okText="确定"
                                cancelText="取消"
                                disabled={store.getState().data.info.qx31 ? (store.getState().data.info.qx31 == 1 ? false : true) : true}
                            >
                                <Tag color='green'>{row}</Tag>
                            </Popconfirm>
                            : null}
                        {record.status == 2 ?
                            <Popconfirm
                                title="确定修改?"
                                onConfirm={this.statusChange.bind(this, 3, record.id, 0, record.ysdw, 1)}
                                okText="确定"
                                cancelText="取消"
                                disabled={store.getState().data.info.qx31 ? (store.getState().data.info.qx31 == 1 ? false : true) : true}
                            >
                                <Tag color='red'>{row}</Tag>
                            </Popconfirm>
                            : null}
                    </>
                ),
            },
        ]
        const {tableloading, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll: true,
            preserveSelectedRowKeys: false,
            // renderCell: function (checked, record, index, originNode) {
            //     if (record.status != 1) {
            //         return originNode
            //     } else {
            //         return false
            //     }
            // },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <Tabs activeKey={this.state.type} onChange={this.onTabChange.bind(this)}>
                    <Tabs.TabPane tab={<>供应信息（欠款总金额：{this.state.qk1}元）</>} key="1"></Tabs.TabPane>
                    <Tabs.TabPane tab={<>进货信息（欠款总金额：{this.state.qk2}元）</>} key="2"></Tabs.TabPane>
                    <Tabs.TabPane tab={<>运输信息（欠款总金额：{this.state.qk3}元）</>} key="3"></Tabs.TabPane>
                </Tabs>
                <div className='sellist'>
                    <Row justify="space-between">
                        <Space wrap>
                            {/*<DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year"
                                        value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null}
                                        allowClear={false}/>
                            <Select placeholder="月份" style={{width: 120}}
                                    value={this.state.newcondition.month ? this.state.newcondition.month : 0}
                                    onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                {this.arrsel(this.state.month_react)}
                            </Select>
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'gyrq')}
                                         value={[(this.state.newcondition.gyrq1 ? moment(this.state.newcondition.gyrq1) : null), (this.state.newcondition.gyrq2 ? moment(this.state.newcondition.gyrq2) : null)]}
                                         placeholder={['供应日期', '供应日期']}/>*/}
                            <Select placeholder="合同编号"
                                    value={this.state.newcondition.htbh ? this.state.newcondition.htbh : null}
                                    onChange={this.onSelChange.bind(this, 'htbh')} style={{width: 200}} allowClear
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.htbh_react)}
                            </Select>
                            <Select placeholder="供应单位"
                                    value={this.state.newcondition.gydw ? this.state.newcondition.gydw : null}
                                    onChange={this.onSelChange.bind(this, 'gydw')} style={{width: 200}} allowClear
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.gydw_react)}
                            </Select>
                            <Select placeholder="进货单位"
                                    value={this.state.newcondition.jhdw ? this.state.newcondition.jhdw : null}
                                    onChange={this.onSelChange.bind(this, 'jhdw')} style={{width: 200}} allowClear
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.jhdw_react)}
                            </Select>
                            <Select placeholder="运输单位"
                                    value={this.state.newcondition.ysdw ? this.state.newcondition.ysdw : null}
                                    onChange={this.onSelChange.bind(this, 'ysdw')} style={{width: 200}} allowClear
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.ysdw_react)}
                            </Select>
                            <Select placeholder="签约单位"
                                    value={this.state.newcondition.qydw ? this.state.newcondition.qydw : null}
                                    onChange={this.onSelChange.bind(this, 'qydw')} style={{width: 200}} allowClear
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.qydw_react)}
                            </Select>
                            <Select placeholder="状态"
                                    value={this.state.newcondition.status ? this.state.newcondition.status : null}
                                    onChange={this.onSelChange.bind(this, 'status')} style={{width: 150}} allowClear
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.status_react)}
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>

                    </Row>
                </div>
                {this.state.type == 1 ?
                    <Table
                        bordered
                        columns={
                            columns
                        }
                        sticky={false}
                        dataSource={this.state.list1}
                        pagination={false}
                        loading={tableloading}
                        // rowSelection={rowSelection}
                        scroll={{y: this.state.tableHeight ? this.state.tableHeight : null}}
                        className='table'
                        size="small"
                        summary={(pageData) => {
                            let totalgysl = 0;
                            let totalgyjssl = 0;
                            let totalgyjsje = 0;
                            let totalgywjssl = 0;
                            let totalgywjsje = 0;
                            let totalyfkje1 = 0;
                            let totalqkje1 = 0;
                            let totalhjqk = 0;
                            pageData.forEach(({gysl, gyjssl, gyjsje, gywjssl, gywjsje, yfkje1, qkje1,hjqk}) => {
                                totalgysl += Number(gysl ? gysl : 0);
                                totalgyjssl += Number(gyjssl ? gyjssl : 0);
                                totalgyjsje += Number(gyjsje ? gyjsje : 0);
                                totalgywjssl += Number(gywjssl ? gywjssl : 0);
                                totalgywjsje += Number(gywjsje ? gywjsje : 0);
                                totalyfkje1 += Number(yfkje1 ? yfkje1 : 0);
                                totalqkje1 += Number(qkje1 ? qkje1 : 0);
                                totalhjqk += Number(hjqk ? hjqk : 0);
                            });
                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>{totalgysl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>{totalgyjssl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}>{totalgyjsje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={7}>{totalgywjssl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={8}>{totalgywjsje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={9}>{totalyfkje1.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={10}>{totalqkje1.toFixed(2)}</Table.Summary.Cell>
                                        {/*<Table.Summary.Cell index={11}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={12}>{totalhjqk.toFixed(2)}</Table.Summary.Cell>*/}
                                        <Table.Summary.Cell index={11}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={12}></Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                    : null}
                {this.state.type == 2 ?
                    <Table
                        bordered
                        columns={
                            columns2
                        }
                        sticky={false}
                        dataSource={this.state.list2}
                        pagination={false}
                        loading={tableloading}
                        // rowSelection={rowSelection}
                        scroll={{y: this.state.tableHeight ? this.state.tableHeight : null}}
                        className='table'
                        size="small"
                        summary={(pageData) => {
                            let totalccsl = 0;
                            let totaljhjssl = 0;
                            let totaljhjsje = 0;
                            let totaljhwjssl = 0;
                            let totaljhwjsje = 0;
                            let totalyfkje2 = 0;
                            let totalqkje2 = 0;
                            let totalhjqk = 0
                            pageData.forEach(({ccsl, jhjssl, jhjsje, jhwjssl, jhwjsje, yfkje2, qkje2,hjqk}) => {
                                totalccsl += Number(ccsl ? ccsl : 0);
                                totaljhjssl += Number(jhjssl ? jhjssl : 0);
                                totaljhjsje += Number(jhjsje ? jhjsje : 0);
                                totaljhwjssl += Number(jhwjssl ? jhwjssl : 0);
                                totaljhwjsje += Number(jhwjsje ? jhwjsje : 0);
                                totalyfkje2 += Number(yfkje2 ? yfkje2 : 0);
                                totalqkje2 += Number(qkje2 ? qkje2 : 0);
                                totalhjqk += Number(hjqk ? hjqk : 0);
                            });
                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>{totalccsl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>{totaljhjssl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>{totaljhjsje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}>{totaljhwjssl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={7}>{totaljhwjsje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={8}>{totalyfkje2.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={9}>{totalqkje2.toFixed(2)}</Table.Summary.Cell>
                                        {/*<Table.Summary.Cell index={10}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={11}>{totalhjqk.toFixed(2)}</Table.Summary.Cell>*/}
                                        <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={11}></Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                    : null}
                {this.state.type == 3 ?
                    <Table
                        bordered
                        columns={
                            columns3
                        }
                        sticky={false}
                        dataSource={this.state.list3}
                        pagination={false}
                        loading={tableloading}
                        // rowSelection={rowSelection}
                        scroll={{y: this.state.tableHeight ? this.state.tableHeight : null}}
                        className='table'
                        size="small"
                        summary={(pageData) => {
                            let totalyssl = 0;
                            let totalysjssl = 0;
                            let totalysjsje = 0;
                            let totalyswjssl = 0;
                            let totalyswjsje = 0;
                            let totalyfkje3 = 0;
                            let totalqkje3 = 0;
                            let totalhjqk = 0;
                            pageData.forEach(({yssl, ysjssl, ysjsje, yswjssl, yswjsje, yfkje3, qkje3,hjqk}) => {
                                totalyssl += Number(yssl ? yssl : 0);
                                totalysjssl += Number(ysjssl ? ysjssl : 0);
                                totalysjsje += Number(ysjsje ? ysjsje : 0);
                                totalyswjssl += Number(yswjssl ? yswjssl : 0);
                                totalyswjsje += Number(yswjsje ? yswjsje : 0);
                                totalyfkje3 += Number(yfkje3 ? yfkje3 : 0);
                                totalqkje3 += Number(qkje3 ? qkje3 : 0);
                                totalhjqk += Number(hjqk ? hjqk : 0);
                            });
                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>{totalyssl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>{totalysjssl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}>{totalysjsje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={7}>{totalyswjssl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={8}>{totalyswjsje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={9}>{totalyfkje3.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={10}>{totalqkje3.toFixed(2)}</Table.Summary.Cell>
                                       {/* <Table.Summary.Cell index={11}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={12}>{totalhjqk.toFixed(2)}</Table.Summary.Cell>*/}
                                        <Table.Summary.Cell index={11}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={12}></Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                    : null}
                {/* <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div> */}
            </>
        )
    }
}
