import React from 'react';
import {Redirect, Link, withRouter} from 'react-router-dom';
import {
    Switch,
    Space,
    Select,
    Input,
    Button,
    Table,
    Modal,
    Row,
    Col,
    Form,
    message,
    Checkbox,
    Radio,
    Pagination,
    Tag,
    DatePicker,
    Divider,
    Cascader,
    Alert,
    Upload,
    InputNumber
} from 'antd';
import store from "../store/store.js";
import {PlusOutlined, UploadOutlined} from '@ant-design/icons'
import moment from 'moment';
import Qtedit from "../qita/add.js";

const {Option} = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                type: null,
            },
            visible: false,
            loading: false
        };
    }

    componentDidMount() {
        let that = this
    }

    // 税率计算
    slCalc = (data) => {
        let that = this
        let jshj = data.jshj ? Number(data.jshj) : 0
        let sl = data.sl ? data.sl : 0
        let sl_react = this.state.sl_react ? this.state.sl_react : []
        sl_react.forEach((item, index) => {
            if (sl == item.id) {
                sl = Number(item.name.replace("%", "")) / 100
            }
        })
        let je = jshj / (1 + sl)
        let se = je * sl
        se = se.toFixed(2)
        je = je.toFixed(2)
        let condition = Object.assign({}, this.state.condition, {je, se})
        this.setState({
            condition: condition
        })
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, {[name]: value})
        if (name == 'gmflx') {
            data.gmfmc = null
            data.gmfmc2 = null
        }
        if (name == 'xsflx') {
            data.xsfmc = null
            data.xsfmc2 = null
        }
        this.setState({
            condition: data
        })
        setTimeout(() => {
            if (name == 'sl' || name == 'jshj') {
                that.slCalc(data)
            }
        })
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, {[name]: value})
        this.setState({
            condition: data
        })
        setTimeout(() => {
            if (name == 'jshj') {
                that.slCalc(data)
            }
        })
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, {[name]: dateString})
        this.setState({
            condition: data
        })
    }


    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, {[name]: value})
        that.setState({
            condition: data
        })
    }

    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }

    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }

    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}
                        disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }

    getdata = (arrname, e) => {
        let that = this
        fetch('/Fapiao/view', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                if (arrname) {
                    that.setState({
                        [arrname]: data[arrname] ? data[arrname] : [],
                    })
                    return
                }
                if (this.state.condition.id) {
                    that.setState({
                        condition: data.data,
                    })
                }
                that.setState({
                    fplx_react: data.fplx_react ? data.fplx_react : [],
                    sfkrlx_react: data.sfkrlx_react ? data.sfkrlx_react : [],
                    sl_react: data.sl_react ? data.sl_react : [],
                    sfkr_react1: data.sfkr_react1 ? data.sfkr_react1 : [],
                    sfkr_react2: data.sfkr_react2 ? data.sfkr_react2 : [],
                    sfkr_react3: data.sfkr_react3 ? data.sfkr_react3 : [],
                    sfkr_react4: data.sfkr_react4 ? data.sfkr_react4 : [],
                    fpzf_react: data.fpzf_react ? data.fpzf_react : [],
                })
            })
    }

    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }

    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Fapiao/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }

    picChange(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({loading2: true});
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log(info.file.response)
            if (info.file.response.status == 0) {
                message.error(info.file.response.msg)
                return
            }

            // var fjlist = this.state.condition.fjlist
            // fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, {
                [name]: info.file.response.id,
                [name + '_url']: info.file.response.url,
                [name + '_name']: info.file.response.name
            })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };

    clearNewField = (open) => {
        let that = this
        setTimeout(() => {
            let condition = Object.assign({}, that.state.condition, {newfield: null})
            that.setState({
                condition: condition,
            })
        });
    }
    function

    numberToChinese(str) {
        var num = parseFloat(str);
        var strOutput = "",
            strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分';
        num += "00";
        var intPos = num.indexOf('.');
        if (intPos >= 0) {
            num = num.substring(0, intPos) + num.substr(intPos + 1, 2);
        }
        strUnit = strUnit.substr(strUnit.length - num.length);
        for (var i = 0; i < num.length; i++) {
            strOutput += '零壹贰叁肆伍陆柒捌玖'.substr(num.substr(i, 1), 1) + strUnit.substr(i, 1);
        }
        return strOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零').replace(/零([亿|万])/g, '$1').replace(/零+元/, '元').replace(/亿零{0,3}万/, '亿').replace(/^元/, "零元")
    }

    render() {
        return (
            <>
                {this.state.condition.id ?
                    <a href='javascript:void(0)'
                       onClick={this.mdchange.bind(this)}>{store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ? '编辑' : '查看') : '查看'}</a>
                    :
                    <>
                        {this.props.type == "link" ?
                            <a onClick={this.mdchange.bind(this)}>新增</a>
                            :
                            <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>
                        }

                    </>
                }
                <Modal maskClosable={false} title="编辑" width={800} open={this.state.visible}
                       onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{span: 24}}
                        disabled={store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={12}>
                                <Form.Item
                                    label="开票日期"
                                    required={true}
                                >
                                    <DatePicker style={{width: '100%'}} placeholder="请输入"
                                                onChange={this.onTimeChange.bind(this, 'riqi')}
                                                value={this.state.condition.riqi ? moment(this.state.condition.riqi) : null}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="发票类型"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.fplx ? this.state.condition.fplx : null}
                                        onChange={this.onSelChange.bind(this, 'fplx')}
                                    >
                                        {this.arrsel(this.state.fplx_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="购买方类型"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.gmflx ? this.state.condition.gmflx : null}
                                        onChange={this.onSelChange.bind(this, 'gmflx')}
                                    >
                                        {this.arrsel(this.state.sfkrlx_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={
                                        <Space>
                                            <>购买方名称</>
                                            {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                <>
                                                    {this.state.condition.gmflx == 3 ?
                                                        <Qtedit type="link"
                                                                refresh={this.getdata.bind(this, 'sfkr_react3')}/>
                                                        : null}
                                                </>
                                                : null) : null}
                                        </Space>
                                    }
                                    required={true}
                                >
                                    {this.state.condition.gmflx == 4 ?
                                        <Select
                                            placeholder="请选择" showSearch optionFilterProp="children"
                                            value={this.state.condition.gmfmc2 ? this.state.condition.gmfmc2 : null}
                                            onChange={this.onSelChange.bind(this, 'gmfmc2')}
                                        >
                                            {this.state.condition.gmflx == 4 ? this.arrsel(this.state.sfkr_react4) : null}
                                        </Select>
                                        :
                                        <Select
                                            placeholder="请选择" showSearch optionFilterProp="children"
                                            value={this.state.condition.gmfmc ? this.state.condition.gmfmc : null}
                                            onChange={this.onSelChange.bind(this, 'gmfmc')}
                                        >
                                            {this.state.condition.gmflx == 1 ? this.arrsel(this.state.sfkr_react1) : null}
                                            {this.state.condition.gmflx == 2 ? this.arrsel(this.state.sfkr_react2) : null}
                                            {this.state.condition.gmflx == 3 ? this.arrsel(this.state.sfkr_react3) : null}
                                        </Select>
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="销售方类型"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.xsflx ? this.state.condition.xsflx : null}
                                        onChange={this.onSelChange.bind(this, 'xsflx')}
                                    >
                                        {this.arrsel(this.state.sfkrlx_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={
                                        <Space>
                                            <>销售方名称</>
                                            {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                <>
                                                    {this.state.condition.xsflx == 3 ?
                                                        <Qtedit type="link"
                                                                refresh={this.getdata.bind(this, 'sfkr_react3')}/>
                                                        : null}
                                                </>
                                                : null) : null}
                                        </Space>
                                    }
                                    required={true}
                                >
                                    {this.state.condition.xsflx == 4 ?
                                        <Select
                                            placeholder="请选择" showSearch optionFilterProp="children"
                                            value={this.state.condition.xsfmc2 ? this.state.condition.xsfmc2 : null}
                                            onChange={this.onSelChange.bind(this, 'xsfmc2')}
                                        >
                                            {this.state.condition.xsflx == 4 ? this.arrsel(this.state.sfkr_react4) : null}
                                        </Select>
                                        :
                                        <Select
                                            placeholder="请选择" showSearch optionFilterProp="children"
                                            value={this.state.condition.xsfmc ? this.state.condition.xsfmc : null}
                                            onChange={this.onSelChange.bind(this, 'xsfmc')}
                                        >
                                            {this.state.condition.xsflx == 1 ? this.arrsel(this.state.sfkr_react1) : null}
                                            {this.state.condition.xsflx == 2 ? this.arrsel(this.state.sfkr_react2) : null}
                                            {this.state.condition.xsflx == 3 ? this.arrsel(this.state.sfkr_react3) : null}
                                        </Select>
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="货物信息" banner style={{marginBottom: 24}}/>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="价税合计（元）"
                                    required={true}
                                    tooltip={"冲红发票请填写负数"}
                                >
                                    <InputNumber type="text" placeholder="请输入"
                                                 style={{width: '100%'}}
                                                 value={this.state.condition.jshj ? this.state.condition.jshj : null}
                                                 onChange={this.onSelChange.bind(this, 'jshj')}
                                                 formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                 parser={(value) => value.replace(/\$\s?|(,*)/g, '')} prefix={"￥"}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="价税合计（大写）"
                                >
                                    <Input placeholder="请输入"
                                           value={this.state.condition.jshj ? this.numberToChinese(this.state.condition.jshj) : null}
                                           onChange={this.onChange.bind(this, 'se')} disabled={true}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="税率"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.sl ? this.state.condition.sl : null}
                                        onChange={this.onSelChange.bind(this, 'sl')}
                                    >
                                        {this.arrsel(this.state.sl_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="税额（元）"
                                >
                                    <InputNumber type="text" placeholder="请输入"
                                                 style={{width: '100%'}}
                                                 value={this.state.condition.se ? this.state.condition.se : null}
                                                 onChange={this.onSelChange.bind(this, 'se')}
                                                 disabled={true}
                                                 formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                 parser={(value) => value.replace(/\$\s?|(,*)/g, '')} prefix={"￥"}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="金额（元）"
                                >
                                    <InputNumber type="text" placeholder="请输入"
                                                 style={{width: '100%'}}
                                                 value={this.state.condition.je ? this.state.condition.je : null}
                                                 onChange={this.onSelChange.bind(this, 'je')}
                                                 disabled={true}
                                                 formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                 parser={(value) => value.replace(/\$\s?|(,*)/g, '')} prefix={"￥"}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="备注"
                                >
                                    <Input placeholder="请输入"
                                           value={this.state.condition.bz ? this.state.condition.bz : null}
                                           onChange={this.onChange.bind(this, 'bz')}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="发票作废"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'fpzf')}
                                                 value={this.state.condition.fpzf ? this.state.condition.fpzf : 2}>
                                        {this.arrradio(this.state.fpzf_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="发票附件"
                                >
                                    <Row justify="space-between">
                                        <Space align="center">
                                            <Upload
                                                showUploadList={false}
                                                action="/Common/uploadfile"
                                                onChange={this.picChange.bind(this, 'fpfj')}
                                                multiple={true}
                                            >
                                                <Button icon={<UploadOutlined/>}>上传</Button>
                                            </Upload>
                                            <div>
                                                <a href={this.state.condition.fpfj_url}
                                                   target="_blank">{this.state.condition.fpfj_name} </a>
                                            </div>
                                        </Space>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                        {store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ?
                            <Row justify="center">
                                <Button className='blue' style={{width: 140}} loading={this.state.loading}
                                        onClick={this.sub.bind(this)}>确认</Button>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}
