import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip, DatePicker, InputNumber } from 'antd';
import store from "../store/store.js";
import { QuestionCircleOutlined } from '@ant-design/icons'
import EditQc from "./add.js";
import Addkh from "./addkh2.js";
import Tz from './tz.js'
import HkTz from './hktz.js'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        this.props.NAV_CHANGE('/hkmx', '回款明细')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }
    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 200 - selheight
        that.setState({
            tableHeight: yheight
        })
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onTableChange = (index, name, value) => {
        let that = this
        console.log(index, name, value)
        let list = that.state.list.concat()
        list[index][name] = value
        list[index]['jsheji'] = (Number(list[index]['js1'] ? list[index]['js1'] : 0)
            + Number(list[index]['js2'] ? list[index]['js2'] : 0)
            + Number(list[index]['js3'] ? list[index]['js3'] : 0)
            + Number(list[index]['js4'] ? list[index]['js4'] : 0)
            + Number(list[index]['js5'] ? list[index]['js5'] : 0)
            + Number(list[index]['js6'] ? list[index]['js6'] : 0)
            + Number(list[index]['js7'] ? list[index]['js7'] : 0)
            + Number(list[index]['js8'] ? list[index]['js8'] : 0)
            + Number(list[index]['js9'] ? list[index]['js9'] : 0)
            + Number(list[index]['js10'] ? list[index]['js10'] : 0)
            + Number(list[index]['js11'] ? list[index]['js11'] : 0)
            + Number(list[index]['js12'] ? list[index]['js12'] : 0)).toFixed(2)

        list[index]['hkheji'] = (Number(list[index]['hk1'] ? list[index]['hk1'] : 0)
            + Number(list[index]['hk2'] ? list[index]['hk2'] : 0)
            + Number(list[index]['hk3'] ? list[index]['hk3'] : 0)
            + Number(list[index]['hk4'] ? list[index]['hk4'] : 0)
            + Number(list[index]['hk5'] ? list[index]['hk5'] : 0)
            + Number(list[index]['hk6'] ? list[index]['hk6'] : 0)
            + Number(list[index]['hk7'] ? list[index]['hk7'] : 0)
            + Number(list[index]['hk8'] ? list[index]['hk8'] : 0)
            + Number(list[index]['hk9'] ? list[index]['hk9'] : 0)
            + Number(list[index]['hk10'] ? list[index]['hk10'] : 0)
            + Number(list[index]['hk11'] ? list[index]['hk11'] : 0)
            + Number(list[index]['hk12'] ? list[index]['hk12'] : 0)).toFixed(2)
        list[index]['ye'] = (Number(list[index]['qc']) + Number(list[index]['jsheji']) - Number(list[index]['hkheji'])).toFixed(2)
        that.setState({
            list: list
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Yszkmx/index2', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    kid_react: data.kid_react ? data.kid_react : [],
                    qid_react: data.qid_react ? data.qid_react : [],
                })
            })
    }
    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Yszkmx/index2', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }
    editRow = (index, state, e) => {
        let that = this
        let list = that.state.list.concat()
        list[index]['isedit'] = state
        console.log(list)
        that.setState({
            list: list
        })
        if (state == 0) {
            fetch('/Yszkmx/doview2', {
                // post提交
                method: "POST",
                body: JSON.stringify(list[index])
            })
                .then(res => res.json())
                .then(data => {

                    if (data.status == 0) {
                        message.error(data.msg)
                        return
                    }
                    if (data.status == 1) {
                        message.success(data.msg)
                        that.getdata()
                    }
                })
        }
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata(1, null)
        } else {
            that.setState({
                condition: {
                    condition: {}
                },
                loading: false
            })
            that.props.refresh()
        }
    }
    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 80,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                onCell: (record, index) => {
                    if (record.type == 1) {
                        return {
                            rowSpan: 2
                        }
                    } else {
                        return {
                            rowSpan: 0
                        }
                    }
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '客户/供应商名称',
                dataIndex: 'khmc',
                key: 'khmc',
                fixed: 'left',
                width: 250,
                ellipsis: {
                    showTitle: false,
                },
                onCell: (record, index) => {
                    if (record.type == 1) {
                        return {
                            rowSpan: 2
                        }
                    } else {
                        return {
                            rowSpan: 0
                        }
                    }
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '签约单位',
                dataIndex: 'qydw',
                key: 'qydw',
                fixed: 'left',
                width: 250,
                ellipsis: {
                    showTitle: false,
                },
                onCell: (record, index) => {
                    if (record.type == 1) {
                        return {
                            rowSpan: 2
                        }
                    } else {
                        return {
                            rowSpan: 0
                        }
                    }
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '分类',
                dataIndex: 'type_str',
                key: 'type_str',
                fixed: 'left',
                width: 80,
                ellipsis: {
                    showTitle: false,
                },
            },
            {
                title: '期初',
                dataIndex: 'qc',
                key: 'qc',
                fixed: 'left',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.isedit == 1) {
                        return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'qc')} />
                    } else {
                        return (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        )
                    }
                }
            },
            {
                title: '调整',
                dataIndex: 'tz',
                key: 'tz',
                fixed: 'left',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    return <Tz refresh={this.getdata.bind(this)} year={this.state.oldcondition.year ? this.state.oldcondition.year : this.state.newcondition.year} num={row} kid={record.kid} qid={record.qid} type={record.type} />
                }
            },
            {
                title: '回款',
                dataIndex: 'tz',
                key: 'tz',
                fixed: 'left',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    return <HkTz refresh={this.getdata.bind(this)} year={this.state.oldcondition.year ? this.state.oldcondition.year : this.state.newcondition.year} num={record.hkheji} kid={record.kid} qid={record.qid} type={record.type} />
                }
            },
            {
                title: <Space><span>余额</span><Tooltip title="期初+结算金额-回款+调整"><QuestionCircleOutlined /></Tooltip></Space>,
                dataIndex: 'ye',
                key: 'ye',
                align: "center",
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => {
                    return (
                        <Tooltip placement="topLeft" title={row}>
                            {row}
                        </Tooltip>
                    )
                }
            },
            {
                title: '结算金额',
                dataIndex: 'js',
                key: 'js',
                children: [
                    {
                        title: '1月',
                        dataIndex: 'js1',
                        key: 'js1',
                        ellipsis: {
                            showTitle: false,
                        },
                    },
                    {
                        title: '2月',
                        dataIndex: 'js2',
                        key: 'js2',
                        ellipsis: {
                            showTitle: false,
                        },
                    },
                    {
                        title: '3月',
                        dataIndex: 'js3',
                        key: 'js3',
                        ellipsis: {
                            showTitle: false,
                        },
                    },
                    {
                        title: '4月',
                        dataIndex: 'js4',
                        key: 'js4',
                        ellipsis: {
                            showTitle: false,
                        },
                    },
                    {
                        title: '5月',
                        dataIndex: 'js5',
                        key: 'js5',
                        ellipsis: {
                            showTitle: false,
                        },
                    },
                    {
                        title: '6月',
                        dataIndex: 'js6',
                        key: 'js6',
                        ellipsis: {
                            showTitle: false,
                        },
                    },
                    {
                        title: '7月',
                        dataIndex: 'js7',
                        key: 'js7',
                        ellipsis: {
                            showTitle: false,
                        },
                    },
                    {
                        title: '8月',
                        dataIndex: 'js8',
                        key: 'js8',
                        ellipsis: {
                            showTitle: false,
                        },
                    },
                    {
                        title: '9月',
                        dataIndex: 'js9',
                        key: 'js9',
                        ellipsis: {
                            showTitle: false,
                        },
                    },
                    {
                        title: '10月',
                        dataIndex: 'js10',
                        key: 'js10',
                        ellipsis: {
                            showTitle: false,
                        },
                    },
                    {
                        title: '11月',
                        dataIndex: 'js11',
                        key: 'js11',
                        ellipsis: {
                            showTitle: false,
                        },
                    },
                    {
                        title: '12月',
                        dataIndex: 'js12',
                        key: 'js12',
                        ellipsis: {
                            showTitle: false,
                        },
                    },
                    {
                        title: '合计',
                        dataIndex: 'jsheji',
                        key: 'jsheji',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                ]
            },
            {
                title: '',
                dataIndex: 'wjs',
                key: 'wjs',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width: 10,
                render: (row, record, index) => {

                }
            },
            {
                title: '回款',
                dataIndex: 'hk',
                key: 'hk',
                children: [
                    {
                        title: '1月',
                        dataIndex: 'hk1',
                        key: 'hk1',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                    {
                        title: '2月',
                        dataIndex: 'hk2',
                        key: 'hk2',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                    {
                        title: '3月',
                        dataIndex: 'hk3',
                        key: 'hk3',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                    {
                        title: '4月',
                        dataIndex: 'hk4',
                        key: 'hk4',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                    {
                        title: '5月',
                        dataIndex: 'hk5',
                        key: 'hk5',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                    {
                        title: '6月',
                        dataIndex: 'hk6',
                        key: 'hk6',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                    {
                        title: '7月',
                        dataIndex: 'hk7',
                        key: 'hk7',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                    {
                        title: '8月',
                        dataIndex: 'hk8',
                        key: 'hk8',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                    {
                        title: '9月',
                        dataIndex: 'hk9',
                        key: 'hk9',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                    {
                        title: '10月',
                        dataIndex: 'hk10',
                        key: 'hk10',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                    {
                        title: '11月',
                        dataIndex: 'hk11',
                        key: 'hk11',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                    {
                        title: '12月',
                        dataIndex: 'hk12',
                        key: 'hk12',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                    {
                        title: '合计',
                        dataIndex: 'hkheji',
                        key: 'hkheji',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                ]
            },

            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 80,
                fixed: 'right',
                render: (row, record, index) => {
                    return (
                        <>
                            {store.getState().data.info.qx9 ? (store.getState().data.info.qx9 == 1 ?
                                <Space>
                                    {record.isedit == 1 ? <a href='javascript:void(0)' onClick={this.editRow.bind(this, index, 0)}>完成</a> : <a href='javascript:void(0)' onClick={this.editRow.bind(this, index, 1)}>编辑</a>}
                                </Space>
                                : null) : null}
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideSelectAll: true,
            preserveSelectedRowKeys: false,
            renderCell: function (checked, record, index, originNode) {
                if (record.status != 1) {
                    return originNode
                } else {
                    return false
                }
            },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" align="top" >
                        <Space wrap style={{ width: 'calc(100% - 200px)' }}>
                            <DatePicker style={{ width: '100%' }} placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null} allowClear={false} />
                            <Input placeholder="客户名称" value={this.state.newcondition.khmc ? this.state.newcondition.khmc : null} onChange={this.onChange.bind(this, 'khmc')} />
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                        {store.getState().data.info.qx9 ? (store.getState().data.info.qx9 == 1 ?
                            <Space wrap>
                                <Addkh refresh={this.getdata.bind(this)} condition={this.state.oldcondition} kid_react={this.state.kid_react} qid_react={this.state.qid_react} />
                                {/* <Button type="primary" className='blue'>下载</Button> */}
                            </Space>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    scroll={{ x: 4000, y: 800 }}
                    className='table'
                    size="small"
                />
            </>
        )
    }
}
