import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag,Divider } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
            },
            qx_react: [],
            status_react: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            fetch('/User/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg)
                        return
                    }
                    if (this.state.condition.id) {
                        that.setState({
                            condition: data.data,
                        })
                    }
                    that.setState({
                        qx_react: data.qx_react,
                        status_react: data.status_react,
                    })
                })
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/User/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    if (this.state.condition.id == store.getState().data.info.id) {
                        this.props.setinfo(data.logininfo)
                    }
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }

    render() {
        return (
            <>
                {this.state.condition.id ? <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx20 ? (store.getState().data.info.qx20 == 1 ?'编辑': '查看') : '查看'}</a> : <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>}
                <Modal maskClosable={false} title="编辑" width={900} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx20 ? (store.getState().data.info.qx20 == 1 ?false: true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={24}>
                                <Form.Item
                                    label="用户名"
                                    required={true}
                                >
                                    <Input className="input" placeholder="请输入" value={this.state.condition.username ? this.state.condition.username : null} onChange={this.onChange.bind(this, 'username')}  />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="密码"
                                    required={this.state.condition.id?false:true}
                                >
                                    <Input.Password className="input" placeholder="请输入" value={this.state.condition.password ? this.state.condition.password : null} onChange={this.onChange.bind(this, 'password')}  />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="姓名"
                                    required={true}
                                >
                                    <Input className="input" placeholder="请输入" value={this.state.condition.name ? this.state.condition.name : null} onChange={this.onChange.bind(this, 'name')}  />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Divider orientation="left">工作表</Divider>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="生产计划权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx25')} value={this.state.condition.qx25 ? this.state.condition.qx25 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="生产记录权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx1')} value={this.state.condition.qx1 ? this.state.condition.qx1 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item
                                    label="加油统计权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx3')} value={this.state.condition.qx3 ? this.state.condition.qx3 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="物资收发权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx4')} value={this.state.condition.qx4 ? this.state.condition.qx4 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="泵管收发权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx5')} value={this.state.condition.qx5 ? this.state.condition.qx5 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="车辆维修权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx6')} value={this.state.condition.qx6 ? this.state.condition.qx6 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {/* <Col span={6}>
                                <Form.Item
                                    label="维修费统计权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx7')} value={this.state.condition.qx7 ? this.state.condition.qx7 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col> */}
                            <Col span={6}>
                                <Form.Item
                                    label="商砼销售权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx8')} value={this.state.condition.qx8 ? this.state.condition.qx8 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="应收应付权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx9')} value={this.state.condition.qx9 ? this.state.condition.qx9 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {/* <Col span={6}>
                                <Form.Item
                                    label="应收应付权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx10')} value={this.state.condition.qx10 ? this.state.condition.qx10 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col> */}
                            <Col span={6}>
                                <Form.Item
                                    label="财务收支权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx11')} value={this.state.condition.qx11 ? this.state.condition.qx11 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="员工考勤权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx12')} value={this.state.condition.qx12 ? this.state.condition.qx12 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="工资计算权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx13')} value={this.state.condition.qx13 ? this.state.condition.qx13 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="结算信息权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx14')} value={this.state.condition.qx14 ? this.state.condition.qx14 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="合同信息权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx15')} value={this.state.condition.qx15 ? this.state.condition.qx15 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="商务成本权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx26')} value={this.state.condition.qx26 ? this.state.condition.qx26 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="建材销售权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx30')} value={this.state.condition.qx30 ? this.state.condition.qx30 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Divider orientation="left">数据表</Divider>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="员工信息表权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx16')} value={this.state.condition.qx16 ? this.state.condition.qx16 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="车辆信息表权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx17')} value={this.state.condition.qx17 ? this.state.condition.qx17 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="信息表权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx18')} value={this.state.condition.qx18 ? this.state.condition.qx18 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {/* <Col span={6}>
                                <Form.Item
                                    label="工程信息表权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx19')} value={this.state.condition.qx19 ? this.state.condition.qx19 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col> */}
                            <Col span={6}>
                                <Form.Item
                                    label="系统账号权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx20')} value={this.state.condition.qx20 ? this.state.condition.qx20 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Divider orientation="left">统计表</Divider>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="外租统计权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx2')} value={this.state.condition.qx2 ? this.state.condition.qx2 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {/* <Col span={6}>
                                <Form.Item
                                    label="总方量权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx21')} value={this.state.condition.qx21 ? this.state.condition.qx21 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="业务成本合计权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx22')} value={this.state.condition.qx22 ? this.state.condition.qx22 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="期间费用权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx23')} value={this.state.condition.qx23 ? this.state.condition.qx23 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="利润统计权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx24')} value={this.state.condition.qx24 ? this.state.condition.qx24 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col> */}
                            <Col span={6}>
                                <Form.Item
                                    label="生产统计权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx27')} value={this.state.condition.qx27 ? this.state.condition.qx27 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="油耗分析权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx28')} value={this.state.condition.qx28 ? this.state.condition.qx28 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="维修统计权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx29')} value={this.state.condition.qx29 ? this.state.condition.qx29 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="销售统计权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx31')} value={this.state.condition.qx31 ? this.state.condition.qx31 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="成本分析权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx32')} value={this.state.condition.qx32 ? this.state.condition.qx32 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="利润分析权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx33')} value={this.state.condition.qx33 ? this.state.condition.qx33 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="商砼统计权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx34')} value={this.state.condition.qx34 ? this.state.condition.qx34 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="未结算统计权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx35')} value={this.state.condition.qx35 ? this.state.condition.qx35 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="收支统计权限"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'qx36')} value={this.state.condition.qx36 ? this.state.condition.qx36 : null}>
                                        {this.arrradio(this.state.qx_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="状态"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'status')} value={this.state.condition.status ? this.state.condition.status : null}>
                                        {this.arrradio(this.state.status_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        {store.getState().data.info.qx20 ? (store.getState().data.info.qx20 == 1 ?
                            <Row justify="center">
                                <Button className='blue' style={{ width: 140 }} loading={this.state.loading} onClick={this.sub.bind(this)}>确认</Button>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}
