import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip, DatePicker, Tabs } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
import Fkjl from './fkjl.js';
const { Option } = Select;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
            list1: [],
            list2: [],
            list3: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
            type: '1'
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        this.props.NAV_CHANGE('/stxstj', '商砼统计')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }
    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 200 - selheight
        that.setState({
            tableHeight: yheight
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('Stxstj/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list1: data.data,
                    list2: data.data2,
                    qk1: data.qk1,
                    qk2: data.qk2,
                    tableloading: false,
                    count: Number(data.count),
                    status_react: data.status_react ? data.status_react : [],
                    month_react: data.month_react ? data.month_react : [],
                    dw_react: data.dw_react ? data.dw_react : [],
                })
            })
    }
    delSel() {
        let that = this
        fetch('Stxstj/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, { [name + '1']: dateStrings[0], [name + '2']: dateStrings[1] })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    onTabChange = (value) => {
        let that = this
        that.setState({
            type: value
        })
    }
    statusChange = (type, dwid, hntjbzid, status2, e) => {
        let that = this
        fetch('/Stxstj/statusChange', {
            method: "POST",
            body: JSON.stringify({
                type,
                dwid,
                hntjbzid,
                status2
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                } else {
                    message.success(data.msg)
                    that.getdata()
                    // if (type == 1) {
                    //     that.getdata()
                    // } else if (type == 2) {
                    //     that.getdata2()
                    // } else if (type == 3) {
                    //     that.getdata3()
                    // }
                }

            })
    }
    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                width: 80,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应单位',
                dataIndex: 'dw_str',
                key: 'dw_str',
                // width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '销售金额',
                dataIndex: 'je',
                key: 'je',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '销售数量',
                dataIndex: 'dcfl',
                key: 'dcfl',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '销售结算数量',
                dataIndex: 'dcjsfl',
                key: 'dcjsfl',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '销售结算金额',
                dataIndex: 'dcjsje',
                key: 'dcjsje',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '销售未结算数量',
                dataIndex: 'dcwjsfl',
                key: 'dcwjsfl',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '销售未结算金额',
                dataIndex: 'dcwjsje',
                key: 'dcwjsje',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '已付款金额',
                dataIndex: 'fkjl',
                key: 'fkjl',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Fkjl type={1} dwid={record.dw} num={row} year={this.state.oldcondition.year} refresh={this.getdata.bind(this)} />
                ),
            },
            {
                title: '欠款金额',
                dataIndex: 'qkje',
                key: 'qkje',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '签约单位',
                dataIndex: 'qydw',
                key: 'qydw',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Space split=",">{row}</Space>
                ),
            },
            {
                title: '状态',
                dataIndex: 'status_str',
                key: 'status_str',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <>
                        {record.status == 1 ?
                            <Popconfirm
                                title="确定修改?"
                                onConfirm={this.statusChange.bind(this, 1, record.dw, 0, 2)}
                                okText="确定"
                                cancelText="取消"
                                disabled={store.getState().data.info.qx34 ? (store.getState().data.info.qx34 == 1 ? false : true) : true}
                            >
                                <Tag color='green'>{row}</Tag>
                            </Popconfirm>
                            : null}
                        {record.status == 2 ?
                            <Popconfirm
                                title="确定修改?"
                                onConfirm={this.statusChange.bind(this, 1, record.dw, 0, 1)}
                                okText="确定"
                                cancelText="取消"
                                disabled={store.getState().data.info.qx34 ? (store.getState().data.info.qx34 == 1 ? false : true) : true}
                            >
                                <Tag color='red'>{row}</Tag>
                            </Popconfirm>
                            : null}
                    </>
                ),
            },
        ]
        const columns2 = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                width: 80,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '混凝土搅拌站',
                dataIndex: 'hntjbzid_str',
                key: 'hntjbzid_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵入金额',
                dataIndex: 'jsje',
                key: 'jsje',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    row
                ),
            },
            {
                title: '抵入数量',
                dataIndex: 'drfl',
                key: 'drfl',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵入结算数量',
                dataIndex: 'drjsfl',
                key: 'drjsfl',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵入结算金额',
                dataIndex: 'drjsje',
                key: 'drjsje',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵入未结算数量',
                dataIndex: 'drwjsfl',
                key: 'drwjsfl',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵入未结算金额',
                dataIndex: 'drwjsje',
                key: 'drwjsje',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '已付款金额',
                dataIndex: 'fkjl',
                key: 'fkjl',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Fkjl type={2} hntjbzid={record.hntjbzid} num={row} year={this.state.oldcondition.year} refresh={this.getdata.bind(this)} />
                ),
            },
            {
                title: '欠款金额',
                dataIndex: 'qkje',
                key: 'qkje',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '签约单位',
                dataIndex: 'qydw',
                key: 'qydw',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Space split=",">{row}</Space>
                ),
            },
            {
                title: '状态',
                dataIndex: 'status_str',
                key: 'status_str',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <>
                        {record.status == 1 ?
                            <Popconfirm
                                title="确定修改?"
                                onConfirm={this.statusChange.bind(this, 2, 0, record.hntjbzid, 2)}
                                okText="确定"
                                cancelText="取消"
                                disabled={store.getState().data.info.qx34 ? (store.getState().data.info.qx34 == 1 ? false : true) : true}
                            >
                                <Tag color='green'>{row}</Tag>
                            </Popconfirm>
                            : null}
                        {record.status == 2 ?
                            <Popconfirm
                                title="确定修改?"
                                onConfirm={this.statusChange.bind(this, 2, 0, record.hntjbzid, 1)}
                                okText="确定"
                                cancelText="取消"
                                disabled={store.getState().data.info.qx34 ? (store.getState().data.info.qx34 == 1 ? false : true) : true}
                            >
                                <Tag color='red'>{row}</Tag>
                            </Popconfirm>
                            : null}
                    </>
                ),
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll: true,
            preserveSelectedRowKeys: false,
            // renderCell: function (checked, record, index, originNode) {
            //     if (record.status != 1) {
            //         return originNode
            //     } else {
            //         return false
            //     }
            // },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <Tabs activeKey={this.state.type} onChange={this.onTabChange.bind(this)}>
                    <Tabs.TabPane tab={<>混凝土供应信息（欠款总金额：{this.state.qk1}元）</>} key="1"></Tabs.TabPane>
                    <Tabs.TabPane tab={<>混凝土采购信息（欠款总金额：{this.state.qk2}元）</>} key="2"></Tabs.TabPane>
                </Tabs>
                <div className='sellist'>
                    <Row justify="space-between">
                        <Space wrap>
                            {/*<DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null} allowClear={false} />
                            <Select placeholder="月份" style={{ width: 120 }} value={this.state.newcondition.month ? this.state.newcondition.month : 0} onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                {this.arrsel(this.state.month_react)}
                            </Select>*/}
                            <RangePicker allowEmpty={[true, true]} picker="month" onChange={this.onRangeChange.bind(this, 'addtime')} value={[(this.state.newcondition.addtime1 ? moment(this.state.newcondition.addtime1) : null), (this.state.newcondition.addtime2 ? moment(this.state.newcondition.addtime2) : null)]} placeholder={['生产日期', '生产日期']} />
                            <Select placeholder="供应单位" value={this.state.newcondition.dw ? this.state.newcondition.dw : null} onChange={this.onSelChange.bind(this, 'dw')} style={{ width: 250 }} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.dw_react)}
                            </Select>
                            <Select placeholder="状态" value={this.state.newcondition.status ? this.state.newcondition.status : null} onChange={this.onSelChange.bind(this, 'status')} style={{ width: 150 }} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.status_react)}
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>

                    </Row>
                </div>
                {this.state.type == 1 ?
                    <Table
                        bordered
                        columns={
                            columns
                        }
                        sticky={false}
                        dataSource={this.state.list1}
                        pagination={false}
                        loading={tableloading}
                        // rowSelection={rowSelection}
                        scroll={{ y: this.state.tableHeight ? this.state.tableHeight : null }}
                        className='table'
                        size="small"
                        summary={(pageData) => {
                            let totalje = 0;
                            let totaldcfl = 0;
                            let totaldcjsfl = 0;
                            let totaldcjsje = 0;
                            let totaldcwjsfl = 0;
                            let totaldcwjsje = 0;
                            let totalfkjl = 0;
                            let totalqkje = 0;
                            pageData.forEach(({ je, dcfl, dcjsfl, dcjsje, dcwjsfl, dcwjsje, fkjl ,qkje}) => {
                                totalje += Number(je ? je : 0);
                                totaldcfl += Number(dcfl ? dcfl : 0);
                                totaldcjsfl += Number(dcjsfl ? dcjsfl : 0);
                                totaldcjsje += Number(dcjsje ? dcjsje : 0);
                                totaldcwjsfl += Number(dcwjsfl ? dcwjsfl : 0);
                                totaldcwjsje += Number(dcwjsje ? dcwjsje : 0);
                                totalfkjl += Number(fkjl ? fkjl : 0);
                                totalqkje += Number(qkje ? qkje : 0);
                            });
                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>{totalje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>{totaldcfl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>{totaldcjsfl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>{totaldcjsje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}>{totaldcwjsfl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={7}>{totaldcwjsje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={8}>{totalfkjl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={9}>{totalqkje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={11}></Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                    : null}
                {this.state.type == 2 ?
                    <Table
                        bordered
                        columns={
                            columns2
                        }
                        sticky={false}
                        dataSource={this.state.list2}
                        pagination={false}
                        loading={tableloading}
                        // rowSelection={rowSelection}
                        scroll={{ y: this.state.tableHeight ? this.state.tableHeight : null }}
                        className='table'
                        size="small"
                        summary={(pageData) => {
                            let totaljsje = 0;
                            let totaldrfl = 0;
                            let totaldrjsfl = 0;
                            let totaldrjsje = 0;
                            let totaldrwjsfl = 0;
                            let totaldcwjsje = 0;
                            let totalfkjl = 0;
                            let totalqkje = 0;
                            pageData.forEach(({ jsje, drfl, drjsfl, drjsje, drwjsfl, dcwjsje, fkjl ,qkje}) => {
                                totaljsje += Number(jsje ? jsje : 0);
                                totaldrfl += Number(drfl ? drfl : 0);
                                totaldrjsfl += Number(drjsfl ? drjsfl : 0);
                                totaldrjsje += Number(drjsje ? drjsje : 0);
                                totaldrwjsfl += Number(drwjsfl ? drwjsfl : 0);
                                totaldcwjsje += Number(dcwjsje ? dcwjsje : 0);
                                totalfkjl += Number(fkjl ? fkjl : 0);
                                totalqkje += Number(qkje ? qkje : 0);
                            });
                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>{totaljsje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>{totaldrfl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>{totaldrjsfl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>{totaldrjsje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}>{totaldrwjsfl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={7}>{totaldcwjsje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={8}>{totalfkjl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={9}>{totalqkje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={11}></Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                    : null}
            </>
        )
    }
}
