import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm,Upload } from 'antd';
import store from "../store/store.js";
import { FormOutlined,UploadOutlined,DeleteOutlined,PlusOutlined} from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                now: new Date()
            },
            list: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        setTimeout(function () {
            if (name == 'srsl' || name == 'srdj') {
                let srsl = data.srsl ? data.srsl : 0
                let srdj = data.srdj ? data.srdj : 0
                var srje = (Number(srsl) * Number(srdj)).toFixed(2)
                let data2 = Object.assign({}, that.state.condition, { srje: srje })
                that.setState({
                    condition: data2
                })
            }
            if (name == 'fcsl' || name == 'fcdj') {
                let fcsl = data.fcsl ? data.fcsl : 0
                let fcdj = data.fcdj ? data.fcdj : 0
                var fcje = (Number(fcsl) * Number(fcdj)).toFixed(2)
                let data2 = Object.assign({}, that.state.condition, { fcje: fcje })
                that.setState({
                    condition: data2
                })
            }
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index} disabled={(item.isdel == 1 ||  item.status==2) ? true : false}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 ||  item.status==2) ? true : false}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
            if (that.props.notable != 1) {
                that.getdata2()
            }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                    now: new Date()
                },
                loading: false
            })
        }
    }
    getdata = (arrname,e) =>{
        let that = this
        fetch('/Bgsfhz/view', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                if(arrname){
                    that.setState({
                        [arrname]: data[arrname] ? data[arrname] : [],
                    })
                    return
                }
                if (this.state.condition.id) {
                    that.setState({
                        condition: data.data,
                    })
                }
                that.setState({
                    sffl_react: data.sffl_react?data.sffl_react:[],
                    projectid_react: data.projectid_react?data.projectid_react:[],
                    sgdwid_react: data.sgdwid_react?data.sgdwid_react:[],
                    khid_react: data.khid_react ? data.khid_react : []
                })
            })
    }
    sub(isnext = 0, e) {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Bgsfhz/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    if (isnext == 1) {
                        that.nextAdd()
                    } else {
                        this.mdchange()
                    }
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    getdata2(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        fetch('/Bgsfhz/index', {
            // post提交
            method: "POST",
            body: JSON.stringify({
                limit: 3
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count)
                })
            })
    }
    //继续添加
    nextAdd = (e) => {
        let that = this
        that.setState({
            condition: {
                id: 0,
            },
            list: [],
            loading: false
        })
        that.getdata2()
    }
    addItem_projectid = () => {
        let that = this
        fetch('/Xmmcbg/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, projectid: data.projectid ? data.projectid : null })
                    this.setState({
                        condition: condition,
                        projectid_react: data.projectid_react ? data.projectid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_sgdwid = () => {
        let that = this
        fetch('/Sgdw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, sgdwid: data.sgdwid ? data.sgdwid : null })
                    this.setState({
                        condition: condition,
                        sgdwid_react: data.sgdwid_react ? data.sgdwid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_khid = () => {
        let that = this
        fetch('/Hntjbz/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, khid: data.khid ? data.khid : null })
                    this.setState({
                        condition: condition,
                        khid_react: data.khid_react ? data.khid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    clearNewField = (open) =>{
        let that = this
        setTimeout(() => {
            let condition = Object.assign({}, that.state.condition, { newfield: null })
            that.setState({
                condition: condition,
            })
        });
    }
     picChange2(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file.response)

            var fjlist = this.state.condition[name]
            if (!fjlist) {
                fjlist = []
            }
            fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: fjlist })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    delpic(index, name, e) {
        var fjlist = this.state.condition[name]
        fjlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { [name]: fjlist })
        this.setState({
            condition: data,
        })
    }
    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 80,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '收发分类',
                dataIndex: 'sffl_str',
                key: 'sffl_str',
                align: "center",
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '收发日期',
                dataIndex: 'riqi_str',
                key: 'riqi_str',
                align: "center",
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '项目名称',
                dataIndex: 'xmmc',
                key: 'xmmc',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '施工单位',
                dataIndex: 'sgdw_str',
                key: 'sgdw_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '3米直管',
                dataIndex: 'smzg',
                key: 'smzg',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '2米直管',
                dataIndex: 'lmzg',
                key: 'lmzg',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '1.5米',
                dataIndex: 'ydwm',
                key: 'ydwm',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '0.5米直管',
                dataIndex: 'ldwmzg',
                key: 'ldwmzg',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '弯头大90°',
                dataIndex: 'wtdjsd',
                key: 'wtdjsd',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '弯头中90°',
                dataIndex: 'wtzjsd',
                key: 'wtzjsd',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '弯头小90°',
                dataIndex: 'wtxjsd',
                key: 'wtxjsd',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '45°',
                dataIndex: 'sswd',
                key: 'sswd',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '管卡',
                dataIndex: 'gk',
                key: 'gk',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '胶管',
                dataIndex: 'jg',
                key: 'jg',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '高压胶圈',
                dataIndex: 'gyjq',
                key: 'gyjq',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '溜管直管',
                dataIndex: 'lgzg',
                key: 'lgzg',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '溜管弯管',
                dataIndex: 'lgwg',
                key: 'lgwg',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '配套螺栓',
                dataIndex: 'ptls',
                key: 'ptls',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '溜槽',
                dataIndex: 'lc',
                key: 'lc',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '备注',
                dataIndex: 'beizhu',
                key: 'beizhu',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
        ]
        const { tableloading } = this.state;
        return (
            <>
                {this.state.condition.id ? <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx5 ? (store.getState().data.info.qx5 == 1 ?'编辑': '查看') : '查看'}</a> : <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>}
                <Modal maskClosable={false} title="编辑" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx5 ? (store.getState().data.info.qx5 == 1 ?false: true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={8}>
                                <Form.Item
                                    label="收发日期"
                                    required={true}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'riqi')} value={this.state.condition.riqi ? moment(this.state.condition.riqi) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="创建日期"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'addtime')} value={this.state.condition.addtime ? moment(this.state.condition.addtime) : moment(this.state.condition.now)} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="项目信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="收发分类"
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.sffl ? this.state.condition.sffl : null} onChange={this.onSelChange.bind(this, 'sffl')}>
                                        {this.arrsel(this.state.sffl_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="项目名称（泵管）"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.projectid ? this.state.condition.projectid : null}
                                        onChange={this.onSelChange.bind(this, 'projectid')}
                                        onDropdownVisibleChange = {this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_projectid.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.projectid_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                             {/* <Col span={8}>
                                <Form.Item
                                    label="项目名称（泵管）"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.xmmc ? this.state.condition.xmmc : null} onChange={this.onChange.bind(this, 'xmmc')}  />
                                </Form.Item>
                            </Col> */}
                            {/*<Col span={8}>
                                <Form.Item
                                    label="施工单位"
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.sgdwid ? this.state.condition.sgdwid : null}
                                        onChange={this.onSelChange.bind(this, 'sgdwid')}
                                        onDropdownVisibleChange = {this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_sgdwid.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.sgdwid_react)}
                                    </Select>
                                </Form.Item>
                            </Col>*/}
                            <Col span={8}>
                                <Form.Item
                                    label="客户"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.khid ? this.state.condition.khid : null}
                                        onChange={this.onSelChange.bind(this, 'khid')}
                                    >
                                        {this.arrsel(this.state.khid_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="项目联系人"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.xmlxr ? this.state.condition.xmlxr : null} onChange={this.onChange.bind(this, 'xmlxr')}  />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="联系电话"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.lxdh ? this.state.condition.lxdh : null} onChange={this.onChange.bind(this, 'lxdh')}  />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="泵管信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="3米直管"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.smzg ? this.state.condition.smzg : null} onChange={this.onChange.bind(this, 'smzg')}  suffix={<div className='huise'>库存：{this.state.condition.smzgkc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="2米直管"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.lmzg ? this.state.condition.lmzg : null} onChange={this.onChange.bind(this, 'lmzg')}  suffix={<div className='huise'>库存：{this.state.condition.lmzgkc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="1.5米"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.ydwm ? this.state.condition.ydwm : null} onChange={this.onChange.bind(this, 'ydwm')}  suffix={<div className='huise'>库存：{this.state.condition.ydwmkc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="1米直管"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.ymzg ? this.state.condition.ymzg : null} onChange={this.onChange.bind(this, 'ymzg')}  suffix={<div className='huise'>库存：{this.state.condition.ymzgkc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="0.5米直管"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.ldwmzg ? this.state.condition.ldwmzg : null} onChange={this.onChange.bind(this, 'ldwmzg')}  suffix={<div className='huise'>库存：{this.state.condition.ldwmzgkc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="弯头大90°"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.wtdjsd ? this.state.condition.wtdjsd : null} onChange={this.onChange.bind(this, 'wtdjsd')}  suffix={<div className='huise'>库存：{this.state.condition.wtdjsdkc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="弯头中90°"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.wtzjsd ? this.state.condition.wtzjsd : null} onChange={this.onChange.bind(this, 'wtzjsd')}  suffix={<div className='huise'>库存：{this.state.condition.wtzjsdkc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="弯头小90°"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.wtxjsd ? this.state.condition.wtxjsd : null} onChange={this.onChange.bind(this, 'wtxjsd')}  suffix={<div className='huise'>库存：{this.state.condition.wtxjsdkc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="45°"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.sswd ? this.state.condition.sswd : null} onChange={this.onChange.bind(this, 'sswd')}  suffix={<div className='huise'>库存：{this.state.condition.sswdkc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="管卡"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.gk ? this.state.condition.gk : null} onChange={this.onChange.bind(this, 'gk')}  suffix={<div className='huise'>库存：{this.state.condition.gkkc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="胶管"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.jg ? this.state.condition.jg : null} onChange={this.onChange.bind(this, 'jg')}  suffix={<div className='huise'>库存：{this.state.condition.jgkc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="高压胶圈"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.gyjq ? this.state.condition.gyjq : null} onChange={this.onChange.bind(this, 'gyjq')}  suffix={<div className='huise'>库存：{this.state.condition.gyjqkc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="溜管直管"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.lgzg ? this.state.condition.lgzg : null} onChange={this.onChange.bind(this, 'lgzg')}  suffix={<div className='huise'>库存：{this.state.condition.lgzgkc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="溜管弯管"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.lgwg ? this.state.condition.lgwg : null} onChange={this.onChange.bind(this, 'lgwg')}  suffix={<div className='huise'>库存：{this.state.condition.lgwgkc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="配套螺栓"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.ptls ? this.state.condition.ptls : null} onChange={this.onChange.bind(this, 'ptls')}  suffix={<div className='huise'>库存：{this.state.condition.ptlskc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="溜槽"
                                >
                                    <Input type="text" placeholder="请输入" value={this.state.condition.lc ? this.state.condition.lc : null} onChange={this.onChange.bind(this, 'lc')}  suffix={<div className='huise'>库存：{this.state.condition.lckc}</div>} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="备注" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="备注"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.beizhu ? this.state.condition.beizhu : null} onChange={this.onChange.bind(this, 'beizhu')}  />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="附件"
                                    required={true}
                                >
                                    <Row justify="space-between">
                                        <Space align="center">
                                            <Upload
                                                showUploadList={false}
                                                action="/Common/uploadfile"
                                                onChange={this.picChange2.bind(this, 'fj')}
                                                multiple={true}
                                            >
                                                <Button icon={<UploadOutlined />}>上传</Button>
                                            </Upload>
                                            <Space wrap>
                                                {this.state.condition.fj ? this.state.condition.fj.map((item, index) =>
                                                    <div>
                                                        <a href={item.url} target="_blank">{item.name} </a> <DeleteOutlined className="hongse" onClick={this.delpic.bind(this, index, 'fj')} />
                                                    </div>) : null}

                                            </Space>
                                        </Space>
                                    </Row>
                                </Form.Item>
                            </Col>
                            {this.props.notable == 1 ?
                                null
                                :
                                <>
                                    <Col span={24}>
                                        <Divider orientation="left" style={{ marginTop: 0 }}>最近添加信息</Divider>
                                    </Col>
                                    <Col span={24}>
                                        <Table
                                            bordered
                                            columns={columns}
                                            sticky={false}
                    dataSource={this.state.list}
                                            pagination={false}
                                            loading={tableloading}
                                            // rowSelection={rowSelection}
                                            scroll={{ x: 4000, }}
                                            className='table'
                                            size="small"
                                        />
                                    </Col>
                                </>
                            }
                        </Row>
                        {store.getState().data.info.qx5 ? (store.getState().data.info.qx5 == 1 ?
                            <Row justify="center">
                                <Space>
                                    <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this)}>完成</Button>
                                    {!this.state.condition.id ?
                                        <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this, '1')}>完成并继续添加下一条</Button>
                                        : null}
                                </Space>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}
