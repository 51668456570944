import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip, DatePicker } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
import Add from './add';
import Jiesuan from './jiesuan';
import Fkedit from './fkedit';
import Dcdzd from './dcdzd.js';
const { Option } = Select;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {},
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx8)
        })
    }
    componentDidMount() {
        this.props.NAV_CHANGE('/dhntmx', '商砼销售')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }
    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 240 - selheight
        that.setState({
            tableHeight: yheight
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} >{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Dhntmx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    sfbljs_react: data.sfbljs_react ? data.sfbljs_react : [],
                    projectid_react: data.projectid_react ? data.projectid_react : [],
                    sgdwid_react: data.sgdwid_react ? data.sgdwid_react : [],
                    hntjbzid_react: data.hntjbzid_react ? data.hntjbzid_react : [],
                    dw_react: data.dw_react ? data.dw_react : [],
                })
            })
    }
    delSel() {
        let that = this
        fetch('/Dhntmx/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }
    delSel2(id, e) {
        let that = this
        fetch('/Dhntmx/delSel2', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }
    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Dhntmx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }
    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, { [name + '1']: dateStrings[0], [name + '2']: dateStrings[1] })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {},
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 80,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '付款日期',
                dataIndex: 'fkrq_str',
                key: 'fkrq_str',
                ellipsis: {
                    showTitle: false,
                },
                fixed: 'left',
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '结算日期',
                dataIndex: 'jsrq_str',
                key: 'jsrq_str',
                fixed: 'left',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '生产日期',
                dataIndex: 'addtime_str',
                key: 'addtime_str',
                fixed: 'left',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '采购单位',
                dataIndex: 'dw_str',
                key: 'dw_str',
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '实际联络人',
                dataIndex: 'sjllr',
                key: 'sjllr',
                ellipsis: {
                    showTitle: false,
                },
                fixed: 'left',
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '项目名称（抵混凝土）',
                dataIndex: 'xmmc',
                key: 'xmmc',
                ellipsis: {
                    showTitle: false,
                },
                fixed: 'left',
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '混凝土搅拌站',
                dataIndex: 'hntjbz_str',
                key: 'hntjbz_str',
                ellipsis: {
                    showTitle: false,
                },
                fixed: 'left',
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '标号',
                dataIndex: 'bh_str',
                key: 'bh_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '砂浆',
                dataIndex: 'sj',
                key: 'sj',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '差额',
                dataIndex: 'ce',
                key: 'ce',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵入方量',
                dataIndex: 'drfl',
                key: 'drfl',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵出方量',
                dataIndex: 'dcfl',
                key: 'dcfl',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵入单价',
                dataIndex: 'drdj',
                key: 'drdj',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵出单价',
                dataIndex: 'dcdj',
                key: 'dcdj',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵入超时',
                dataIndex: 'cs1',
                key: 'cs1',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵出超时',
                dataIndex: 'cs2',
                key: 'cs2',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵入补运费',
                dataIndex: 'byf1',
                key: 'byf1',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵出补运费',
                dataIndex: 'byf2',
                key: 'byf2',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
             {
                title: '抵入泵费',
                dataIndex: 'drbf',
                key: 'drbf',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
             {
                title: '抵出泵费',
                dataIndex: 'dcbf',
                key: 'dcbf',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵入金额',
                dataIndex: 'jsje',
                key: 'jsje',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '抵出金额',
                dataIndex: 'je',
                key: 'je',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '施工部位',
                dataIndex: 'sgbw',
                key: 'sgbw',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '备注',
                dataIndex: 'beizhu',
                key: 'beizhu',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '对账日期',
                dataIndex: 'dzrq_str',
                key: 'dzrq_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '施工单位（抵混凝土）',
                dataIndex: 'sgdw',
                key: 'sgdw',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '是否办理结算',
                dataIndex: 'sfbljs_str',
                key: 'sfbljs_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '付款状态',
                dataIndex: 'fkzt_str',
                key: 'fkzt_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作人',
                dataIndex: 'aidname',
                key: 'aidname',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={record.updatetime}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 150,
                fixed: 'right',
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <Add id={record.id} refresh={this.getdata.bind(this)} />
                                {store.getState().data.info.qx8 ? (store.getState().data.info.qx8 == 1 ?
                                    <Popconfirm
                                        title="确定删除?"
                                        onConfirm={this.delSel2.bind(this, record.id)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <a href="#" className="hongse">删除</a>
                                    </Popconfirm>
                                    : null) : null}
                            </Space>
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll: true,
            preserveSelectedRowKeys: false,
            // renderCell: function (checked, record, index, originNode) {
            //     if (record.status != 1) {
            //         return originNode
            //     } else {
            //         return false
            //     }
            // },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" align="top" >
                        <Space wrap style={{ width: 'calc(100% - 200px)' }}>
                            <Select placeholder="是否办理结算" value={this.state.newcondition.sfbljs ? this.state.newcondition.sfbljs : null} onChange={this.onSelChange.bind(this, 'sfbljs')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sfbljs_react)}
                            </Select>
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'addtime')} value={[(this.state.newcondition.addtime1 ? moment(this.state.newcondition.addtime1) : null), (this.state.newcondition.addtime2 ? moment(this.state.newcondition.addtime2) : null)]} placeholder={['生产日期', '生产日期']} />
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'jsrq')} value={[(this.state.newcondition.jsrq1 ? moment(this.state.newcondition.jsrq1) : null), (this.state.newcondition.jsrq2 ? moment(this.state.newcondition.jsrq2) : null)]} placeholder={['结算日期', '结算日期']} />
                            <Select placeholder="采购单位" value={this.state.newcondition.dw ? this.state.newcondition.dw : null} onChange={this.onSelChange.bind(this, 'dw')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.dw_react)}
                            </Select>
                            <Input placeholder="施工单位" value={this.state.newcondition.sgdw ? this.state.newcondition.sgdw : null} onChange={this.onChange.bind(this, 'sgdw')} />
                            <Input placeholder="项目名称" value={this.state.newcondition.xmmc ? this.state.newcondition.xmmc : null} onChange={this.onChange.bind(this, 'xmmc')} />
                            {/* <Select placeholder="施工单位" value={this.state.newcondition.sgdwid ? this.state.newcondition.sgdwid : null} onChange={this.onSelChange.bind(this, 'sgdwid')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sgdwid_react)}
                            </Select>
                            <Select placeholder="项目名称" value={this.state.newcondition.projectid ? this.state.newcondition.projectid : null} onChange={this.onSelChange.bind(this, 'projectid')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.projectid_react)}
                            </Select> */}
                            <Select placeholder="混凝土搅拌站" value={this.state.newcondition.hntjbzid ? this.state.newcondition.hntjbzid : null} onChange={this.onSelChange.bind(this, 'hntjbzid')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.hntjbzid_react)}
                            </Select>
                            <div className="site-input-group-wrapper">
                                <Input.Group compact>
                                    <Input
                                        style={{
                                            width: 100,
                                            textAlign: 'center',
                                            borderRight: 0,
                                        }}
                                        type="text"
                                        placeholder="抵入方量"
                                        value={this.state.newcondition.drfl1 ? this.state.newcondition.drfl1 : null}
                                        onChange={this.onChange.bind(this, 'drfl1')}
                                    />
                                    <Input
                                        className="site-input-split"
                                        style={{
                                            width: 30,
                                            borderLeft: 0,
                                            borderRight: 0,
                                            pointerEvents: 'none',
                                        }}
                                        placeholder="~"
                                        disabled
                                    />
                                    <Input
                                        className="site-input-right"
                                        style={{
                                            width: 100,
                                            textAlign: 'center',
                                        }}
                                        type="text"
                                        placeholder="抵入方量"
                                        value={this.state.newcondition.drfl2 ? this.state.newcondition.drfl2 : null}
                                        onChange={this.onChange.bind(this, 'drfl2')}
                                    />
                                </Input.Group>
                            </div>
                            <div className="site-input-group-wrapper">
                                <Input.Group compact>
                                    <Input
                                        style={{
                                            width: 100,
                                            textAlign: 'center',
                                            borderRight: 0,
                                        }}
                                        type="text"
                                        placeholder="抵出方量"
                                        value={this.state.newcondition.dcfl1 ? this.state.newcondition.dcfl1 : null}
                                        onChange={this.onChange.bind(this, 'dcfl1')}
                                    />
                                    <Input
                                        className="site-input-split"
                                        style={{
                                            width: 30,
                                            borderLeft: 0,
                                            borderRight: 0,
                                            pointerEvents: 'none',
                                        }}
                                        placeholder="~"
                                        disabled
                                    />
                                    <Input
                                        className="site-input-right"
                                        style={{
                                            width: 100,
                                            textAlign: 'center',
                                        }}
                                        type="text"
                                        placeholder="抵出方量"
                                        value={this.state.newcondition.dcfl2 ? this.state.newcondition.dcfl2 : null}
                                        onChange={this.onChange.bind(this, 'dcfl2')}
                                    />
                                </Input.Group>
                            </div>
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'fkrq')} value={[(this.state.newcondition.fkrq1 ? moment(this.state.newcondition.fkrq1) : null), (this.state.newcondition.fkrq2 ? moment(this.state.newcondition.fkrq2) : null)]} placeholder={['付款日期', '付款日期']} />
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'dzrq')} value={[(this.state.newcondition.dzrq1 ? moment(this.state.newcondition.dzrq1) : null), (this.state.newcondition.dzrq2 ? moment(this.state.newcondition.dzrq2) : null)]} placeholder={['对账日期', '对账日期']} />
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                        {store.getState().data.info.qx8 ? (store.getState().data.info.qx8 == 1 ?
                            <div style={{ maxWidth: 200 }}>
                                <Space wrap gutter={[6, 6]} style={{ justifyContent: 'flex-end' }}>
                                    <Add refresh={this.getdata.bind(this)} />
                                    {/* <Button type="primary" className='blue'>导出</Button> */}
                                    <Dcdzd />
                                </Space>
                            </div>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    rowSelection={rowSelection}
                    scroll={{ x: 3500, y: this.state.tableHeight ? this.state.tableHeight : null }}
                    className='table'
                    size="small"
                    summary={(pageData) => {
                        let totaldrfl = 0;
                        let totaldcfl = 0;
                        let totaldrdj = 0;
                        let totalcs1 = 0;
                        let totalbyf1 = 0;
                        let totaljsje = 0;
                        let totaldcdj = 0;
                        let totalcs2 = 0;
                        let totalbyf2 = 0;
                        let totaldrbf = 0;
                        let totaldcbf = 0;
                        let totalje = 0;
                        let totalce = 0;
                        pageData.forEach(({ drfl, dcfl, drdj, cs1, byf1, jsje, dcdj, cs2, byf2,drbf,dcbf, je, ce }) => {
                            totaldrfl += Number(drfl ? drfl : 0);
                            totaldcfl += Number(dcfl ? dcfl : 0);
                            totaldrdj += Number(drdj ? drdj : 0);
                            totalcs1 += Number(cs1 ? cs1 : 0);
                            totalbyf1 += Number(byf1 ? byf1 : 0);
                            totaljsje += Number(jsje ? jsje : 0);
                            totaldcdj += Number(dcdj ? dcdj : 0);
                            totalcs2 += Number(cs2 ? cs2 : 0);
                            totalbyf2 += Number(byf2 ? byf2 : 0);
                            totaldrbf += Number(drbf ? drbf : 0);
                            totaldcbf += Number(dcbf ? dcbf : 0);
                            totalje += Number(je ? je : 0);
                            totalce += Number(ce ? ce : 0);
                        });
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}>{totalce.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}>{totaldrfl.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}>{totaldcfl.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={14}>{totaldrdj.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}>{totaldcdj.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={16}>{totalcs1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={17}>{totalcs2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={18}>{totalbyf1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={19}>{totalbyf2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={20}>{totaldrbf.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={21}>{totaldcbf.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={22}>{totaljsje.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={23}>{totalje.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={24}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={25}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={26}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={27}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={28}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={29}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={30}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
                {/* <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div> */}
                <div className='page page3'>
                    <div className='l'>
                        <Space wrap>
                            {hasSelected ?
                                <>
                                    {store.getState().data.info.qx8 ? (store.getState().data.info.qx8 == 1 ?
                                        <>
                                            <Jiesuan refresh={this.getdata.bind(this)} arrid={selectedRowKeys} />
                                            <Fkedit refresh={this.getdata.bind(this)} arrid={selectedRowKeys} />
                                        </>
                                        : null) : null}
                                </>
                                : null}
                        </Space>
                    </div>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div>
            </>
        )
    }
}
