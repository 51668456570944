import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip, DatePicker } from 'antd';
import store from "../store/store.js";
import { AppstoreOutlined, TransactionOutlined, PayCircleOutlined, EditOutlined } from '@ant-design/icons'
import { Column } from '@ant-design/plots';
import Tzcz from './tzcz.js'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        this.props.NAV_CHANGE('/lrfx', '利润分析')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }
    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 150 - selheight
        that.setState({
            tableHeight: yheight
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
        setTimeout(() => {
            that.getdata(1)
        });
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Lrfx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                })
            })
    }
    sub() {
        let that = this
        var arr = that.state.list.concat()
        fetch('/Lrfx/doview', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                message.success(data.msg)
                that.getdata()
            })
    }
    onChange2(name, index, e) {
        let that = this
        let value = e.target.value
        console.log(name, index, value)
        let list = that.state.list.concat()
        list[index][name] = value
        list[index]['heji'] = (Number(list[index]['m1']) + Number(list[index]['m2']) + Number(list[index]['m3']) + Number(list[index]['m4']) + Number(list[index]['m5']) + Number(list[index]['m6']) + Number(list[index]['m7']) + Number(list[index]['m8']) + Number(list[index]['m9']) + Number(list[index]['m10']) + Number(list[index]['m11']) + Number(list[index]['m12'])).toFixed(2)
        this.setState({
            list: list
        })
    }
    onFocus = (name, index, e) => {
        let that = this
        console.log(name, index, e)
        let list = that.state.list.concat()
        list[index][name + '_f'] = 1
        that.setState({
            list
        })
    }
    onBlur = (name, index, e) => {
        let that = this
        console.log(name, index, e)
        let list = that.state.list.concat()
        list[index][name + '_f'] = 0
        that.setState({
            list
        })
    }
    render() {
        const columns = [
            {
                title: '项目',
                dataIndex: 'name',
                key: 'name',
                align: "center",
                // width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
                onCell: (record, index) => {
                    if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                        return {
                            colSpan: 14,
                        };
                    }
                    return {};
                }
            },
            {
                title: '1月',
                dataIndex: 'm1',
                key: 'm1',
                align: "center",
                // width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.name == "设备首付款" || record.name == "设备年还款金额" || record.name == "企业所得税") {
                        return <Input placeholder='请输入' type="text" step="0.01" value={row} prefix={<EditOutlined />} onChange={this.onChange2.bind(this, 'm1', index)} bordered={record.m1_f == 1 ? true : false} onFocus={this.onFocus.bind(this, 'm1', index)} onBlur={this.onBlur.bind(this, 'm1', index)} />
                    } else if (record.name == "调整产值") {
                        return <Tzcz refresh={this.getdata.bind(this)} year={this.state.oldcondition.year ? this.state.oldcondition.year : this.state.newcondition.year} num={row} month={1} />
                    } else {
                        return <>
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        </>
                    }
                },
                onCell: (record, index) => {
                    if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                        return {
                            colSpan: 0,
                        };
                    }
                    return {};
                }
            },
            {
                title: '2月',
                dataIndex: 'm2',
                key: 'm2',
                align: "center",
                // width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.name == "设备首付款" || record.name == "设备年还款金额" || record.name == "企业所得税") {
                        return <Input placeholder='请输入' type="text" step="0.01" value={row} prefix={<EditOutlined />} onChange={this.onChange2.bind(this, 'm2', index)} bordered={record.m2_f == 1 ? true : false} onFocus={this.onFocus.bind(this, 'm2', index)} onBlur={this.onBlur.bind(this, 'm2', index)} />
                    } else if (record.name == "调整产值") {
                        return <Tzcz refresh={this.getdata.bind(this)} year={this.state.oldcondition.year ? this.state.oldcondition.year : this.state.newcondition.year} num={row} month={2} />
                    } else {
                        return <>
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        </>
                    }
                },
                onCell: (record, index) => {
                    if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                        return {
                            colSpan: 0,
                        };
                    }
                    return {};
                }
            },
            {
                title: '3月',
                dataIndex: 'm3',
                key: 'm3',
                align: "center",
                // width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.name == "设备首付款" || record.name == "设备年还款金额" || record.name == "企业所得税") {
                        return <Input placeholder='请输入' type="text" step="0.01" value={row} prefix={<EditOutlined />} onChange={this.onChange2.bind(this, 'm3', index)} bordered={record.m3_f == 1 ? true : false} onFocus={this.onFocus.bind(this, 'm3', index)} onBlur={this.onBlur.bind(this, 'm3', index)} />
                    } else if (record.name == "调整产值") {
                        return <Tzcz refresh={this.getdata.bind(this)} year={this.state.oldcondition.year ? this.state.oldcondition.year : this.state.newcondition.year} num={row} month={3} />
                    } else {
                        return <>
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        </>
                    }
                },
                onCell: (record, index) => {
                    if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                        return {
                            colSpan: 0,
                        };
                    }
                    return {};
                }
            },
            {
                title: '4月',
                dataIndex: 'm4',
                key: 'm4',
                align: "center",
                // width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.name == "设备首付款" || record.name == "设备年还款金额" || record.name == "企业所得税") {
                        return <Input placeholder='请输入' type="text" step="0.01" value={row} prefix={<EditOutlined />} onChange={this.onChange2.bind(this, 'm4', index)} bordered={record.m4_f == 1 ? true : false} onFocus={this.onFocus.bind(this, 'm4', index)} onBlur={this.onBlur.bind(this, 'm4', index)} />
                    } else if (record.name == "调整产值") {
                        return <Tzcz refresh={this.getdata.bind(this)} year={this.state.oldcondition.year ? this.state.oldcondition.year : this.state.newcondition.year} num={row} month={4} />
                    } else {
                        return <>
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        </>
                    }
                },
                onCell: (record, index) => {
                    if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                        return {
                            colSpan: 0,
                        };
                    }
                    return {};
                }
            },
            {
                title: '5月',
                dataIndex: 'm5',
                key: 'm5',
                align: "center",
                // width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.name == "设备首付款" || record.name == "设备年还款金额" || record.name == "企业所得税") {
                        return <Input placeholder='请输入' type="text" step="0.01" value={row} prefix={<EditOutlined />} onChange={this.onChange2.bind(this, 'm5', index)} bordered={record.m5_f == 1 ? true : false} onFocus={this.onFocus.bind(this, 'm5', index)} onBlur={this.onBlur.bind(this, 'm5', index)} />
                    } else if (record.name == "调整产值") {
                        return <Tzcz refresh={this.getdata.bind(this)} year={this.state.oldcondition.year ? this.state.oldcondition.year : this.state.newcondition.year} num={row} month={5} />
                    } else {
                        return <>
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        </>
                    }
                },
                onCell: (record, index) => {
                    if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                        return {
                            colSpan: 0,
                        };
                    }
                    return {};
                }
            },
            {
                title: '6月',
                dataIndex: 'm6',
                key: 'm6',
                align: "center",
                // width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.name == "设备首付款" || record.name == "设备年还款金额" || record.name == "企业所得税") {
                        return <Input placeholder='请输入' type="text" step="0.01" value={row} prefix={<EditOutlined />} onChange={this.onChange2.bind(this, 'm6', index)} bordered={record.m6_f == 1 ? true : false} onFocus={this.onFocus.bind(this, 'm6', index)} onBlur={this.onBlur.bind(this, 'm6', index)} />
                    } else if (record.name == "调整产值") {
                        return <Tzcz refresh={this.getdata.bind(this)} year={this.state.oldcondition.year ? this.state.oldcondition.year : this.state.newcondition.year} num={row} month={6} />
                    } else {
                        return <>
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        </>
                    }
                },
                onCell: (record, index) => {
                    if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                        return {
                            colSpan: 0,
                        };
                    }
                    return {};
                }
            },
            {
                title: '7月',
                dataIndex: 'm7',
                key: 'm7',
                align: "center",
                // width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.name == "设备首付款" || record.name == "设备年还款金额" || record.name == "企业所得税") {
                        return <Input placeholder='请输入' type="text" step="0.01" value={row} prefix={<EditOutlined />} onChange={this.onChange2.bind(this, 'm7', index)} bordered={record.m7_f == 1 ? true : false} onFocus={this.onFocus.bind(this, 'm7', index)} onBlur={this.onBlur.bind(this, 'm7', index)} />
                    } else if (record.name == "调整产值") {
                        return <Tzcz refresh={this.getdata.bind(this)} year={this.state.oldcondition.year ? this.state.oldcondition.year : this.state.newcondition.year} num={row} month={7} />
                    } else {
                        return <>
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        </>
                    }
                },
                onCell: (record, index) => {
                    if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                        return {
                            colSpan: 0,
                        };
                    }
                    return {};
                }
            },
            {
                title: '8月',
                dataIndex: 'm8',
                key: 'm8',
                align: "center",
                // width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.name == "设备首付款" || record.name == "设备年还款金额" || record.name == "企业所得税") {
                        return <Input placeholder='请输入' type="text" step="0.01" value={row} prefix={<EditOutlined />} onChange={this.onChange2.bind(this, 'm8', index)} bordered={record.m8_f == 1 ? true : false} onFocus={this.onFocus.bind(this, 'm8', index)} onBlur={this.onBlur.bind(this, 'm8', index)} />
                    } else if (record.name == "调整产值") {
                        return <Tzcz refresh={this.getdata.bind(this)} year={this.state.oldcondition.year ? this.state.oldcondition.year : this.state.newcondition.year} num={row} month={8} />
                    } else {
                        return <>
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        </>
                    }
                },
                onCell: (record, index) => {
                    if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                        return {
                            colSpan: 0,
                        };
                    }
                    return {};
                }
            },
            {
                title: '9月',
                dataIndex: 'm9',
                key: 'm9',
                align: "center",
                // width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.name == "设备首付款" || record.name == "设备年还款金额" || record.name == "企业所得税") {
                        return <Input placeholder='请输入' type="text" step="0.01" value={row} prefix={<EditOutlined />} onChange={this.onChange2.bind(this, 'm9', index)} bordered={record.m9_f == 1 ? true : false} onFocus={this.onFocus.bind(this, 'm9', index)} onBlur={this.onBlur.bind(this, 'm9', index)} />
                    } else if (record.name == "调整产值") {
                        return <Tzcz refresh={this.getdata.bind(this)} year={this.state.oldcondition.year ? this.state.oldcondition.year : this.state.newcondition.year} num={row} month={9} />
                    } else {
                        return <>
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        </>
                    }
                },
                onCell: (record, index) => {
                    if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                        return {
                            colSpan: 0,
                        };
                    }
                    return {};
                }
            },
            {
                title: '10月',
                dataIndex: 'm10',
                key: 'm10',
                align: "center",
                // width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.name == "设备首付款" || record.name == "设备年还款金额" || record.name == "企业所得税") {
                        return <Input placeholder='请输入' type="text" step="0.01" value={row} prefix={<EditOutlined />} onChange={this.onChange2.bind(this, 'm10', index)} bordered={record.m10_f == 1 ? true : false} onFocus={this.onFocus.bind(this, 'm10', index)} onBlur={this.onBlur.bind(this, 'm10', index)} />
                    } else if (record.name == "调整产值") {
                        return <Tzcz refresh={this.getdata.bind(this)} year={this.state.oldcondition.year ? this.state.oldcondition.year : this.state.newcondition.year} num={row} month={10} />
                    } else {
                        return <>
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        </>
                    }
                },
                onCell: (record, index) => {
                    if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                        return {
                            colSpan: 0,
                        };
                    }
                    return {};
                }
            },
            {
                title: '11月',
                dataIndex: 'm11',
                key: 'm11',
                align: "center",
                // width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.name == "设备首付款" || record.name == "设备年还款金额" || record.name == "企业所得税") {
                        return <Input placeholder='请输入' type="text" step="0.01" value={row} prefix={<EditOutlined />} onChange={this.onChange2.bind(this, 'm11', index)} bordered={record.m11_f == 1 ? true : false} onFocus={this.onFocus.bind(this, 'm11', index)} onBlur={this.onBlur.bind(this, 'm11', index)} />
                    } else if (record.name == "调整产值") {
                        return <Tzcz refresh={this.getdata.bind(this)} year={this.state.oldcondition.year ? this.state.oldcondition.year : this.state.newcondition.year} num={row} month={11} />
                    } else {
                        return <>
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        </>
                    }
                },
                onCell: (record, index) => {
                    if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                        return {
                            colSpan: 0,
                        };
                    }
                    return {};
                }
            },
            {
                title: '12月',
                dataIndex: 'm12',
                key: 'm12',
                align: "center",
                // width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.name == "设备首付款" || record.name == "设备年还款金额" || record.name == "企业所得税") {
                        return <Input placeholder='请输入' type="text" step="0.01" value={row} prefix={<EditOutlined />} onChange={this.onChange2.bind(this, 'm12', index)} bordered={record.m12_f == 1 ? true : false} onFocus={this.onFocus.bind(this, 'm12', index)} onBlur={this.onBlur.bind(this, 'm12', index)} />
                    } else if (record.name == "调整产值") {
                        return <Tzcz refresh={this.getdata.bind(this)} year={this.state.oldcondition.year ? this.state.oldcondition.year : this.state.newcondition.year} num={row} month={12} />
                    } else {
                        return <>
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        </>
                    }
                },
                onCell: (record, index) => {
                    if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                        return {
                            colSpan: 0,
                        };
                    }
                    return {};
                }
            },
            {
                title: '合计',
                dataIndex: 'heji',
                key: 'heji',
                align: "center",
                // width: 50,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
                onCell: (record, index) => {
                    if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                        return {
                            colSpan: 0,
                        };
                    }
                    return {};
                }
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" align="top" >
                        <Space wrap style={{ width: 'calc(100% - 200px)' }}>
                            <DatePicker style={{ width: '100%' }} placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null} allowClear={false} />
                            <Button type="primary" onClick={this.sub.bind(this)}>提交</Button>
                            {/* <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button> */}
                        </Space>
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    scroll={{ y: this.state.tableHeight ? this.state.tableHeight : null }}
                    rowClassName={(record, index) => {
                        if (record.name == "方量" || record.name == "产值" || record.name == "成本" || (record.name == "期间费用" && record.is_title == 1) || record.name == "利润") {
                            return 'lanbj';
                        } else if (record.name == "总方量" || record.name == "总产值" || record.name == "主营业务成本合计" || (record.name == "期间费用" && record.is_title == -1) || record.name == "利润总额") {
                            return 'huangbj';
                        }
                    }}
                    className='table'
                    size="small"
                />
            </>
        )
    }
}
