import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip,DatePicker } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        this.getdata(1)
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata(1)
        } else {
            that.setState({
                newcondition: {
                    year: new Date().getFullYear().toString(),
                    month: new Date().getMonth() + 1,
                },
                oldcondition: {},
                list: [],
                tableloading: false,
                selectedRowKeys: [],
                count: 0,
                downloading: false,
            })
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} >{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Xmjh/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    projectid_react: data.projectid_react ? data.projectid_react : [],
                    chid_react: data.chid_react ? data.chid_react : [],
                    wdbdw_react: data.wdbdw_react ? data.wdbdw_react : [],
                    sgdwid_react: data.sgdwid_react ? data.sgdwid_react : [],
                    hntjbzid_react: data.hntjbzid_react ? data.hntjbzid_react : [],
                    month_react: data.month_react ? data.month_react : [],
                })
            })
    }
    chooseCh = (value, e) => {
        let that = this
        // let chid_react = this.props.chid_react.concat()
        // let option = {}
        // for (const key in chid_react) {
        //     const item = chid_react[key];
        //     if (value == item.id) {
        //         option.key = key
        //     }
        // }
        that.props.onChange('jhid', value)
        that.mdchange()
    }
    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, { [name + '1']: dateStrings[0], [name + '2']: dateStrings[1] })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    render() {
        const columns = [
            {
                title: '计划名称',
                dataIndex: 'jhmc',
                key: 'jhmc',
                width:100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        <a onClick={this.chooseCh.bind(this, record.id)}>{row}</a>
                    </Tooltip>
                ),
            },
            {
                title: '生产日期',
                dataIndex: 'scrq_str',
                key: 'scrq_str',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '项目名称',
                dataIndex: 'xmmc',
                key: 'xmmc',
                width:150,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '自有设备',
                dataIndex: 'zysb_arr',
                key: 'zysb_arr',
                // ellipsis: {
                //     showTitle: false,
                // },
                render: (row, record) => (
                    <Space wrap split=",">
                        {row}
                    </Space>
                ),
            },
            {
                title: '外租设备',
                dataIndex: 'wzsb_arr',
                key: 'wzsb_arr',
                width:200,
                // ellipsis: {
                //     showTitle: false,
                // },
                render: (row, record) => (
                    <Space wrap split=",">
                        {row}
                    </Space>
                ),
            },
            {
                title: '生产时间',
                dataIndex: 'scsj',
                key: 'scsj',
                width: 90,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '计划方量',
                dataIndex: 'jhfl',
                key: 'jhfl',
                width: 90,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },

            {
                title: '施工单位',
                dataIndex: 'sgdw_str',
                key: 'sgdw_str',
                width:150,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '混凝土搅拌站',
                dataIndex: 'hntjbz_str',
                key: 'hntjbz_str',
                width:150,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 100,
                fixed: 'right',
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                {this.props.value == record.id ?
                                    <a className='hongse'>已选择</a>
                                    :
                                    <a onClick={this.chooseCh.bind(this, record.id)}>选择</a>
                                }
                            </Space>
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideSelectAll: true,
            preserveSelectedRowKeys: false,
            renderCell: function (checked, record, index, originNode) {
                if (record.status != 1) {
                    return originNode
                } else {
                    return false
                }
            },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <Button type="primary" onClick={this.mdchange.bind(this)} >计划名称</Button>
                <Modal maskClosable={false} title="计划名称" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                   <div className='sellist'>
                    <Row justify="space-between" align="top" >
                        <Space wrap>
                            <DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null} />
                            <Select placeholder="月份" style={{ width: 120 }} value={this.state.newcondition.month ? this.state.newcondition.month : 0} onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                {this.arrsel(this.state.month_react)}
                            </Select>
                            <Input placeholder="计划名称" value={this.state.newcondition.jhmc ? this.state.newcondition.jhmc : null} onChange={this.onChange.bind(this, 'jhmc')} />
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'scrq')} value={[(this.state.newcondition.scrq1 ? moment(this.state.newcondition.scrq1) : null), (this.state.newcondition.scrq2 ? moment(this.state.newcondition.scrq2) : null)]} placeholder={['生产日期', '生产日期']} />
                            <Select placeholder="项目名称" value={this.state.newcondition.projectid ? this.state.newcondition.projectid : null} onChange={this.onSelChange.bind(this, 'projectid')} style={{ width: 150 }} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.projectid_react)}
                            </Select>
                            {/* <Select placeholder="车辆编号" value={this.state.newcondition.chid ? this.state.newcondition.chid : null} onChange={this.onSelChange.bind(this, 'chid')} style={{ width: 150 }}  allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.chid_react)}
                            </Select> */}
                            <Select placeholder="施工单位" value={this.state.newcondition.sgdwid ? this.state.newcondition.sgdwid : null} onChange={this.onSelChange.bind(this, 'sgdwid')} style={{ width: 150 }} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sgdwid_react)}
                            </Select>
                            <Select placeholder="混凝土搅拌站" value={this.state.newcondition.hntjbzid ? this.state.newcondition.hntjbzid : null} onChange={this.onSelChange.bind(this, 'hntjbzid')} style={{ width: 150 }} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.hntjbzid_react)}
                            </Select>
                            <Select placeholder="自有设备" value={this.state.newcondition.chid ? this.state.newcondition.chid : null} onChange={this.onSelChange.bind(this, 'chid')} style={{ width: 150 }} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.chid_react)}
                            </Select>
                            <Select placeholder="外租设备" value={this.state.newcondition.wdbdw ? this.state.newcondition.wdbdw : null} onChange={this.onSelChange.bind(this, 'wdbdw')} style={{ width: 150 }} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.wdbdw_react)}
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                    </Row>
                </div>
                    <Table
                        bordered
                        columns={columns}
                        sticky={false}
                        dataSource={this.state.list}
                        pagination={false}
                        loading={tableloading}
                        // rowSelection={rowSelection}
                        scroll={{ x: 1400, y: 800 }}
                        className='table'
                        size="small"
                    />
                    <div className='page'>
                        <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                    </div>
                </Modal>
            </>
        )
    }
}
