import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
import Add from './add';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {},
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        // this.props.NAV_CHANGE('/gcxx', '工程信息表')
        
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            this.getdata(1)
        } else {
            that.setState({
                newcondition: {},
                oldcondition: {},
                list: [],
                tableloading: false,
                selectedRowKeys: [],
                count: 0,
                downloading: false,
            })
            that.props.refresh()
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Zhsz/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count)
                })
            })
    }
    delSel() {
        let that = this
        fetch('/Zhsz/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }
    delSel2(id, e) {
        let that = this
        fetch('/Zhsz/delSel2', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
            })
    }
    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Zhsz/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }
    render() {
        const columns = [
            {
                title: '单位名称',
                dataIndex: 'dwmc',
                key: 'dwmc',
                // fixed:'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '期初资金余额',
                dataIndex: 'qczjye',
                key: 'qczjye',
                // fixed:'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '开户银行',
                dataIndex: 'khyh',
                key: 'khyh',
                // fixed:'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '账号',
                dataIndex: 'zh',
                key: 'zh',
                // fixed:'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '银行简称',
                dataIndex: 'yhjc',
                key: 'yhjc',
                // fixed:'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '是否正常使用',
                dataIndex: 'status',
                key: 'status',
                width: 130,
                render: (row, record) => {
                    return <>
                        {row == 1 ? <Tag color='green'>{record.status_str}</Tag> : null}
                        {row == 2 ? <Tag color='red'>{record.status_str}</Tag> : null}
                    </>
                },
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 100,
                // fixed:'right',
                render: (row, record) => {
                    return (
                        <>
                            {store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ?
                                <Space>
                                    <Add id={record.id} refresh={this.getdata.bind(this)} />
                                    {/* <Popconfirm
                                        title="确定删除?"
                                        onConfirm={this.delSel2.bind(this, record.id)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <a href="#" className="hongse">删除</a>
                                    </Popconfirm> */}
                                </Space>
                                : null) : null}
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideSelectAll: true,
            preserveSelectedRowKeys: false,
            renderCell: function (checked, record, index, originNode) {
                if (record.status != 1) {
                    return originNode
                } else {
                    return false
                }
            },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <a onClick={this.mdchange.bind(this)}>账户设置</a>
                <Modal maskClosable={false} title="编辑" width={1200} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <div className='sellist'>
                        <Row justify="space-between">
                            <div>
                                <Space wrap>
                                    <Input placeholder="单位名称" value={this.state.newcondition.dwmc ? this.state.newcondition.dwmc : null} onChange={this.onChange.bind(this, 'dwmc')}  style={{ width: 200 }} />
                                    <Button type="primary" className='blue' onClick={this.getdata.bind(this, 1)}>搜索</Button>
                                </Space>
                            </div>
                            {store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ?
                                <Space wrap>
                                    <Add refresh={this.getdata.bind(this)} />
                                    {/* <Button type="primary" className='blue'>导出</Button> */}
                                </Space>
                                : null) : null}
                        </Row>
                    </div>
                    <Table
                        bordered
                        columns={columns}
                        sticky={false}
                    dataSource={this.state.list}
                        pagination={false}
                        loading={tableloading}
                        // rowSelection={rowSelection}
                        scroll={{}}
                        className='table'
                        size="small"
                    />
                    <div className='page'>
                        <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                    </div>
                </Modal>
            </>
        )
    }
}
