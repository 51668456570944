import React from 'react';
import {Button, Checkbox, DatePicker, message, Pagination, Popconfirm, Row, Select, Space, Table, Tooltip} from 'antd';
import store from "../store/store.js";
import moment from 'moment';
import Add from './add';
import Jiesuan from './jiesuan.js';
import Dcdzd from './dcdzd.js';
import { InfoCircleOutlined } from '@ant-design/icons'

const {Option} = Select;
const {RangePicker} = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
            type: "1"
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }

    componentDidMount() {
        this.props.NAV_CHANGE('/jcxs', '建材销售')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }

    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 240 - selheight
        that.setState({
            tableHeight: yheight
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, {[name]: dateString})
        this.setState({
            newcondition: data
        })
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
    };

    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, {current: page, pagesize: pagesize})
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }

    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, {current: 1, pagesize: old.pagesize})
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('Jcxs/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    htbh_react: data.htbh_react ? data.htbh_react : [],
                    gydw_react: data.gydw_react ? data.gydw_react : [],
                    jhdw_react: data.jhdw_react ? data.jhdw_react : [],
                    ysdw_react: data.ysdw_react ? data.ysdw_react : [],
                    qydw_react: data.qydw_react ? data.qydw_react : [],
                    month_react: data.month_react ? data.month_react : [],
                    sfbljs_react: data.sfbljs_react ? data.sfbljs_react : [],
                })
            })
    }

    delSel() {
        let that = this
        fetch('Jcxs/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Jcxs/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }

    delSel2(id, e) {
        let that = this
        fetch('/Jcxs/delSel2', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, {
            [name + '1']: dateStrings[0],
            [name + '2']: dateStrings[1]
        })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }

    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }

    onTabChange = (value) => {
        let that = this
        that.setState({
            type: value
        })
    }

    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                width: 60,
                align: 'center',
                ellipsis: {
                    showTitle: false,
                },
                fixed: 'left',
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应日期',
                dataIndex: 'gyrq_str',
                key: 'gyrq_str',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                fixed: 'left',
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '货物名称',
                dataIndex: 'hwmc_str',
                key: 'hwmc_str',
                ellipsis: {
                    showTitle: false,
                },
                fixed: 'left',
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '签约单位',
                dataIndex: 'qydw_str',
                key: 'qydw_str',
                ellipsis: {
                    showTitle: false,
                },
                fixed: 'left',
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '合同编号',
                dataIndex: 'htbh_str',
                key: 'htbh_str',
                width: 130,
                ellipsis: {
                    showTitle: false,
                },
                fixed: 'left',
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '税率(%)',
                dataIndex: 'sl_str',
                key: 'sl_str',
                width: 80,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '税金',
                dataIndex: 'sj',
                key: 'sj',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应单位',
                dataIndex: 'gydw_str',
                key: 'gydw_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应数量',
                dataIndex: 'gysl',
                key: 'gysl',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row} {row != record.ccsl ? <InfoCircleOutlined style={{color: 'red'}}/> : null}
                    </Tooltip>
                ),
            },
            {
                title: '供应单价',
                dataIndex: 'gydj',
                key: 'gydj',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应金额',
                dataIndex: 'gyje',
                key: 'gyje',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '利润金额',
                dataIndex: 'lrje',
                key: 'lrje',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '进货单位',
                dataIndex: 'jhdw_str',
                key: 'jhdw_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '运输单位',
                dataIndex: 'ysdw_str',
                key: 'ysdw_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '出厂数量',
                dataIndex: 'ccsl',
                key: 'ccsl',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '出厂单价',
                dataIndex: 'ccdj',
                key: 'ccdj',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '运输单价',
                dataIndex: 'ysdj',
                key: 'ysdj',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '运费',
                dataIndex: 'yf',
                key: 'yf',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '进货金额',
                dataIndex: 'jhje',
                key: 'jhje',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应是否结算',
                dataIndex: 'gysfjs_str',
                key: 'gysfjs_str',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应结算日期',
                dataIndex: 'gyjsrq_str',
                key: 'gyjsrq_str',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '进货是否结算',
                dataIndex: 'jhsfjs_str',
                key: 'jhsfjs_str',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '进货结算日期',
                dataIndex: 'jhjsrq_str',
                key: 'jhjsrq_str',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '运输是否结算',
                dataIndex: 'yssfjs_str',
                key: 'yssfjs_str',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '运输结算日期',
                dataIndex: 'ysjsrq_str',
                key: 'ysjsrq_str',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '备注',
                dataIndex: 'beizhu',
                key: 'beizhu',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作人',
                dataIndex: 'aidname',
                key: 'aidname',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={record.updatetime}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'caozuo',
                key: 'caozuo',
                width: 80,
                ellipsis: {
                    showTitle: false,
                },
                fixed: 'right',
                render: (row, record) => (
                    <Space>
                        <Add id={record.id} refresh={this.getdata.bind(this)}/>
                        {store.getState().data.info.qx30 ? (store.getState().data.info.qx30 == 1 ?
                            <Popconfirm
                                title="确定删除?"
                                onConfirm={this.delSel2.bind(this, record.id)}
                                okText="确定"
                                cancelText="取消"
                            >
                                <a href="#" className="hongse">删除</a>
                            </Popconfirm>
                            : null) : null}
                    </Space>
                ),
            },
        ]
        const {tableloading, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll: true,
            preserveSelectedRowKeys: false,
            // renderCell: function (checked, record, index, originNode) {
            //     if (record.status != 1) {
            //         return originNode
            //     } else {
            //         return false
            //     }
            // },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" align="top">
                        <Space wrap style={{width: 'calc(100% - 200px)'}}>
                            <DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year"
                                        value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null}/>
                            <Select placeholder="月份" style={{width: 120}}
                                    value={this.state.newcondition.month ? this.state.newcondition.month : 0}
                                    onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                {this.arrsel(this.state.month_react)}
                            </Select>
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'gyrq')}
                                         value={[(this.state.newcondition.gyrq1 ? moment(this.state.newcondition.gyrq1) : null), (this.state.newcondition.gyrq2 ? moment(this.state.newcondition.gyrq2) : null)]}
                                         placeholder={['供应日期', '供应日期']}/>
                            <Select placeholder="合同编号"
                                    value={this.state.newcondition.htbh ? this.state.newcondition.htbh : null}
                                    onChange={this.onSelChange.bind(this, 'htbh')} style={{width: 120}} allowClear
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.htbh_react)}
                            </Select>
                            <Select placeholder="供应单位"
                                    value={this.state.newcondition.gydw ? this.state.newcondition.gydw : null}
                                    onChange={this.onSelChange.bind(this, 'gydw')} style={{width: 200}} allowClear
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.gydw_react)}
                            </Select>
                            <Select placeholder="进货单位"
                                    value={this.state.newcondition.jhdw ? this.state.newcondition.jhdw : null}
                                    onChange={this.onSelChange.bind(this, 'jhdw')} style={{width: 200}} allowClear
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.jhdw_react)}
                            </Select>
                            <Select placeholder="运输单位"
                                    value={this.state.newcondition.ysdw ? this.state.newcondition.ysdw : null}
                                    onChange={this.onSelChange.bind(this, 'ysdw')} style={{width: 200}} allowClear
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.ysdw_react)}
                            </Select>
                            <Select placeholder="签约单位"
                                    value={this.state.newcondition.qydw ? this.state.newcondition.qydw : null}
                                    onChange={this.onSelChange.bind(this, 'qydw')} style={{width: 200}} allowClear
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.qydw_react)}
                            </Select>
                            <Select placeholder="供应是否结算"
                                    value={this.state.newcondition.gysfjs ? this.state.newcondition.gysfjs : null}
                                    onChange={this.onSelChange.bind(this, 'gysfjs')} style={{width: 150}} allowClear
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sfbljs_react)}
                            </Select>
                            <Select placeholder="进货是否结算"
                                    value={this.state.newcondition.jhsfjs ? this.state.newcondition.jhsfjs : null}
                                    onChange={this.onSelChange.bind(this, 'jhsfjs')} style={{width: 150}} allowClear
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sfbljs_react)}
                            </Select>
                            <Select placeholder="运输是否结算"
                                    value={this.state.newcondition.yssfjs ? this.state.newcondition.yssfjs : null}
                                    onChange={this.onSelChange.bind(this, 'yssfjs')} style={{width: 150}} allowClear
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sfbljs_react)}
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                        {store.getState().data.info.qx30 ? (store.getState().data.info.qx30 == 1 ?
                            <div style={{maxWidth: 200}}>
                                <Space wrap>
                                    <Add refresh={this.getdata.bind(this)}/>
                                    <Dcdzd/>
                                </Space>
                            </div>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    rowSelection={rowSelection}
                    scroll={{x: 3200, y: this.state.tableHeight ? this.state.tableHeight : null}}
                    className='table'
                    size="small"
                    summary={(pageData) => {
                        let totalsj = 0;
                        let totalgysl = 0;
                        let totalgyje = 0;
                        let totallrje = 0;
                        let totalccsl = 0;
                        let totalyf = 0;
                        let totaljhje = 0;
                        pageData.forEach(({sj, gysl, gyje, lrje, ccsl, yf, jhje}) => {
                            totalsj += Number(sj ? sj : 0);
                            totalgysl += Number(gysl ? gysl : 0);
                            totalgyje += Number(gyje ? gyje : 0);
                            totallrje += Number(lrje ? lrje : 0);
                            totalccsl += Number(ccsl ? ccsl : 0);
                            totalyf += Number(yf ? yf : 0);
                            totaljhje += Number(jhje ? jhje : 0);
                        });
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>{totalsj.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}>{totalgysl.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}>{totalgyje.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}>{totallrje.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={14}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}>{totalccsl.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={16}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={17}>{totalyf.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={18}>{totaljhje.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={19}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={20}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={21}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={22}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={23}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={24}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={25}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={26}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={27}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={28}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={29}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
                <div className='page page3'>
                    <div className='l'>
                        <Space wrap>
                            {hasSelected ?
                                <>
                                    {store.getState().data.info.qx30 ? (store.getState().data.info.qx30 == 1 ?
                                        <>
                                            <Jiesuan refresh={this.getdata.bind(this)} arrid={selectedRowKeys}/>
                                        </>
                                        : null) : null}
                                </>
                                : null}
                        </Space>
                    </div>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1}
                                pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50}
                                total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true}
                                showTotal={total => `共 ${total} 条数据`}/>
                </div>
            </>
        )
    }
}
