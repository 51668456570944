import React from 'react';
import {
    Button,
    Checkbox,
    DatePicker,
    message,
    Pagination,
    Popconfirm,
    Row,
    Select,
    Space,
    Table,
    Tooltip,
    Cascader
} from 'antd';
import store from "../store/store.js";
import Add from './add.js'
import Zhsz from "../zhsz2/index.js";
import moment from 'moment';

const {RangePicker} = DatePicker;
const {Option} = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
            month_react: [
                {id: 1, name: '1月'},
                {id: 2, name: '2月'},
                {id: 3, name: '3月'},
                {id: 4, name: '4月'},
                {id: 5, name: '5月'},
                {id: 6, name: '6月'},
                {id: 7, name: '7月'},
                {id: 8, name: '8月'},
                {id: 9, name: '9月'},
                {id: 10, name: '10月'},
                {id: 11, name: '11月'},
                {id: 12, name: '12月'},
            ]
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }

    componentDidMount() {
        this.props.NAV_CHANGE('/liushui', '流水')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }

    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 240 - selheight
        that.setState({
            tableHeight: yheight
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, {[name]: dateString})
        this.setState({
            newcondition: data
        })
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        if (name == 'skrlx') {
            data.skr = null
        }
        if (name == 'fkrlx') {
            data.fkr = null
        }
        this.setState({
            newcondition: data
        })
    }

    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
    };

    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, {current: page, pagesize: pagesize})
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }

    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, {current: 1, pagesize: old.pagesize})
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Szqk2/liushui', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    month_react: data.month_react ? data.month_react : [],
                    yt_react: data.yt_react ? data.yt_react : [],
                    sfkrlx_react: data.sfkrlx_react ? data.sfkrlx_react : [],
                    km_react: data.km_react ? data.km_react : [],
                    sfkr_react1: data.sfkr_react1 ? data.sfkr_react1 : [],
                    sfkr_react2: data.sfkr_react2 ? data.sfkr_react2 : [],
                    sfkr_react3: data.sfkr_react3 ? data.sfkr_react3 : [],
                    sfkr_react4: data.sfkr_react4 ? data.sfkr_react4 : [],
                    sfkrlx_react: data.sfkrlx_react ? data.sfkrlx_react : [],
                })
            })
    }

    delSel() {
        let that = this
        fetch('/Szqk2/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    delSel2(id, e) {
        let that = this
        fetch('/Szqk2/delSel2', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
            })
    }

    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Szqk2/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }

    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, {
            [name + '1']: dateStrings[0],
            [name + '2']: dateStrings[1]
        })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }

    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }

    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 60,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '日期',
                dataIndex: 'riqi_str',
                key: 'riqi_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '科目',
                dataIndex: 'km',
                key: 'km',
                width: 200,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '收款人',
                dataIndex: 'skr_str',
                key: 'skr_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 300,
                // width: 300,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '收款人账户',
                dataIndex: 'skrzh_str',
                key: 'skrzh_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 200,
                // width: 300,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '收款人账户结余',
                dataIndex: 'jy',
                key: 'jy',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '付款人',
                dataIndex: 'fkr_str',
                key: 'fkr_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 300,
                // width: 300,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '付款人账户',
                dataIndex: 'fkrzh_str',
                key: 'fkrzh_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 200,
                // width: 300,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '付款人账户结余',
                dataIndex: 'jy2',
                key: 'jy2',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '金额',
                dataIndex: 'je',
                key: 'je',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },

            {
                title: '摘要',
                dataIndex: 'zy',
                key: 'zy',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },

            {
                title: '用途',
                dataIndex: 'yt_str',
                key: 'yt_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '备注',
                dataIndex: 'bz',
                key: 'bz',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '是否开具发票',
                dataIndex: 'sfkjfp_str',
                key: 'sfkjfp_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作人',
                dataIndex: 'aidname',
                key: 'aidname',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={record.updatetime}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 100,
                fixed: 'right',
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <Add id={record.id} refresh={this.getdata.bind(this)}/>
                                {store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ?
                                    <Popconfirm
                                        title="确定删除?"
                                        onConfirm={this.delSel2.bind(this, record.id)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <a href="#" className="hongse">删除</a>
                                    </Popconfirm>
                                    : null) : null}
                            </Space>
                        </>
                    )
                },
            },
        ]
        const {tableloading, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideSelectAll: true,
            preserveSelectedRowKeys: false,
            renderCell: function (checked, record, index, originNode) {
                if (record.status != 1) {
                    return originNode
                } else {
                    return false
                }
            },
        };
        const hasSelected = selectedRowKeys.length > 0;
        const filter = (inputValue, path) =>
            path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" align="top">
                        <div wrap style={{width: 'calc(100% - 200px)'}}>
                            <div style={{marginBottom: 15}}>
                                <Space wrap>
                                    <DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')}
                                                picker="year"
                                                value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null}
                                                allowClear={false}/>
                                    <Select placeholder="月份" style={{width: 120}}
                                            value={this.state.newcondition.month ? this.state.newcondition.month : 0}
                                            onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                        {this.arrsel(this.state.month_react)}
                                    </Select>
                                    <RangePicker allowEmpty={[true, true]}
                                                 onChange={this.onRangeChange.bind(this, 'riqi')}
                                                 value={[(this.state.newcondition.riqi1 ? moment(this.state.newcondition.riqi1) : null), (this.state.newcondition.riqi2 ? moment(this.state.newcondition.riqi2) : null)]}
                                                 placeholder={['日期', '日期']}/>
                                    <Select placeholder="科目" showSearch optionFilterProp="children"
                                            style={{width: 200}}
                                            value={this.state.newcondition.km ? this.state.newcondition.km : null}
                                            onChange={this.onSelChange.bind(this, 'km')} allowClear>
                                        {this.arrsel(this.state.km_react)}
                                    </Select>
                                    <Select placeholder="用途" showSearch optionFilterProp="children"
                                            style={{width: 120}}
                                            value={this.state.newcondition.yt ? this.state.newcondition.yt : null}
                                            onChange={this.onSelChange.bind(this, 'yt')} allowClear>
                                        {this.arrsel(this.state.yt_react)}
                                    </Select>
                                    <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                                    <Button type="primary" className='grey'
                                            onClick={this.reset.bind(this)}>重置</Button>
                                    {store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ?
                                        <Zhsz refresh={this.getdata.bind(this)}/>
                                        : null) : null}
                                </Space>
                            </div>
                            <div>
                                <Space wrap>
                                    <Select placeholder="查询类型" showSearch optionFilterProp="children"
                                            style={{width: 120}}
                                            value={this.state.newcondition.cxlx ? this.state.newcondition.cxlx : null}
                                            onChange={this.onSelChange.bind(this, 'cxlx')} allowClear>
                                        {this.arrsel([{id: 1, name: '分类查询'}, {id: 2, name: '合并查询'}])}
                                    </Select>
                                    {this.state.newcondition.cxlx == 1 ?
                                        <>
                                            <Cascader options={this.state.sfkrlx_react ? this.state.sfkrlx_react : []}
                                                      style={{width: 250}}
                                                      onChange={this.onSelChange.bind(this, 'shoukaunren')}
                                                      placeholder="收款人"
                                                      showSearch={{
                                                          filter,
                                                      }}
                                                      value={this.state.newcondition.shoukaunren ? this.state.newcondition.shoukaunren : []}/>
                                            <Cascader options={this.state.sfkrlx_react ? this.state.sfkrlx_react : []}
                                                      style={{width: 250}}
                                                      onChange={this.onSelChange.bind(this, 'fukuanren')}
                                                      placeholder="付款人"
                                                      showSearch={{
                                                          filter,
                                                      }}
                                                      value={this.state.newcondition.fukuanren ? this.state.newcondition.fukuanren : []}/>
                                            {/* <Select placeholder="收款人类型" showSearch optionFilterProp="children" style={{width: 120}}
                                                value={this.state.newcondition.skrlx ? this.state.newcondition.skrlx : null}
                                                onChange={this.onSelChange.bind(this, 'skrlx')} allowClear>
                                            {this.arrsel(this.state.sfkrlx_react)}
                                        </Select>
                                        {this.state.newcondition.skrlx ?
                                            <Select placeholder="收款人" showSearch optionFilterProp="children" style={{width: 250}}
                                                    value={this.state.newcondition.skr ? this.state.newcondition.skr : null}
                                                    onChange={this.onSelChange.bind(this, 'skr')} allowClear>
                                                {this.state.newcondition.skrlx == 1 ? this.arrsel(this.state.sfkr_react1) : null}
                                                {this.state.newcondition.skrlx == 2 ? this.arrsel(this.state.sfkr_react2) : null}
                                                {this.state.newcondition.skrlx == 3 ? this.arrsel(this.state.sfkr_react3) : null}
                                                {this.state.newcondition.skrlx == 4 ? this.arrsel(this.state.sfkr_react4) : null}
                                            </Select>
                                            : null}
                                        <Select placeholder="付款人类型" showSearch optionFilterProp="children" style={{width: 120}}
                                                value={this.state.newcondition.fkrlx ? this.state.newcondition.fkrlx : null}
                                                onChange={this.onSelChange.bind(this, 'fkrlx')} allowClear>
                                            {this.arrsel(this.state.sfkrlx_react)}
                                        </Select>
                                        {this.state.newcondition.fkrlx ?
                                            <Select placeholder="付款人" showSearch optionFilterProp="children" style={{width: 250}}
                                                    value={this.state.newcondition.fkr ? this.state.newcondition.fkr : null}
                                                    onChange={this.onSelChange.bind(this, 'fkr')} allowClear>
                                                {this.state.newcondition.fkrlx == 1 ? this.arrsel(this.state.sfkr_react1) : null}
                                                {this.state.newcondition.fkrlx == 2 ? this.arrsel(this.state.sfkr_react2) : null}
                                                {this.state.newcondition.fkrlx == 3 ? this.arrsel(this.state.sfkr_react3) : null}
                                                {this.state.newcondition.fkrlx == 4 ? this.arrsel(this.state.sfkr_react4) : null}
                                            </Select>
                                            : null}*/}
                                        </>
                                        : null}
                                    {this.state.newcondition.cxlx == 2 ? <>
                                        <Cascader options={this.state.sfkrlx_react ? this.state.sfkrlx_react : []}
                                                  style={{width: 250}}
                                                  showSearch={{
                                                      filter,
                                                  }}
                                                  onChange={this.onSelChange.bind(this, 'sfkr')} placeholder="收付款人"
                                                  value={this.state.newcondition.sfkr ? this.state.newcondition.sfkr : []}/>

                                    </> : null}

                                </Space>
                            </div>
                        </div>
                        {store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ?
                            <Space wrap>
                                <Add refresh={this.getdata.bind(this)}/>
                                {/* <Button type="primary" className='blue'>导出</Button> */}
                            </Space>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    scroll={{y: this.state.tableHeight ? this.state.tableHeight : null, x: 2400}}
                    className='table'
                    size="small"
                    summary={(pageData) => {
                        let totalje = 0;
                        pageData.forEach(({je}) => {
                            je = je.replace(/,/g,'')
                            totalje += Number(je ? je : 0);
                        });
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}>{totalje.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={14}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={16}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
                <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1}
                                pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50}
                                total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true}
                                showTotal={total => `共 ${total} 条数据`}/>
                </div>
            </>
        )
    }
}
