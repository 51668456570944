import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip, DatePicker } from 'antd';
import store from "../store/store.js";
import { AppstoreOutlined, TransactionOutlined, PayCircleOutlined, EditOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                // year: new Date().getFullYear().toString(),
            },
            oldcondition: {},
            list: [],
            tableloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        this.props.NAV_CHANGE('/fpjs', '发票结算')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }
    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        // let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 150 /*- selheight*/
        that.setState({
            tableHeight: yheight
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
        setTimeout(() => {
            that.getdata(1)
        });
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Yetj/index2', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                })
            })
    }

    render() {
        const columns = [
            {
                title: '付款人/购买方',
                dataIndex: 'fkr_str',
                key: 'fkr_str',
                align: "center",
                // width: 50,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '付款金额',
                dataIndex: 'fkje',
                key: 'fkje',
                align: "center",
            },
            {
                title: '收款人/销售方',
                dataIndex: 'skr_str',
                key: 'skr_str',
                align: "center",
            },
            {
                title: '已开发票',
                dataIndex: 'ykfp',
                key: 'ykfp',
                align: "center",
            },
            {
                title: '欠款',
                dataIndex: 'qk',
                key: 'qk',
                align: "center",
            },
            {
                title: '欠票',
                dataIndex: 'qp',
                key: 'qp',
                align: "center",
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        return (
            <>
               {/* <div className='sellist'>
                    <Row justify="space-between" align="top" >
                        <Space wrap style={{ width: 'calc(100% - 200px)' }}>
                            <DatePicker style={{ width: '100%' }} placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null} allowClear={false} />
                             <Button type="primary" onClick={this.sub.bind(this)}>提交</Button>
                             <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                    </Row>
                </div>*/}
                <Row justify="space-between" align="stretch">
                    <Table
                        bordered
                        columns={columns}
                        sticky={false}
                        dataSource={this.state.list}
                        pagination={false}
                        loading={tableloading}
                        scroll={{ y: this.state.tableHeight ? this.state.tableHeight : null }}
                        className='table'
                        size="small"
                    />
                </Row>
            </>
        )
    }
}
