import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip, DatePicker, InputNumber } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        this.props.NAV_CHANGE('/yfzkmx', '应收应付')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize',this.tableHeight)
    }
    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 240 - selheight
        that.setState({
            tableHeight:yheight
        })
    }
    componentWillUnmount(){
        window.removeEventListener('resize',this.tableHeight)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onTableChange = (index, name, value) => {
        let that = this
        console.log(index, name, value)
        let list = that.state.list.concat()
        list[index][name] = value
        list[index]['jsheji'] = (Number(list[index]['js1'] ? list[index]['js1'] : 0)
            + Number(list[index]['js2'] ? list[index]['js2'] : 0)
            + Number(list[index]['js3'] ? list[index]['js3'] : 0)
            + Number(list[index]['js4'] ? list[index]['js4'] : 0)
            + Number(list[index]['js5'] ? list[index]['js5'] : 0)
            + Number(list[index]['js6'] ? list[index]['js6'] : 0)
            + Number(list[index]['js7'] ? list[index]['js7'] : 0)
            + Number(list[index]['js8'] ? list[index]['js8'] : 0)
            + Number(list[index]['js9'] ? list[index]['js9'] : 0)
            + Number(list[index]['js10'] ? list[index]['js10'] : 0)
            + Number(list[index]['js11'] ? list[index]['js11'] : 0)
            + Number(list[index]['js12'] ? list[index]['js12'] : 0)).toFixed(2)

        list[index]['hkheji'] = (Number(list[index]['hk1'] ? list[index]['hk1'] : 0)
            + Number(list[index]['hk2'] ? list[index]['hk2'] : 0)
            + Number(list[index]['hk3'] ? list[index]['hk3'] : 0)
            + Number(list[index]['hk4'] ? list[index]['hk4'] : 0)
            + Number(list[index]['hk5'] ? list[index]['hk5'] : 0)
            + Number(list[index]['hk6'] ? list[index]['hk6'] : 0)
            + Number(list[index]['hk7'] ? list[index]['hk7'] : 0)
            + Number(list[index]['hk8'] ? list[index]['hk8'] : 0)
            + Number(list[index]['hk9'] ? list[index]['hk9'] : 0)
            + Number(list[index]['hk10'] ? list[index]['hk10'] : 0)
            + Number(list[index]['hk11'] ? list[index]['hk11'] : 0)
            + Number(list[index]['hk12'] ? list[index]['hk12'] : 0)).toFixed(2)

        list[index]['ye'] = (Number(list[index]['qc']) + Number(list[index]['jsheji']) - Number(list[index]['hkheji'])).toFixed(2)
        list[index]['yfyejqysye'] = (Number(list[index]['ye']) - Number(list[index]['ysye'])).toFixed(2)
        console.log(list)
        that.setState({
            list: list
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Yfzkmx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count)
                })
            })
    }
    delSel() {
        let that = this
        fetch('/Yfzkmx/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }
    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Yfzkmx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }
    editRow = (index, state, e) => {
        let that = this
        let list = that.state.list.concat()
        list[index]['isedit'] = state
        console.log(list)
        that.setState({
            list: list
        })
        if (state == 0) {
            fetch('/Yfzkmx/doview', {
                // post提交
                method: "POST",
                body: JSON.stringify(list[index])
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg)
                        return
                    }
                    if (data.status == 1) {
                        message.success(data.msg)
                        that.getdata()
                    }
                })
        }
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 80,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应商名称',
                dataIndex: 'khmc',
                key: 'khmc',
                fixed: 'left',
                width: 200,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '期初',
                dataIndex: 'qc',
                key: 'qc',
                fixed: 'left',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.isedit == 1) {
                        return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'qc')} />
                    } else {
                        return (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        )
                    }
                }
            },
            {
                title: '结算金额',
                dataIndex: 'js',
                key: 'js',
                children: [
                    {
                        title: '1月',
                        dataIndex: 'js1',
                        key: 'js1',
                        ellipsis: {
                            showTitle: false,
                        },
                        // render: (row, record, index) => {
                        //     if (record.isedit == 1) {
                        //         return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'js1')} />
                        //     } else {
                        //         return (
                        //             <Tooltip placement="topLeft" title={row}>
                        //                 {row}
                        //             </Tooltip>
                        //         )
                        //     }
                        // }
                    },
                    {
                        title: '2月',
                        dataIndex: 'js2',
                        key: 'js2',
                        ellipsis: {
                            showTitle: false,
                        },
                        // render: (row, record, index) => {
                        //     if (record.isedit == 1) {
                        //         return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'js2')} />
                        //     } else {
                        //         return (
                        //             <Tooltip placement="topLeft" title={row}>
                        //                 {row}
                        //             </Tooltip>
                        //         )
                        //     }
                        // }
                    },
                    {
                        title: '3月',
                        dataIndex: 'js3',
                        key: 'js3',
                        ellipsis: {
                            showTitle: false,
                        },
                        // render: (row, record, index) => {
                        //     if (record.isedit == 1) {
                        //         return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'js3')} />
                        //     } else {
                        //         return (
                        //             <Tooltip placement="topLeft" title={row}>
                        //                 {row}
                        //             </Tooltip>
                        //         )
                        //     }
                        // }
                    },
                    {
                        title: '4月',
                        dataIndex: 'js4',
                        key: 'js4',
                        ellipsis: {
                            showTitle: false,
                        },
                        // render: (row, record, index) => {
                        //     if (record.isedit == 1) {
                        //         return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'js4')} />
                        //     } else {
                        //         return (
                        //             <Tooltip placement="topLeft" title={row}>
                        //                 {row}
                        //             </Tooltip>
                        //         )
                        //     }
                        // }
                    },
                    {
                        title: '5月',
                        dataIndex: 'js5',
                        key: 'js5',
                        ellipsis: {
                            showTitle: false,
                        },
                        // render: (row, record, index) => {
                        //     if (record.isedit == 1) {
                        //         return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'js5')} />
                        //     } else {
                        //         return (
                        //             <Tooltip placement="topLeft" title={row}>
                        //                 {row}
                        //             </Tooltip>
                        //         )
                        //     }
                        // }
                    },
                    {
                        title: '6月',
                        dataIndex: 'js6',
                        key: 'js6',
                        ellipsis: {
                            showTitle: false,
                        },
                        // render: (row, record, index) => {
                        //     if (record.isedit == 1) {
                        //         return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'js6')} />
                        //     } else {
                        //         return (
                        //             <Tooltip placement="topLeft" title={row}>
                        //                 {row}
                        //             </Tooltip>
                        //         )
                        //     }
                        // }
                    },
                    {
                        title: '7月',
                        dataIndex: 'js7',
                        key: 'js7',
                        ellipsis: {
                            showTitle: false,
                        },
                        // render: (row, record, index) => {
                        //     if (record.isedit == 1) {
                        //         return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'js7')} />
                        //     } else {
                        //         return (
                        //             <Tooltip placement="topLeft" title={row}>
                        //                 {row}
                        //             </Tooltip>
                        //         )
                        //     }
                        // }
                    },
                    {
                        title: '8月',
                        dataIndex: 'js8',
                        key: 'js8',
                        ellipsis: {
                            showTitle: false,
                        },
                        // render: (row, record, index) => {
                        //     if (record.isedit == 1) {
                        //         return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'js8')} />
                        //     } else {
                        //         return (
                        //             <Tooltip placement="topLeft" title={row}>
                        //                 {row}
                        //             </Tooltip>
                        //         )
                        //     }
                        // }
                    },
                    {
                        title: '9月',
                        dataIndex: 'js9',
                        key: 'js9',
                        ellipsis: {
                            showTitle: false,
                        },
                        // render: (row, record, index) => {
                        //     if (record.isedit == 1) {
                        //         return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'js9')} />
                        //     } else {
                        //         return (
                        //             <Tooltip placement="topLeft" title={row}>
                        //                 {row}
                        //             </Tooltip>
                        //         )
                        //     }
                        // }
                    },
                    {
                        title: '10月',
                        dataIndex: 'js10',
                        key: 'js10',
                        ellipsis: {
                            showTitle: false,
                        },
                        // render: (row, record, index) => {
                        //     if (record.isedit == 1) {
                        //         return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'js10')} />
                        //     } else {
                        //         return (
                        //             <Tooltip placement="topLeft" title={row}>
                        //                 {row}
                        //             </Tooltip>
                        //         )
                        //     }
                        // }
                    },
                    {
                        title: '11月',
                        dataIndex: 'js11',
                        key: 'js11',
                        ellipsis: {
                            showTitle: false,
                        },
                        // render: (row, record, index) => {
                        //     if (record.isedit == 1) {
                        //         return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'js11')} />
                        //     } else {
                        //         return (
                        //             <Tooltip placement="topLeft" title={row}>
                        //                 {row}
                        //             </Tooltip>
                        //         )
                        //     }
                        // }
                    },
                    {
                        title: '12月',
                        dataIndex: 'js12',
                        key: 'js12',
                        ellipsis: {
                            showTitle: false,
                        },
                        // render: (row, record, index) => {
                        //     if (record.isedit == 1) {
                        //         return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'js12')} />
                        //     } else {
                        //         return (
                        //             <Tooltip placement="topLeft" title={row}>
                        //                 {row}
                        //             </Tooltip>
                        //         )
                        //     }
                        // }
                    },
                    {
                        title: '合计',
                        dataIndex: 'jsheji',
                        key: 'jsheji',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                ]
            },
            {
                title: '',
                dataIndex: 'wjs',
                key: 'wjs',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width: 10,
                render: (row, record, index) => {

                }
            },
            {
                title: '付款',
                dataIndex: 'hk',
                key: 'hk',
                children: [
                    {
                        title: '1月',
                        dataIndex: 'hk1',
                        key: 'hk1',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            if (record.isedit == 1) {
                                return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'hk1')} />
                            } else {
                                return (
                                    <Tooltip placement="topLeft" title={row}>
                                        {row}
                                    </Tooltip>
                                )
                            }
                        }
                    },
                    {
                        title: '2月',
                        dataIndex: 'hk2',
                        key: 'hk2',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            if (record.isedit == 1) {
                                return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'hk2')} />
                            } else {
                                return (
                                    <Tooltip placement="topLeft" title={row}>
                                        {row}
                                    </Tooltip>
                                )
                            }
                        }
                    },
                    {
                        title: '3月',
                        dataIndex: 'hk3',
                        key: 'hk3',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            if (record.isedit == 1) {
                                return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'hk3')} />
                            } else {
                                return (
                                    <Tooltip placement="topLeft" title={row}>
                                        {row}
                                    </Tooltip>
                                )
                            }
                        }
                    },
                    {
                        title: '4月',
                        dataIndex: 'hk4',
                        key: 'hk4',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            if (record.isedit == 1) {
                                return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'hk4')} />
                            } else {
                                return (
                                    <Tooltip placement="topLeft" title={row}>
                                        {row}
                                    </Tooltip>
                                )
                            }
                        }
                    },
                    {
                        title: '5月',
                        dataIndex: 'hk5',
                        key: 'hk5',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            if (record.isedit == 1) {
                                return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'hk5')} />
                            } else {
                                return (
                                    <Tooltip placement="topLeft" title={row}>
                                        {row}
                                    </Tooltip>
                                )
                            }
                        }
                    },
                    {
                        title: '6月',
                        dataIndex: 'hk6',
                        key: 'hk6',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            if (record.isedit == 1) {
                                return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'hk6')} />
                            } else {
                                return (
                                    <Tooltip placement="topLeft" title={row}>
                                        {row}
                                    </Tooltip>
                                )
                            }
                        }
                    },
                    {
                        title: '7月',
                        dataIndex: 'hk7',
                        key: 'hk7',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            if (record.isedit == 1) {
                                return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'hk7')} />
                            } else {
                                return (
                                    <Tooltip placement="topLeft" title={row}>
                                        {row}
                                    </Tooltip>
                                )
                            }
                        }
                    },
                    {
                        title: '8月',
                        dataIndex: 'hk8',
                        key: 'hk8',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            if (record.isedit == 1) {
                                return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'hk8')} />
                            } else {
                                return (
                                    <Tooltip placement="topLeft" title={row}>
                                        {row}
                                    </Tooltip>
                                )
                            }
                        }
                    },
                    {
                        title: '9月',
                        dataIndex: 'hk9',
                        key: 'hk9',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            if (record.isedit == 1) {
                                return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'hk9')} />
                            } else {
                                return (
                                    <Tooltip placement="topLeft" title={row}>
                                        {row}
                                    </Tooltip>
                                )
                            }
                        }
                    },
                    {
                        title: '10月',
                        dataIndex: 'hk10',
                        key: 'hk10',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            if (record.isedit == 1) {
                                return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'hk10')} />
                            } else {
                                return (
                                    <Tooltip placement="topLeft" title={row}>
                                        {row}
                                    </Tooltip>
                                )
                            }
                        }
                    },
                    {
                        title: '11月',
                        dataIndex: 'hk11',
                        key: 'hk11',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            if (record.isedit == 1) {
                                return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'hk11')} />
                            } else {
                                return (
                                    <Tooltip placement="topLeft" title={row}>
                                        {row}
                                    </Tooltip>
                                )
                            }
                        }
                    },
                    {
                        title: '12月',
                        dataIndex: 'hk12',
                        key: 'hk12',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record, index) => {
                            if (record.isedit == 1) {
                                return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'hk12')} />
                            } else {
                                return (
                                    <Tooltip placement="topLeft" title={row}>
                                        {row}
                                    </Tooltip>
                                )
                            }
                        }
                    },
                    {
                        title: '合计',
                        dataIndex: 'hkheji',
                        key: 'hkheji',
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (row, record) => {
                            return (
                                <Tooltip placement="topLeft" title={row}>
                                    {row}
                                </Tooltip>
                            )
                        }
                    },
                ]
            },
            {
                title: '余额',
                dataIndex: 'ye',
                key: 'ye',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => {
                    return (
                        <Tooltip placement="topLeft" title={row}>
                            {row}
                        </Tooltip>
                    )
                }
            },
            {
                title: '应付余额减去应收余额',
                dataIndex: 'yfyejqysye',
                key: 'yfyejqysye',
                align: "center",
                width: 200,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => {
                    return (
                        <Tooltip placement="topLeft" title={row}>
                            {row}
                        </Tooltip>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 80,
                fixed: 'right',
                render: (row, record, index) => {
                    return (
                        <>
                            {store.getState().data.info.qx10 ? (store.getState().data.info.qx10 == 1 ?
                                <Space>
                                    {record.isedit == 1 ? <a href='javascript:void(0)' onClick={this.editRow.bind(this, index, 0)}>完成</a> : <a href='javascript:void(0)' onClick={this.editRow.bind(this, index, 1)}>编辑</a>}
                                </Space>
                                : null) : null}
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideSelectAll: true,
            preserveSelectedRowKeys: false,
            renderCell: function (checked, record, index, originNode) {
                if (record.status != 1) {
                    return originNode
                } else {
                    return false
                }
            },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" align="top" >
                        <Space wrap style={{ width: 'calc(100% - 200px)' }}>
                            <DatePicker style={{ width: '100%' }} placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null} />
                            <Input placeholder="客户名称" value={this.state.newcondition.khmc ? this.state.newcondition.khmc : null} onChange={this.onChange.bind(this, 'khmc')}  />
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                        {store.getState().data.info.qx10 ? (store.getState().data.info.qx10 == 1 ?
                            <Space wrap>
                                <Button type="primary" className='blue'>下载</Button>
                            </Space>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    scroll={{ x: 4300,y:this.state.tableHeight?this.state.tableHeight:null }}
                    className='table'
                    size="small"
                    summary={(pageData) => {
                        let totalqc = 0;
                        let totaljs1 = 0;
                        let totaljs2 = 0;
                        let totaljs3 = 0;
                        let totaljs4 = 0;
                        let totaljs5 = 0;
                        let totaljs6 = 0;
                        let totaljs7 = 0;
                        let totaljs8 = 0;
                        let totaljs9 = 0;
                        let totaljs10 = 0;
                        let totaljs11 = 0;
                        let totaljs12 = 0;
                        let totaljsheji = 0;
                        // let totalwjs = 0;
                        let totalhk1 = 0;
                        let totalhk2 = 0;
                        let totalhk3 = 0;
                        let totalhk4 = 0;
                        let totalhk5 = 0;
                        let totalhk6 = 0;
                        let totalhk7 = 0;
                        let totalhk8 = 0;
                        let totalhk9 = 0;
                        let totalhk10 = 0;
                        let totalhk11 = 0;
                        let totalhk12 = 0;
                        let totalhkheji = 0;
                        let totalye = 0;
                        let totalyfyejqysye = 0;
                        pageData.forEach(({ qc, js1, js2, js3, js4, js5, js6, js7, js8, js9, js10, js11, js12, jsheji, /* wjs, */ hk1, hk2, hk3, hk4, hk5, hk6, hk7, hk8, hk9, hk10, hk11, hk12, hkheji, ye, yfyejqysye }) => {
                            totalqc += Number(qc ? qc : 0);
                            totaljs1 += Number(js1 ? js1 : 0);
                            totaljs2 += Number(js2 ? js2 : 0);
                            totaljs3 += Number(js3 ? js3 : 0);
                            totaljs4 += Number(js4 ? js4 : 0);
                            totaljs5 += Number(js5 ? js5 : 0);
                            totaljs6 += Number(js6 ? js6 : 0);
                            totaljs7 += Number(js7 ? js7 : 0);
                            totaljs8 += Number(js8 ? js8 : 0);
                            totaljs9 += Number(js9 ? js9 : 0);
                            totaljs10 += Number(js10 ? js10 : 0);
                            totaljs11 += Number(js11 ? js11 : 0);
                            totaljs12 += Number(js12 ? js12 : 0);
                            totaljsheji += Number(jsheji ? jsheji : 0);
                            // totalwjs += Number(wjs ? wjs : 0);
                            totalhk1 += Number(hk1 ? hk1 : 0);
                            totalhk2 += Number(hk2 ? hk2 : 0);
                            totalhk3 += Number(hk3 ? hk3 : 0);
                            totalhk4 += Number(hk4 ? hk4 : 0);
                            totalhk5 += Number(hk5 ? hk5 : 0);
                            totalhk6 += Number(hk6 ? hk6 : 0);
                            totalhk7 += Number(hk7 ? hk7 : 0);
                            totalhk8 += Number(hk8 ? hk8 : 0);
                            totalhk9 += Number(hk9 ? hk9 : 0);
                            totalhk10 += Number(hk10 ? hk10 : 0);
                            totalhk11 += Number(hk11 ? hk11 : 0);
                            totalhk12 += Number(hk12 ? hk12 : 0);
                            totalhkheji += Number(hkheji ? hkheji : 0);
                            totalye += Number(ye ? ye : 0);
                            totalyfyejqysye += Number(yfyejqysye ? yfyejqysye : 0);
                        });
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2} align="center">{totalqc.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>{totaljs1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{totaljs2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>{totaljs3.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>{totaljs4.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>{totaljs5.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>{totaljs6.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}>{totaljs7.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}>{totaljs8.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}>{totaljs9.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}>{totaljs10.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}>{totaljs11.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={14}>{totaljs12.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}>{totaljsheji.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={16}>{/* {totalwjs.toFixed(2)} */}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={17}>{totalhk1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={18}>{totalhk2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={19}>{totalhk3.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={20}>{totalhk4.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={21}>{totalhk5.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={22}>{totalhk6.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={23}>{totalhk7.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={24}>{totalhk8.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={25}>{totalhk9.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={26}>{totalhk10.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={27}>{totalhk11.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={28}>{totalhk12.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={29}>{totalhkheji.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={30} align='center'>{totalye.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={31} align='center'>{totalyfyejqysye.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={32}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
                {/* <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div> */}
            </>
        )
    }
}
