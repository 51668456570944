import React from 'react';
import {Redirect, Link, withRouter} from 'react-router-dom';
import {
    Switch,
    Space,
    Select,
    Input,
    Button,
    Table,
    Modal,
    Row,
    Col,
    Form,
    message,
    Checkbox,
    Radio,
    Pagination,
    Tag,
    Popconfirm,
    Tooltip,
    DatePicker
} from 'antd';
import store from "../store/store.js";
import {AppstoreOutlined, TransactionOutlined, PayCircleOutlined, EditOutlined} from '@ant-design/icons'
import moment from 'moment';

const {Option} = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
            },
            oldcondition: {},
            list: [],
            tableloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }

    componentDidMount() {
        this.props.NAV_CHANGE('/yetj', '余额统计')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }

    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 150 - selheight
        that.setState({
            tableHeight: yheight
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, {[name]: dateString})
        this.setState({
            newcondition: data
        })
        setTimeout(() => {
            that.getdata(1)
        });
    }

    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, {current: 1, pagesize: old.pagesize})
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Yetj/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                that.setState({
                    list: data.data?data.data:[],
                    tableloading: false,
                    year_react: data.year_react ? data.year_react : [],
                    sfkr_react4: data.sfkr_react4 ? data.sfkr_react4 : [],
                })
            })
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }

    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 100
            },
            {
                title: '单位名称',
                dataIndex: 'dwmc',
                key: 'dwmc',
                align: "center",
                // width: 50,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '公司类型',
                dataIndex: 'gslx',
                key: 'gslx',
                align: "center",
            },
            {
                title: '期初余额',
                dataIndex: 'qcye',
                key: 'qcye',
                align: "center",
            },
            {
                title: '本期发生',
                dataIndex: 'bqfs',
                key: 'bqfs',
                align: "center",
                children: [
                    {
                        title: '收款',
                        dataIndex: 'sk',
                        key: 'sk',
                        align: "center",
                    },
                    {
                        title: '付款',
                        dataIndex: 'fk',
                        key: 'fk',
                        align: "center",
                    },
                    {
                        title: '余额',
                        dataIndex: 'ye',
                        key: 'ye',
                        align: "center",
                    },
                ]
            },
            {
                title: '期末余额',
                dataIndex: 'qmye',
                key: 'qmye',
                align: "center",
            },
        ]
        const {tableloading, selectedRowKeys} = this.state;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" align="top">
                        <Space wrap style={{width: 'calc(100% - 200px)'}}>
                            <Select placeholder="年份"
                                    value={this.state.newcondition.year ? this.state.newcondition.year : null}
                                    onChange={this.onSelChange.bind(this, 'year')} allowClear style={{width: 150}}
                                    showSearch optionFilterProp="children"
                                    options={this.state.year_react?this.state.year_react:[]}
                            >
                            </Select>
                            <Select placeholder="单位名称" showSearch optionFilterProp="children" style={{width: 250}}
                                    value={this.state.newcondition.sfkr ? this.state.newcondition.sfkr : null}
                                    onChange={this.onSelChange.bind(this, 'sfkr')} allowClear>
                                {this.arrsel(this.state.sfkr_react4)}
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                        </Space>
                    </Row>
                </div>
                <Row justify="space-between" align="stretch">
                    <Table
                        bordered
                        columns={columns}
                        sticky={false}
                        dataSource={this.state.list}
                        pagination={false}
                        loading={tableloading}
                        scroll={{y: this.state.tableHeight ? this.state.tableHeight : null}}
                        className='table'
                        size="small"
                    />
                </Row>
            </>
        )
    }
}
