import React from 'react';
import {
    Button,
    Checkbox,
    DatePicker,
    Input,
    message,
    Pagination,
    Popconfirm,
    Row,
    Select,
    Space,
    Table,
    Tooltip
} from 'antd';
import store from "../store/store.js";
import moment from 'moment';
import Add from './add';

const {Option} = Select;
const {RangePicker} = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }

    componentDidMount() {
        this.props.NAV_CHANGE('/htxx', '合同信息')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }

    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 240 - selheight
        that.setState({
            tableHeight: yheight
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, {[name]: dateString})
        this.setState({
            newcondition: data
        })
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
    };

    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, {current: page, pagesize: pagesize})
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}
                        disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }

    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, {current: 1, pagesize: old.pagesize})
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('htxx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    htfl_react: data.htfl_react ? data.htfl_react : [],
                    qydw_react: data.qydw_react ? data.qydw_react : [],
                })
            })
    }

    delSel() {
        let that = this
        fetch('htxx/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('htxx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }

    delSel2(id, e) {
        let that = this
        fetch('/Htxx/delSel2', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, {
            [name + '1']: dateStrings[0],
            [name + '2']: dateStrings[1]
        })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }

    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }

    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                fixed: 'left',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '客户名称',
                dataIndex: 'czdw',
                key: 'czdw',
                ellipsis: {
                    showTitle: false,
                },
                width: 300,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '合同期限',
                dataIndex: 'htrq',
                key: 'htrq',
                width: 200,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '签约单位',
                dataIndex: 'qydw',
                key: 'qydw',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '合同单价',
                dataIndex: 'htdjnum',
                key: 'htdjnum',
                align: "center",
                width: 100,
                render: (row, record) => (
                    <Add id={record.id} refresh={this.getdata.bind(this)} title={row}/>
                ),
            },
            {
                title: '签约日期',
                dataIndex: 'qyrq_str',
                key: 'qyrq_str',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '合同付款条件',
                dataIndex: 'htfktj',
                key: 'htfktj',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '纳税人资格及税率',
                dataIndex: 'nsrzgjsl',
                key: 'nsrzgjsl',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '分类',
                dataIndex: 'fl_str',
                key: 'fl_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '备注',
                dataIndex: 'beizhu',
                key: 'beizhu',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作人',
                dataIndex: 'aidname',
                key: 'aidname',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={record.updatetime}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 150,
                fixed: 'right',
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <Add id={record.id} refresh={this.getdata.bind(this)}/>
                                {store.getState().data.info.qx15 ? (store.getState().data.info.qx15 == 1 ?
                                    <Popconfirm
                                        title="确定删除?"
                                        onConfirm={this.delSel2.bind(this, record.id)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <a href="#" className="hongse">删除</a>
                                    </Popconfirm>
                                    : null) : null}
                            </Space>
                        </>
                    )
                },
            },
        ]
        const {tableloading, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideSelectAll: true,
            preserveSelectedRowKeys: false,
            renderCell: function (checked, record, index, originNode) {
                if (record.status != 1) {
                    return originNode
                } else {
                    return false
                }
            },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" align="top">
                        <Space wrap style={{width: 'calc(100% - 200px)'}}>
                            <Input placeholder="客户名称"
                                   value={this.state.newcondition.czdw ? this.state.newcondition.czdw : null}
                                   onChange={this.onChange.bind(this, 'czdw')}/>
                            <Select placeholder="签约单位" style={{width: 200}}
                                    value={this.state.newcondition.qydw ? this.state.newcondition.qydw : null}
                                    onChange={this.onSelChange.bind(this, 'qydw')} allowClear showSearch
                                    optionFilterProp="children">
                                {this.arrsel(this.state.qydw_react)}
                            </Select>
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'qyrq')}
                                         value={[(this.state.newcondition.qyrq1 ? moment(this.state.newcondition.qyrq1) : null), (this.state.newcondition.qyrq2 ? moment(this.state.newcondition.qyrq2) : null)]}
                                         placeholder={['签约日期', '签约日期']}/>
                            <Select placeholder="分类" showSearch optionFilterProp="children" style={{width: 120}}
                                    value={this.state.newcondition.fl ? this.state.newcondition.fl : null}
                                    onChange={this.onSelChange.bind(this, 'fl')} allowClear>
                                {this.arrsel(this.state.htfl_react)}
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                        {store.getState().data.info.qx15 ? (store.getState().data.info.qx15 == 1 ?
                            <Space wrap>
                                <Add refresh={this.getdata.bind(this)}/>
                                {/* <Button type="primary" className='blue'>导出</Button> */}
                            </Space>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    scroll={{x: 2200, y: this.state.tableHeight ? this.state.tableHeight : null}}
                    className='table'
                    size="small"
                    summary={(pageData) => {
                        let totalzje = 0;
                        pageData.forEach(({zje}) => {
                            totalzje += Number(zje ? zje : 0);
                        });
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}
                                                        align='center'>{totalzje.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
                <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1}
                                pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50}
                                total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true}
                                showTotal={total => `共 ${total} 条数据`}/>
                </div>
            </>
        )
    }
}
