import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip, DatePicker, Typography, Progress } from 'antd';
import store from "../store/store.js";
import { Column } from '@ant-design/plots';
import { FormOutlined, CarOutlined, ExperimentOutlined, DollarOutlined, FunnelPlotOutlined, RocketOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            numlist: {},
            numlist2: {},
            numlist3: {},
            numlist4: {},
            data: [
                {
                    type: '30以内',
                    value: 0,
                },
                {
                    type: '30-60',
                    value: 0,
                },
                {
                    type: '60-100',
                    value: 0,
                },
                {
                    type: '100-200',
                    value: 0,
                },
                {
                    type: '200-300',
                    value: 0,
                },
                {
                    type: '300-500',
                    value: 0,
                },
                {
                    type: '500以上',
                    value: 0,
                },
            ],
            data2: [
                {
                    type: '30以内',
                    value: 0,
                },
                {
                    type: '30-60',
                    value: 0,
                },
                {
                    type: '60-100',
                    value: 0,
                },
                {
                    type: '100-200',
                    value: 0,
                },
                {
                    type: '200-300',
                    value: 0,
                },
                {
                    type: '300-500',
                    value: 0,
                },
                {
                    type: '500以上',
                    value: 0,
                },
            ],
            data3: [
                {
                    type: '30以内',
                    value: 0,
                },
                {
                    type: '30-60',
                    value: 0,
                },
                {
                    type: '60-100',
                    value: 0,
                },
                {
                    type: '100-200',
                    value: 0,
                },
                {
                    type: '200-300',
                    value: 0,
                },
                {
                    type: '300-500',
                    value: 0,
                },
                {
                    type: '500以上',
                    value: 0,
                },
            ],
            data4: [
                {
                    type: '30以内',
                    value: 0,
                },
                {
                    type: '30-60',
                    value: 0,
                },
                {
                    type: '60-100',
                    value: 0,
                },
                {
                    type: '100-200',
                    value: 0,
                },
                {
                    type: '200-300',
                    value: 0,
                },
                {
                    type: '300-500',
                    value: 0,
                },
                {
                    type: '500以上',
                    value: 0,
                },
            ],
            total_num1: 0,
            total_num2: 0,
            total_num3: 0,
            total_num4: 0,
            month_react: [
                { id: 1, name: '1月' },
                { id: 2, name: '2月' },
                { id: 3, name: '3月' },
                { id: 4, name: '4月' },
                { id: 5, name: '5月' },
                { id: 6, name: '6月' },
                { id: 7, name: '7月' },
                { id: 8, name: '8月' },
                { id: 9, name: '9月' },
                { id: 10, name: '10月' },
                { id: 11, name: '11月' },
                { id: 12, name: '12月' },
            ],
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        this.props.NAV_CHANGE('/cbfx', '成本分析')
        this.getdata(1)
        this.getdata2(1)
        this.getdata3(1)
        this.getdata4(1)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        setTimeout(() => {
            if (name == "khid") {
                that.getdata2(1)
            } else if (name == "projectid") {
                that.getdata3(1)
            } else if (name == "chid") {
                that.getdata4(1)
            }
        });
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} >{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        var arr = that.state.condition
        fetch('/Cbfx/index5', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    chid_react: data.chid_react ? data.chid_react : [],
                    khid_react: data.khid_react ? data.khid_react : [],
                    month_react: data.month_react ? data.month_react : [],
                    projectid_react: data.projectid_react ? data.projectid_react : [],
                    numlist: data.numlist ? data.numlist : {},
                    data: data.data,
                    total_num1: data.total_num,
                })
            })
    }
    getdata2(subtype = 0, e) {
        console.log(subtype)
        let that = this
        var arr = that.state.condition
        fetch('/Cbfx/index6', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    // message.error(data.msg)
                    return
                }
                that.setState({
                    numlist2: data.numlist ? data.numlist : {},
                    data2: data.data,
                    total_num2: data.total_num,
                })
            })
    }
    getdata3(subtype = 0, e) {
        console.log(subtype)
        let that = this
        var arr = that.state.condition
        fetch('/Cbfx/index7', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    // message.error(data.msg)
                    return
                }
                that.setState({
                    numlist3: data.numlist ? data.numlist : {},
                    data3: data.data,
                    total_num3: data.total_num,
                })
            })
    }
    getdata4(subtype = 0, e) {
        console.log(subtype)
        let that = this
        var arr = that.state.condition
        fetch('/Cbfx/index8', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    // message.error(data.msg)
                    return
                }
                that.setState({
                    numlist4: data.numlist ? data.numlist : {},
                    data4: data.data,
                    total_num4: data.total_num,
                })
            })
    }
    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.condition, { [name + '1']: dateStrings[0], [name + '2']: dateStrings[1] })
        if (data) {
            this.setState({
                condition: data
            })
        }
    }
    reset() {
        let that = this
        that.setState({
            condition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
        })
        setTimeout(() => {
            that.getdata(1, null)
            that.getdata2(1, null)
            that.getdata3(1, null)
            that.getdata4(1, null)
        })
    }
    getAllData = () => {
        let that = this
        that.getdata(1, null)
        that.getdata2(1, null)
        that.getdata3(1, null)
        that.getdata4(1, null)
    }
    render() {
        let config = {
            data: this.state.data,
            xField: 'type',
            yField: 'value',
            seriesField: 'type',
            color: ['#ff0c00', '#ff3d00', '#ff6c00', '#ff9e00', '#ffe600', '#f5ee00', '#c7db00', '#9ac900', '#84c000', '#57ae00', '#40a400', '#1f9600'],
            label: {
                // 可手动配置 label 数据标签位置
                position: 'top',
                // offsetY: 5,
                // 'top', 'bottom', 'middle',
                // 配置样式
                style: {
                    // fill: '#1890FF',
                    opacity: 0.6,
                },
                formatter: (a, b) => {
                    // console.log(a)
                    let str = a.value
                    if (this.state.total_num1 != 0) {
                        str += " / " + (a.value / this.state.total_num1 * 100).toFixed(2) + '%'
                    }
                    return str
                }
            },
            height: 400,
            xAxis: {
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
            },
            legend: false,
            tooltip: {
                showTitle: false,
                formatter: (datum) => {
                    // console.log(datum);
                    let str = datum.value
                    if (this.state.total_num1 != 0) {
                        str += " / " + (datum.value / this.state.total_num1 * 100).toFixed(2) + '%'
                    }
                    return { name: datum.type, value: str };
                },
            },
            meta: {
                type: {
                    alias: '方量',
                },
                value: {
                    alias: '次数',
                },
            },
        };
        let config2 = {
            data: this.state.data2,
            xField: 'type',
            yField: 'value',
            seriesField: 'type',
            color: ['#ff0c00', '#ff3d00', '#ff6c00', '#ff9e00', '#ffe600', '#f5ee00', '#c7db00', '#9ac900', '#84c000', '#57ae00', '#40a400', '#1f9600'],
            label: {
                // 可手动配置 label 数据标签位置
                position: 'top',
                // offsetY: 5,
                // 'top', 'bottom', 'middle',
                // 配置样式
                style: {
                    // fill: '#1890FF',
                    opacity: 0.6,
                },
                formatter: (a, b) => {
                    // console.log(a)
                    let str = a.value
                    if (this.state.total_num2 != 0) {
                        str += " / " + (a.value / this.state.total_num2 * 100).toFixed(2) + '%'
                    }
                    return str
                }
            },
            height: 400,
            xAxis: {
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
            },
            legend: false,
            tooltip: {
                showTitle: false,
                formatter: (datum) => {
                    // console.log(datum);
                    let str = datum.value
                    if (this.state.total_num1 != 0) {
                        str += " / " + (datum.value / this.state.total_num2 * 100).toFixed(2) + '%'
                    }
                    return { name: datum.type, value: str };
                },
            },
            meta: {
                type: {
                    alias: '方量',
                },
                value: {
                    alias: '次数',
                },
            },
        };
        let config3 = {
            data: this.state.data3,
            xField: 'type',
            yField: 'value',
            seriesField: 'type',
            color: ['#ff0c00', '#ff3d00', '#ff6c00', '#ff9e00', '#ffe600', '#f5ee00', '#c7db00', '#9ac900', '#84c000', '#57ae00', '#40a400', '#1f9600'],
            label: {
                // 可手动配置 label 数据标签位置
                position: 'top',
                // offsetY: 5,
                // 'top', 'bottom', 'middle',
                // 配置样式
                style: {
                    // fill: '#1890FF',
                    opacity: 0.6,
                },
                formatter: (a, b) => {
                    // console.log(a)
                    let str = a.value
                    if (this.state.total_num3 != 0) {
                        str += " / " + (a.value / this.state.total_num3 * 100).toFixed(2) + '%'
                    }
                    return str
                }
            },
            height: 400,
            xAxis: {
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
            },
            legend: false,
            tooltip: {
                showTitle: false,
                formatter: (datum) => {
                    // console.log(datum);
                    let str = datum.value
                    if (this.state.total_num1 != 0) {
                        str += " / " + (datum.value / this.state.total_num3 * 100).toFixed(2) + '%'
                    }
                    return { name: datum.type, value: str };
                },
            },
            meta: {
                type: {
                    alias: '方量',
                },
                value: {
                    alias: '次数',
                },
            },
        };
        let config4 = {
            data: this.state.data4,
            xField: 'type',
            yField: 'value',
            seriesField: 'type',
            color: ['#ff0c00', '#ff3d00', '#ff6c00', '#ff9e00', '#ffe600', '#f5ee00', '#c7db00', '#9ac900', '#84c000', '#57ae00', '#40a400', '#1f9600'],
            label: {
                // 可手动配置 label 数据标签位置
                position: 'top',
                // offsetY: 5,
                // 'top', 'bottom', 'middle',
                // 配置样式
                style: {
                    // fill: '#1890FF',
                    opacity: 0.6,
                },
                formatter: (a, b) => {
                    // console.log(a)
                    let str = a.value
                    if (this.state.total_num4 != 0) {
                        str += " / " + (a.value / this.state.total_num4 * 100).toFixed(2) + '%'
                    }
                    return str
                }
            },
            height: 400,
            xAxis: {
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
            },
            legend: false,
            tooltip: {
                showTitle: false,
                formatter: (datum) => {
                    // console.log(datum);
                    let str = datum.value
                    if (this.state.total_num1 != 0) {
                        str += " / " + (datum.value / this.state.total_num4 * 100).toFixed(2) + '%'
                    }
                    return { name: datum.type, value: str };
                },
            },
            meta: {
                type: {
                    alias: '方量',
                },
                value: {
                    alias: '次数',
                },
            },
        };
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between">
                        <Space wrap>
                            <DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.condition.year ? moment(this.state.condition.year) : null} />
                            <Select placeholder="月份" style={{ width: 120 }} value={this.state.condition.month ? this.state.condition.month : 0} onChange={this.onSelChange.bind(this, 'month')} allowClear={false}>
                                {this.arrsel(this.state.month_react)}
                            </Select>
                            <Button type="primary" onClick={this.getAllData.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                    </Row>
                </div>
                <div className='tjlrbox'>
                    <div className='lrtj'>
                        <div className='title'>
                            <Space size={[30, 0]}>
                                <div className='p1'>总计</div>
                            </Space>
                        </div>
                        <div className='tj'>
                            <div className='item'>
                                <div className='p1'>总方量</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num1}</div>
                                    <div className='a2'>方</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>总产值</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num2}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>总油耗</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num3}</div>
                                    <div className='a2'>升</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>单方油耗</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num4}</div>
                                    <div className='a2'>升/每方</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>易损件总额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num5}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>维修总额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num6}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>工资总额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num7}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>单方成本</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num8}</div>
                                    <div className='a2'>元/每方</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>外租总方量</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num17}</div>
                                    <div className='a2'>方</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>应收结算金额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num18}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>应付结算金额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num19}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>回款金额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num9}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>欠款总金额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num10}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            {/* <div className='item'>
                                <div className='p1'>回款比例</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num11}%</div>
                                </div>
                            </div> */}

                            <div className='item'>
                                <div className='p1'>商砼抵扣</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num13}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>商砼差额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num14}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>中介费</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num12}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            {/* <div className='item'>
                                <div className='p1'>利润总额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num15}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div> */}
                            {/* <div className='item'>
                                <div className='p1'>利润比例</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist.num16}%</div>
                                </div>
                            </div> */}
                        </div>
                        <div className='chart'>
                            <div className='title_total'>
                                <span className='a1'>出车次数</span>
                                <span className='a2'>（ 总数：{this.state.total_num1 ? this.state.total_num1 : 0} ）</span>
                            </div>
                            <Column {...config} />
                        </div>
                    </div>
                    <div className='lrtj'>
                        <div className='title'>
                            <Space size={[30, 0]}>
                                <div className='p1'>客户</div>
                                <Select placeholder="请选择" style={{ width: 200 }} value={this.state.condition.khid ? this.state.condition.khid : null} onChange={this.onSelChange.bind(this, 'khid')} allowClear showSearch optionFilterProp="children">
                                    {this.arrsel(this.state.khid_react)}
                                </Select>
                            </Space>
                        </div>
                        <div className='tj'>
                            <div className='item'>
                                <div className='p1'>总方量</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num1}</div>
                                    <div className='a2'>方</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>总产值</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num2}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>总油耗</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num3}</div>
                                    <div className='a2'>升</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>单方油耗</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num4}</div>
                                    <div className='a2'>升/每方</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>商务成本</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num10}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            {/* <div className='item'>
                                <div className='p1'>商砼抵扣</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num8}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>商砼差额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num9}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div> */}
                            <div className='item'>
                                <div className='p1'>利润总额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num12}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>外租总方量</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num13}</div>
                                    <div className='a2'>方</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>应收结算金额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num14}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>应付结算金额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num14}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>回款金额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num5}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>欠款总金额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num6}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            {/* <div className='item'>
                                <div className='p1'>回款比例</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num7}%</div>
                                </div>
                            </div> */}
                            {/* <div className='item'>
                                <div className='p1'>单方成本</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist2.num11}</div>
                                    <div className='a2'>元/每方</div>
                                </div>
                            </div> */}
                        </div>
                        <div className='chart'>
                            <div className='title_total'>
                                <span className='a1'>出车次数</span>
                                <span className='a2'>（ 总数：{this.state.total_num2 ? this.state.total_num2 : 0} ）</span>
                            </div>
                            <Column {...config2} />
                        </div>
                    </div>
                    <div className='lrtj'>
                        <div className='title'>
                            <Space size={[30, 0]}>
                                <div className='p1'>项目</div>
                                <Select placeholder="请选择" style={{ width: 200 }} value={this.state.condition.projectid ? this.state.condition.projectid : null} onChange={this.onSelChange.bind(this, 'projectid')} allowClear showSearch optionFilterProp="children">
                                    {this.arrsel(this.state.projectid_react)}
                                </Select>
                            </Space>
                        </div>
                        <div className='tj'>
                            <div className='item'>
                                <div className='p1'>总方量</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist3.num1}</div>
                                    <div className='a2'>方</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>总产值</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist3.num2}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>总油耗</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist3.num3}</div>
                                    <div className='a2'>升</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>单方油耗</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist3.num4}</div>
                                    <div className='a2'>升/每方</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>商务成本</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist3.num5}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            {/* <div className='item'>
                                <div className='p1'>单方成本</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist3.num6}</div>
                                    <div className='a2'>元/每方</div>
                                </div>
                            </div> */}
                        </div>
                        <div className='chart'>
                            <div className='title_total'>
                                <span className='a1'>出车次数</span>
                                <span className='a2'>（ 总数：{this.state.total_num3 ? this.state.total_num3 : 0} ）</span>
                            </div>
                            <Column {...config3} />
                        </div>
                    </div>
                    <div className='lrtj nomb'>
                        <div className='title'>
                            <Space size={[30, 0]}>
                                <div className='p1'>车辆</div>
                                <Select placeholder="请选择" style={{ width: 120 }} value={this.state.condition.chid ? this.state.condition.chid : null} onChange={this.onSelChange.bind(this, 'chid')} allowClear showSearch optionFilterProp="children">
                                    {this.arrsel(this.state.chid_react)}
                                </Select>
                            </Space>
                        </div>
                        <div className='tj'>
                            <div className='item'>
                                <div className='p1'>总方量</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist4.num1}</div>
                                    <div className='a2'>方</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>总产值</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist4.num2}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>总油耗</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist4.num3}</div>
                                    <div className='a2'>升</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>单方油耗</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist4.num4}</div>
                                    <div className='a2'>升/每方</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>总易损</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist4.num5}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            {/* <div className='item'>
                                <div className='p1'>单方易损</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist4.num6}</div>
                                    <div className='a2'>元/每方</div>
                                </div>
                            </div> */}
                            <div className='item'>
                                <div className='p1'>维修总额</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist4.num9}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>总工资</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist4.num7}</div>
                                    <div className='a2'>元</div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='p1'>单方成本</div>
                                <div className='p2'>
                                    <div className='a1'>{this.state.numlist4.num8}</div>
                                    <div className='a2'>元/每方</div>
                                </div>
                            </div>
                        </div>
                        <div className='chart'>
                            <div className='title_total'>
                                <span className='a1'>出车次数</span>
                                <span className='a2'>（ 总数：{this.state.total_num4 ? this.state.total_num4 : 0} ）</span>
                            </div>
                            <Column {...config4} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
