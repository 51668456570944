import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm } from 'antd';
import store from "../store/store.js";
import { PlusOutlined } from '@ant-design/icons'
import moment from 'moment';
import Add from '../clwxmx/add.js';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                sclist: [],
            },
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            console.log(that.props.list)
        } else {
            that.setState({
                loading: false
            })
        }
    }
    render() {
        const columns = [
            {
                title: '维修内容/零件名称',
                dataIndex: 'name',
                key: 'name',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '异常原因',
                dataIndex: 'ycyy',
                key: 'ycyy',
                width: 400,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <>
                        {record.is_jg == 1 ?
                            <div className='ljinfo'>目标价格：{record.dj}元 /<span className={`c2`}>{record.mbdj}元</span> </div>
                            : null}
                        {record.is_sm == 1 || record.is_sm == 2 ?
                            <>
                                {(record.sm && record.zfl < record.sm) || (record.sm2 && record.zfl > record.sm2) ?
                                    <div className='ljinfo'>目标方量：{record.zfl}方 /
                                        <span className={`c2`}>
                                            <Space split="~"><span>{record.sm}</span>{record.sm2 ? <span>{record.sm2}</span> : null}</Space>方
                                        </span>
                                        {(record.sm && record.zfl < record.sm) ? <span className='hongse'>（维修异常）</span> : null}
                                        {(record.sm2 && record.zfl > record.sm2) ? <span className='hongse'>（保养异常）</span> : null}
                                    </div>
                                    : null}
                                {(record.sx && record.during < record.sx) || (record.sx2 && record.during > record.sx2) ?
                                    <div className='ljinfo'>目标时限：{record.during}天/
                                        <span className={`c2`}>
                                            <Space split="~"><span>{record.sx}</span>{record.sx2 ? <span>{record.sx2}</span> : null}</Space>天
                                        </span>
                                        {(record.sx && record.during < record.sx) ? <span className='hongse'>（维修异常）</span> : null}
                                        {(record.sx2 && record.during > record.sx2) ? <span className='hongse'>（保养异常）</span> : null}
                                    </div>
                                    : null}
                            </>
                            : null}
                    </>
                ),
            },
            {
                title: '操作',
                dataIndex: 'ycnr',
                key: 'ycnr',
                width: 100,
                align: 'center',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <>
                        <Add id={record.cid} disabled={true} />
                    </>
                ),
            },
        ]
        return (
            <>
                <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >
                    {this.props.is_sm == 1 ? <div className='hongse'>维修异常</div> : null}
                    {this.props.is_sm2 == 1 ? <div className='hongse'>保养异常</div> : null}
                    {this.props.is_jg == 1 ? <div className='hongse'>价格异常</div> : null}
                </a>
                <Modal maskClosable={true} title="异常内容" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Table
                        bordered
                        columns={columns}
                        sticky={false}
                        dataSource={this.props.list}
                        pagination={false}
                        // loading={tableloading}
                        // rowSelection={rowSelection}
                        // scroll={{ y: this.state.tableHeight ? this.state.tableHeight : null }}
                        className='table'
                        size="small"
                    />
                </Modal>
            </>
        )
    }
}