import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm, AutoComplete, Transfer } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {},
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        let that = this
        let data = Object.assign({}, that.state.condition, { arrid: nextProps.arrid })
        that.setState({
            condition: data
        })
    }
    onSelChange(name, value, Option) {
        let that = this
        console.log(name, value, Option)
        let data = Object.assign({}, that.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.condition, { [name + '1']: dateStrings[0], [name + '2']: dateStrings[1] })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    getdata = () => {
        let that = this
        fetch('/Dhntmx/dzdview', {
            method: "POST",
            body: JSON.stringify({

            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    dw_react: data.dw_react ? data.dw_react : [],
                })
            })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {},
                downloading: false
            })
        }
    }
    sub(e) {
        let that = this
        that.setState({
            downloading: true
        })
        // console.log(arr)
        fetch('/Dhntmx/dodzd', {
            // post提交
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    that.setState({
                        downloading: false
                    })
                    return
                }
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }
    onTransferChange = (nextTargetKeys, direction, moveKeys) => {
        // console.log('targetKeys:', nextTargetKeys);
        // console.log('direction:', direction);
        // console.log('moveKeys:', moveKeys);
        let that = this
        let data = Object.assign({}, that.state.condition, { sgbw: nextTargetKeys })
        that.setState({
            condition: data
        })
    };
    render() {
        return (
            <>
                <Button type="primary" onClick={this.mdchange.bind(this)}>导出对账单</Button>
                <Modal maskClosable={false} title="导出对账单" width={600} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={12}>
                                <Form.Item
                                    label="采购单位"
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.dw ? this.state.condition.dw : null} allowClear onChange={this.onSelChange.bind(this, 'dw')} >
                                        {this.arrsel(this.state.dw_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="结算日期起始"
                                    required={true}
                                >
                                    <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'jsrq')} value={[(this.state.condition.jsrq1 ? moment(this.state.condition.jsrq1) : null), (this.state.condition.jsrq2 ? moment(this.state.condition.jsrq2) : null)]} placeholder={['开始日期', '结束日期']} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* {store.getState().data.info.qx1 ? (store.getState().data.info.qx1 == 1 ? */}
                        <Row justify="center">
                            <Space>
                                <Button className='blue' loading={this.state.downloading} onClick={this.sub.bind(this)}>提交</Button>
                            </Space>
                        </Row>
                        {/* : null) : null} */}
                    </Form>
                </Modal>
            </>
        )
    }
}