import React from 'react';
import {Redirect, Link, withRouter} from 'react-router-dom';
import {
    Switch,
    Space,
    Select,
    Input,
    Button,
    Table,
    Modal,
    Row,
    Col,
    Form,
    message,
    Checkbox,
    Radio,
    Pagination,
    Tag,
    Divider,
    DatePicker
} from 'antd';
import store from "../store/store.js";
import {FormOutlined} from '@ant-design/icons'
import moment from 'moment';

const {Option} = Select;
const CheckboxGroup = Checkbox.Group;
const {RangePicker} = DatePicker;
export default class Doview_arr extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                uid: props.uid ? props.uid : 0,
            },
            qx_react: [],
            status_react: [],
            visible: false,
            loading: false
        };
    }

    componentDidMount() {
        let that = this
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, {[name]: value})
        this.setState({
            condition: data
        })
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}
                        disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }

    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.condition, {[name + '1']: dateStrings[0], [name + '2']: dateStrings[1]})
        if (data) {
            this.setState({
                condition: data
            })
        }
    }

    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {

        } else {
            that.setState({
                condition: {
                    uid: this.props.uid,
                },
                loading: false
            })
        }
    }

    sub() {
        let that = this
        that.setState({
            loading: true
        })
        let arr = that.state.condition
        arr.uid = this.props.uid
        fetch('/Kq/doview_arr', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }

    render() {
        return (
            <>
                <a href='javascript:void(0)' onClick={this.mdchange.bind(this)}>操作</a>
                <Modal maskClosable={false} title="批量操作" width={600} open={this.state.visible}
                       onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{span: 24}}
                        disabled={store.getState().data.info.qx20 ? (store.getState().data.info.qx20 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={24}>
                                <Form.Item
                                    label="日期范围"
                                >
                                    <RangePicker allowEmpty={[false, false]}
                                                 onChange={this.onRangeChange.bind(this, 'riqi')}
                                                 value={[(this.state.condition.riqi1 ? moment(this.state.condition.riqi1) : null), (this.state.condition.riqi2 ? moment(this.state.condition.riqi2) : null)]}
                                                 placeholder={['开始日期', '结束日期']}
                                                 disabledDate={(current) => {
                                                     // console.log(current.format('YYYY'),current.format('M'))
                                                     // Can not select days before today and today
                                                     return this.props.year == current.format('YYYY') && this.props.month == current.format('M') ? false : true;
                                                 }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="考勤状态"
                                    tooltip={'不选为正常出勤状态'}
                                >
                                    <Select
                                        allowClear
                                        placeholder="请选择"
                                        showSearch
                                        optionFilterProp="children"
                                        value={this.state.condition.value ? this.state.condition.value : null}
                                        onChange={this.onSelChange.bind(this, 'value')}
                                    >
                                        {this.arrsel(this.props.status_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Button className='blue' style={{width: 140}} loading={this.state.loading}
                                    onClick={this.sub.bind(this)}>确认</Button>
                        </Row>
                    </Form>
                </Modal>
            </>
        )
    }
}
