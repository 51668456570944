import React from 'react';
import {Button, Checkbox, DatePicker, message, Pagination, Popconfirm, Row, Select, Space, Table, Tooltip} from 'antd';
import store from "../store/store.js";
import Add from './add.js'
import Zhsz from "../zhsz/index.js";
import moment from 'moment';

const {RangePicker} = DatePicker;
const {Option} = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
            month_react: [
                {id: 1, name: '1月'},
                {id: 2, name: '2月'},
                {id: 3, name: '3月'},
                {id: 4, name: '4月'},
                {id: 5, name: '5月'},
                {id: 6, name: '6月'},
                {id: 7, name: '7月'},
                {id: 8, name: '8月'},
                {id: 9, name: '9月'},
                {id: 10, name: '10月'},
                {id: 11, name: '11月'},
                {id: 12, name: '12月'},
            ]
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }

    componentDidMount() {
        this.props.NAV_CHANGE('/fapiao', '发票')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }

    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 240 - selheight
        that.setState({
            tableHeight: yheight
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, {[name]: dateString})
        this.setState({
            newcondition: data
        })
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        if (name == 'gmflx') {
            data.gmfmc = null
        }
        if (name == 'xsflx') {
            data.xsfmc = null
        }
        this.setState({
            newcondition: data
        })
    }

    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
    };

    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, {current: page, pagesize: pagesize})
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }

    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, {current: 1, pagesize: old.pagesize})
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Fapiao/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    month_react: data.month_react ? data.month_react : [],
                    sfkrlx_react: data.sfkrlx_react ? data.sfkrlx_react : [],
                    sfkr_react1: data.sfkr_react1 ? data.sfkr_react1 : [],
                    sfkr_react2: data.sfkr_react2 ? data.sfkr_react2 : [],
                    sfkr_react3: data.sfkr_react3 ? data.sfkr_react3 : [],
                    sfkr_react4: data.sfkr_react4 ? data.sfkr_react4 : [],
                    sfkrlx_react: data.sfkrlx_react ? data.sfkrlx_react : [],
                })
            })
    }

    delSel() {
        let that = this
        fetch('/Fapiao/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    delSel2(id, e) {
        let that = this
        fetch('/Fapiao/delSel2', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
            })
    }

    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Fapiao/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }

    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, {
            [name + '1']: dateStrings[0],
            [name + '2']: dateStrings[1]
        })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }

    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }

    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 60,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '开票日期',
                dataIndex: 'riqi_str',
                key: 'riqi_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '发票类型',
                dataIndex: 'fplx_str',
                key: 'fplx_str',
                width: 200,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '购买方名称',
                dataIndex: 'gmfmc_str',
                key: 'gmfmc_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 300,
                // width: 300,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '销售方名称',
                dataIndex: 'xsfmc_str',
                key: 'xsfmc_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 200,
                // width: 300,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '价税合计',
                dataIndex: 'jshj',
                key: 'jshj',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '税率',
                dataIndex: 'sl_str',
                key: 'sl_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '税额',
                dataIndex: 'se',
                key: 'se',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '金额',
                dataIndex: 'je',
                key: 'je',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '备注',
                dataIndex: 'bz',
                key: 'bz',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '发票作废',
                dataIndex: 'fpzf_str',
                key: 'fpzf_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作人',
                dataIndex: 'aidname',
                key: 'aidname',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={record.updatetime}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 100,
                fixed: 'right',
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <Add id={record.id} refresh={this.getdata.bind(this)}/>
                                {store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ?
                                    <Popconfirm
                                        title="确定删除?"
                                        onConfirm={this.delSel2.bind(this, record.id)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <a href="#" className="hongse">删除</a>
                                    </Popconfirm>
                                    : null) : null}
                            </Space>
                        </>
                    )
                },
            },
        ]
        const {tableloading, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideSelectAll: true,
            preserveSelectedRowKeys: false,
            renderCell: function (checked, record, index, originNode) {
                if (record.status != 1) {
                    return originNode
                } else {
                    return false
                }
            },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" align="top">
                        <Space wrap style={{width: 'calc(100% - 200px)'}}>
                            <DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year"
                                        value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null}
                                        allowClear={false}/>
                            <Select placeholder="月份" style={{width: 120}}
                                    value={this.state.newcondition.month ? this.state.newcondition.month : 0}
                                    onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                {this.arrsel(this.state.month_react)}
                            </Select>
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'riqi')}
                                         value={[(this.state.newcondition.riqi1 ? moment(this.state.newcondition.riqi1) : null), (this.state.newcondition.riqi2 ? moment(this.state.newcondition.riqi2) : null)]}
                                         placeholder={['日期', '日期']}/>
                            <Select placeholder="购买方类型" showSearch optionFilterProp="children" style={{width: 120}}
                                    value={this.state.newcondition.gmflx ? this.state.newcondition.gmflx : null}
                                    onChange={this.onSelChange.bind(this, 'gmflx')} allowClear>
                                {this.arrsel(this.state.sfkrlx_react)}
                            </Select>
                            {this.state.newcondition.gmflx ?
                                <Select placeholder="购买方名称" showSearch optionFilterProp="children" style={{width: 250}}
                                        value={this.state.newcondition.gmfmc ? this.state.newcondition.gmfmc : null}
                                        onChange={this.onSelChange.bind(this, 'gmfmc')} allowClear>
                                    {this.state.newcondition.gmflx == 1 ? this.arrsel(this.state.sfkr_react1) : null}
                                    {this.state.newcondition.gmflx == 2 ? this.arrsel(this.state.sfkr_react2) : null}
                                    {this.state.newcondition.gmflx == 3 ? this.arrsel(this.state.sfkr_react3) : null}
                                    {this.state.newcondition.gmflx == 4 ? this.arrsel(this.state.sfkr_react4) : null}
                                </Select>
                                : null}
                            <Select placeholder="销售方类型" showSearch optionFilterProp="children" style={{width: 120}}
                                    value={this.state.newcondition.xsflx ? this.state.newcondition.xsflx : null}
                                    onChange={this.onSelChange.bind(this, 'xsflx')} allowClear>
                                {this.arrsel(this.state.sfkrlx_react)}
                            </Select>
                            {this.state.newcondition.xsflx ?
                                <Select placeholder="销售方名称" showSearch optionFilterProp="children" style={{width: 250}}
                                        value={this.state.newcondition.xsfmc ? this.state.newcondition.xsfmc : null}
                                        onChange={this.onSelChange.bind(this, 'xsfmc')} allowClear>
                                    {this.state.newcondition.xsflx == 1 ? this.arrsel(this.state.sfkr_react1) : null}
                                    {this.state.newcondition.xsflx == 2 ? this.arrsel(this.state.sfkr_react2) : null}
                                    {this.state.newcondition.xsflx == 3 ? this.arrsel(this.state.sfkr_react3) : null}
                                    {this.state.newcondition.xsflx == 4 ? this.arrsel(this.state.sfkr_react4) : null}
                                </Select>
                                : null}
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                        {store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ?
                            <Space wrap>
                                <Add refresh={this.getdata.bind(this)}/>
                                {/* <Button type="primary" className='blue'>导出</Button> */}
                            </Space>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    scroll={{y: this.state.tableHeight ? this.state.tableHeight : null, x: 2200}}
                    className='table'
                    size="small"
                   summary={(pageData) => {
                        let totalje = 0;
                        let totaljshj = 0;
                        let totalse = 0;
                        pageData.forEach(({jshj,se,je}) => {
                            jshj = jshj.replace(/,/g,'')
                            totaljshj += Number(jshj ? jshj : 0);
                            se = se.replace(/,/g,'')
                            totalse += Number(se ? se : 0);
                            je = je.replace(/,/g,'')
                            totalje += Number(je ? je : 0);
                        });
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>{totaljshj.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>{totalse.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>{totalje.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={14}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}Ï
                />
                <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1}
                                pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50}
                                total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true}
                                showTotal={total => `共 ${total} 条数据`}/>
                </div>
            </>
        )
    }
}
