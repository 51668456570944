import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, DatePicker, Upload } from 'antd';
import store from "../store/store.js";
import { FormOutlined, UploadOutlined,DeleteOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                idcardfj: [],
                jszfj: [],
                czzfj: [],
            },
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            fetch('/Ygxx/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg)
                        return
                    }
                    if (this.state.condition.id) {
                        that.setState({
                            condition: data.data,
                        })
                    }
                    that.setState({
                        gw_react: data.gw_react ? data.gw_react : [],
                        ywjsz_react: data.ywjsz_react ? data.ywjsz_react : [],
                        ywsb_react: data.ywsb_react ? data.ywsb_react : [],
                        ywgzz_react: data.ywgzz_react ? data.ywgzz_react : [],
                        status_react: data.status_react ? data.status_react : [],
                    })
                })
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                    idcardfj: [],
                    jszfj: [],
                    czzfj: [],
                },
                loading: false
            })
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Ygxx/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    // 上传图片
    picChange(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log(info.file.response)
            if (info.file.response.status == 0) {
                message.error(info.file.response.msg)
                return
            }

            // var fjlist = this.state.condition.fjlist
            // fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: info.file.response.id, [name + '_url']: info.file.response.url, [name + '_name']: info.file.response.name })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    // 上传图片
    picChange2(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file.response)

            var fjlist = this.state.condition[name]
            if (!fjlist) {
                fjlist = []
            }
            fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: fjlist })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    delpic(index, name, e) {
        var fjlist = this.state.condition[name]
        fjlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { [name]: fjlist })
        this.setState({
            condition: data,
        })
    }
    render() {
        return (
            <>
                {this.state.condition.id ? <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx16 ? (store.getState().data.info.qx16 == 1 ? '编辑' : '查看') : '查看'}</a> : <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>}
                <Modal maskClosable={false} title="编辑" width={800} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx16 ? (store.getState().data.info.qx16 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={12}>
                                <Form.Item
                                    label="姓名"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.name ? this.state.condition.name : null} onChange={this.onChange.bind(this, 'name')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="岗位"
                                    required={true}
                                >
                                    <Select placeholder="请选择" value={this.state.condition.gw ? this.state.condition.gw : null} onChange={this.onSelChange.bind(this, 'gw')}>
                                        {this.arrsel(this.state.gw_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="身份证号"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.idcard ? this.state.condition.idcard : null} onChange={this.onChange.bind(this, 'idcard')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="手机号"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.tel ? this.state.condition.tel : null} onChange={this.onChange.bind(this, 'tel')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="开户行"
                                    // required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.khh ? this.state.condition.khh : null} onChange={this.onChange.bind(this, 'khh')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="银行卡号"
                                    // required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.yhkh ? this.state.condition.yhkh : null} onChange={this.onChange.bind(this, 'yhkh')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="紧急联系人"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jjlxr ? this.state.condition.jjlxr : null} onChange={this.onChange.bind(this, 'jjlxr')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="紧急联系人联系方式"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jjlxrlxfs ? this.state.condition.jjlxrlxfs : null} onChange={this.onChange.bind(this, 'jjlxrlxfs')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="有无驾驶证"
                                    required={true}
                                >
                                    <Select placeholder="请选择" value={this.state.condition.ywjsz ? this.state.condition.ywjsz : null} onChange={this.onSelChange.bind(this, 'ywjsz')}>
                                        {this.arrsel(this.state.ywjsz_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="有无社保"
                                    required={true}
                                >
                                    <Select placeholder="请选择" value={this.state.condition.ywsb ? this.state.condition.ywsb : null} onChange={this.onSelChange.bind(this, 'ywsb')}>
                                        {this.arrsel(this.state.ywsb_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="有无工作证"
                                    required={true}
                                >
                                    <Select placeholder="请选择" value={this.state.condition.ywgzz ? this.state.condition.ywgzz : null} onChange={this.onSelChange.bind(this, 'ywgzz')}>
                                        {this.arrsel(this.state.ywgzz_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="身份证附件"
                                >
                                    <Row justify="space-between">
                                        <Space align="center">
                                            <Upload
                                                showUploadList={false}
                                                action="/Common/uploadfile"
                                                onChange={this.picChange2.bind(this, 'idcardfj')}
                                                multiple={true}
                                            >
                                                <Button icon={<UploadOutlined />}>上传</Button>
                                            </Upload>
                                            <Space wrap>
                                                {this.state.condition.idcardfj ? this.state.condition.idcardfj.map((item, index) =>
                                                    <div>
                                                        <a href={item.url} target="_blank">{item.name} </a> <DeleteOutlined className="hongse" onClick={this.delpic.bind(this, index, 'idcardfj')} />
                                                    </div>) : null}

                                            </Space>
                                        </Space>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="驾驶证附件"
                                >
                                    <Row justify="space-between">
                                        <Space align="center">
                                            <Upload
                                                showUploadList={false}
                                                action="/Common/uploadfile"
                                                onChange={this.picChange2.bind(this, 'jszfj')}
                                                multiple={true}
                                            >
                                                <Button icon={<UploadOutlined />}>上传</Button>
                                            </Upload>
                                            <Space wrap>
                                                {this.state.condition.jszfj ? this.state.condition.jszfj.map((item, index) =>
                                                    <div>
                                                        <a href={item.url} target="_blank">{item.name} </a> <DeleteOutlined className="hongse" onClick={this.delpic.bind(this, index, 'jszfj')} />
                                                    </div>) : null}

                                            </Space>
                                        </Space>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="操作证附件"
                                >
                                    <Row justify="space-between">
                                        <Space align="center">
                                            <Upload
                                                showUploadList={false}
                                                action="/Common/uploadfile"
                                                onChange={this.picChange2.bind(this, 'czzfj')}
                                                multiple={true}
                                            >
                                                <Button icon={<UploadOutlined />}>上传</Button>
                                            </Upload>
                                            <Space wrap>
                                                {this.state.condition.czzfj ? this.state.condition.czzfj.map((item, index) =>
                                                    <div>
                                                        <a href={item.url} target="_blank">{item.name} </a> <DeleteOutlined className="hongse" onClick={this.delpic.bind(this, index, 'czzfj')} />
                                                    </div>) : null}

                                            </Space>
                                        </Space>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="状态"
                                    required={true}
                                    tooltip={"选择并提交为离职之后将不可编辑状态，入职日期和离职日期"}
                                >
                                    <Select disabled={this.state.condition.noedit == 1} placeholder="请选择" value={this.state.condition.status ? this.state.condition.status : null} onChange={this.onSelChange.bind(this, 'status')}>
                                        {this.arrsel(this.state.status_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="入职日期"
                                    required={true}
                                >
                                    <DatePicker disabled={this.state.condition.noedit == 1} style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'rztime')} value={this.state.condition.rztime ? moment(this.state.condition.rztime) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="离职日期"
                                    required={this.state.condition.status == 2 ? true : false}
                                >
                                    <DatePicker disabled={this.state.condition.noedit == 1} style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'lztime')} value={this.state.condition.lztime ? moment(this.state.condition.lztime) : null} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {store.getState().data.info.qx16 ? (store.getState().data.info.qx16 == 1 ?
                            <Row justify="center">
                                <Button className='blue' style={{ width: 140 }} loading={this.state.loading} onClick={this.sub.bind(this)}>确认</Button>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}
