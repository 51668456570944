import React from 'react';
import {Redirect, Link, withRouter} from 'react-router-dom';
import {
    Switch,
    Space,
    Select,
    Input,
    Button,
    Table,
    Modal,
    Row,
    Col,
    Form,
    message,
    Checkbox,
    Radio,
    Pagination,
    Tag,
    Popconfirm,
    Tooltip,
    DatePicker
} from 'antd';
import store from "../store/store.js";
import {FormOutlined} from '@ant-design/icons'
import moment from 'moment';
import Add from './add';
import Jieyu from './jieyu';
import Kucun from './kc_index.js';

const {RangePicker} = DatePicker;
const {Option} = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
            month_react: [
                {id: 1, name: '1月'},
                {id: 2, name: '2月'},
                {id: 3, name: '3月'},
                {id: 4, name: '4月'},
                {id: 5, name: '5月'},
                {id: 6, name: '6月'},
                {id: 7, name: '7月'},
                {id: 8, name: '8月'},
                {id: 9, name: '9月'},
                {id: 10, name: '10月'},
                {id: 11, name: '11月'},
                {id: 12, name: '12月'},
            ]
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }

    componentDidMount() {
        this.props.NAV_CHANGE('/jckgl2', '泵管收发')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }

    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 240 - selheight
        that.setState({
            tableHeight: yheight
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, {[name]: dateString})
        this.setState({
            newcondition: data
        })
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
    };

    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, {current: page, pagesize: pagesize})
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }

    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, {current: 1, pagesize: old.pagesize})
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Bgsfhz/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    month_react: data.month_react ? data.month_react : [],
                    sffl_react: data.sffl_react ? data.sffl_react : [],
                    projectid_react: data.projectid_react ? data.projectid_react : [],
                    sgdwid_react: data.sgdwid_react ? data.sgdwid_react : [],
                })
            })
    }

    delSel() {
        let that = this
        fetch('/Bgsfhz/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    delSel2(id, e) {
        let that = this
        fetch('/Bgsfhz/delSel2', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Bgsfhz/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }

    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, {
            [name + '1']: dateStrings[0],
            [name + '2']: dateStrings[1]
        })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }

    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }

    arrChange(edit) {
        let that = this
        fetch('/Bgsfhz/arrChange', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
                edit
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                that.getdata(0, null)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 80,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '收发分类',
                dataIndex: 'sffl_str',
                key: 'sffl_str',
                align: "center",
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '创建日期',
                dataIndex: 'addtime_str',
                key: 'addtime_str',
                align: "center",
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '收发日期',
                dataIndex: 'riqi_str',
                key: 'riqi_str',
                align: "center",
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '项目名称（泵管）',
                dataIndex: 'xmmc',
                key: 'xmmc',
                width: 200,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            /*{
                title: '施工单位',
                dataIndex: 'sgdw_str',
                key: 'sgdw_str',
                width: 200,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },*/
            /*{
                title: '混凝土搅拌站',
                dataIndex: 'hntjbz_str',
                key: 'hntjbz_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },*/
             {
                title: '客户',
                dataIndex: 'khid_str',
                key: 'khid_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '项目联系人',
                dataIndex: 'xmlxr',
                key: 'xmlxr',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '联系电话',
                dataIndex: 'lxdh',
                key: 'lxdh',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '3米直管',
                dataIndex: 'smzg',
                key: 'smzg',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '2米直管',
                dataIndex: 'lmzg',
                key: 'lmzg',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '1.5米',
                dataIndex: 'ydwm',
                key: 'ydwm',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '1米直管',
                dataIndex: 'ymzg',
                key: 'ymzg',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '0.5米直管',
                dataIndex: 'ldwmzg',
                key: 'ldwmzg',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '弯头大90°',
                dataIndex: 'wtdjsd',
                key: 'wtdjsd',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '弯头中90°',
                dataIndex: 'wtzjsd',
                key: 'wtzjsd',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '弯头小90°',
                dataIndex: 'wtxjsd',
                key: 'wtxjsd',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '45°',
                dataIndex: 'sswd',
                key: 'sswd',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '管卡',
                dataIndex: 'gk',
                key: 'gk',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '胶管',
                dataIndex: 'jg',
                key: 'jg',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '高压胶圈',
                dataIndex: 'gyjq',
                key: 'gyjq',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '溜管直管',
                dataIndex: 'lgzg',
                key: 'lgzg',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '溜管弯管',
                dataIndex: 'lgwg',
                key: 'lgwg',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '配套螺栓',
                dataIndex: 'ptls',
                key: 'ptls',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '溜槽',
                dataIndex: 'lc',
                key: 'lc',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '备注',
                dataIndex: 'beizhu',
                key: 'beizhu',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '状态',
                dataIndex: 'status_str',
                key: 'status_str',
                width: 80,
                render: (row, record) => (
                    <>
                        {record.status == 1 && <Tag color={'green'}>{row}</Tag>}
                        {record.status == 2 && <Tag color={'red'}>{row}</Tag>}
                    </>
                ),
            },
            {
                title: '操作人',
                dataIndex: 'aidname',
                key: 'aidname',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={record.updatetime}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 150,
                fixed: 'right',
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <Add id={record.id} refresh={this.getdata.bind(this)}/>
                                {store.getState().data.info.qx5 ? (store.getState().data.info.qx5 == 1 ?
                                    <Popconfirm
                                        title="确定删除?"
                                        onConfirm={this.delSel2.bind(this, record.id)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <a href="#" className="hongse">删除</a>
                                    </Popconfirm>
                                    : null) : null}
                            </Space>
                        </>
                    )
                },
            },
        ]
        const {tableloading, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll: true,
            preserveSelectedRowKeys: false,
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between">
                        <Space wrap>
                            {store.getState().data.info.qx5 == 1  && hasSelected ?
                                <>
                                    <Button type="primary" onClick={()=>this.arrChange(1)} className='blue'>开启</Button>
                                    <Button type="primary" onClick={()=>this.arrChange(2)} className='blue' danger>关闭</Button>
                                </>
                                : null}
                            {/*<DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null} />
                            <Select placeholder="月份" style={{ width: 120 }} value={this.state.newcondition.month ? this.state.newcondition.month : 0} onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                {this.arrsel(this.state.month_react)}
                            </Select>*/}
                            <RangePicker allowEmpty={[true, true]} picker="month"
                                         onChange={this.onRangeChange.bind(this, 'addtime')}
                                         value={[(this.state.newcondition.addtime1 ? moment(this.state.newcondition.addtime1) : null), (this.state.newcondition.addtime2 ? moment(this.state.newcondition.addtime2) : null)]}
                                         placeholder={['创建日期', '创建日期']}/>
                            <RangePicker allowEmpty={[true, true]} picker="month"
                                         onChange={this.onRangeChange.bind(this, 'riqi')}
                                         value={[(this.state.newcondition.riqi1 ? moment(this.state.newcondition.riqi1) : null), (this.state.newcondition.riqi2 ? moment(this.state.newcondition.riqi2) : null)]}
                                         placeholder={['收发日期', '收发日期']}/>
                            <Select placeholder="收发分类" style={{width: 150}} showSearch optionFilterProp="children"
                                    value={this.state.newcondition.sffl ? this.state.newcondition.sffl : null}
                                    onChange={this.onSelChange.bind(this, 'sffl')} allowClear>
                                {this.arrsel(this.state.sffl_react)}
                            </Select>
                            <Select placeholder="项目名称" style={{width: 210}} showSearch optionFilterProp="children"
                                    value={this.state.newcondition.projectid ? this.state.newcondition.projectid : null}
                                    onChange={this.onSelChange.bind(this, 'projectid')} allowClear>
                                {this.arrsel(this.state.projectid_react)}
                            </Select>
                            {/* <Select placeholder="施工单位" style={{ width: 210 }} showSearch optionFilterProp="children" value={this.state.newcondition.sgdwid ? this.state.newcondition.sgdwid : null} onChange={this.onSelChange.bind(this, 'sgdwid')} allowClear>
                                {this.arrsel(this.state.sgdwid_react)}
                            </Select>*/}
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                            {store.getState().data.info.qx5 ? (store.getState().data.info.qx5 != -1 ?
                                <Kucun/>
                                : null) : null}
                            <Jieyu refresh={this.getdata.bind(this)}/>
                        </Space>
                        {store.getState().data.info.qx5 ? (store.getState().data.info.qx5 == 1 ?
                            <Space wrap>
                                <Add refresh={this.getdata.bind(this)}/>
                                {/* <Button type="primary" className='blue'>导出</Button> */}
                            </Space>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    rowSelection={rowSelection}
                    scroll={{x: 3200, y: this.state.tableHeight ? this.state.tableHeight : null}}
                    className='table'
                    size="small"
                    summary={(pageData) => {
                        let totalsmzg = 0;
                        let totallmzg = 0;
                        let totalydwm = 0;
                        let totalymzg = 0;
                        let totalldwmzg = 0;
                        let totalwtdjsd = 0;
                        let totalwtzjsd = 0;
                        let totalwtxjsd = 0;
                        let totalsswd = 0;
                        let totalgk = 0;
                        let totaljg = 0;
                        let totalgyjq = 0;
                        let totallgzg = 0;
                        let totallgwg = 0;
                        let totalptls = 0;
                        let totallc = 0;
                        pageData.forEach(({
                                              sffl,
                                              smzg,
                                              lmzg,
                                              ydwm,
                                              ymzg,
                                              ldwmzg,
                                              wtdjsd,
                                              wtzjsd,
                                              wtxjsd,
                                              sswd,
                                              gk,
                                              jg,
                                              gyjq,
                                              lgzg,
                                              lgwg,
                                              ptls,
                                              lc
                                          }) => {
                            if (sffl == 1) {
                                totalsmzg += Number(smzg ? smzg : 0);
                                totallmzg += Number(lmzg ? lmzg : 0);
                                totalydwm += Number(ydwm ? ydwm : 0);
                                totalymzg += Number(ymzg ? ymzg : 0);
                                totalldwmzg += Number(ldwmzg ? ldwmzg : 0);
                                totalwtdjsd += Number(wtdjsd ? wtdjsd : 0);
                                totalwtzjsd += Number(wtzjsd ? wtzjsd : 0);
                                totalwtxjsd += Number(wtxjsd ? wtxjsd : 0);
                                totalsswd += Number(sswd ? sswd : 0);
                                totalgk += Number(gk ? gk : 0);
                                totaljg += Number(jg ? jg : 0);
                                totalgyjq += Number(gyjq ? gyjq : 0);
                                totallgzg += Number(lgzg ? lgzg : 0);
                                totallgwg += Number(lgwg ? lgwg : 0);
                                totalptls += Number(ptls ? ptls : 0);
                                totallc += Number(lc ? lc : 0);
                            } else if (sffl == 2) {
                                totalsmzg -= Number(smzg ? smzg : 0);
                                totallmzg -= Number(lmzg ? lmzg : 0);
                                totalydwm -= Number(ydwm ? ydwm : 0);
                                totalymzg -= Number(ymzg ? ymzg : 0);
                                totalldwmzg -= Number(ldwmzg ? ldwmzg : 0);
                                totalwtdjsd -= Number(wtdjsd ? wtdjsd : 0);
                                totalwtzjsd -= Number(wtzjsd ? wtzjsd : 0);
                                totalwtxjsd -= Number(wtxjsd ? wtxjsd : 0);
                                totalsswd -= Number(sswd ? sswd : 0);
                                totalgk -= Number(gk ? gk : 0);
                                totaljg -= Number(jg ? jg : 0);
                                totalgyjq -= Number(gyjq ? gyjq : 0);
                                totallgzg -= Number(lgzg ? lgzg : 0);
                                totallgwg -= Number(lgwg ? lgwg : 0);
                                totalptls -= Number(ptls ? ptls : 0);
                                totallc -= Number(lc ? lc : 0);
                            }

                        });
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}>{totalsmzg.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}>{totallmzg.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}>{totalydwm.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}>{totalymzg.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}>{totalldwmzg.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={14}>{totalwtdjsd.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}>{totalwtzjsd.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={16}>{totalwtxjsd.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={17}>{totalsswd.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={18}>{totalgk.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={19}>{totaljg.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={20}>{totalgyjq.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={21}>{totallgzg.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={22}>{totallgwg.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={23}>{totalptls.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={24}>{totallc.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={25}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={26}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={27}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={28}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
                <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1}
                                pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50}
                                total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true}
                                showTotal={total => `共 ${total} 条数据`}/>
                </div>
            </>
        )
    }
}
