import { createStore } from 'redux'
import rootRedux from './reducer/rootRedux.js'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2 // 查看 'Merge Process' 部分的具体情况
};

const myPersistReducer = persistReducer(persistConfig, rootRedux)

const store = createStore(myPersistReducer)

export const persistor = persistStore(store)
// const store = createStore(rootRedux);
export default store;