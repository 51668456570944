import React from 'react';
import {Redirect, Link, withRouter} from 'react-router-dom';
import {
    Switch,
    Space,
    Select,
    Input,
    Button,
    Table,
    Modal,
    Row,
    Col,
    Form,
    message,
    Checkbox,
    Radio,
    Pagination,
    Tag,
    Popconfirm,
    Tooltip,
    DatePicker,
    Alert
} from 'antd';
import store from "../store/store.js";
import {FormOutlined, CheckCircleOutlined} from '@ant-design/icons'
import moment from 'moment';
import Doview_arr from "./doview_arr";

const {Option} = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
            list: [],
            list2: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
            month_react: [
                {id: 1, name: '1月'},
                {id: 2, name: '2月'},
                {id: 3, name: '3月'},
                {id: 4, name: '4月'},
                {id: 5, name: '5月'},
                {id: 6, name: '6月'},
                {id: 7, name: '7月'},
                {id: 8, name: '8月'},
                {id: 9, name: '9月'},
                {id: 10, name: '10月'},
                {id: 11, name: '11月'},
                {id: 12, name: '12月'},
            ],
            daylist: [],
            columns: [
                {
                    title: '序号',
                    dataIndex: 'xh',
                    key: 'xh',
                    align: "center",
                    width: 100,
                    fixed: 'left',
                    ellipsis: {
                        showTitle: false,
                    },
                    render: (row, record) => (
                        <Tooltip placement="topLeft" title={row}>
                            {row}
                        </Tooltip>
                    ),
                },
                {
                    title: '人员',
                    dataIndex: 'name',
                    key: 'name',
                    fixed: 'left',
                    width: 100,
                    ellipsis: {
                        showTitle: false,
                    },
                    render: (row, record) => (
                        <Tooltip placement="topLeft" title={row}>
                            {row}
                        </Tooltip>
                    ),
                },
                {
                    title: '工资',
                    dataIndex: 'jbgz',
                    key: 'jbgz',
                    width: 100,
                    ellipsis: {
                        showTitle: false,
                    },
                    render: (row, record) => (
                        <Tooltip placement="topLeft" title={row}>
                            {row}
                        </Tooltip>
                    ),
                },
                {
                    title: '出勤天数',
                    dataIndex: 'cqts',
                    key: 'cqts',
                    fixed: 'right',
                    width: 120,
                    ellipsis: {
                        showTitle: false,
                    },
                    render: (row, record) => (
                        <Tooltip placement="topLeft" title={row}>
                            {row}
                        </Tooltip>
                    ),
                },
                {
                    title: '缺勤扣款',
                    dataIndex: 'kk',
                    key: 'kk',
                    fixed: 'right',
                    width: 120,
                    ellipsis: {
                        showTitle: false,
                    },
                    render: (row, record) => (
                        <Tooltip placement="topLeft" title={row}>
                            {row}
                        </Tooltip>
                    ),
                },
            ]
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }

    componentDidMount() {
        this.props.NAV_CHANGE('/kq', '员工考勤')
        this.getdata(1)
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, {[name]: dateString})
        this.setState({
            newcondition: data
        })
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
    };
    onTableChange = (index, name, type, value) => {
        let that = this
        console.log(index, name, value)
        let list = that.state[type].concat()
        list[index][name] = value
        if (name.indexOf("day") != -1) {
            var reg2 = new RegExp("day");
            var day = name.replace(reg2, "");
            fetch('/Kq/doview', {
                // post提交
                method: "POST",
                body: JSON.stringify({
                    uid: list[index]['id'] ? list[index]['id'] : 0,
                    riqi: list[index]['time' + day] ? list[index]['time' + day] : 0,
                    value: value ? value : 0
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg)
                    }
                    that.setState({
                        [type]: list
                    })
                    this.getdata(0)
                })
        }
        // that.setState({
        //     list: list
        // })
    }

    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, {current: page, pagesize: pagesize})
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}
                        disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }

    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, {current: 1, pagesize: old.pagesize})
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Kq/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                }
                that.setState({
                    list: data.data,
                    list2: data.data2,
                    tableloading: false,
                    daylist: data.daylist,
                    qjlx1_react: data.qjlx1_react,
                    qjlx2_react: data.qjlx2_react,
                })
            })
    }

    delSel() {
        let that = this
        fetch('/Kq/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    download(download, e) {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = download;
        // console.log(arr)
        fetch('/Kq/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }

    arrcolumns(arr, type) {
        if (arr) {
            let list = this.state.columns.concat()
            for (const key in arr) {
                const item = arr[key];
                let newitem = {
                    title: item.day + "(" + item.title + ")",
                    dataIndex: 'day' + item.day,
                    key: 'day' + item.day,
                    width: 100,
                    align: 'center',
                    ellipsis: {
                        showTitle: false,
                    },
                    render: (row, record, index) => (
                        <>
                            {record.gw == 1 || record.gw == 3 ?
                                <>
                                    {record['day' + item.day + '_isok'] == 1 ?
                                        <CheckCircleOutlined className="is_ok"/>
                                        : null}
                                    <Select
                                        size="small"
                                        style={{width: "100%"}}
                                        bordered={false}
                                        allowClear
                                        showArrow={false}
                                        value={row ? row : null}
                                        disabled={store.getState().data.info.qx12 ? (store.getState().data.info.qx12 == 1 ? ((row == "离职" || row == "未入职") ? true : false) : true) : true}
                                        fieldNames={{label: 'name', value: 'id'}}
                                        onChange={this.onTableChange.bind(this, index, 'day' + item.day, type)}
                                        options={this.state.qjlx1_react ? this.state.qjlx1_react : []}
                                        className='sel'
                                    />

                                </>
                                : null}
                            {record.gw == 2 ?
                                <>
                                    {record['day' + item.day + '_isok'] == 1 ?
                                        <CheckCircleOutlined className="is_ok"/>
                                        : null}
                                    <Select
                                        size="small"
                                        style={{width: "100%"}}
                                        bordered={false}
                                        allowClear
                                        showArrow={false}
                                        value={row ? row : null}
                                        disabled={store.getState().data.info.qx12 ? (store.getState().data.info.qx12 == 1 ? ((row == "离职" || row == "未入职") ? true : false) : true) : true}
                                        fieldNames={{label: 'name', value: 'id'}}
                                        onChange={this.onTableChange.bind(this, index, 'day' + item.day, type)}
                                        options={this.state.qjlx2_react ? this.state.qjlx2_react : []}
                                        className='sel'
                                    />

                                </>
                                : null}
                        </>
                    ),
                }
                list.splice(-2, 0, newitem)
            }
            if (type == 'list') {
                let jbf = {
                    title: '加班费',
                    dataIndex: 'jbf',
                    key: 'jbf',
                    fixed: 'right',
                    width: 120,
                    ellipsis: {
                        showTitle: false,
                    },
                    render: (row, record) => (
                        <Tooltip placement="topLeft" title={row}>
                            {row}
                        </Tooltip>
                    ),
                }
                list.push(jbf)
            }
            let caozuo = {
                title: '批量操作',
                dataIndex: 'caozuo',
                key: 'caozuo',
                fixed: 'right',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    store.getState().data.info.qx12 ? store.getState().data.info.qx12 == 1 ?
                        <Doview_arr
                            refresh={this.getdata.bind(this)}
                            uid={record.id}
                            year={this.state.oldcondition.year ? this.state.oldcondition.year : new Date().getFullYear().toString()}
                            month={this.state.oldcondition.month ? this.state.oldcondition.month : new Date().getMonth() + 1}
                            status_react={type == 'list' ? this.state.qjlx1_react : this.state.qjlx2_react}/>
                        : null : null
                ),
            }
            list.push(caozuo)
            return list
        }
    }

    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }

    render() {
        const {tableloading, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideSelectAll: true,
            preserveSelectedRowKeys: false,
            renderCell: function (checked, record, index, originNode) {
                if (record.status != 1) {
                    return originNode
                } else {
                    return false
                }
            },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between">
                        <Space wrap>
                            <DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year"
                                        value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null}
                                        allowClear={false}/>
                            <Select placeholder="月份" style={{width: 120}}
                                    value={this.state.newcondition.month ? this.state.newcondition.month : null}
                                    onChange={this.onSelChange.bind(this, 'month')} allowClear={false}>
                                {this.arrsel(this.state.month_react)}
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                        {store.getState().data.info.qx12 ? (store.getState().data.info.qx12 == 1 ?
                            <Space wrap>
                                <Button type="primary" className='blue'
                                        onClick={this.download.bind(this, 1)}>导出司机岗</Button>
                                <Button type="primary" className='blue'
                                        onClick={this.download.bind(this, 2)}>导出管理岗</Button>
                            </Space>
                            : null) : null}
                    </Row>
                </div>
                <Alert message="司机岗" type="info" style={{marginBottom: 16}}/>
                <Table
                    bordered
                    columns={this.arrcolumns(this.state.daylist, 'list')}
                    // sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    scroll={{x: 4000, y: 400}}
                    className='table kqtable'
                    size="small"
                />
                <Alert message="管理岗" type="info" style={{marginBottom: 16}}/>
                <Table
                    bordered
                    columns={this.arrcolumns(this.state.daylist, 'list2')}
                    // sticky={false}
                    dataSource={this.state.list2}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    scroll={{x: 4000, y: 400}}
                    className='table kqtable'
                    size="small"
                />
                {/* <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div> */}
            </>
        )
    }
}
