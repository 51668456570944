import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Upload,Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm } from 'antd';
import store from "../store/store.js";
import { PlusOutlined,InfoCircleOutlined,UploadOutlined,DeleteOutlined } from '@ant-design/icons'
import moment from 'moment';
import Chxz from '../public/chxz.js';
import Chxm from '../public/chxm.js';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                sclist: [],
            },
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value, Option) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        setTimeout(function () {
            if (name == 'chid') {
                let data2 = Object.assign({}, that.state.condition, {
                    cp: Option.key ? that.state.chid_react[Option.key]['cp'] : null,
                    sblx: Option.key ? that.state.chid_react[Option.key]['sblx'] : null
                })
                that.setState({
                    condition: data2
                })
            }
        })
    }
    getSgbw = (id) => {
        let that = this
        fetch('/Common/getSgbw', {
            method: "POST",
            body: JSON.stringify({
                id: id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.setState({
                    sgbw_react: data.sgbw_react ? data.sgbw_react : [],
                })
            })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        setTimeout(function () {
            if (name == 'jyss' || name == 'je') {
                let jyss = data.jyss ? data.jyss : 0
                let je = data.je ? data.je : 0
                var dj = (Number(je) / Number(jyss)).toFixed(2)
                let data2 = Object.assign({}, that.state.condition, { jyss: jyss, dj: dj })
                that.setState({
                    condition: data2
                })
            }
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            fetch('/Jymx/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg)
                        return
                    }
                    if (this.state.condition.id) {
                        that.setState({
                            condition: data.data,
                        })
                    }
                    that.setState({
                        chid_react: data.chid_react ? data.chid_react : [],
                        sj_react: data.sj_react ? data.sj_react : [],
                        guanli_react: data.guanli_react ? data.guanli_react : [],
                        sgbw_react: data.sgbw_react ? data.sgbw_react : [],
                        jydd_react: data.jydd_react ? data.jydd_react : [],
                        zffs_react: data.zffs_react ? data.zffs_react : [],
                    })
                })
            if (that.props.notable != 1) {
                that.getdata2()
            }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                    sclist: [],
                },
                loading: false
            })
        }
    }
    sub(isnext = 0, e) {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Jymx/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    if (isnext == 1) {
                        that.nextAdd()
                    } else {
                        this.mdchange()
                    }
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    getdata2(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        fetch('/Jymx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify({
                limit: 3
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count)
                })
            })
    }
    //继续添加
    nextAdd = (e) => {
        let that = this
        that.setState({
            condition: {
                id: 0,
            },
            list: [],
            loading: false
        })
        that.getdata2()
    }
    addItem_jydd = () => {
        let that = this
        fetch('/Jydd/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, jydd: data.jydd ? data.jydd : null })
                    this.setState({
                        condition: condition,
                        jydd_react: data.jydd_react ? data.jydd_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    clearNewField = (open) => {
        let that = this
        setTimeout(() => {
            let condition = Object.assign({}, that.state.condition, { newfield: null })
            that.setState({
                condition: condition,
            })
        });
    }
    arrscmc(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <>
                    <Col span={8}>
                        <Form.Item
                            label={<Space><span>生产日期</span>{(this.state.condition.chid!=item.chid || item.type!=1)?<InfoCircleOutlined className="hongse" />:null}</Space>}
                        // required={true}
                        >
                            <Input placeholder="请输入" value={item.scrq_str ? item.scrq_str : null}  disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="项目名称"
                        // required={true}
                        >
                            <Input placeholder="请输入" value={item.xmmc ? item.xmmc : null}  disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="客户"
                        // required={true}
                        >
                            <Input placeholder="请输入" value={item.kh ? item.kh : null}  disabled={true} />
                        </Form.Item>
                    </Col>
                </>
            )
        }
    }
    setsclist = (arr, e) => {
        let that = this
        fetch('/Common/getsclist', {
            method: "POST",
            body: JSON.stringify({
                arrid: arr
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                // if (this.state.condition.id) {
                let condition = Object.assign({}, that.state.condition, { xmmc: arr, sclist: data.sclist })
                that.setState({
                    condition: condition,
                })
                // }
            })
    }
    // 上传图片
    picChange2(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file.response)

            var fjlist = this.state.condition[name]
            if (!fjlist) {
                fjlist = []
            }
            fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: fjlist })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    delpic(index, name, e) {
        var fjlist = this.state.condition[name]
        fjlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { [name]: fjlist })
        this.setState({
            condition: data,
        })
    }
    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 80,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车辆编号',
                dataIndex: 'clbh',
                key: 'clbh',
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车牌',
                dataIndex: 'cp',
                key: 'cp',
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '类型',
                dataIndex: 'sblx',
                key: 'sblx',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '司机',
                dataIndex: 'sj_str',
                key: 'sj_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '日期',
                dataIndex: 'riqi_str',
                key: 'riqi_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '时间',
                dataIndex: 'shijian_str',
                key: 'shijian_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '送油升数',
                dataIndex: 'syss',
                key: 'syss',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '加油升数',
                dataIndex: 'jyss',
                key: 'jyss',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '单价',
                dataIndex: 'dj',
                key: 'dj',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '金额',
                dataIndex: 'je',
                key: 'je',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '加油地点',
                dataIndex: 'jydd_str',
                key: 'jydd_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '支付方式',
                dataIndex: 'zffs_str',
                key: 'zffs_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },

            {
                title: '经办人',
                dataIndex: 'jbr_str',
                key: 'jbr_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '审核人',
                dataIndex: 'shr_str',
                key: 'shr_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '备注',
                dataIndex: 'beizhu',
                key: 'beizhu',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
        ]
        const { tableloading } = this.state;
        return (
            <>
                {this.state.condition.id ? <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx3 ? (store.getState().data.info.qx3 == 1 ? '编辑' : '查看') : '查看'}</a> : <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>}
                <Modal maskClosable={false} title="编辑" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx3 ? (store.getState().data.info.qx3 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={24}>
                                <Alert type="info" message="设备&司机" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="车辆编号"
                                    required={true}
                                >
                                    <Input.Group compact>
                                        <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.chid ? this.state.condition.chid : null} onChange={this.onSelChange.bind(this, 'chid')} style={{ width: 'calc(100% - 89px)' }} disabled={true} >
                                            {this.arrsel(this.state.chid_react)}
                                        </Select>
                                        <Chxz value={this.state.condition.chid ? this.state.condition.chid : null} onChange={this.onSelChange.bind(this)} chid_react={this.state.chid_react ? this.state.chid_react : []} />
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="车牌"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.cp ? this.state.condition.cp : null} onChange={this.onChange.bind(this, 'cp')}  disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="类型"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.sblx ? this.state.condition.sblx : null} onChange={this.onChange.bind(this, 'sblx')}  disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="司机"
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.sj ? this.state.condition.sj : null} onChange={this.onSelChange.bind(this, 'sj')}>
                                        {this.arrsel(this.state.sj_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message={<Space><span>生产信息</span><Chxm xmmc={this.state.condition.xmmc ? this.state.condition.xmmc : []} onChange={this.setsclist.bind(this)} /></Space>} banner style={{ marginBottom: 24 }} />
                            </Col>
                            {this.arrscmc(this.state.condition.sclist)}
                            <Col span={24}>
                                <Alert type="info" message="油票信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="日期"
                                    required={true}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'riqi')} value={this.state.condition.riqi ? moment(this.state.condition.riqi) : null} showTime />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="送油升数"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.syss ? this.state.condition.syss : null} onChange={this.onChange.bind(this, 'syss')}  type="text" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="加油升数"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jyss ? this.state.condition.jyss : null} onChange={this.onChange.bind(this, 'jyss')}  type="text" min={0} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="金额"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.je ? this.state.condition.je : null} onChange={this.onChange.bind(this, 'je')}  type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="单价"
                                    tooltip="【金额】 除以 【加油升数】"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.dj ? this.state.condition.dj : null} onChange={this.onChange.bind(this, 'dj')}  type="text" suffix="元" disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="加油地点"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.jydd ? this.state.condition.jydd : null}
                                        onChange={this.onSelChange.bind(this, 'jydd')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_jydd.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.jydd_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="支付方式"
                                // required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.zffs ? this.state.condition.zffs : null} allowClear onChange={this.onSelChange.bind(this, 'zffs')}  >
                                        {this.arrsel(this.state.zffs_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="油票信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="经办人"
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.jbr ? this.state.condition.jbr : null} onChange={this.onSelChange.bind(this, 'jbr')}>
                                        {this.arrsel(this.state.guanli_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="审核人"
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.shr ? this.state.condition.shr : null} onChange={this.onSelChange.bind(this, 'shr')}>
                                        {this.arrsel(this.state.guanli_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="备注"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.beizhu ? this.state.condition.beizhu : null} onChange={this.onChange.bind(this, 'beizhu')}  />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="附件"
                                    required={true}
                                    tooltip={'至少上传三个附件'}
                                >
                                    <Row justify="space-between">
                                        <Space align="center">
                                            <Upload
                                                showUploadList={false}
                                                action="/Common/uploadfile"
                                                onChange={this.picChange2.bind(this, 'fjlist')}
                                                multiple={true}
                                            >
                                                <Button icon={<UploadOutlined />}>上传</Button>
                                            </Upload>
                                            <Space wrap>
                                                {this.state.condition.fjlist ? this.state.condition.fjlist.map((item, index) =>
                                                    <div>
                                                        <a href={item.url} target="_blank">{item.name} </a> <DeleteOutlined className="hongse" onClick={this.delpic.bind(this, index, 'fjlist')} />
                                                    </div>) : null}

                                            </Space>
                                        </Space>
                                    </Row>
                                </Form.Item>
                            </Col>
                            {this.props.notable == 1 ?
                                null
                                :
                                <>
                                    <Col span={24}>
                                        <Divider orientation="left" style={{ marginTop: 0 }}>最近添加信息</Divider>
                                    </Col>
                                    <Col span={24}>
                                        <Table
                                            bordered
                                            columns={columns}
                                            sticky={false}
                                            dataSource={this.state.list}
                                            pagination={false}
                                            loading={tableloading}
                                            // rowSelection={rowSelection}
                                            scroll={{ x: 2500, }}
                                            className='table'
                                            size="small"
                                        />
                                    </Col>
                                </>
                            }
                        </Row>
                        {store.getState().data.info.qx3 ? (store.getState().data.info.qx3 == 1 ?
                            <Row justify="center">
                                <Space>
                                    <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this)}>完成</Button>
                                    {!this.state.condition.id ?
                                        <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this, '1')}>完成并继续添加下一条</Button>
                                        : null}
                                </Space>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}
