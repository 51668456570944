import React from 'react';
import {Tag, Alert, message} from 'antd';
import {Redirect, Link, withRouter} from 'react-router-dom';
import {Line, Column, Pie} from '@ant-design/plots';
import store from "../store/store.js";
import {
    FormOutlined,
    AuditOutlined,
    LayoutOutlined,
    ReconciliationOutlined,
    BuildOutlined,
    IdcardOutlined,
    EditOutlined,
    BarChartOutlined,
    AlertOutlined,
    ApartmentOutlined,
    ApiOutlined,
    AppstoreAddOutlined,
    AppstoreOutlined,
    BankOutlined,
    BarcodeOutlined,
    BarsOutlined,
    BlockOutlined,
    BookOutlined,
    BranchesOutlined,
    BugOutlined,
    BulbOutlined,
    CalendarOutlined,
    UserSwitchOutlined,
    ClusterOutlined,
    CreditCardOutlined,
    TeamOutlined,
    DeploymentUnitOutlined,
    FolderOpenOutlined,
    DollarCircleOutlined,
    EuroCircleOutlined,
    UserOutlined
} from '@ant-design/icons'

export default class shouye extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                num1: 0,
                num2: 0,
                num3: 0,
                num4: 0,
                num5: 0,
                num6: 0,
                num7: 0,
                num8: 0,
                num9: 0,
                num10: 0,
                num11: 0,
                num12: 0,
                num13: 0,
                num14: 0,
                num15: 0,
                num16: 0,
                num17: 0,
                num18: 0,
                num19: 0,
                num20: 0,
                num21: 0,
                num22: 0,
                num23: 0,
                num24: 0,
                num25: 0,
            }
        };
        store.subscribe(() => {
            // console.log('state状态改变了,新状态如下')
            // console.log(store.getState().data.isLogin)
        })
    }

    componentDidMount() {
        this.props.NAV_CHANGE('/', '首页')
        this.getdata()
    }

    getdata() {
        let that = this
        fetch('/Shouye/index', {
            // post提交
            method: "POST",
            // body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                }
                that.setState({
                    condition: data.data
                })
            })
    }

    render() {
        return (
            <>
                <Alert message="工作表" type="info" banner icon={<EditOutlined/>}/>
                <div className="tongji">
                    {store.getState().data.info.qx25 ? (store.getState().data.info.qx25 != -1 ?
                        <Link to={{pathname: '/xmjh'}} className="item">
                            <div className="pic">
                                <AuditOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">生产计划</div>
                                <div className="p1">{this.state.condition.num25}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx1 ? (store.getState().data.info.qx1 != -1 ?
                        <Link to={{pathname: '/mrtjjl'}} className="item">
                            <div className="pic">
                                <AlertOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">生产记录</div>
                                <div className="p1">{this.state.condition.num1}</div>
                            </div>
                        </Link>
                        : null) : null}

                    {store.getState().data.info.qx3 ? (store.getState().data.info.qx3 != -1 ?
                        <Link to={{pathname: '/jymx'}} className="item">
                            <div className="pic">
                                <ApiOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">加油统计</div>
                                <div className="p1">{this.state.condition.num3}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx4 ? (store.getState().data.info.qx4 != -1 ?
                        <Link to={{pathname: '/jckgl'}} className="item">
                            <div className="pic">
                                <AppstoreAddOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">物资收发</div>
                                <div className="p1">{this.state.condition.num4}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx5 ? (store.getState().data.info.qx5 != -1 ?
                        <Link to={{pathname: '/jckgl2'}} className="item">
                            <div className="pic">
                                <AppstoreOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">泵管收发</div>
                                <div className="p1">{this.state.condition.num5}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx6 ? (store.getState().data.info.qx6 != -1 ?
                        <Link to={{pathname: '/clwxmx'}} className="item">
                            <div className="pic">
                                <BankOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">车辆维修</div>
                                <div className="p1">{this.state.condition.num6}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {/* {store.getState().data.info.qx7 ? (store.getState().data.info.qx7 != -1 ?
						<Link to={{ pathname: '/wxftj' }} className="item">
							<div className="pic">
								<BarcodeOutlined />
							</div>
							<div className="info">
								<div className="p2">维修费统计</div>
								<div className="p1">{this.state.condition.num7}</div>
							</div>
						</Link>
						: null) : null} */}
                    {store.getState().data.info.qx8 ? (store.getState().data.info.qx8 != -1 ?
                        <Link to={{pathname: '/dhntmx'}} className="item">
                            <div className="pic">
                                <BarsOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">商砼销售</div>
                                <div className="p1">{this.state.condition.num8}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx9 ? (store.getState().data.info.qx9 != -1 ?
                        <>
                            <Link to={{pathname: '/yszkmx'}} className="item">
                                <div className="pic">
                                    <BlockOutlined/>
                                </div>
                                <div className="info">
                                    <div className="p2">应收应付</div>
                                    {/* <div className="p1">{this.state.condition.num9}</div> */}
                                </div>
                            </Link>
                            <Link to={{pathname: '/hkmx'}} className="item">
                                <div className="pic">
                                    <BlockOutlined/>
                                </div>
                                <div className="info">
                                    <div className="p2">回款明细</div>
                                    {/* <div className="p1">{this.state.condition.num9}</div> */}
                                </div>
                            </Link>
                        </>
                        : null) : null}
                    {/* {store.getState().data.info.qx10 ? (store.getState().data.info.qx10 != -1 ?
						<Link to={{ pathname: '/yfzkmx' }} className="item">
							<div className="pic">
								<BookOutlined />
							</div>
							<div className="info">
								<div className="p2">应收应付</div>
								<div className="p1">{this.state.condition.num10}</div>
							</div>
						</Link>
						: null) : null} */}
                    {store.getState().data.info.qx11 ? (store.getState().data.info.qx11 != -1 ?
                        <>
                            <Link to={{pathname: '/szqk'}} className="item">
                                <div className="pic">
                                    <BranchesOutlined/>
                                </div>
                                <div className="info">
                                    <div className="p2">财务收支</div>
                                    <div className="p1">{this.state.condition.num11}</div>
                                </div>
                            </Link>
                            <Link to={{pathname: '/liushui'}} className="item">
                                <div className="pic">
                                    <BranchesOutlined/>
                                </div>
                                <div className="info">
                                    <div className="p2">流水</div>
                                    {/*<div className="p1">{this.state.condition.num11}</div>*/}
                                </div>
                            </Link>
                            <Link to={{pathname: '/fapiao'}} className="item">
                                <div className="pic">
                                    <BranchesOutlined/>
                                </div>
                                <div className="info">
                                    <div className="p2">发票</div>
                                    {/*<div className="p1">{this.state.condition.num11}</div>*/}
                                </div>
                            </Link>
                        </>
                        : null) : null}
                    {store.getState().data.info.qx12 ? (store.getState().data.info.qx12 != -1 ?
                        <Link to={{pathname: '/kq'}} className="item">
                            <div className="pic">
                                <BugOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">员工考勤</div>
                                <div className="p1">{this.state.condition.num12}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx13 ? (store.getState().data.info.qx13 != -1 ?
                        <Link to={{pathname: '/gzjs'}} className="item">
                            <div className="pic">
                                <BuildOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">工资计算</div>
                                <div className="p1">{this.state.condition.num13}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx14 ? (store.getState().data.info.qx14 != -1 ?
                        <Link to={{pathname: '/jsmx'}} className="item">
                            <div className="pic">
                                <BulbOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">结算信息</div>
                                <div className="p1">{this.state.condition.num14}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx15 ? (store.getState().data.info.qx15 != -1 ?
                        <Link to={{pathname: '/htxx'}} className="item">
                            <div className="pic">
                                <CalendarOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">合同信息</div>
                                <div className="p1">{this.state.condition.num15}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx26 ? (store.getState().data.info.qx26 != -1 ?
                        <Link to={{pathname: '/swcb'}} className="item">
                            <div className="pic">
                                <BranchesOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">商务成本</div>
                                <div className="p1">{this.state.condition.num26}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx30 ? (store.getState().data.info.qx30 != -1 ?
                        <Link to={{pathname: '/jcxs'}} className="item">
                            <div className="pic">
                                <DeploymentUnitOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">建材销售</div>
                                <div className="p1">{this.state.condition.num30}</div>
                            </div>
                        </Link>
                        : null) : null}
                </div>
                <Alert message="数据表" type="info" banner icon={<LayoutOutlined/>}/>
                <div className="tongji">
                    {store.getState().data.info.qx16 ? (store.getState().data.info.qx16 != -1 ?
                        <Link to={{pathname: '/ygxx'}} className="item">
                            <div className="pic">
                                <UserOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">员工信息表</div>
                                <div className="p1">{this.state.condition.num16}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx17 ? (store.getState().data.info.qx17 != -1 ?
                        <Link to={{pathname: '/clxx'}} className="item">
                            <div className="pic">
                                <LayoutOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">车辆信息表</div>
                                <div className="p1">{this.state.condition.num17}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 != -1 ?
                        <Link to={{pathname: '/xinxibiao'}} className="item">
                            <div className="pic">
                                <UserSwitchOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">信息表</div>
                                <div className="p1">{this.state.condition.num18}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {/* {store.getState().data.info.qx19 ? (store.getState().data.info.qx19 != -1 ?
						<Link to={{ pathname: '/gcxx' }} className="item">
							<div className="pic">
								<CreditCardOutlined />
							</div>
							<div className="info">
								<div className="p2">工程信息表</div>
								<div className="p1">{this.state.condition.num19}</div>
							</div>
						</Link>
						: null) : null} */}
                    {store.getState().data.info.qx20 ? (store.getState().data.info.qx20 != -1 ?
                        <Link to={{pathname: '/user'}} className="item">
                            <div className="pic">
                                <TeamOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">系统账号</div>
                                <div className="p1">{this.state.condition.num20}</div>
                            </div>
                        </Link>
                        : null) : null}
                </div>
                <Alert message="统计表" type="info" banner icon={<BarChartOutlined/>}/>
                <div className="tongji">
                    {store.getState().data.info.qx2 ? (store.getState().data.info.qx2 != -1 ?
                        <Link to={{pathname: '/wdbmx'}} className="item">
                            <div className="pic">
                                <ApartmentOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">外租统计</div>
                                <div className="p1">{this.state.condition.num2}</div>
                            </div>
                        </Link>
                        : null) : null}
                    {/* {store.getState().data.info.qx21 ? (store.getState().data.info.qx21 != -1 ?
						<Link to={{ pathname: '/zfl' }} className="item">
							<div className="pic">
								<DeploymentUnitOutlined />
							</div>
							<div className="info">
								<div className="p2">总方量</div>
								<div className="p1">{this.state.condition.num21}</div>
							</div>
						</Link>
						: null) : null}
					{store.getState().data.info.qx22 ? (store.getState().data.info.qx22 != -1 ?
						<Link to={{ pathname: '/ywcbhj' }} className="item">
							<div className="pic">
								<FolderOpenOutlined />
							</div>
							<div className="info">
								<div className="p2">业务成本合计</div>
								<div className="p1">{this.state.condition.num22}</div>
							</div>
						</Link>
						: null) : null}
					{store.getState().data.info.qx23 ? (store.getState().data.info.qx23 != -1 ?
						<Link to={{ pathname: '/qjfy' }} className="item">
							<div className="pic">
								<DollarCircleOutlined />
							</div>
							<div className="info">
								<div className="p2">期间费用</div>
								<div className="p1">{this.state.condition.num23}</div>
							</div>
						</Link>
						: null) : null}
					{store.getState().data.info.qx24 ? (store.getState().data.info.qx24 != -1 ?
						<Link to={{ pathname: '/lrtj' }} className="item">
							<div className="pic">
								<EuroCircleOutlined />
							</div>
							<div className="info">
								<div className="p2">利润统计</div>
								<div className="p1">{this.state.condition.num24}</div>
							</div>
						</Link>
						: null) : null} */}
                    {store.getState().data.info.qx27 ? (store.getState().data.info.qx27 != -1 ?
                        <Link to={{pathname: '/scjhfx'}} className="item">
                            <div className="pic">
                                <ApartmentOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">生产统计</div>
                                {/* <div className="p1">{this.state.condition.num24}</div> */}
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx28 ? (store.getState().data.info.qx28 != -1 ?
                        <Link to={{pathname: '/yhtj'}} className="item">
                            <div className="pic">
                                <LayoutOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">油耗分析</div>
                                {/* <div className="p1">{this.state.condition.num24}</div> */}
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx29 ? (store.getState().data.info.qx29 != -1 ?
                        <Link to={{pathname: '/wxtj'}} className="item">
                            <div className="pic">
                                <FolderOpenOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">维修统计</div>
                                {/* <div className="p1">{this.state.condition.num24}</div> */}
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx31 ? (store.getState().data.info.qx31 != -1 ?
                        <Link to={{pathname: '/jcxstj'}} className="item">
                            <div className="pic">
                                <LayoutOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">销售统计</div>
                                {/* <div className="p1">{this.state.condition.num17}</div> */}
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx32 ? (store.getState().data.info.qx32 != -1 ?
                        <Link to={{pathname: '/cbfx'}} className="item">
                            <div className="pic">
                                <DollarCircleOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">成本分析</div>
                                {/* <div className="p1">{this.state.condition.num17}</div> */}
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx33 ? (store.getState().data.info.qx33 != -1 ?
                        <Link to={{pathname: '/lrfx'}} className="item">
                            <div className="pic">
                                <DollarCircleOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">利润分析</div>
                                {/* <div className="p1">{this.state.condition.num17}</div> */}
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx34 ? (store.getState().data.info.qx34 != -1 ?
                        <Link to={{pathname: '/stxstj'}} className="item">
                            <div className="pic">
                                <ApartmentOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">商砼统计</div>
                                {/* <div className="p1">{this.state.condition.num17}</div> */}
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx35 ? (store.getState().data.info.qx35 != -1 ?
                        <Link to={{pathname: '/wjstj'}} className="item">
                            <div className="pic">
                                <FolderOpenOutlined/>
                            </div>
                            <div className="info">
                                <div className="p2">未结算统计</div>
                                {/* <div className="p1">{this.state.condition.num17}</div> */}
                            </div>
                        </Link>
                        : null) : null}
                    {store.getState().data.info.qx36 ? (store.getState().data.info.qx36 != -1 ?
                        <>
                            <Link to={{pathname: '/yetj'}} className="item">
                                <div className="pic">
                                    <DollarCircleOutlined/>
                                </div>
                                <div className="info">
                                    <div className="p2">余额统计</div>
                                    {/* <div className="p1">{this.state.condition.num17}</div> */}
                                </div>
                            </Link>
                            <Link to={{pathname: '/fpjs'}} className="item">
                                <div className="pic">
                                    <DollarCircleOutlined/>
                                </div>
                                <div className="info">
                                    <div className="p2">发票结算</div>
                                    {/* <div className="p1">{this.state.condition.num17}</div> */}
                                </div>
                            </Link>
                        </>
                        : null) : null}
                </div>
            </>
        )
    }
}
