import { connect } from 'react-redux';

//=====引入组件=====
import Shouye from '../../shouye/index.js'

//=====react-redux 封装组件=====

// 哪些 Redux 全局的 state 是我们组件想要通过 props 获取的？
function mapStateToProps(state) {
    return {
        info: state.data.info,
    }
}

// 哪些 action 创建函数是我们想要通过 props 获取的？
function mapDispatchToProps(dispatch) {
    return {
        GET_INFO: function () {
            let action = {
                type: "GET_INFO",
                // info: info,
            }
            dispatch(action);
        },
        NAV_CHANGE: function (nav, navname,opennav) {
			let action = {
				type: "NAV_CHANGE",
				nav: nav,
				navname: navname,
				opennav: opennav,
			}
			// console.log(action);
			dispatch(action);
		},
    };
}

//封装传递state和dispatch
var ShouyeAction = connect(mapStateToProps, mapDispatchToProps)(Shouye);

export default ShouyeAction