import React from 'react';
import {Redirect, Link, withRouter} from 'react-router-dom';
import {
    Switch,
    Space,
    Select,
    Input,
    Button,
    Table,
    Modal,
    Row,
    Col,
    Form,
    message,
    Checkbox,
    Radio,
    Pagination,
    Tag,
    Popconfirm,
    Tooltip,
    DatePicker
} from 'antd';
import store from "../store/store.js";
import {FormOutlined} from '@ant-design/icons'
import moment from 'moment';
import Add from './add';
import Wxftj from './wxftj';
import Jiesuan from './jiesuan.js'
import Dcdzd from './dcdzd.js'

const {RangePicker} = DatePicker;
const {Option} = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
            month_react: [
                {id: 1, name: '1月'},
                {id: 2, name: '2月'},
                {id: 3, name: '3月'},
                {id: 4, name: '4月'},
                {id: 5, name: '5月'},
                {id: 6, name: '6月'},
                {id: 7, name: '7月'},
                {id: 8, name: '8月'},
                {id: 9, name: '9月'},
                {id: 10, name: '10月'},
                {id: 11, name: '11月'},
                {id: 12, name: '12月'},
            ]
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }

    componentDidMount() {
        this.props.NAV_CHANGE('/clwxmx', '车辆维修')
        this.getdata(1, null)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }

    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 240 - selheight
        that.setState({
            tableHeight: yheight
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, {[name]: dateString})
        this.setState({
            newcondition: data
        })
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
    };

    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, {current: page, pagesize: pagesize})
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }

    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, {current: 1, pagesize: old.pagesize})
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Clwxmx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    month_react: data.month_react ? data.month_react : [],
                    chid_react: data.chid_react ? data.chid_react : [],
                    wxdw_react: data.wxdw_react ? data.wxdw_react : [],
                    sblx_react: data.sblx_react ? data.sblx_react : [],
                    sfbljs_react: data.sfbljs_react ? data.sfbljs_react : [],
                    ljmc_react: data.ljmc_react ? data.ljmc_react : [],
                    wxnr_react: data.wxnr_react ? data.wxnr_react : [],
                })
            })
    }

    delSel() {
        let that = this
        fetch('/Clwxmx/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    delSel2(id, e) {
        let that = this
        fetch('/Clwxmx/delSel2', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Clwxmx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }

    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, {
            [name + '1']: dateStrings[0],
            [name + '2']: dateStrings[1]
        })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }

    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }

    render() {
        const expandedRowRender = (record, index, indent, expanded) => {
            let columns_1 = [{
                title: '维修内容', dataIndex: 'title', key: 'title', ellipsis: {
                    showTitle: false,
                }, render: (row, record2) => (<Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>),
            }, {
                title: '工时费', dataIndex: 'gsf', key: 'gsf', ellipsis: {
                    showTitle: false,
                }, render: (row, record2) => (<Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>),
            }, {
                title: '目标价格', dataIndex: 'mbdj', key: 'mbdj', ellipsis: {
                    showTitle: false,
                }, render: (row, record2) => (<span className={`c` + (record2.mbdj && record2.gsf > record2.mbdj ? 2 : 1)}>{record2.mbdj}元</span>),
            }, {
                title: '最近维修时间', dataIndex: 'lasttime', key: 'lasttime', ellipsis: {
                    showTitle: false,
                }, render: (row, record2) => (<Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>),
            }, {
                title: '目标方量', dataIndex: 'mbfl', key: 'mbfl', ellipsis: {
                    showTitle: false,
                }, render: (row, record2) => (<div className={"ljinfo"}>
                    {record2.zfl}方 / <span
                    className={`c` + ((record2.sm && record2.zfl < record2.sm) || (record2.sm2 && record2.zfl > record2.sm2) ? 2 : 1)}>
                                        <Space split="~"><span>{record2.sm}</span>{record2.sm2 ?
                                            <span>{record2.sm2}</span> : null}</Space>方
                                    </span>
                    {(record2.sm && record2.zfl < record2.sm) ? <span className='hongse'>（维修异常）</span> : null}
                    {(record2.sm2 && record2.zfl > record2.sm2) ? <span className='hongse'>（保养异常）</span> : null}
                </div>),
            }, {
                title: '目标时限', dataIndex: 'mbsx', key: 'mbsx', ellipsis: {
                    showTitle: false,
                }, render: (row, record2) => (<div className={"ljinfo"}>
                    {record2.during}天 /
                    <span
                        className={`c` + ((record2.sx && record2.during < record2.sx) || (record2.sx2 && record2.during > record2.sx2) ? 2 : 1)}>
                                        <Space split="~"><span>{record2.sx}</span>{record2.sx2 ?
                                            <span>{record2.sx2}</span> : null}</Space>天
                                    </span>
                    {(record2.sx && record2.during < record2.sx) ? <span className='hongse'>（维修异常）</span> : null}
                    {(record2.sx2 && record2.during > record2.sx2) ? <span className='hongse'>（保养异常）</span> : null}
                </div>),
            },]
            let columns_2 = [{
                title: '零件名称', dataIndex: 'title', key: 'title', ellipsis: {
                    showTitle: false,
                }, render: (row, record2) => (<Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>),
            }, {
                title: '数量', dataIndex: 'sl', key: 'sl', ellipsis: {
                    showTitle: false,
                }, render: (row, record2) => (<Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>),
            }, {
                title: '单价', dataIndex: 'dj', key: 'dj', ellipsis: {
                    showTitle: false,
                }, render: (row, record2) => (<Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>),
            },{
                title: '目标价格', dataIndex: 'mbdj', key: 'mbdj', ellipsis: {
                    showTitle: false,
                }, render: (row, record2) => (<span className={`c` + (record2.mbdj && record2.gsf > record2.mbdj ? 2 : 1)}>{record2.mbdj}元</span>),
            }, {
                title: '零件费', dataIndex: 'ljf', key: 'ljf', ellipsis: {
                    showTitle: false,
                }, render: (row, record2) => (<Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>),
            }, {
                title: '最近维修时间', dataIndex: 'lasttime', key: 'lasttime', ellipsis: {
                    showTitle: false,
                }, render: (row, record2) => (<Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>),
            }, {
                title: '目标方量', dataIndex: 'mbfl', key: 'mbfl', ellipsis: {
                    showTitle: false,
                }, render: (row, record2) => (<div className={"ljinfo"}>
                    {record2.zfl}方 / <span
                    className={`c` + ((record2.sm && record2.zfl < record2.sm) || (record2.sm2 && record2.zfl > record2.sm2) ? 2 : 1)}>
                                        <Space split="~"><span>{record2.sm}</span>{record2.sm2 ?
                                            <span>{record2.sm2}</span> : null}</Space>方
                                    </span>
                    {(record2.sm && record2.zfl < record2.sm) ? <span className='hongse'>（维修异常）</span> : null}
                    {(record2.sm2 && record2.zfl > record2.sm2) ? <span className='hongse'>（保养异常）</span> : null}
                </div>),
            }, {
                title: '目标时限', dataIndex: 'mbsx', key: 'mbsx', ellipsis: {
                    showTitle: false,
                }, render: (row, record2) => (<div className={"ljinfo"}>
                    {record2.during}天 /
                    <span
                        className={`c` + ((record2.sx && record2.during < record2.sx) || (record2.sx2 && record2.during > record2.sx2) ? 2 : 1)}>
                                        <Space split="~"><span>{record2.sx}</span>{record2.sx2 ?
                                            <span>{record2.sx2}</span> : null}</Space>天
                                    </span>
                    {(record2.sx && record2.during < record2.sx) ? <span className='hongse'>（维修异常）</span> : null}
                    {(record2.sx2 && record2.during > record2.sx2) ? <span className='hongse'>（保养异常）</span> : null}
                </div>),
            },]
            return <>
                <Table columns={columns_1} dataSource={record.wxflist} pagination={false}/>
                <Table columns={columns_2} dataSource={record.pjflist} pagination={false}/>
            </>;
        }
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 80,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '日期',
                dataIndex: 'riqi_str',
                key: 'riqi_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                fixed: 'left',
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '结算日期',
                dataIndex: 'jsrq_str',
                key: 'jsrq_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                fixed: 'left',
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '维修单位',
                dataIndex: 'wxdw_str',
                key: 'wxdw_str',
                ellipsis: {
                    showTitle: false,
                },
                fixed: 'left',
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车辆编号',
                dataIndex: 'clbh',
                key: 'clbh',
                width: 120,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车牌',
                dataIndex: 'cp',
                key: 'cp',
                fixed: 'left',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '类型',
                dataIndex: 'sblx',
                key: 'sblx',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '司机一',
                dataIndex: 'sj1_str',
                key: 'sj1_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '司机二',
                dataIndex: 'sj2_str',
                key: 'sj2_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },


            {
                title: '配件费',
                dataIndex: 'je',
                key: 'je',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '工时费',
                dataIndex: 'gsf',
                key: 'gsf',
                ellipsis: {
                    showTitle: false,
                },
                width: 100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '外购材料费',
                dataIndex: 'wgclf',
                key: 'wgclf',
                ellipsis: {
                    showTitle: false,
                },
                width: 130,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '外加工费',
                dataIndex: 'wjgf',
                key: 'wjgf',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '不可预估费',
                dataIndex: 'bkygf',
                key: 'bkygf',
                ellipsis: {
                    showTitle: false,
                },
                width: 130,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '配件处理清理',
                dataIndex: 'pjclql',
                key: 'pjclql',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '赠送配件情况',
                dataIndex: 'zspjqk',
                key: 'zspjqk',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '审核人',
                dataIndex: 'shr_str',
                key: 'shr_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },

            {
                title: '备注',
                dataIndex: 'beizhu',
                key: 'beizhu',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作人',
                dataIndex: 'aidname',
                key: 'aidname',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={record.updatetime}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 150,
                fixed: 'right',
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <Add id={record.id} refresh={this.getdata.bind(this)}/>
                                {store.getState().data.info.qx6 ? (store.getState().data.info.qx6 == 1 ?
                                    <Popconfirm
                                        title="确定删除?"
                                        onConfirm={this.delSel2.bind(this, record.id)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <a href="#" className="hongse">删除</a>
                                    </Popconfirm>
                                    : null) : null}
                            </Space>
                        </>
                    )
                },
            },
        ]
        const {tableloading, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll: true,
            preserveSelectedRowKeys: false,
            // renderCell: function (checked, record, index, originNode) {
            //     if (record.status != 1) {
            //         return originNode
            //     } else {
            //         return false
            //     }
            // },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" align="top">
                        <Space wrap style={{width: 'calc(100% - 200px)'}}>
                            <DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year"
                                        value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null}
                                        allowClear={false}/>
                            <Select placeholder="月份" style={{width: 120}}
                                    value={this.state.newcondition.month ? this.state.newcondition.month : 0}
                                    onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                {this.arrsel(this.state.month_react)}
                            </Select>
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'riqi')}
                                         value={[(this.state.newcondition.riqi1 ? moment(this.state.newcondition.riqi1) : null), (this.state.newcondition.riqi2 ? moment(this.state.newcondition.riqi2) : null)]}
                                         placeholder={['日期', '日期']}/>
                            <Select placeholder="是否办理结算"
                                    value={this.state.newcondition.sfbljs ? this.state.newcondition.sfbljs : null}
                                    onChange={this.onSelChange.bind(this, 'sfbljs')} allowClear style={{width: 150}}
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sfbljs_react)}
                            </Select>
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'jsrq')}
                                         value={[(this.state.newcondition.jsrq1 ? moment(this.state.newcondition.jsrq1) : null), (this.state.newcondition.jsrq2 ? moment(this.state.newcondition.jsrq2) : null)]}
                                         placeholder={['结算日期', '结算日期']}/>
                            <Select placeholder="维修单位"
                                    value={this.state.newcondition.wxdw ? this.state.newcondition.wxdw : null}
                                    onChange={this.onSelChange.bind(this, 'wxdw')} allowClear style={{width: 150}}
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.wxdw_react)}
                            </Select>
                            <Select placeholder="车牌"
                                    value={this.state.newcondition.chid ? this.state.newcondition.chid : null}
                                    onChange={this.onSelChange.bind(this, 'chid')} allowClear style={{width: 150}}
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.chid_react)}
                            </Select>
                            <Select placeholder="类型"
                                    value={this.state.newcondition.sblx ? this.state.newcondition.sblx : null}
                                    onChange={this.onSelChange.bind(this, 'sblx')} allowClear style={{width: 150}}
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sblx_react)}
                            </Select>
                            <Select placeholder="维修内容"
                                    value={this.state.newcondition.wxnr ? this.state.newcondition.wxnr : null}
                                    onChange={this.onSelChange.bind(this, 'wxnr')} allowClear style={{width: 150}}
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.wxnr_react)}
                            </Select>
                            <Select placeholder="零件名称"
                                    value={this.state.newcondition.ljmc ? this.state.newcondition.ljmc : null}
                                    onChange={this.onSelChange.bind(this, 'ljmc')} allowClear style={{width: 150}}
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.ljmc_react)}
                            </Select>

                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                            <Wxftj condition={this.state.oldcondition}/>
                        </Space>
                        {store.getState().data.info.qx6 ? (store.getState().data.info.qx6 == 1 ?
                            <div style={{maxWidth: 200}}>
                                <Space wrap>
                                    <Add refresh={this.getdata.bind(this)}/>
                                    <Dcdzd/>
                                </Space>
                            </div>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    rowSelection={rowSelection}
                    scroll={{x: 2500, y: this.state.tableHeight ? this.state.tableHeight : null}}
                    className='table'
                    size="small"
                    expandable={{
                        expandedRowRender,
                    }}
                    summary={(pageData) => {
                        let totalje = 0;
                        let totalgsf = 0;
                        let totalwgclf = 0;
                        let totalwjgf = 0;
                        let totalbkygf = 0;
                        pageData.forEach(({je, gsf, wgclf, wjgf, bkygf}) => {
                            totalje += Number(je ? je : 0);
                            totalgsf += Number(gsf ? gsf : 0);
                            totalwgclf += Number(wgclf ? wgclf : 0);
                            totalwjgf += Number(wjgf ? wjgf : 0);
                            totalbkygf += Number(bkygf ? bkygf : 0);
                        });
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}>{totalje.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}>{totalgsf.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}>{totalwgclf.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={14}>{totalwjgf.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}>{totalbkygf.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={16}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={17}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={18}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={19}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={20}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={21}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
                <div className='page page3'>
                    <div className='l'>
                        <Space wrap>
                            {hasSelected ?
                                <>
                                    {store.getState().data.info.qx6 ? (store.getState().data.info.qx6 == 1 ?
                                        <>
                                            <Jiesuan refresh={this.getdata.bind(this)} arrid={selectedRowKeys}/>
                                        </>
                                        : null) : null}
                                </>
                                : null}
                        </Space>
                    </div>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1}
                                pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50}
                                total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true}
                                showTotal={total => `共 ${total} 条数据`}/>
                </div>
            </>
        )
    }
}
