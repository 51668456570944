import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Tabs, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip } from 'antd';
import store from "../store/store.js";
import Khxx from "../khxx/index.js";
import Gcxx from "../gcxx/index.js";
import Sgdw from "../sgdw/index.js";
import Hntjbz from "../hntjbz/index.js";
import Zhsz from "../zhsz/index.js";
import Bh from "../bh/index.js";
import Qydw from "../qydw/index.js";
import Wdbdw from "../wdbdw/index.js";
import Jydd from "../jydd/index.js";
import Htfl from "../htfl/index.js";
import Cgdw from "../cgdw/index.js";
import Kemu from "../kemu/index.js";
import Qita from "../qita/index.js";
import Yt from "../yt/index.js";
import Wxnr from "../wxnr/index.js";
import Ljmc from "../ljmc/index.js";
import Xmmcbg from "../xmmcbg/index.js";

import Hwmc from "../hwmc/index.js";
import Htbh from "../htbh/index.js";
import Sl from "../sl/index.js";
import Gydw from "../gydw/index.js";
import Ysdw from "../ysdw/index.js";
import Jhdw from "../jhdw/index.js";
import Wxdw from "../wxdw/index.js";

import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {},
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        this.props.NAV_CHANGE('/xinxibiao', '信息表')
    }
    render() {
        return (
            <>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="客户表" key="1">
                        <Khxx />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="项目表" key="2">
                        <Gcxx />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="施工单位表" key="3">
                        <Sgdw />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="混凝土搅拌站" key="4">
                        <Hntjbz />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="标号" key="5">
                        <Bh />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="签约单位" key="6">
                        <Qydw />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="外租泵单位" key="7">
                        <Wdbdw />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="加油地点" key="8">
                        <Jydd />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="合同分类" key="9">
                        <Htfl />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="采购单位" key="10">
                        <Cgdw />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="科目" key="11">
                        <Kemu />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="其他" key="12">
                        <Qita />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="用途" key="13">
                        <Yt />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="维修内容" key="14">
                        <Wxnr />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="零件名称" key="15">
                        <Ljmc />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="货物名称" key="16">
                        <Hwmc />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="合同编号" key="17">
                        <Htbh />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="税率" key="18">
                        <Sl />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="供应单位" key="19">
                        <Gydw />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="进货单位" key="20">
                        <Jhdw />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="运输单位" key="21">
                        <Ysdw />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="维修单位" key="22">
                        <Wxdw />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="项目名称（泵管）" key="23">
                        <Xmmcbg />
                    </Tabs.TabPane>
                </Tabs>
            </>
        )
    }
}
