import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm,Upload } from 'antd';
import store from "../store/store.js";
import { FormOutlined,UploadOutlined,DeleteOutlined } from '@ant-design/icons'
import moment from 'moment';
import Chxz from '../public/chxz.js';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
            },
            list: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value, Option) {
        let that = this
        console.log(name, value, Option)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        // return
        setTimeout(function () {
            if (name == 'chid') {
                let data2 = Object.assign({}, that.state.condition, {
                    cp: Option.key ? that.state.chid_react[Option.key]['cp'] : null,
                })
                that.setState({
                    condition: data2
                })
            }
            if (name == 'pm') {
                let data2 = Object.assign({}, that.state.condition, {
                    kc: Option.key ? that.state.pm_react[Option.key]['kc'] : null,
                })
                that.setState({
                    condition: data2
                })
            }
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        setTimeout(function () {
            if (name == 'srsl' || name == 'srdj') {
                let srsl = data.srsl ? data.srsl : 0
                let srdj = data.srdj ? data.srdj : 0
                var srje = (Number(srsl) * Number(srdj)).toFixed(2)
                let data2 = Object.assign({}, that.state.condition, { srje: srje })
                that.setState({
                    condition: data2
                })
            }
            if (name == 'fcsl' || name == 'fcdj') {
                let fcsl = data.fcsl ? data.fcsl : 0
                let fcdj = data.fcdj ? data.fcdj : 0
                var fcje = (Number(fcsl) * Number(fcdj)).toFixed(2)
                let data2 = Object.assign({}, that.state.condition, { fcje: fcje })
                that.setState({
                    condition: data2
                })
            }
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            fetch('/Ysjsfhz/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg)
                        return
                    }
                    if (this.state.condition.id) {
                        that.setState({
                            condition: data.data,
                        })
                    }
                    that.setState({
                        chid_react: data.chid_react ? data.chid_react : [],
                        pm_react: data.pm_react ? data.pm_react : [],
                        sf_react: data.sf_react ? data.sf_react : [],
                        sfbljs_react: data.sfbljs_react ? data.sfbljs_react : []
                    })
                })
            if (that.props.notable != 1) {
                that.getdata2()
            }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }
    sub(isnext = 0, e) {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Ysjsfhz/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    if (isnext == 1) {
                        that.nextAdd()
                    } else {
                        this.mdchange()
                    }
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    getdata2(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        fetch('/Ysjsfhz/index', {
            // post提交
            method: "POST",
            body: JSON.stringify({
                limit: 3
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count)
                })
            })
    }
    //继续添加
    nextAdd = (e) => {
        let that = this
        that.setState({
            condition: {
                id: 0,
            },
            list: [],
            loading: false
        })
        that.getdata2()
    }
     // 上传图片
    picChange2(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file.response)

            var fjlist = this.state.condition[name]
            if (!fjlist) {
                fjlist = []
            }
            fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: fjlist })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    delpic(index, name, e) {
        var fjlist = this.state.condition[name]
        fjlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { [name]: fjlist })
        this.setState({
            condition: data,
        })
    }
    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 60,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '日期',
                dataIndex: 'riqi1_str',
                key: 'riqi1_str',
                align: "center",
                fixed: 'left',
                width:120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '品名',
                dataIndex: 'pm_str',
                key: 'pm_str',
                align: "center",
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应商',
                dataIndex: 'gys',
                key: 'gys',
                align: "center",
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '收入',
                dataIndex: 'sr',
                key: 'sr',
                children: [
                    {
                        title: '数量',
                        dataIndex: 'srsl',
                        key: 'srsl',
                        align: "center",
                        ellipsis: {
                            showTitle: false,
                        },
                        width:100,
                        render: (row, record) => (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        ),
                    },
                    {
                        title: '单价',
                        dataIndex: 'srdj',
                        key: 'srdj',
                        align: "center",
                        ellipsis: {
                            showTitle: false,
                        },
                        width:100,
                        render: (row, record) => (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        ),
                    },
                    {
                        title: '金额',
                        dataIndex: 'srje',
                        key: 'srje',
                        align: "center",
                        ellipsis: {
                            showTitle: false,
                        },
                        width:100,
                        render: (row, record) => (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        ),
                    },
                ]
            },
            {
                title: '发出数量',
                dataIndex: 'fcsl',
                key: 'fcsl',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            // {
            //     title: '发出',
            //     dataIndex: 'sr',
            //     key: 'sr',
            //     children: [
            //         {
            //             title: '数量',
            //             dataIndex: 'fcsl',
            //             key: 'fcsl',
            //             align: "center",
            //             ellipsis: {
            //                 showTitle: false,
            //             },
            //             render: (row, record) => (
            //                 <Tooltip placement="topLeft" title={row}>
            //                     {row}
            //                 </Tooltip>
            //             ),
            //         },
            //         {
            //             title: '单价',
            //             dataIndex: 'fcdj',
            //             key: 'fcdj',
            //             align: "center",
            //             ellipsis: {
            //                 showTitle: false,
            //             },
            //             render: (row, record) => (
            //                 <Tooltip placement="topLeft" title={row}>
            //                     {row}
            //                 </Tooltip>
            //             ),
            //         },
            //         {
            //             title: '金额',
            //             dataIndex: 'fcje',
            //             key: 'fcje',
            //             align: "center",
            //             ellipsis: {
            //                 showTitle: false,
            //             },
            //             render: (row, record) => (
            //                 <Tooltip placement="topLeft" title={row}>
            //                     {row}
            //                 </Tooltip>
            //             ),
            //         },
            //     ]
            // },
            {
                title: '验收人',
                dataIndex: 'ysr',
                key: 'ysr',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width:120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车辆编号',
                dataIndex: 'clbh',
                key: 'clbh',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width:120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车牌',
                dataIndex: 'cp',
                key: 'cp',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width:120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '验收人',
                dataIndex: 'ysr',
                key: 'ysr',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width:120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '领用人',
                dataIndex: 'lyr',
                key: 'lyr',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width:120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },]
        const { tableloading } = this.state;
        return (
            <>
                {this.state.condition.id ? <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx4 ? (store.getState().data.info.qx4 == 1 ? '编辑' : '查看') : '查看'}</a> : <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>}
                <Modal maskClosable={false} title="编辑" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx4 ? (store.getState().data.info.qx4 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={8}>
                                <Form.Item
                                    label="日期"
                                    required={true}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'riqi1')} value={this.state.condition.riqi1 ? moment(this.state.condition.riqi1) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="商品信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="品名"
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.pm ? this.state.condition.pm : null} onChange={this.onSelChange.bind(this, 'pm')}>
                                        {this.arrsel(this.state.pm_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="库存数量"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.kc ? this.state.condition.kc : null} onChange={this.onChange.bind(this, 'kc')}  disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="收入/发出" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="收发类型"
                                    required={true}
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.type ? this.state.condition.type : null} onChange={this.onSelChange.bind(this, 'type')}>
                                        {this.arrsel(this.state.sf_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={16}></Col>
                            {/* {this.state.condition.type == 1 ? <Col span={16}></Col> : null} */}
                            {this.state.condition.type == 1 ?
                                <>
                                    <Col span={8}>
                                        <Form.Item
                                            label="收入-数量"
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.srsl ? this.state.condition.srsl : null} onChange={this.onChange.bind(this, 'srsl')}  type="text" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="收入-单价"
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.srdj ? this.state.condition.srdj : null} onChange={this.onChange.bind(this, 'srdj')}  type="text" suffix="元" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="收入-金额"
                                            tooltip="【收入-数量】乘以【收入-单价】"
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.srje ? this.state.condition.srje : null} onChange={this.onChange.bind(this, 'srje')}  type="text" suffix="元" disabled={true} />
                                        </Form.Item>
                                    </Col>
                                </>
                                : null}
                            {this.state.condition.type == 2 ?
                                <>
                                    <Col span={8}>
                                        <Form.Item
                                            label="发出-数量"
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.fcsl ? this.state.condition.fcsl : null} onChange={this.onChange.bind(this, 'fcsl')}  type="text" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="发出-单价"
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.fcdj ? this.state.condition.fcdj : null} onChange={this.onChange.bind(this, 'fcdj')}  type="text" suffix="元" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="发出-金额"
                                            tooltip="【发出-数量】乘以【发出-单价】"
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.fcje ? this.state.condition.fcje : null} onChange={this.onChange.bind(this, 'fcje')}  type="text" suffix="元" disabled={true} />
                                        </Form.Item>
                                    </Col>
                                </>
                                : null}
                            <Col span={24}>
                                <Alert type="info" message="其他信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="是否办理结算"
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.sfbljs ? this.state.condition.sfbljs : null} allowClear={false} onChange={this.onSelChange.bind(this, 'sfbljs')} >
                                        {this.arrsel(this.state.sfbljs_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="结算日期"
                                    required={this.state.condition.sfbljs == 1 ? true : false}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'jsrq')} value={this.state.condition.jsrq ? moment(this.state.condition.jsrq) : null} disabled={this.state.condition.sfbljs != 1 ? true : false} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="验收人"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.ysr ? this.state.condition.ysr : null} onChange={this.onChange.bind(this, 'ysr')}  />
                                </Form.Item>
                            </Col>
                            {this.state.condition.type == 2 ?
                                <>
                                    <Col span={8}>
                                        <Form.Item
                                            label="车辆编号"
                                        // required={true}
                                        >
                                            <Input.Group compact>
                                                <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.chid ? this.state.condition.chid : null} onChange={this.onSelChange.bind(this, 'chid')} style={{ width: 'calc(100% - 89px)' }} disabled={true} >
                                                    {this.arrsel(this.state.chid_react)}
                                                </Select>
                                                <Chxz value={this.state.condition.chid ? this.state.condition.chid : null} onChange={this.onSelChange.bind(this)} chid_react={this.state.chid_react ? this.state.chid_react : []} />
                                            </Input.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="车牌"
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.cp ? this.state.condition.cp : null} onChange={this.onChange.bind(this, 'cp')}  disabled={true} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="领用人"
                                        >
                                            <Input placeholder="请输入" value={this.state.condition.lyr ? this.state.condition.lyr : null} onChange={this.onChange.bind(this, 'lyr')}  />
                                        </Form.Item>
                                    </Col>
                                </>
                                : null}
                            <Col span={8}>
                                <Form.Item
                                    label="备注"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.bz ? this.state.condition.bz : null} onChange={this.onChange.bind(this, 'bz')}  />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="附件"
                                >
                                    <Row justify="space-between">
                                        <Space align="center">
                                            <Upload
                                                showUploadList={false}
                                                action="/Common/uploadfile"
                                                onChange={this.picChange2.bind(this, 'fj')}
                                                multiple={true}
                                            >
                                                <Button icon={<UploadOutlined />}>上传</Button>
                                            </Upload>
                                            <Space wrap>
                                                {this.state.condition.fj ? this.state.condition.fj.map((item, index) =>
                                                    <div>
                                                        <a href={item.url} target="_blank">{item.name} </a> <DeleteOutlined className="hongse" onClick={this.delpic.bind(this, index, 'fj')} />
                                                    </div>) : null}

                                            </Space>
                                        </Space>
                                    </Row>
                                </Form.Item>
                            </Col>
                            {this.props.notable == 1 ?
                                null
                                :
                                <>
                                    <Col span={24}>
                                        <Divider orientation="left" style={{ marginTop: 0 }}>最近添加信息</Divider>
                                    </Col>
                                    <Col span={24}>
                                        <Table
                                            bordered
                                            columns={columns}
                                            sticky={false}
                                            dataSource={this.state.list}
                                            pagination={false}
                                            loading={tableloading}
                                            // rowSelection={rowSelection}
                                            scroll={{ x: 1600, }}
                                            className='table'
                                            size="small"
                                        />
                                    </Col>
                                </>
                            }
                        </Row>
                        {store.getState().data.info.qx4 ? (store.getState().data.info.qx4 == 1 ?
                            <Row justify="center">
                                <Space>
                                    <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this)}>完成</Button>
                                    {!this.state.condition.id ?
                                        <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this, '1')}>完成并继续添加下一条</Button>
                                        : null}
                                </Space>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}
