import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class Wxftj extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            list2: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            fetch('/Clwxmx/wxftj', {
                method: "POST",
                body: JSON.stringify(this.props.condition?this.props.condition:{})
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg)
                        return
                    }
                    that.setState({
                        list: data.data,
                        list2: data.data2,
                    })
                })
        } else {
            that.setState({
                list: [],
                list2: [],
                loading: false
            })
        }
    }

    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车辆编号',
                dataIndex: 'clbh',
                key: 'clbh',
                width: 150,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车牌',
                dataIndex: 'cp',
                key: 'cp',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '类型',
                dataIndex: 'sblx',
                key: 'sblx',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '司机',
                dataIndex: 'sj_str',
                key: 'sj_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '总金额',
                dataIndex: 'zje',
                key: 'zje',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
        ]
        const columns2 = [
            {
                title: '维修单位',
                dataIndex: 'wxdw',
                key: 'wxdw',
                width: 150,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '配件金额',
                dataIndex: 'je',
                key: 'je',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '工时费',
                dataIndex: 'gsf',
                key: 'gsf',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '外购材料费',
                dataIndex: 'wgclf',
                key: 'wgclf',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '外加工费',
                dataIndex: 'wjgf',
                key: 'wjgf',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '不可预估费',
                dataIndex: 'bkygf',
                key: 'bkygf',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '总金额',
                dataIndex: 'zje',
                key: 'zje',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },

        ]
        const { tableloading, tableloading2 } = this.state;
        return (
            <>
                <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >维修费统计</a>
                <Modal maskClosable={false} title="维修费统计" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <p>{this.props.condition.year}年{this.props.condition.month?(this.props.condition.month+'月'):''}</p>
                    <Table
                        bordered
                        columns={columns}
                        sticky={false}
                    dataSource={this.state.list}
                        pagination={false}
                        loading={tableloading}
                        // rowSelection={rowSelection}
                        className='table'
                        size="small"
                        summary={(pageData) => {
                            let totalzje = 0;
                            pageData.forEach(({ zje }) => {
                                totalzje += Number(zje ? zje : 0);
                            });
                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>{totalzje.toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                    <Table
                        bordered
                        columns={columns2}
                        sticky={false}
                    dataSource={this.state.list2}
                        pagination={false}
                        loading={tableloading2}
                        // rowSelection={rowSelection}
                        className='table'
                        size="small"
                        summary={(pageData) => {
                            let totalje = 0;
                            let totalgsf = 0;
                            let totalwgclf = 0;
                            let totalwjgf = 0;
                            let totalbkygf = 0;
                            let totalzje = 0;
                            pageData.forEach(({ je,gsf,zje,wgclf,wjgf,bkygf }) => {
                                totalje += Number(je ? je : 0);
                                totalgsf += Number(gsf ? gsf : 0);
                                totalwgclf += Number(wgclf ? wgclf : 0);
                                totalwjgf += Number(wjgf ? wjgf : 0);
                                totalbkygf += Number(bkygf ? bkygf : 0);
                                totalzje += Number(zje ? zje : 0);
                            });
                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>{totalje.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>{totalgsf.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>{totalwgclf.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>{totalwjgf.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>{totalbkygf.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}>{totalzje.toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                </Modal>
            </>
        )
    }
}