import React from 'react';
import {Redirect, Link, withRouter} from 'react-router-dom';
import {
    Switch,
    Space,
    Select,
    Input,
    Button,
    Table,
    Modal,
    Row,
    Col,
    Form,
    message,
    Checkbox,
    Radio,
    Pagination,
    Tag,
    DatePicker,
    Divider,
    Cascader,
    InputNumber
} from 'antd';
import store from "../store/store.js";
import {PlusOutlined} from '@ant-design/icons'
import moment from 'moment';
import Qtedit from "../qita/add.js";

const {Option} = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                type: null,
            },
            visible: false,
            loading: false
        };
    }

    componentDidMount() {
        let that = this
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, {[name]: value})
        if (name == 'skrlx') {
            data.skr = null
            data.skr2 = null
            if (value != 4) {
                data.skrzh = null
                data.jy = 0
                this.setState({
                    sfkrzh_react1: [],
                })
            }
        }
        if (name == 'fkrlx') {
            data.fkr = null
            data.fkr2 = null
            if (value != 4) {
                data.fkrzh = null
                data.jy2 = 0
                this.setState({
                    sfkrzh_react2: [],
                })
            }
        }
        if (name == 'skr2') {
            data.skrzh = null
            data.jy = 0
            that.getZhlist('sfkrzh_react1', value)
        }
        if (name == 'fkr2') {
            data.fkrzh = null
            data.jy2 = 0
            that.getZhlist('sfkrzh_react2', value)
        }
        if (name == 'je') {
            data.jy = Number(that.state.condition.syqjy) + Number(value)
            data.jy2 = Number(that.state.condition.syqjy2) - Number(value)
        }
        this.setState({
            condition: data
        })
    }

    getZhlist(name, zid) {
        let that = this
        fetch('/Szqk2/getZhlist', {
            method: "POST",
            body: JSON.stringify({
                zid
            })
        })
            .then(res => res.json())
            .then(data => {
                that.setState({
                    [name]: data.data
                })
                data.data.map((item, index) => {
                    if (item.status != 2) {
                        if (name == 'sfkrzh_react1') {
                            var condition = Object.assign({}, this.state.condition, {skrzh: item.id})
                            that.getSqjy(condition.riqi, condition.skrzh, condition.fkrzh)
                        }
                        if (name == 'sfkrzh_react2') {
                            var condition = Object.assign({}, this.state.condition, {fkrzh: item.id})
                            that.getSqjy(condition.riqi, condition.skrzh, condition.fkrzh)
                        }
                        this.setState({
                            condition: condition
                        })
                        return
                    }
                })
            })
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, {[name]: value})
        this.setState({
            condition: data
        })
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, {[name]: dateString})
        this.setState({
            condition: data
        })
        if (name == 'riqi' && (data.skrzh || data.fkrzh)) {
            setTimeout(() => {
                that.getSqjy(dateString, data.skrzh, data.fkrzh)
            })
        }
    }

    getSqjy = (riqi, skrzh, fkrzh) => {
        let that = this
        fetch('/Szqk2/getSqjy', {
            method: "POST",
            body: JSON.stringify({
                riqi,
                skrzh,
                fkrzh,
                id: that.state.condition.id ? that.state.condition.id : 0
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                let condition = Object.assign({}, this.state.condition, {syqjy: data.syqjy,syqjy2: data.syqjy2})
                this.setState({
                    condition: condition
                })
                let jy = Number(data.syqjy) + Number(that.state.condition.je)
                let jy2 = Number(data.syqjy2) - Number(that.state.condition.je)
                let data2 = Object.assign({}, that.state.condition, { jy,jy2 })
                that.setState({
                    condition: data2
                })
            })
    }

    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, {[name]: value})
        that.setState({
            condition: data
        })
    }

    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }

    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }

    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}
                        disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }

    getdata = (arrname, e) => {
        let that = this
        fetch('/Szqk2/ls_view', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                if (arrname) {
                    that.setState({
                        [arrname]: data[arrname] ? data[arrname] : [],
                    })
                    return
                }
                if (this.state.condition.id) {
                    that.setState({
                        condition: data.data,
                    })
                }
                that.setState({
                    yt_react: data.yt_react ? data.yt_react : [],
                    km_react: data.km_react ? data.km_react : [],
                    sfkrlx_react: data.sfkrlx_react ? data.sfkrlx_react : [],
                    sfkjfp_react: data.sfkjfp_react ? data.sfkjfp_react : [],
                    sfkr_react1: data.sfkr_react1 ? data.sfkr_react1 : [],
                    sfkr_react2: data.sfkr_react2 ? data.sfkr_react2 : [],
                    sfkr_react3: data.sfkr_react3 ? data.sfkr_react3 : [],
                    sfkr_react4: data.sfkr_react4 ? data.sfkr_react4 : [],
                    sfkrzh_react1: data.sfkrzh_react1 ? data.sfkrzh_react1 : [],
                    sfkrzh_react2: data.sfkrzh_react2 ? data.sfkrzh_react2 : [],
                })
            })
    }

    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }

    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Szqk2/ls_doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }

    addItem = () => {
        let that = this
        fetch('/Yt/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, {
                        newfield: null,
                        yt: data.yt ? data.yt : null
                    })
                    this.setState({
                        condition: condition,
                        yt_react: data.yt_react ? data.yt_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_km = () => {
        let that = this
        fetch('/Kemu/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, {
                        newfield: null,
                        km: data.kmid ? data.km : null
                    })
                    this.setState({
                        condition: condition,
                        km_react: data.km_react ? data.km_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    clearNewField = (open) => {
        let that = this
        setTimeout(() => {
            let condition = Object.assign({}, that.state.condition, {newfield: null})
            that.setState({
                condition: condition,
            })
        });
    }
    numberToChinese(str) {
        var num = parseFloat(str);
        var strOutput = "",
            strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分';
        num += "00";
        var intPos = num.indexOf('.');
        if (intPos >= 0) {
            num = num.substring(0, intPos) + num.substr(intPos + 1, 2);
        }
        strUnit = strUnit.substr(strUnit.length - num.length);
        for (var i = 0; i < num.length; i++) {
            strOutput += '零壹贰叁肆伍陆柒捌玖'.substr(num.substr(i, 1), 1) + strUnit.substr(i, 1);
        }
        return strOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零').replace(/零([亿|万])/g, '$1').replace(/零+元/, '元').replace(/亿零{0,3}万/, '亿').replace(/^元/, "零元")
    }
    render() {
        return (
            <>
                {this.state.condition.id ?
                    <a href='javascript:void(0)'
                       onClick={this.mdchange.bind(this)}>{store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ? '编辑' : '查看') : '查看'}</a>
                    :
                    <>
                        {this.props.type == "link" ?
                            <a onClick={this.mdchange.bind(this)}>新增</a>
                            :
                            <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>
                        }

                    </>
                }
                <Modal maskClosable={false} title="编辑" width={800} open={this.state.visible}
                       onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{span: 24}}
                        disabled={store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={12}>
                                <Form.Item
                                    label="日期"
                                    required={true}
                                >
                                    <DatePicker style={{width: '100%'}} placeholder="请输入"
                                                onChange={this.onTimeChange.bind(this, 'riqi')}
                                                value={this.state.condition.riqi ? moment(this.state.condition.riqi) : null}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="科目"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.km ? this.state.condition.km : null}
                                        onChange={this.onSelChange.bind(this, 'km')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider
                                                    style={{
                                                        margin: '8px 0',
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: '0 8px 4px',
                                                    }}
                                                >
                                                    <Input placeholder="请输入"
                                                           value={this.state.condition.newfield ? this.state.condition.newfield : null}
                                                           onChange={this.onChange.bind(this, 'newfield')}/>
                                                    <Button type="text" icon={<PlusOutlined/>}
                                                            onClick={this.addItem_km.bind(this)}>
                                                        创建并选择
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.km_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="收款人类型"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.skrlx ? this.state.condition.skrlx : null}
                                        onChange={this.onSelChange.bind(this, 'skrlx')}
                                    >
                                        {this.arrsel(this.state.sfkrlx_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={
                                        <Space>
                                            <>收款人</>
                                            {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                <>
                                                    {this.state.condition.skrlx == 3 ?
                                                        <Qtedit type="link"
                                                                refresh={this.getdata.bind(this, 'sfkr_react3')}/>
                                                        : null}
                                                </>
                                                : null) : null}
                                        </Space>
                                    }
                                    required={true}
                                >
                                    {this.state.condition.skrlx == 4 ?
                                        <Select
                                            placeholder="请选择" showSearch optionFilterProp="children"
                                            value={this.state.condition.skr2 ? this.state.condition.skr2 : null}
                                            onChange={this.onSelChange.bind(this, 'skr2')}
                                        >
                                            {this.state.condition.skrlx == 4 ? this.arrsel(this.state.sfkr_react4) : null}
                                        </Select>
                                        :
                                        <Select
                                            placeholder="请选择" showSearch optionFilterProp="children"
                                            value={this.state.condition.skr ? this.state.condition.skr : null}
                                            onChange={this.onSelChange.bind(this, 'skr')}
                                        >
                                            {this.state.condition.skrlx == 1 ? this.arrsel(this.state.sfkr_react1) : null}
                                            {this.state.condition.skrlx == 2 ? this.arrsel(this.state.sfkr_react2) : null}
                                            {this.state.condition.skrlx == 3 ? this.arrsel(this.state.sfkr_react3) : null}
                                        </Select>
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="收款人账户"
                                    // required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.skrzh ? this.state.condition.skrzh : null}
                                        onChange={this.onSelChange.bind(this, 'skrzh')}
                                        disabled={this.state.condition.skrlx != 4 ? true : false}
                                    >
                                        {this.arrsel(this.state.sfkrzh_react1)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {this.state.condition.skrzh ?
                                <Col span={12}>
                                    <Form.Item
                                        label="收款人账户结余（元）"
                                    >
                                        <InputNumber type="text" placeholder="请输入"
                                                     style={{width:'100%'}}
                                                     value={this.state.condition.jy ? this.state.condition.jy : null}
                                                     onChange={this.onSelChange.bind(this, 'jy')}
                                                     formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                     parser={(value) => value.replace(/\$\s?|(,*)/g, '')} prefix={"￥"}
                                                     disabled={true}
                                        />
                                    </Form.Item>
                                </Col>
                                : <Col span={12}></Col>}
                            <Col span={12}>
                                <Form.Item
                                    label="付款人类型"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.fkrlx ? this.state.condition.fkrlx : null}
                                        onChange={this.onSelChange.bind(this, 'fkrlx')}
                                    >
                                        {this.arrsel(this.state.sfkrlx_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={
                                        <Space>
                                            <>付款人</>
                                            {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                <>
                                                    {this.state.condition.fkrlx == 3 ?
                                                        <Qtedit type="link"
                                                                refresh={this.getdata.bind(this, 'sfkr_react3')}/>
                                                        : null}
                                                </>
                                                : null) : null}
                                        </Space>
                                    }
                                    required={true}
                                >
                                    {this.state.condition.fkrlx == 4 ?
                                        <Select
                                            placeholder="请选择" showSearch optionFilterProp="children"
                                            value={this.state.condition.fkr2 ? this.state.condition.fkr2 : null}
                                            onChange={this.onSelChange.bind(this, 'fkr2')}
                                        >
                                            {this.state.condition.fkrlx == 4 ? this.arrsel(this.state.sfkr_react4) : null}
                                        </Select>
                                        :
                                        <Select
                                            placeholder="请选择" showSearch optionFilterProp="children"
                                            value={this.state.condition.fkr ? this.state.condition.fkr : null}
                                            onChange={this.onSelChange.bind(this, 'fkr')}
                                        >
                                            {this.state.condition.fkrlx == 1 ? this.arrsel(this.state.sfkr_react1) : null}
                                            {this.state.condition.fkrlx == 2 ? this.arrsel(this.state.sfkr_react2) : null}
                                            {this.state.condition.fkrlx == 3 ? this.arrsel(this.state.sfkr_react3) : null}
                                        </Select>
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="付款人账户"
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.fkrzh ? this.state.condition.fkrzh : null}
                                        onChange={this.onSelChange.bind(this, 'fkrzh')}
                                        disabled={this.state.condition.fkrlx != 4 ? true : false}
                                    >
                                        {this.arrsel(this.state.sfkrzh_react2)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {this.state.condition.fkrzh?
                                <Col span={12}>
                                    <Form.Item
                                        label="付款人账户结余（元）"
                                    >
                                        <InputNumber type="text" placeholder="请输入"
                                                     style={{width:'100%'}}
                                                     value={this.state.condition.jy2 ? this.state.condition.jy2 : null}
                                                     onChange={this.onSelChange.bind(this, 'jy2')}
                                                     formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                     parser={(value) => value.replace(/\$\s?|(,*)/g, '')} prefix={"￥"}
                                                     disabled={true}
                                        />
                                    </Form.Item>
                                </Col>
                                : <Col span={12}></Col>}
                            <Col span={12}>
                                <Form.Item
                                    label="金额（元）"
                                    required={true}
                                >
                                    <InputNumber type="text" placeholder="请输入"
                                                 style={{width:'100%'}}
                                                 value={this.state.condition.je ? this.state.condition.je : null}
                                                 onChange={this.onSelChange.bind(this, 'je')}
                                                 formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                 parser={(value) => value.replace(/\$\s?|(,*)/g, '')} prefix={"￥"}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="金额（大写）"
                                >
                                    <Input placeholder="请输入"
                                           value={this.state.condition.je ? this.numberToChinese(this.state.condition.je) : null}
                                           onChange={this.onChange.bind(this, 'se')} disabled={true}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="摘要"
                                    required={true}
                                >
                                    <Input placeholder="请输入"
                                           value={this.state.condition.zy ? this.state.condition.zy : null}
                                           onChange={this.onChange.bind(this, 'zy')}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="用途"
                                    required={true}
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.yt ? this.state.condition.yt : null}
                                        onChange={this.onSelChange.bind(this, 'yt')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider
                                                    style={{
                                                        margin: '8px 0',
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: '0 8px 4px',
                                                    }}
                                                >
                                                    <Input placeholder="请输入"
                                                           value={this.state.condition.newfield ? this.state.condition.newfield : null}
                                                           onChange={this.onChange.bind(this, 'newfield')}/>
                                                    <Button type="text" icon={<PlusOutlined/>}
                                                            onClick={this.addItem.bind(this)}>
                                                        创建并选择
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.yt_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="备注"
                                >
                                    <Input placeholder="请输入"
                                           value={this.state.condition.bz ? this.state.condition.bz : null}
                                           onChange={this.onChange.bind(this, 'bz')}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="是否开具发票"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'sfkjfp')}
                                                 value={this.state.condition.sfkjfp ? this.state.condition.sfkjfp : null}>
                                        {this.arrradio(this.state.sfkjfp_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        {store.getState().data.info.qx11 ? (store.getState().data.info.qx11 == 1 ?
                            <Row justify="center">
                                <Button className='blue' style={{width: 140}} loading={this.state.loading}
                                        onClick={this.sub.bind(this)}>确认</Button>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}
