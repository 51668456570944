import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip, DatePicker, Typography, Progress } from 'antd';
import store from "../store/store.js";
import { Bar } from '@ant-design/plots';
import { FormOutlined, CarOutlined, ExperimentOutlined, DollarOutlined, FunnelPlotOutlined, RocketOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
                bartype: 1,
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
            month_react: [
                { id: 1, name: '1月' },
                { id: 2, name: '2月' },
                { id: 3, name: '3月' },
                { id: 4, name: '4月' },
                { id: 5, name: '5月' },
                { id: 6, name: '6月' },
                { id: 7, name: '7月' },
                { id: 8, name: '8月' },
                { id: 9, name: '9月' },
                { id: 10, name: '10月' },
                { id: 11, name: '11月' },
                { id: 12, name: '12月' },
            ],
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        this.props.NAV_CHANGE('/yhtj', '油耗分析')
        this.getdata(1)
        // this.tableHeight()
        // window.addEventListener('resize', this.tableHeight)
    }
    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight

        let yheight = bgheight - 240 - selheight
        that.setState({
            tableHeight: yheight
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} >{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        if (subtype == 1) {
            arr.isall = 1
        }
        fetch('/Yhtj/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    num1: data.num1 ? data.num1 : 0,
                    num2: data.num2 ? data.num2 : 0,
                    num3: data.num3 ? data.num3 : 0,
                    num4: data.num4 ? data.num4 : 0,
                    num5: data.num5 ? data.num5 : 0,
                    num6: data.num6 ? data.num6 : 0,
                    num7: data.num7 ? data.num7 : 0,
                    month_react: data.month_react ? data.month_react : [],
                    chid_react: data.chid_react ? data.chid_react : [],
                    khid_react: data.khid_react ? data.khid_react : [],
                    projectid_react: data.projectid_react ? data.projectid_react : [],
                    bartype_react: data.bartype_react ? data.bartype_react : [],
                })
            })
    }
    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, { [name + '1']: dateStrings[0], [name + '2']: dateStrings[1] })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
                bartype: 1
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    barChange = (value) => {
        let that = this
        that.setState({
            bartype: value
        })
    }
    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车辆编号',
                dataIndex: 'clbh',
                key: 'clbh',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车牌号',
                dataIndex: 'cp',
                key: 'cp',
                width: 150,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '类型',
                dataIndex: 'sblx_str',
                key: 'sblx_str',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '加油金额',
                dataIndex: 'jyje',
                key: 'jyje',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '加油升数',
                dataIndex: 'jyss',
                key: 'jyss',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '总方量',
                dataIndex: 'zfl',
                key: 'zfl',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '单方油耗',
                dataIndex: 'dfyh',
                key: 'dfyh',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
        ]
        const config = {
            data: this.state.list ? this.state.list : [],
            color: ['#ff0c00', '#ff2400', '#ff3d00', '#ff5500', '#ff6c00', '#ff8600', '#ff9e00', '#ffb500', '#ffe600', '#ffe500', '#f5ee00', '#dde400', '#c7db00', '#b1d200', '#9ac900', '#84c000', '#6fb700', '#57ae00', '#40a400', '#1f9600'],
            xField: 'value',
            yField: 'cp',
            seriesField: 'cp',
            legend: false,
            label: {
                position: 'right'
            },
            tooltip: {
                // formatter: (datum) => {
                //     return { name: '数值', value: datum.value };
                // },
                showTitle: false
            }
        };
        const { tableloading } = this.state;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between">
                        <Space wrap>
                            <DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null} />
                            <Select placeholder="月份" style={{ width: 120 }} value={this.state.newcondition.month ? this.state.newcondition.month : 0} onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                {this.arrsel(this.state.month_react)}
                            </Select>
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'riqi')} value={[(this.state.newcondition.riqi1 ? moment(this.state.newcondition.riqi1) : null), (this.state.newcondition.riqi2 ? moment(this.state.newcondition.riqi2) : null)]} placeholder={['日期', '日期']} />
                            <Select placeholder="车牌号" style={{ width: 150 }} value={this.state.newcondition.chid ? this.state.newcondition.chid : null} onChange={this.onSelChange.bind(this, 'chid')} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.chid_react)}
                            </Select>
                            <Select placeholder="客户" style={{ width: 200 }} value={this.state.newcondition.khid ? this.state.newcondition.khid : null} onChange={this.onSelChange.bind(this, 'khid')} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.khid_react)}
                            </Select>
                            <Select placeholder="项目" style={{ width: 200 }} value={this.state.newcondition.projectid ? this.state.newcondition.projectid : null} onChange={this.onSelChange.bind(this, 'projectid')} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.projectid_react)}
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                    </Row>
                </div>
                <div className='youhaotj'>
                    <div className='item i1'>
                        <div className='p1'>
                            <div className='a1'>设备总数</div>
                            <div className='a2'>{this.state.num1 ? this.state.num1 : 0}</div>
                        </div>
                        <div className='p2'><CarOutlined /></div>
                    </div>
                    <div className='item i2'>
                        <div className='p1'>
                            <div className='a1'>总油耗</div>
                            <div className='a2'>{this.state.num2 ? this.state.num2 : 0}<span className='dw'>升</span></div>
                        </div>
                        <div className='p2'><ExperimentOutlined /></div>
                    </div>
                    <div className='item i3'>
                        <div className='p1'>
                            <div className='a1'>总金额</div>
                            <div className='a2'>{this.state.num3 ? this.state.num3 : 0}<span className='dw'>元</span></div>
                        </div>
                        <div className='p2'><DollarOutlined /></div>
                    </div>
                    <div className='item i4'>
                        <div className='p1'>
                            <div className='a1'>有产值总方量/生产总方量</div>
                            <div className='a2'>
                                <Space split="/">
                                    <span>{this.state.num4 ? this.state.num4 : 0}<span className='dw'>方</span></span>
                                    <span>{this.state.num6 ? this.state.num6 : 0}<span className='dw'>方</span></span>
                                </Space>
                            </div>
                        </div>
                        <div className='p2'><FunnelPlotOutlined /></div>
                    </div>
                    <div className='item i5'>
                        <div className='p1'>
                            <div className='a1'>有产值单方油耗/生产单方油耗</div>
                            <div className='a2'>
                                <Space split="/">
                                    <span>{this.state.num5 ? this.state.num5 : 0}<span className='dw'>升/每方</span></span>
                                    <span>{this.state.num7 ? this.state.num7 : 0}<span className='dw'>升/每方</span></span>
                                </Space>
                            </div>
                        </div>
                        <div className='p2'><RocketOutlined /></div>
                    </div>
                </div>
                <div className='youhaotj2'>
                    <div className='l'>
                        <Title level={5}>
                            <Select placeholder="月份" style={{ width: 150 }} value={this.state.newcondition.bartype ? this.state.newcondition.bartype : 0} onChange={this.onSelChange.bind(this, 'bartype')}>
                                {this.arrsel(this.state.bartype_react)}
                            </Select>
                        </Title>
                        <Bar {...config} />
                    </div>
                    <div className='r'>
                        <Title level={5}>油耗分析</Title>
                        <Table
                            bordered
                            columns={columns}
                            sticky={false}
                            dataSource={this.state.list}
                            pagination={false}
                            loading={tableloading}
                            // rowSelection={rowSelection}
                            scroll={{ y: this.state.tableHeight ? this.state.tableHeight : null }}
                            className='table'
                            size="small"
                        />
                        {/* <div className='page'>
                            <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                        </div> */}
                    </div>
                </div>
            </>
        )
    }
}
