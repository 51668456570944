import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Alert, DatePicker, Divider, Tooltip, Popconfirm, AutoComplete } from 'antd';
import store from "../store/store.js";
import { PlusOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                now: new Date()
            },
            list: [],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value, Option) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        // return
        setTimeout(function () {
            if (name == 'sgbw') {
                that.getSgbw(value)
            }
        })
    }
    getSgbw = (name) => {
        let that = this
        fetch('/Common/getSgbw2', {
            method: "POST",
            body: JSON.stringify({
                name: name
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.setState({
                    sgbw_react: data.sgbw_react ? data.sgbw_react : [],
                })
            })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        setTimeout(function () {
            if (name == 'drfl' || name == 'drdj' || name == 'cs1' || name == 'byf1' || name == 'drbf') {
                let drfl = data.drfl ? data.drfl : 0
                let drdj = data.drdj ? data.drdj : 0
                let cs1 = data.cs1 ? data.cs1 : 0
                let byf1 = data.byf1 ? data.byf1 : 0
                let drbf = data.drbf ? data.drbf : 0
                let je = data.je ? data.je : 0
                var jsje = (Number(drfl) * Number(drdj) + Number(cs1) + Number(byf1) + Number(drbf)).toFixed(2)
                var ce = Number(je) - Number(jsje)
                let data2 = Object.assign({}, that.state.condition, { jsje: jsje, ce: ce })
                that.setState({
                    condition: data2
                })
            }
            if (name == 'dcfl' || name == 'dcdj' || name == 'cs2' || name == 'byf2' || name == 'dcbf') {
                let dcfl = data.dcfl ? data.dcfl : 0
                let dcdj = data.dcdj ? data.dcdj : 0
                let cs2 = data.cs2 ? data.cs2 : 0
                let byf2 = data.byf2 ? data.byf2 : 0
                let dcbf = data.dcbf ? data.dcbf : 0
                let jsje = data.jsje ? data.jsje : 0
                var je = (Number(dcfl) * Number(dcdj) + Number(cs2) + Number(byf2) + Number(dcbf)).toFixed(2)
                var ce = Number(je) - Number(jsje)
                let data2 = Object.assign({}, that.state.condition, { je: je, ce: ce })
                that.setState({
                    condition: data2
                })
            }
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
            if (that.props.notable != 1) {
                that.getdata2()
            }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                    now: new Date()
                },
                loading: false
            })
        }
    }
    getdata = () => {
        let that = this
        fetch('/Dhntmx/view', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                if (this.state.condition.id) {
                    that.setState({
                        condition: data.data,
                    })
                }
                that.setState({
                    // dw_react: data.dw_react?data.dw_react:[],
                    projectid_react: data.projectid_react ? data.projectid_react : [],
                    sgdwid_react: data.sgdwid_react ? data.sgdwid_react : [],
                    hntjbzid_react: data.hntjbzid_react ? data.hntjbzid_react : [],
                    sfbljs_react: data.sfbljs_react ? data.sfbljs_react : [],
                    bh_react: data.bh_react ? data.bh_react : [],
                    dw_react: data.dw_react ? data.dw_react : [],
                    fkzt_react: data.fkzt_react ? data.fkzt_react : [],
                    qydw_react: data.qydw_react ? data.qydw_react : [],
                })
            })
    }
    sub(isnext = 0, e) {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Dhntmx/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    if (isnext == 1) {
                        that.nextAdd()
                    } else {
                        this.mdchange()
                    }
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    getdata2(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        fetch('/Dhntmx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify({
                limit: 3
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count)
                })
            })
    }
    //继续添加
    nextAdd = (e) => {
        let that = this
        that.setState({
            condition: {
                id: 0,
            },
            list: [],
            loading: false
        })
        that.getdata2()
    }
    addItem_projectid = () => {
        let that = this
        fetch('/Project/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, projectid: data.projectid ? data.projectid : null })
                    this.setState({
                        condition: condition,
                        projectid_react: data.projectid_react ? data.projectid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_sgdwid = () => {
        let that = this
        fetch('/Sgdw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, sgdwid: data.sgdwid ? data.sgdwid : null })
                    this.setState({
                        condition: condition,
                        sgdwid_react: data.sgdwid_react ? data.sgdwid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_hntjbzid = () => {
        let that = this
        fetch('/Hntjbz/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, hntjbzid: data.hntjbzid ? data.hntjbzid : null })
                    this.setState({
                        condition: condition,
                        hntjbzid_react: data.hntjbzid_react ? data.hntjbzid_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_bh = () => {
        let that = this
        fetch('/Bh/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, bh: data.bh ? data.bh : null })
                    this.setState({
                        condition: condition,
                        bh_react: data.bh_react ? data.bh_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_dw = () => {
        let that = this
        fetch('/Cgdw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, dw: data.dw ? data.dw : null })
                    this.setState({
                        condition: condition,
                        dw_react: data.dw_react ? data.dw_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    addItem_qydw = () => {
        let that = this
        fetch('/Qydw/additem', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    let condition = Object.assign({}, this.state.condition, { newfield: null, qydw: data.qydw ? data.qydw : null })
                    this.setState({
                        condition: condition,
                        qydw_react: data.qydw_react ? data.qydw_react : []
                    })
                } else {
                    message.error(data.msg)
                }
            })
    }
    clearNewField = (open) => {
        let that = this
        setTimeout(() => {
            let condition = Object.assign({}, that.state.condition, { newfield: null })
            that.setState({
                condition: condition,
            })
        });
    }
    render() {
        const columns = [{
            title: '序号',
            dataIndex: 'xh',
            key: 'xh',
            align: "center",
            width: 80,
            fixed: 'left',
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '付款日期',
            dataIndex: 'fkrq_str',
            key: 'fkrq_str',
            ellipsis: {
                showTitle: false,
            },
            fixed: 'left',
            width:120,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '结算日期',
            dataIndex: 'jsrq_str',
            key: 'jsrq_str',
            fixed: 'left',
            width:120,
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '生产日期',
            dataIndex: 'addtime_str',
            key: 'addtime_str',
            fixed: 'left',
            width:120,
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '采购单位',
            dataIndex: 'dw_str',
            key: 'dw_str',
            fixed: 'left',
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '实际联络人',
            dataIndex: 'sjllr',
            key: 'sjllr',
            ellipsis: {
                showTitle: false,
            },
            fixed: 'left',
            width:120,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '项目名称（抵混凝土）',
            dataIndex: 'xmmc',
            key: 'xmmc',
            ellipsis: {
                showTitle: false,
            },
            fixed: 'left',
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '混凝土搅拌站',
            dataIndex: 'hntjbz_str',
            key: 'hntjbz_str',
            ellipsis: {
                showTitle: false,
            },
            fixed: 'left',
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '标号',
            dataIndex: 'bh_str',
            key: 'bh_str',
            ellipsis: {
                showTitle: false,
            },
            width:100,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '砂浆',
            dataIndex: 'sj',
            key: 'sj',
            ellipsis: {
                showTitle: false,
            },
            width:100,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '差额',
            dataIndex: 'ce',
            key: 'ce',
            ellipsis: {
                showTitle: false,
            },
            width:120,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '抵入方量',
            dataIndex: 'drfl',
            key: 'drfl',
            ellipsis: {
                showTitle: false,
            },
            width:100,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '抵出方量',
            dataIndex: 'dcfl',
            key: 'dcfl',
            ellipsis: {
                showTitle: false,
            },
            width:100,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '抵入单价',
            dataIndex: 'drdj',
            key: 'drdj',
            ellipsis: {
                showTitle: false,
            },
            width:100,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '抵出单价',
            dataIndex: 'dcdj',
            key: 'dcdj',
            ellipsis: {
                showTitle: false,
            },
            width:100,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '抵入超时',
            dataIndex: 'cs1',
            key: 'cs1',
            ellipsis: {
                showTitle: false,
            },
            width:100,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '抵出超时',
            dataIndex: 'cs2',
            key: 'cs2',
            ellipsis: {
                showTitle: false,
            },
            width:100,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '抵入补运费',
            dataIndex: 'byf1',
            key: 'byf1',
            ellipsis: {
                showTitle: false,
            },
            width:100,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '抵出补运费',
            dataIndex: 'byf2',
            key: 'byf2',
            ellipsis: {
                showTitle: false,
            },
            width:120,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '抵入金额',
            dataIndex: 'jsje',
            key: 'jsje',
            ellipsis: {
                showTitle: false,
            },
            width:100,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '抵出金额',
            dataIndex: 'je',
            key: 'je',
            ellipsis: {
                showTitle: false,
            },
            width:100,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '施工部位',
            dataIndex: 'sgbw',
            key: 'sgbw',
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '备注',
            dataIndex: 'beizhu',
            key: 'beizhu',
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '对账日期',
            dataIndex: 'dzrq_str',
            key: 'dzrq_str',
            ellipsis: {
                showTitle: false,
            },
            width:120,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '施工单位（抵混凝土）',
            dataIndex: 'sgdw',
            key: 'sgdw',
            ellipsis: {
                showTitle: false,
            },
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '是否办理结算',
            dataIndex: 'sfbljs_str',
            key: 'sfbljs_str',
            ellipsis: {
                showTitle: false,
            },
            width:120,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },
        {
            title: '付款状态',
            dataIndex: 'fkzt_str',
            key: 'fkzt_str',
            ellipsis: {
                showTitle: false,
            },
            width:120,
            render: (row, record) => (
                <Tooltip placement="topLeft" title={row}>
                    {row}
                </Tooltip>
            ),
        },]
        const { tableloading } = this.state;
        return (
            <>
                {this.state.condition.id ? <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx8 ? (store.getState().data.info.qx8 == 1 ? '编辑' : '查看') : '查看'}</a> : <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>}
                <Modal maskClosable={false} title="编辑" width={1200} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx8 ? (store.getState().data.info.qx8 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={8}>
                                <Form.Item
                                    label="生产日期"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'addtime')} value={this.state.condition.addtime ? moment(this.state.condition.addtime) : moment(this.state.condition.now)} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="签约单位"
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.qydw ? this.state.condition.qydw : null}
                                        onChange={this.onSelChange.bind(this, 'qydw')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_qydw.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.qydw_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="项目信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="采购单位"
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.dw ? this.state.condition.dw : null}
                                        onChange={this.onSelChange.bind(this, 'dw')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_dw.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.dw_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="实际联络人"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.sjllr ? this.state.condition.sjllr : null} onChange={this.onChange.bind(this, 'sjllr')}  />
                                </Form.Item>
                            </Col>
                            {/* <Col span={8}>
                                <Form.Item
                                    label="项目名称"
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.projectid ? this.state.condition.projectid : null}
                                        onChange={this.onSelChange.bind(this, 'projectid')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_projectid.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.projectid_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="施工单位"
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.sgdwid ? this.state.condition.sgdwid : null}
                                        onChange={this.onSelChange.bind(this, 'sgdwid')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_sgdwid.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.sgdwid_react)}
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            <Col span={8}>
                                <Form.Item
                                    label="项目名称（抵混凝土）"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.xmmc ? this.state.condition.xmmc : null} onChange={this.onChange.bind(this, 'xmmc')}  />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="施工单位（抵混凝土）"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.sgdw ? this.state.condition.sgdw : null} onChange={this.onChange.bind(this, 'sgdw')}  />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="施工部位"
                                >
                                    <AutoComplete
                                        options={this.state.sgbw_react ? this.state.sgbw_react : []}
                                        onChange={this.onSelChange.bind(this, 'sgbw')}
                                        placeholder="请输入"
                                        value={this.state.condition.sgbw ? this.state.condition.sgbw : null}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="混凝土搅拌站"
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.hntjbzid ? this.state.condition.hntjbzid : null}
                                        onChange={this.onSelChange.bind(this, 'hntjbzid')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_hntjbzid.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.hntjbzid_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="工程信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="标号"
                                >
                                    <Select
                                        placeholder="请选择" showSearch optionFilterProp="children"
                                        value={this.state.condition.bh ? this.state.condition.bh : null}
                                        onChange={this.onSelChange.bind(this, 'bh')}
                                        onDropdownVisibleChange={this.clearNewField.bind(this)}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 == 1 ?
                                                    <>
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                            }}
                                                        >
                                                            <Input placeholder="请输入" value={this.state.condition.newfield ? this.state.condition.newfield : null} onChange={this.onChange.bind(this, 'newfield')}  />
                                                            <Button type="text" icon={<PlusOutlined />} onClick={this.addItem_bh.bind(this)}>
                                                                创建并选择
                                                            </Button>
                                                        </Space>
                                                    </>
                                                    : null) : null}
                                            </>
                                        )}
                                    >
                                        {this.arrsel(this.state.bh_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="砂浆"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.sj ? this.state.condition.sj : null} onChange={this.onChange.bind(this, 'sj')}  />
                                </Form.Item>
                            </Col>
                            <Col span={8}></Col>
                            <Col span={8}>
                                <Form.Item
                                    label="抵入方量"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.drfl ? this.state.condition.drfl : null} onChange={this.onChange.bind(this, 'drfl')}  type="text" />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    label="抵入单价"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.drdj ? this.state.condition.drdj : null} onChange={this.onChange.bind(this, 'drdj')}  type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="抵入超时"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.cs1 ? this.state.condition.cs1 : null} onChange={this.onChange.bind(this, 'cs1')}  type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="抵入补运费"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.byf1 ? this.state.condition.byf1 : null} onChange={this.onChange.bind(this, 'byf1')}  type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="抵入泵费"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.drbf ? this.state.condition.drbf : null} onChange={this.onChange.bind(this, 'drbf')}  type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="抵入金额"
                                    tooltip="【抵入方量】乘以【抵入单价】加【抵入超时】加【抵入补运费】加【抵入泵费】"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.jsje ? this.state.condition.jsje : null} onChange={this.onChange.bind(this, 'jsje')}  type="text" suffix="元" disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="抵出方量"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.dcfl ? this.state.condition.dcfl : null} onChange={this.onChange.bind(this, 'dcfl')}  type="text" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="抵出单价"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.dcdj ? this.state.condition.dcdj : null} onChange={this.onChange.bind(this, 'dcdj')}  type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="抵出超时"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.cs2 ? this.state.condition.cs2 : null} onChange={this.onChange.bind(this, 'cs2')}  type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="抵出补运费"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.byf2 ? this.state.condition.byf2 : null} onChange={this.onChange.bind(this, 'byf2')}  type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="抵出泵费"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.dcbf ? this.state.condition.dcbf : null} onChange={this.onChange.bind(this, 'dcbf')}  type="text" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="抵出金额"
                                    tooltip="【抵出方量】乘以【抵出单价】加【抵出超时】加【抵出补运费】加【抵出泵费】"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.je ? this.state.condition.je : null} onChange={this.onChange.bind(this, 'je')}  type="text" suffix="元" disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="差额"
                                    tooltip="【抵出金额】减【抵入金额】"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.ce ? this.state.condition.ce : 0} onChange={this.onChange.bind(this, 'ce')}  type="text" suffix="元" disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Alert type="info" message="其他信息" banner style={{ marginBottom: 24 }} />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="付款状态"
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.fkzt ? this.state.condition.fkzt : null} allowClear onChange={this.onSelChange.bind(this, 'fkzt')} disabled={true}>
                                        {this.arrsel(this.state.fkzt_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="付款日期"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'fkrq')} value={this.state.condition.fkrq ? moment(this.state.condition.fkrq) : null} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="对账日期"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'dzrq')} value={this.state.condition.dzrq ? moment(this.state.condition.dzrq) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="是否办理结算"
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.sfbljs ? this.state.condition.sfbljs : null} allowClear onChange={this.onSelChange.bind(this, 'sfbljs')} disabled={true}>
                                        {this.arrsel(this.state.sfbljs_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="结算日期"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'jsrq')} value={this.state.condition.jsrq ? moment(this.state.condition.jsrq) : null} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="备注"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.beizhu ? this.state.condition.beizhu : null} onChange={this.onChange.bind(this, 'beizhu')}  />
                                </Form.Item>
                            </Col>
                            {this.props.notable == 1 ?
                                null
                                :
                                <>
                                    <Col span={24}>
                                        <Divider orientation="left" style={{ marginTop: 0 }}>最近添加信息</Divider>
                                    </Col>
                                    <Col span={24}>
                                        <Table
                                            bordered
                                            columns={columns}
                                            sticky={false}
                    dataSource={this.state.list}
                                            pagination={false}
                                            loading={tableloading}
                                            // rowSelection={rowSelection}
                                            scroll={{ x: 3000, }}
                                            className='table'
                                            size="small"
                                        />
                                    </Col>
                                </>
                            }
                        </Row>
                        {store.getState().data.info.qx8 ? (store.getState().data.info.qx8 == 1 ?
                            <Row justify="center">
                                <Space>
                                    <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this)}>完成</Button>
                                    {!this.state.condition.id ?
                                        <Button className='blue' loading={this.state.loading} onClick={this.sub.bind(this, '1')}>完成并继续添加下一条</Button>
                                        : null}
                                </Space>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}
