import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, DatePicker, Upload } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: props.condition,
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            // that.setState({
            //     condition:that.props.condition
            // })
        } else {
            that.setState({
                // condition: {
                //     id: this.props.id,
                // },
                loading: false
            })
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Yszkmx/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    render() {
        return (
            <>
                <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{this.props.condition.qc}</a>
                <Modal maskClosable={false} title="编辑" width={600} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                        <Col span={24}>
                                <Form.Item
                                    label="期初"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.qc ? this.state.condition.qc : null} onChange={this.onChange.bind(this, 'qc')}  />
                                </Form.Item>
                            </Col>
                        </Row>
                        {store.getState().data.info.qx9 ? (store.getState().data.info.qx9 == 1 ?
                            <Row justify="center">
                                <Button className='blue' style={{ width: 140 }} loading={this.state.loading} onClick={this.sub.bind(this)}>确认</Button>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}